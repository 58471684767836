import React, { Component, createRef } from 'react'
import MemberDashboardIcon from './images/memberdashboard.png'
import { Grid, Avatar, Button, Checkbox } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import { withRouter } from './HOC/withRouter'
import ImageUploadIcon from './CustomTool/ImageUploadIcon'
import PH_MEMBER from '../Component/images/kidphoto.png'
import CtBtn from './CustomTool/CtBtn'
import CtDtp from './CustomTool/CtDtp'
import defaultImg from './images/default.png'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import TableList from './CustomTool/TableList'
import { Edit, Delete } from '@mui/icons-material';
import dayjs from 'dayjs';
import CtDtpTime from './CustomTool/CtDtpTime'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import HOCMemberInfo from './HOC/HOCMemberInfo'
import { fetchRentAtHome } from './API/API'
import { formatDateTime } from './SystemUtility/SystemUtility'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
class ReturnEntry extends Component {
    state = {
        autoEnableDisalbe_Col_Info: [],
        columns: [
            { id: 'Action', label: '', name: 'Search' },// 0
            { id: 'bint_rentsrno', label: '', hide: true },// 1
            { id: 'bint_rentitemsrno', label: '', hide: true },// 2
            { id: 'dat_rentdate', label: '', hide: true },// 3
            { id: 'vac_productcode', label: 'Product Code' },// 4
            { id: 'vac_productcategory', label: 'Category' },// 5
            { id: 'vac_productname', label: 'Product Name' },// 6
            { id: 'dat_rentendatetime', label: 'Return End Date' },// 7
            {
                id: 'Penalty', label: 'Penalty', type: 'CtTxt',
                CtrlProps: {
                    value: '', width: 50, maxLength: 5,
                    autoEnableDisable: true, blankIfDisabled: true,
                    defaultValueColID: 'dec_penaltyamount'
                }
            },// 8
            {
                id: 'ReturnRemark', label: 'Return Remark', type: 'CtMultiLineText',
                CtrlProps: {
                    value: '', width: 500, maxLength: 500,
                    autoEnableDisable: true, blankIfDisabled: true,
                    defaultValueColID: 'vac_returnremark'
                }
            },// 9
        ],
        rows: [],
        actionList: [
            // { name: 'Return Item', icon: <Checkbox label='' defaultChecked />, link: 'ReturnEntry:', actionType: 'AlertResponsive' },
            { name: 'Return Item', link: 'ReturnEntry:', actionType: 'Chk', CtrlProps: { checked: true, disabled: false } },
        ],
        keyColumn: [
            'ProductCode', 'ProductName', 'ReturnEndDate', 'Penalty', 'ReturnRemark',
        ],
        // searchData: {
        //     ChallanNo: '',
        //     RentDate: '',
        //     CardNo: '',
        //     Rent: '',
        //     MemberName: '',
        //     GuardianName: '',
        //     AvailableCardPoints: '',
        //     RentStartDateTime: '',
        //     Hours: '',
        //     Minutes: '',
        //     RentEndDateTime: '',
        //     TotalPoint: '',
        // },
        // searchColumns: [],
        // searchRows: [],
        // searchOpen: false,
        // searchRowsCount: 0,
        // dataLoadStatus: true,
        // ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,
        formData: {
            DtpActualReturnDate: dayjs(),
            txtProductCode: '',
            txtProductName: '',
            DtpReturnEndDate: '',
            txtPenalty: '',
            txtReturnRemark: '',
        },
        onFormLoadCallCount: 0,
    }

    //#region Component
    componentDidMount() {
        //this.setSearchColumns()
        document.title = 'TED : Return Entry'
        this.props.setActiveMenu("ReturnEntry")
        this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo, () => {
            this.onFormLoad()
        })
        // this.onFormLoad()
        document.getElementById('txtDtpActualReturnDate').focus()
    }

    // componentWillReceiveProps(newProps) {
    //     // console.log("this.state.onFormLoadCallCount",this.state.onFormLoadCallCount);
    //     if (this.state.onFormLoadCallCount === 0 && Number(newProps.memberInfo.MemberID) > 0) {
    //         this.onFormLoad(newProps.memberInfo.MemberID)
    //     }
    // }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }
    //#endregion component

    //#region handle
    handleOnChangeAutoEnableDisalbe_ColIdxs = (autoEnableDisalbe_Col_Info) => {
        this.setState({ autoEnableDisalbe_Col_Info })
    }

    handleOnTableCellCtrlValueChange = (ctrlType, row, ctrl, rowIndex, colIndex, actionIndex) => {
        // console.log(ctrlType, ctrl)
        if (ctrlType && row && ctrl && ctrl.target) {
            // console.log(ctrlType, ctrl.target.name, ctrl.target.checked, ctrl.target.value)
            let rows = this.state.rows.map((crow) => {
                if (crow.bint_rentsrno === row.bint_rentsrno && crow.bint_rentitemsrno === row.bint_rentitemsrno) {
                    if (ctrlType === 'Chk' && this.state.autoEnableDisalbe_Col_Info.length > 0) {
                        this.state.autoEnableDisalbe_Col_Info.map((colInfo) => {
                            crow[rowIndex + '_' + colInfo.index + '_EDS'] = !ctrl.target.checked//'_EDS' = EnabledDisabledStatus
                            if (colInfo.blankIfDisabled) {
                                crow[rowIndex + '_' + colInfo.index] = ''
                                crow[rowIndex + '_' + colInfo.index + '_ASVC'] = 1//'_ASVC'=AutoSetValueCount
                            }
                        })
                    }
                    if (ctrlType === 'Chk') {
                        crow[ctrl.target.name] = ctrl.target.checked
                    } else if (ctrlType === 'CtTxt' || ctrlType === 'CtMultiLineText') {
                        if (
                            crow[rowIndex + '_' + colIndex + '_ASVC'] !== undefined/*  &&
                            ctrl.target.value !== crow[ctrl.target.name] */
                        ) {
                            crow[rowIndex + '_' + colIndex + '_ASVC'] = Number(crow[rowIndex + '_' + colIndex + '_ASVC']) + 1
                        }
                        crow[ctrl.target.name] = ctrl.target.value
                    }
                }
                return crow
            })
            this.setState({ rows }, () => {
                // console.log('rows After sst', rows)
            })
        }
    }

    /* setTableCellCtrlEnableChange = (ctrlType, row, ctrl) => {
        // console.log(ctrlType, ctrl)
        if (ctrlType && row && ctrl && ctrl.target) {
            console.log(ctrlType, ctrl.target.name, ctrl.target.checked, ctrl.target.value)
            let rows = this.state.rows.map((crow) => {
                if (crow.bint_rentsrno === row.bint_rentsrno && crow.bint_rentitemsrno === row.bint_rentitemsrno) {
                    if (ctrlType === 'Chk') {
                        crow[ctrl.target.name] = ctrl.target.checked
                    } else if (ctrlType === 'CtTxt' || ctrlType === 'CtMultiLineText') {
                        crow[ctrl.target.name] = ctrl.target.value
                    }
                }
                return crow
            })
            this.setState({ rows }, () => { console.log('rows After sst', rows) })
        }
    } */

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }
    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    onFormLoad = (skipEditLoad = false) => {
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            let reqData = {}
            let bint_rentsrno = 0, dat_actualreturndate = '', bint_memberid = 0, dat_rentdate = ''
            if (skipEditLoad === false && this.props.params.EditReturnDetails && (this.props.params.EditReturnDetails.substring(1).length) > 0) {
                var res = this.props.params.EditReturnDetails.split(":")
                bint_memberid = res[1]
                bint_rentsrno = res[2]
                dat_actualreturndate = res[3]
                dat_rentdate = res[4]
                dat_actualreturndate = dat_actualreturndate.replaceAll("+", ":")
                reqData = {
                    Op: 'EditReturnDetailList',
                    bint_rentsrno,
                    dat_rentdate,
                    bint_memberid,
                    dat_actualreturndate
                }
            } else {
                reqData = {
                    'Op': 'listReturnDetailReturnEntry',
                    'bint_memberid': this.props.memberInfo.MemberID
                }
            }
            fetchRentAtHome(reqData)
                .then(res => {
                    let rows = [],
                        rowsCount = 0,
                        onFormLoadCallCount = Number(this.state.onFormLoadCallCount) + 1
                    if (bint_memberid > 0 && bint_rentsrno > 0 && dat_actualreturndate.length > 0 && dat_rentdate.length > 0 && dat_actualreturndate.length > 0) {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                DtpActualReturnDate: dat_actualreturndate,
                            },
                        })
                    }
                    if (res.data.ReturnList) {
                        rows = res.data.ReturnList
                        rowsCount = rows.length
                    }
                    this.setState({
                        dataLoadStatus: true,
                        rows,
                        rowsCount,
                        onFormLoadCallCount,
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in onFormLoad.', error)
                })
        })
    }

    validateCardNetPoints = (dec_cardpointbalance) => {
        let validateCardNetPoints = false
        if (Number(this.props.memberInfo.CurrentPoints) < Number(dec_cardpointbalance)) {
            // this.props.toastErrorMsg('Enter Remark', 'txtRemark')
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Yes'
                    labelDisagree='No'
                    alertTitle={'No Enough points available, Do you want to continue?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return true
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            return true
        }
        // return validateCardNetPoints
    }

    saveReturn = () => {
        let returnInfo = [], dec_cardpointbalance = 0
        this.state.rows.map((row, index) => {
            if (row[index + '_0_0'] === true) {
                dec_cardpointbalance += Number(row[index + '_8'])
                returnInfo.push({
                    bint_rentsrno: row['bint_rentsrno'],
                    dat_rentdate: row['dat_rentdate'],
                    bint_rentitemsrno: row['bint_rentitemsrno'],
                    dat_returndatetime: formatDateTime(this.state.formData.DtpActualReturnDate),
                    dec_penaltyamount: row[index + '_8'],
                    vac_returnremark: row[index + '_9'],
                    dat_actualreturndatetime: formatDateTime(this.state.formData.DtpActualReturnDate),
                    vac_productcode:row['vac_productcode']
                })
            }
        })
        if (returnInfo.length > 0 && this.validateCardNetPoints(dec_cardpointbalance)) {
            this.props.notifyProcessing()
            // console.log('row', this.state.rows)
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    'Op': 'SaveReturnEntry',
                    'ReturnInfo': returnInfo,
                    'bint_memberid': this.props.memberInfo.MemberID
                }
                fetchRentAtHome(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                const text1 = "Dear", text2 = ",", text3 = "\nWe have received", text4 = "items.",
                                    text5 = "\nAvailable Subscriptions Points are", text6 = "\n\nThanks and Regards,", text7 = "\nTED", text8 = "\n(Toddlers Entertainment Den)"
                                const url = text1.concat(" ", this.props.memberInfo.KidName, text2, text3, " ", returnInfo.length, " ", text4, text5, " ", this.props.memberInfo.CurrentPoints, text6, text7, text8)
                                window.open("https://api.whatsapp.com/send?phone=+91" + this.props.memberInfo.MobileNo + "&text=" + encodeURI(url))
                                this.navigateTo('ReturnEntry')
                                this.onFormLoad(true)
                            })
                            this.updateCurrentBalanceInRedux(res.data.dec_cardpointbalance)
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in saveReturn. ' + error)
                    })
            })
        } else {
            this.props.toastMsg('error', 'There are no Product selected for Return')
        }
    }

    updateCurrentBalanceInRedux = (CurrentPoints) => {
        this.props.setCurrentPoints({ CurrentPoints })
    }
    //#endregion Function

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        // console.log('rows', rows)
        return (
            <div>
                <Grid container direction="row" justifyContent="flext-start" alignItems="center" className="title" spacing={2} marginLeft={0}>
                    <Grid item xs={3} style={{ marginLeft: '50px' }}>
                        <Button
                            variant="outlined"
                            style={{ backgroundColor: '#d6ddf0', borderRadius: '10px' }}
                            startIcon={<Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />}
                            onClick={() => { this.navigateTo('MemberDashboard') }}>Member Dashboard
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <center><label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Return Entry</b></label></center>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" spacing={2} marginTop={0} marginLeft={0} style={{ alignItems: 'center' }}>
                        <Grid item xs={4} md={2}>
                            <img src={this.props.memberInfo.Photo} style={{ width: '10vw', height: 'auto' }} />
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtDtpTime
                                        id='DtpActualReturnDate'
                                        label='Actual Return Date'
                                        width='190'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.DtpActualReturnDate}
                                        onKeyDown={this.props.onKeyDown}
                                        showToolbar={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxtAdornNum
                                        id='txtCardNo'
                                        label='Member ID'
                                        adornment='TED'
                                        readOnly={true}
                                        width='120'
                                        handleOnChange={this.handleOnChange}
                                        value={this.props.memberInfo.CardNo}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtKidName'
                                        label='Kid Name'
                                        disabled={true}
                                        maxLength={100}
                                        width={140}
                                        value={this.props.memberInfo.KidName}
                                        handleOnChange={this.handleOnChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtGuardianName'
                                        label='Guardian Name'
                                        disabled={true}
                                        maxLength={100}
                                        width={140}
                                        handleOnChange={this.handleOnChange}
                                        value={this.props.memberInfo.GuardianName}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtAvailableCardPoints'
                                        label='Available Card Points'
                                        disabled={true}
                                        maxLength={10}
                                        handleOnChange={this.handleOnChange}
                                        value={this.props.memberInfo.CurrentPoints}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item>
                                <CtBtn label='Save' onClick={this.saveReturn} />
                            </Grid>
                            <Grid item style={{ display: 'none' }}>
                                <CtBtn label='New' />
                            </Grid>
                            {localStorage.getItem('De') == 1 ?
                                <Grid item style={{ display: 'none' }}>
                                    <CtBtn label='Delete' />
                                </Grid> : ""}
                            <Grid item style={{ display: 'none' }}>
                                <CtBtn label='Challan Print' />
                            </Grid>
                            <Grid item>
                                <CtBtn label='List' onClick={() => { this.navigateTo('RentAndReturnList') }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </form> <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    // provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                    handleOnTableCellCtrlValueChange={this.handleOnTableCellCtrlValueChange}
                    handleOnChangeAutoEnableDisalbe_ColIdxs={this.handleOnChangeAutoEnableDisalbe_ColIdxs}
                // toolTipNewEntry='New Product Entry'
                />
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HOCVerifyIsUser(HOCMemberInfo(HoCtToastContainer(withRouter(ReturnEntry)))))
