import defaultImg from '../Component/images/default.png'
import { apiURL } from '../Component/API/API'
const initState = {
  navInfo: {
    activeMenu: '',
  },
  memberInfo: {
    Photo: defaultImg,
    MobileNo: '',
    CardNo: '',
    CurrentPoints: 0,
    KidName: '',
    GuardianName: '',
    MemberID: '',
    RecentFromDate: '',
    RecentToDate: '',
    Status: ''
  }
}

const RootReducers = (state = initState, action) => {
  if (action.type === 'SET_ActiveMenu') {
    return {
      ...state,
      navInfo: { ...state.navInfo, activeMenu: action.ActiveMenu }
    }
  } else if (action.type === 'SET_MemberInfo') {
    // console.log("action.MemberInfo",action.MemberInfo);
    let photo = (action.MemberInfo.Photo != "" ? apiURL + action.MemberInfo.Photo : defaultImg)
    // console.log("action.MemberInfo.Status", action.MemberInfo.Status);
    return {
      ...state,
      memberInfo: {
        ...state.memberInfo,
        Photo: photo,
        MobileNo: action.MemberInfo.MobileNo,
        CardNo: action.MemberInfo.CardNo,
        CurrentPoints: action.MemberInfo.CurrentPoints,
        KidName: action.MemberInfo.KidName,
        GuardianName: action.MemberInfo.GuardianName,
        MemberID: action.MemberInfo.MemberID,
        RecentFromDate: action.MemberInfo.RecentFromDate,
        RecentToDate: action.MemberInfo.RecentToDate,
        Status: action.MemberInfo.Status,
      }
    }
  } else if (action.type === 'SET_SubscriptionInfo') {
    return {
      ...state,
      memberInfo: {
        ...state.memberInfo,
        CurrentPoints: action.MemberInfo.CurrentPoints,
        RecentFromDate: action.MemberInfo.RecentFromDate,
        RecentToDate: action.MemberInfo.RecentToDate,
        Status: action.MemberInfo.Status,
      }
    }
  } else if (action.type === 'SET_CurrentPoints') {
    return {
      ...state,
      memberInfo: {
        ...state.memberInfo,
        CurrentPoints: action.MemberInfo.CurrentPoints
      }
    }
  }
  return state;
}

export default RootReducers