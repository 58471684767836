import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box } from '@mui/material'
import { withRouter } from './HOC/withRouter'
// import TableList from './CustomTool/TableList'
// import CtDtp from './CustomTool/CtDtp'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
// import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import CtTxt from './CustomTool/CtTxt'
// import CtTxtNum from './CustomTool/CtTxtNum'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { fetchSales, fetchProductMaster } from './API/API'
import { getRowData } from './SystemUtility/SystemUtility'
import HOCProductInfo, { getProductInfo } from './HOC/HOCProductInfo'
import GuestSales from './GuestSales'

class SalesItemEntry extends Component {

    state = {
        formDataItem: {
            EditSalesDate: '',
            EditInvoiceNo: '',
            EditItemSrNo: '',
            txtProductCode: '',
            cmbProductCategory: '',
            cmbProductName: '',
            txtDescription: '',
            txtMRP: '',
            txtSalesRate: '',
            txtQuantity: 1,
            txtAmount: '',
            txtDiscountPer: '',
            txtDiscountAmt: '',
            txtTaxable: '',
            cmbTaxName: '',
            txtCGST: '',
            txtSGST: '',
            txtIGST: '',
            txtTotalAmount: '',
        },
        ProductCategoryList: [],
        ProductNameList: [],
        TaxList: [],
        dataLoadStatus: true,
        ARD: null,
    }

    //#region Component
    componentDidMount() {
        this.props.setActiveMenu("SalesItemEntry")
        this.onFormLoad()
        document.title = 'TED : Sales Item Entry'

        // document.getElementById('txtProductCategoryName').focus()
    }
    //#endregion

    //#region handle
    handleOnChange = (e) => {
        this.setState({
            formDataItem: { ...this.state.formDataItem, [e.target.name]: e.target.value.trim() }
        }, () => {
            if (e.target.name === 'txtDiscountAmt') {
                if (Number(this.state.formDataItem.txtDiscountAmt) > 0) {
                    this.setState({
                        formDataItem: {
                            ...this.state.formDataItem,
                            txtDiscountPer: 0
                        }
                    }, () => {
                        this.calculateTotalAmount()
                    })
                }
                // } else if (e.target.name === 'txtDiscountPer') {
                //     if (Number(this.state.formDataItem.txtDiscountPer) > 0) {
                //         this.setState({
                //             formDataItem: {
                //                 ...this.state.formDataItem,
                //                 txtDiscountPer: 0
                //             }
                //         })
                //     }
            } else if (
                e.target.name === 'txtSalesRate' ||
                e.target.name === 'txtQuantity' ||
                e.target.name === 'txtDiscountAmt' ||
                e.target.name === 'txtDiscountPer' ||
                e.target.name === 'cmbTaxName' ||
                e.target.name === 'txtTaxable'
            ) {
                // if (e.target.name === 'cmbTaxName') {
                //     // console.log('Selected Tax Row Data : ', this.getRowData(this.state.TaxList, 'vac_taxname', this.state.formDataItem.cmbTaxName))
                //     // const curTaxData = getRowData(this.state.TaxList, 'vac_taxname', this.state.formDataItem.cmbTaxName)
                //     // let sgst = curTaxData.dec_sgstamt, cgst = curTaxData.dec_cgstamt

                //     // console.log('Selected Tax Row Data : ', curTaxData)
                // }
                this.calculateTotalAmount()
            } else if (e.target.name === 'txtProductCode') {
                setTimeout(() => { this.props.getProductInfo(this.setDataLoadStatus, this.state.ProductNameList, this.setProductInfo, this.state.formDataItem.txtProductCode) }, 500)
            } else if (
                (e.target.name === 'cmbProductCategory' || e.target.name === 'cmbProductName') &&
                this.state.formDataItem.cmbProductCategory.trim().length > 0 &&
                this.state.formDataItem.cmbProductName.trim().length > 0
            ) {
                this.props.getProductInfo(
                    this.setDataLoadStatus, this.state.ProductNameList, this.setProductInfo,
                    '', this.state.formDataItem.cmbProductCategory, this.state.formDataItem.cmbProductName
                )
            }

        })
    }
    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    setProductInfo = (ProductInfo, dataLoadStatus) => {
        this.setState({
            dataLoadStatus,
            formDataItem: {
                ...this.state.formDataItem,
                txtProductCode: ProductInfo.vac_productcode ? ProductInfo.vac_productcode : '',
                cmbProductCategory: ProductInfo.vac_productcategory ? ProductInfo.vac_productcategory : '',
                cmbProductName: ProductInfo.vac_productname ? ProductInfo.vac_productname : '',
                txtDescription: ProductInfo.vac_description ? ProductInfo.vac_description : '',
                txtMRP: ProductInfo.dec_mrp ? ProductInfo.dec_mrp : '',
                txtSalesRate: ProductInfo.dec_salestaxable ? ProductInfo.dec_salestaxable : '',
                txtTotalAmount: ProductInfo.dec_salesprice ? ProductInfo.dec_salesprice : '',
                cmbTaxName: ProductInfo.vac_taxname ? ProductInfo.vac_taxname : ''
            }
        }, () => {
            this.calculateTotalAmount()
        })
    }

    setDataLoadStatus = (dataLoadStatus, nextFunctionCall) => {
        this.setState({ dataLoadStatus }, () => {
            if (nextFunctionCall) {
                nextFunctionCall()
            }
        })
    }

    onDeleteItemClick = () => {
        const EditInvoiceNo = this.state.formDataItem.EditInvoiceNo,
            EditItemSrNo = this.state.formDataItem.EditItemSrNo,
            SalesDate = this.state.formDataItem.EditSalesDate,
            cmbProductName = this.state.formDataItem.cmbProductName,
            txtProductCode = this.state.formDataItem.txtProductCode
        if (Number(EditInvoiceNo) > 0 && Number(EditItemSrNo) > 0) {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Product : ' + cmbProductName + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteSalesItem(EditInvoiceNo, SalesDate, EditItemSrNo, txtProductCode)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg('error', 'No records to Delete')
        }
    }

    onNewEntryClick = () => {
        this.setState({
            formDataItem: {
                ...this.state.formDataItem,
                EditItemSrNo: '',
                txtProductCode: '',
                cmbProductCategory: '',
                cmbProductName: '',
                txtDescription: '',
                txtMRP: '',
                txtSalesRate: '',
                txtQuantity: 1,
                txtAmount: '',
                txtDiscountPer: '',
                txtDiscountAmt: '',
                txtTaxable: '',
                cmbTaxName: '',
                txtCGST: '',
                txtSGST: '',
                txtIGST: '',
                txtTotalAmount: '',
            },
        }, () => {
            document.getElementById('txtProductCode').focus()
        })
    }

    deleteSalesItem = (InvoiceNo, SalesDate, ItemSrNo, ProductCode) => {
        if (Number(InvoiceNo) > 0 && Number(ItemSrNo) > 0) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteSalesItemEntry',
                    bint_invoiceno: InvoiceNo,
                    dat_salesdate: SalesDate,
                    bint_itemsrno: ItemSrNo,
                    vac_productcode: ProductCode,
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let txtInvoice = InvoiceNo,
                                SalesItemList = res.data.SalesItemEntryList,
                                txtNetAmount = res.data.SalesInfo[0].NetAmount
                            this.setState({
                                dataLoadStatus: true,
                                formDataItem: {
                                    ...this.state.formDataItem,
                                    EditSalesDate: this.props.EditSalesDate,
                                    EditInvoiceNo: txtInvoice
                                },
                            }, () => {
                                this.props.setSalesInfo(txtInvoice, txtNetAmount, SalesItemList)
                                this.onNewEntryClick()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteSalesItem. ' + error)
                    })
            })
            return true
        }
    }

    validateSalesItem = () => {
        let validateSalesItem = false
        if (this.state.formDataItem.txtProductCode === undefined || Number(this.state.formDataItem.txtProductCode) < 0) {
            this.props.toastErrorMsg('Enter Product Code', 'txtProductCode')
        } else if (this.state.formDataItem.cmbProductCategory === undefined || Number(this.state.formDataItem.cmbProductCategory) < 0) {
            this.props.toastErrorMsg('Select Product Category', 'cmbProductCategory')
        } else if (this.state.formDataItem.cmbProductName === undefined || Number(this.state.formDataItem.cmbProductName) < 0) {
            this.props.toastErrorMsg('Product Name', 'cmbProductName')
        } else if (this.state.formDataItem.txtQuantity === undefined || this.state.formDataItem.txtQuantity == '') {
            this.props.toastErrorMsg('Entey Quantity', 'txtQuantity')
        } else if (this.state.formDataItem.txtQuantity === undefined || Number(this.state.formDataItem.txtQuantity) <= 0) {
            this.props.toastErrorMsg('Quantity must be greater than 0', 'txtQuantity')
        } else if (Number(this.state.formDataItem.txtTaxable) <= 0) {
            this.props.toastErrorMsg('Taxaable must be greater than 0', 'txtTaxable')
        } else if (this.state.formDataItem.cmbTaxName === undefined || Number(this.state.formDataItem.cmbTaxName) < 0) {
            this.props.toastErrorMsg('Select Tax Name', 'cmbTaxName')
        } else {
            validateSalesItem = true
        }
        return validateSalesItem
    }

    saveSalesItem = () => {
        if (this.props.SalesMode === 'MemberSales') {
            this.saveMemberSalesItem()
        }
        else {
            this.saveGuestSalesItem()
        }
    }

    saveMemberSalesItem = () => {
        if (this.validateSalesItem()) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'SaveSales_SalesItemEntry',
                    bint_invoiceno: this.state.formDataItem.EditInvoiceNo,
                    dat_salesdate: this.props.EditSalesDate,
                    vac_cardno: this.props.EditCardNo,
                    bint_memberid: this.props.EditMemberID,
                    dec_netamount: 0,
                    bint_itemsrno: this.state.formDataItem.EditItemSrNo,
                    vac_productcode: this.state.formDataItem.txtProductCode,
                    vac_productcategory: this.state.formDataItem.cmbProductCategory,
                    vac_productname: this.state.formDataItem.cmbProductName,
                    vac_description: this.state.formDataItem.txtDescription,
                    dec_mrp: this.state.formDataItem.txtMRP,
                    dec_salesrate: this.state.formDataItem.txtSalesRate,
                    dec_quantity: this.state.formDataItem.txtQuantity,
                    dec_amount: this.state.formDataItem.txtAmount,
                    dec_discountper: this.state.formDataItem.txtDiscountPer,
                    dec_discountamt: this.state.formDataItem.txtDiscountAmt,
                    dec_taxable: this.state.formDataItem.txtTaxable,
                    vac_taxname: this.state.formDataItem.cmbTaxName,
                    dec_cgst: this.state.formDataItem.txtCGST,
                    dec_sgst: this.state.formDataItem.txtSGST,
                    dec_igst: this.state.formDataItem.txtIGST,
                    dec_totalamount: this.state.formDataItem.txtTotalAmount,
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let txtInvoice = res.data.SalesInfo[0].InvoiceNo,
                                SalesItemList = res.data.SalesItemEntryList,
                                txtNetAmount = res.data.SalesInfo[0].NetAmount
                            this.setState({
                                dataLoadStatus: true,
                                formDataItem: {
                                    ...this.state.formDataItem,
                                    EditSalesDate: this.props.EditSalesDate,
                                    EditInvoiceNo: txtInvoice
                                },
                            }, () => {
                                this.props.setSalesInfo(txtInvoice, txtNetAmount, SalesItemList)
                                this.onNewEntryClick()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in saveSalesItem. ' + error)
                    })
            })
        }
    }

    saveGuestSalesItem = () => {
        if (this.validateSalesItem()) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'GuestSaveSales_SalesItemEntry',
                    bint_invoiceno: this.state.formDataItem.EditInvoiceNo,
                    dat_salesdate: this.props.EditSalesDate,
                    vac_kidname: this.props.EditKidName,
                    vac_guardianname: this.props.EditGuardianName,
                    dec_netamount: 0,
                    bint_itemsrno: this.state.formDataItem.EditItemSrNo,
                    vac_productcode: this.state.formDataItem.txtProductCode,
                    vac_productcategory: this.state.formDataItem.cmbProductCategory,
                    vac_productname: this.state.formDataItem.cmbProductName,
                    vac_description: this.state.formDataItem.txtDescription,
                    dec_mrp: this.state.formDataItem.txtMRP,
                    dec_salesrate: this.state.formDataItem.txtSalesRate,
                    dec_quantity: this.state.formDataItem.txtQuantity,
                    dec_amount: this.state.formDataItem.txtAmount,
                    dec_discountper: this.state.formDataItem.txtDiscountPer,
                    dec_discountamt: this.state.formDataItem.txtDiscountAmt,
                    dec_taxable: this.state.formDataItem.txtTaxable,
                    vac_taxname: this.state.formDataItem.cmbTaxName,
                    dec_cgst: this.state.formDataItem.txtCGST,
                    dec_sgst: this.state.formDataItem.txtSGST,
                    dec_igst: this.state.formDataItem.txtIGST,
                    dec_totalamount: this.state.formDataItem.txtTotalAmount,
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let txtInvoice = res.data.SalesInfo[0].InvoiceNo,
                                SalesItemList = res.data.SalesItemEntryList,
                                txtNetAmount = res.data.SalesInfo[0].NetAmount
                            this.setState({
                                dataLoadStatus: true,
                                formDataItem: {
                                    ...this.state.formDataItem,
                                    EditSalesDate: this.props.EditSalesDate,
                                    EditInvoiceNo: txtInvoice
                                },
                            }, () => {
                                this.props.setSalesInfo(txtInvoice, txtNetAmount, SalesItemList)
                                this.onNewEntryClick()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in saveSalesItem. ' + error)
                    })
            })

        }

    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false,
            ProductCategoryList: this.props.ProductCategoryList,
            TaxList: this.props.TaxNameList,
            ProductNameList: this.props.ProductNameList,
        }, () => {
            let EditInvoiceNo = 0, EditSalesDate = '', EditItemSrNo = 0
            if (Number(this.props.EditInvoiceNo) > 0) {
                EditInvoiceNo = this.props.EditInvoiceNo
                EditSalesDate = this.props.EditSalesDate
            }
            if (Number(this.props.EditItemSrNo) > 0) {
                EditItemSrNo = this.props.EditItemSrNo
                this.setState({
                    dataLoadStatus: true,
                    formDataItem: {
                        ...this.state.formDataItem,
                        EditSalesDate,
                        EditInvoiceNo,
                        EditItemSrNo: this.props.rowItem.bint_itemsrno,
                        txtProductCode: this.props.rowItem.vac_productcode,
                        cmbProductCategory: this.props.rowItem.vac_productcategory,
                        cmbProductName: this.props.rowItem.vac_productname,
                        txtDescription: this.props.rowItem.vac_description,
                        txtMRP: this.props.rowItem.dec_mrp,
                        txtSalesRate: this.props.rowItem.dec_salesrate,
                        txtQuantity: this.props.rowItem.dec_quantity,
                        txtAmount: this.props.rowItem.dec_amount,
                        txtDiscountPer: this.props.rowItem.dec_discountper,
                        txtDiscountAmt: this.props.rowItem.dec_discountamt,
                        txtTaxable: this.props.rowItem.dec_taxable,
                        cmbTaxName: this.props.rowItem.vac_taxname,
                        txtCGST: this.props.rowItem.dec_cgst,
                        txtSGST: this.props.rowItem.dec_sgst,
                        txtIGST: this.props.rowItem.dec_igst,
                        txtTotalAmount: this.props.rowItem.dec_totalamount,
                    }
                }, () => {
                    document.getElementById('txtProductCode').focus()
                })
            } else {
                this.setState({
                    dataLoadStatus: true,
                    formDataItem: {
                        ...this.state.formDataItem,
                        EditSalesDate,
                        EditInvoiceNo,
                    }
                }, () => {
                    this.onNewEntryClick()
                })
            }
        })
    }

    calculateTotalAmount = () => {
        let txtAmount = 0, txtTaxable = 0, txtTotalAmount = 0, txtCGST = 0,
            txtSGST = 0, txtIGST = 0, txtDiscountAmt = 0
        if (Number(this.state.formDataItem.txtSalesRate) > 0 && Number(this.state.formDataItem.txtQuantity) > 0) {
            txtAmount = Number(this.state.formDataItem.txtSalesRate) * Number(this.state.formDataItem.txtQuantity)
            txtTaxable = txtAmount
        }
        if (Number(this.state.formDataItem.txtDiscountPer) > 0) {
            txtDiscountAmt = (Number(txtAmount) * Number(this.state.formDataItem.txtDiscountPer)) / 100
            // }else if (Number(this.state.formDataItem.txtDiscountPer) <= 0) {
            //     txtDiscountAmt = 0
        } else {
            txtDiscountAmt = Number(this.state.formDataItem.txtDiscountAmt) //Number(txtAmount) - Number(this.state.formDataItem.txtDiscountAmt)
        }
        if (Number(txtDiscountAmt) > 0) {
            txtTaxable = txtAmount - Number(txtDiscountAmt)
        }
        if (this.state.formDataItem.cmbTaxName.length > 0) {
            const curTaxData = getRowData(this.state.TaxList, 'vac_taxname', this.state.formDataItem.cmbTaxName)
            let sgst = curTaxData.dec_sgstamt, cgst = curTaxData.dec_cgstamt
            txtCGST = txtTaxable * cgst
            txtSGST = txtTaxable * sgst
            txtCGST = Number(txtCGST).toFixed(2)
            txtSGST = Number(txtSGST).toFixed(2)
        }
        if (Number(txtTaxable) > 0) {
            txtTotalAmount = Number(txtTaxable) + Number(txtCGST) + Number(txtSGST) + Number(txtIGST)
            txtTotalAmount = Number(txtTotalAmount).toFixed(0)
        }
        this.setState({
            formDataItem: {
                ...this.state.formDataItem,
                txtAmount, txtTaxable, txtTotalAmount, txtCGST, txtSGST, txtIGST, txtDiscountAmt
            }
        }, () => {
            // console.log("this.state.formDataItem.txtTaxable", this.state.formDataItem.txtTaxable);
        })
    }
    //#endregion

    render() {
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}>
                        <b>Sales Item Entry</b>
                    </label>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtProductCode'
                                    label='Product Code*'
                                    maxLength={100}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtProductCode}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtCmb
                                    id='cmbProductCategory'
                                    label='Product Category*'
                                    width='150'
                                    maxLength={100}
                                    items={this.state.ProductCategoryList}
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.cmbProductCategory}
                                    onKeyDown={this.props.onKeyDown}
                                    colID='vac_productcategory'
                                />
                            </Grid>
                            <Grid item>
                                <CtCmb
                                    id='cmbProductName'
                                    label='Product Name*'
                                    width='150'
                                    maxLength={100}
                                    items={this.state.ProductNameList}
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.cmbProductName}
                                    onKeyDown={this.props.onKeyDown}
                                    colID='vac_productname'
                                    dataFilter={[{ filterColID: 'vac_productcategory', value: this.state.formDataItem.cmbProductCategory }]}

                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item style={{ display: this.state.formDataItem.txtDescription && this.state.formDataItem.txtDescription.length > 0 ? 'block' : 'none' }}>
                                <CtTxt
                                    id='txtDescription'
                                    label='Description*'
                                    maxLength={100}
                                    disabled={true}
                                    width='460'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtDescription}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtMRP'
                                    label='MRP*'
                                    maxLength={10}
                                    disabled={true}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtMRP}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtSalesRate'
                                    label='Sales Rate*'
                                    maxLength={10}
                                    disabled={true}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtSalesRate}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtQuantity'
                                    label='Quantity*'
                                    maxLength={10}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtQuantity}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtAmount'
                                    label='Amount*'
                                    maxLength={10}
                                    disabled={true}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtAmount}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtDiscountPer'
                                    label='Dis. %'
                                    maxLength={10}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtDiscountPer}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtDiscountAmt'
                                    label='Dis. Amount'
                                    maxLength={10}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtDiscountAmt}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtTaxable'
                                    label='Taxable*'
                                    maxLength={10}
                                    disabled={true}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtTaxable}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtCmb
                                    id='cmbTaxName'
                                    label='Tax*'
                                    width='150'
                                    maxLength={100}
                                    items={this.state.TaxList}
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.cmbTaxName}
                                    onKeyDown={this.props.onKeyDown}
                                    colID='vac_taxname'
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtCGST'
                                    label='CGST*'
                                    maxLength={10}
                                    width='130'
                                    disabled={true}
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtCGST}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtSGST'
                                    label='SGST*'
                                    maxLength={10}
                                    width='130'
                                    disabled={true}
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtSGST}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtIGST'
                                    label='IGST*'
                                    maxLength={10}
                                    width='130'
                                    disabled={true}
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtIGST}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtTotalAmount'
                                    label='Total Amount*'
                                    maxLength={10}
                                    width='130'
                                    disabled={true}
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formDataItem.txtTotalAmount}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item>
                                <CtBtn label='Save' onClick={this.saveSalesItem} />
                            </Grid>
                            <Grid item>
                                <CtBtn label='New' onClick={this.onNewEntryClick} />
                            </Grid>
                            {localStorage.getItem('De') == 1 ?
                                <Grid item>
                                    <CtBtn label='Delete' onClick={this.onDeleteItemClick} />
                                </Grid> : ""}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HoCtToastContainer(HOCProductInfo(withRouter(SalesItemEntry))))