import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import UserDashboard from './UserDashboard'
import MemberDashboard from './MemberDashboard'
import MemberMaster from './MemberMaster'
import Subscription from './Subscription'
import ProductCategoryMaster from './ProductCategoryMaster'
import RentAtStore from './RentAtStore'
import ProductMaster from './ProductMaster'
import SalesList from './SalesList'
import SalesEntry from './SalesEntry'
import RentAtHome from './RentAtHome'
import ReturnEntry from './ReturnEntry'
import UserManagement from './UserManagement'
import ReturnDueList from './ReturnDueList'
import Login from './Login'
import MemberList from './MemberList'
import InwardList from './InwardList'
import InwardEntry from './InwardEntry'
import InwardItemEntry from './InwardItemEntry'
import PurchaseSubscription from './PurchaseSubscription'
import OutwardList from './OutwardList'
import OutwardEntry from './OutwardEntry'
import OutwardItemEntry from './OutwardItemEntry'
import Settings from './Settings'
import TransactionHistorySelection from './TrasactionHistorySelection'
import RentAndReturnList from './RentAndReturnList'
import GuestSales from './GuestSales'
import GroupMaster from './GroupMaster'
import MemberID from './MemberID'

class Navigation extends Component {

    render() {
        return (
            <div className="Navigation">
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/UserDashboard" element={<UserDashboard />} />
                    <Route path="/MemberDashboard" element={<MemberDashboard />} />
                    <Route path="/MemberMaster:EditMemberID" element={<MemberMaster />} />
                    <Route path="/MemberMaster" element={<MemberMaster />} />
                    <Route path="/ProductCategoryMaster" element={<ProductCategoryMaster />} />
                    <Route path="/RentAtStore" element={<RentAtStore />} />
                    <Route path="/RentAtHome:EditRentAtHome" element={<RentAtHome />} />
                    <Route path="/RentAtHome" element={<RentAtHome />} />
                    <Route path="/Products" element={<ProductMaster />} />
                    <Route path="/MemberList" element={<MemberList />} />
                    <Route path="/SalesList" element={<SalesList />} />
                    <Route path="/SalesEntry:EditInvoiceNo" element={<SalesEntry />} />
                    <Route path="/SalesEntry" element={<SalesEntry />} />
                    <Route path="/SubscriptionPlan" element={<Subscription />} />
                    <Route path="/ReturnEntry:EditReturnDetails" element={<ReturnEntry />} />
                    <Route path="/ReturnEntry" element={<ReturnEntry />} />
                    <Route path="/UserManagement" element={<UserManagement />} />
                    <Route path="/ReturnDueList" element={<ReturnDueList />} />
                    <Route path="/InwardItemEntry" element={<InwardItemEntry />} />
                    <Route path="/InwardList" element={<InwardList />} />
                    <Route path="/InwardEntry:EditInwardno" element={<InwardEntry />} />
                    <Route path="/InwardEntry" element={<InwardEntry />} />
                    <Route path="/PurchaseSubscription:CardNo" element={<PurchaseSubscription />} />
                    <Route path="/PurchaseSubscription" element={<PurchaseSubscription />} />
                    <Route path="/OutwardList" element={<OutwardList />} />
                    <Route path="/OutwardEntry" element={<OutwardEntry />} />
                    <Route path="/OutwardItemEntry" element={<OutwardItemEntry />} />
                    <Route path="/Settings" element={<Settings />} />
                    <Route path="/TransactionHistorySelection" element={<TransactionHistorySelection />} />
                    <Route path="/RentAndReturnList" element={<RentAndReturnList />} />
                    <Route path="/GuestSales:EditInvoiceNo" element={<GuestSales />} />
                    <Route path="/GuestSales" element={<GuestSales />} />
                    <Route path="/GroupMaster" element={<GroupMaster />} />
                    <Route path="/MemberID" element={<MemberID />} />
                </Routes>
            </div>
        )
    }
}
export default Navigation