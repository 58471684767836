import React, { Component } from 'react'
import { Grid } from '@mui/material'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { fetchSubscriptionEntry, fetchTaxList } from './API/API'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import { withRouter } from './HOC/withRouter'

class Settings extends Component {
    state = {
        btnSetRemoveADText: 'Set As Authorized Device',
        Tax: [],
        formData: {
            cmbTax: '',
        },
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Settings'
        this.props.setActiveMenu("Settings")
        this.setBtnText()
        this.getTaxList()
    }
    //#endregion Component

    //#region Handle
    handleOnChange = (e) => {
        const ctrlName = e.target.name,
            ctrlValue = e.target.value
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }
    //#endregion Handle

    //#region function

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    setBtnText = () => {
        let btnSetRemoveADText = ''
        if (localStorage.getItem('AD') && Number(localStorage.getItem('AD')) === 1) {
            btnSetRemoveADText = 'Remove As Authorized Device'
        }
        else {
            btnSetRemoveADText = 'Set As Authorized Device'
        }
        this.setState({ btnSetRemoveADText })
    }

    OnClick = () => {
        if (this.state.btnSetRemoveADText === 'Set As Authorized Device') {
            localStorage.setItem('AD', 1)
        }
        else {
            localStorage.removeItem('AD')
        }
        this.setBtnText()
    }

    getTaxList = () => {
        const reqData = {
            'Op': 'TaxList',
        }
        fetchTaxList(reqData)
            .then(res => {
                let Tax = res.data.TaxList
                this.setState({
                    Tax
                }, () => {
                    this.getMembershipChargeTax()
                })
            })
            .catch(error => {
                console.log('Unknown error occurred in getTaxList.', error)
            })
    }

    getMembershipChargeTax = () => {
        const reqData = {
            'Op': 'GetMembershipChargeTax',
        }
        fetchSubscriptionEntry(reqData)
            .then(res => {
                let MembershipChargeTax = res.data.GetMembershipChargeTax[0].vac_taxname
                // console.log("MembershipChargeTax", res.data.GetMembershipChargeTax.vac_taxname);
                this.setState({
                    formData: {
                        ...this.state.formData,
                        cmbTax: MembershipChargeTax,
                    }
                })
            })
            .catch(error => {
                console.log('Unknown error occurred in GetMembershipChargeTax.', error)
            })
    }

    setMembershipChargeTax = () => {
        if (this.state.formData.cmbTax == '') {
            this.props.toastErrorMsg('Select Tax', 'cmbTax')
        } else {
            this.props.notifyProcessing()
            const reqData = {
                'Op': 'UpdateMembershipChargeTax',
                vac_taxname: this.state.formData.cmbTax
            }
            fetchSubscriptionEntry(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                })
                .catch(error => {
                    console.log('Unknown error occurred in GetMembershipChargeTax.', error)
                })
        }
    }
    //#endregion function

    render() {
        return (
            <div>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0}>
                    <Grid item>
                        <CtBtn id='btnSetRemoveAD' label={this.state.btnSetRemoveADText} onClick={this.OnClick} />
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0}>
                    <Grid item>
                        <CtCmb
                            id='cmbTax'
                            label='Membership Charge Tax %*'
                            items={this.state.Tax}
                            value={this.state.formData.cmbTax}
                            // disabled={this.state.disabledProfileData.AccountTypeDS}
                            handleOnChange={this.handleOnChange}
                            width='250'
                            maxLength={50}
                            colID='TaxName'
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn id='btnMembershipChargeTax' label='Set Membership Charge Tax' onClick={this.setMembershipChargeTax} />
                    </Grid>

                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HoCtToastContainer(withRouter(Settings)))
