import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import CtDtp from './CustomTool/CtDtp'
import CtBtn from './CustomTool/CtBtn'
import { Edit, Delete, Print } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import CtTxt from './CustomTool/CtTxt'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { fetchInwardList, apiURL } from './API/API'
import InwardItemEntry from './InwardItemEntry'
import { get_DMY_from_YMD, get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'
import { getRowData } from './SystemUtility/SystemUtility'

class InwardEntry extends Component {

    state = {
        status: [],
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_inwardno', label: 'Inward No.', hide: 'true' },
            { id: 'bint_inwarditemno', label: 'Inward Item No.' },
            { id: 'vac_productcode', label: 'Product Code' },
            { id: 'vac_productname', label: 'Product Name' },
            { id: 'vac_productcategory', label: 'Product Category' },
            { id: 'dec_qty', label: 'Qty' },
            { id: 'vac_note', label: 'Note' },
        ],
        formData: {
            txtInwardNo: '',
            dtpInwardDate: get_YMD_from_SYS(new Date()),
            MemberID: this.props.memberInfo.MemberID,
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'InwardEntry:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'InwardEntry', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
            { name: 'Print QR Code', icon: <Print />, link: 'InwardEntry', actionType: 'AlertResponsive' },
        ],
        keyColumn: [
            'bint_inwardno', 'bint_inwarditemno', 'vac_productcode', 'vac_productname', 'dec_qty'
        ],
        searchData: {
            bint_inwardno: '',
            bint_inwarditemno: '',
            dat_inwarddate: '',
            vac_productname: '',
            vac_productcategory: '',
            dec_qty: '',
            vac_note: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        ProductCategoryList: [],
        ProductNameList: [],
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Inward Entry'
        this.props.setActiveMenu("InwardEntry")
        this.setSearchColumns()
        this.onFormLoad()
    }
    //#endregion

    //#region handle

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (searchOpen === true && (
                    (this.state.searchData.bint_inwardno.length === 0 || row['bint_inwardno'].toString().includes(this.state.searchData.bint_inwardno)) &&
                    (this.state.searchData.bint_inwarditemno.length === 0 || row['bint_inwarditemno'].toString().includes(this.state.searchData.bint_inwarditemno))
                        (this.state.searchData.dat_inwarddate.length === 0 || row['dat_inwarddate'].toString().includes(this.state.searchData.dat_inwarddate))
                        (this.state.searchData.vac_productname.length === 0 || row['vac_productname'].toString().includes(this.state.searchData.vac_productname)) &&
                    (this.state.searchData.vac_productcategory.length === 0 || row['vac_productcategory'].toString().includes(this.state.searchData.vac_productcategory))
                        (this.state.searchData.vac_note.length === 0 || row['vac_note'].toString().includes(this.state.searchData.vac_note))
                        (this.state.searchData.dec_qty.length === 0 || row['dec_qty'].toString().includes(this.state.searchData.dec_qty))

                )) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleARDonActionClick = (values) => {
        // console.log('values', values)
        const txtInwardNo = values[0].value,
            actionName = values[0].actionName,
            EditItemSrNo = values[1].value,
            ProductCode = values[2].value,
            ProductName = values[3].value,
            Qty = values[4].value
        if (actionName === "Edit") {
            const curRowData = getRowData(this.state.rows, 'bint_inwarditemno', EditItemSrNo)
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    // labelAgree='Delete'
                    labelDisagree='Close'
                    // alertTitle={'Do you want to Delete  : ' + txtInvoiceNo + ' ?'}
                    alertMessage={
                        <InwardItemEntry
                            EditInwardNo={txtInwardNo} EditItemSrNo={EditItemSrNo}
                            updateEditInwardNo={this.updateTxtInwardNo}
                            updateTxtInwardNo={this.updateTxtInwardNo}
                            updateInwardNoItemList={this.updateInwardNoItemList}
                            EditInwardDate={this.state.formData.dtpInwardDate}
                            rowItem={curRowData}
                            ProductCategoryList={this.state.ProductCategoryList}
                            ProductNameList={this.state.ProductNameList}
                            EditMemberID={this.state.formData.MemberID} />
                    }
                    // handleOnClickYes={() => {
                    //     return this.deletePeanuteDiseaseCalculator(intSrNo)
                    // }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Product : ' + ProductName + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.DeleteInwardItemEntry(txtInwardNo, EditItemSrNo, this.state.formData.dtpInwardDate, ProductCode)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else if (actionName === "Print QR Code") {
            this.openProductQRCode(ProductCode, ProductName, Qty)
        }
    }
    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    updateTxtInwardNo = (txtInwardNo) => {
        this.setState({ formData: { ...this.state.formData, txtInwardNo } })
    }

    updateInwardItemList = (rows) => {
        const rowsCount = rows.length
        this.setState({ rows, rowsCount })
    }

    updateInwardNoItemList = (txtInwardNo, rows) => {
        const rowsCount = rows.length
        this.setState({ rows, rowsCount, formData: { ...this.state.formData, txtInwardNo } })
    }

    onNewEntryClick = () => {
        // if (this.validateSales() === true) {
        let ARD = null
        this.setState({ ARD }, () => {
            ARD = <AlertResponsiveDialog
                labelDisagree='Close'
                // alertTitle={'Sales Item Entry'}
                alertMessage={
                    <InwardItemEntry
                        EditInwardNo={this.state.formData.txtInwardNo}
                        updateEditInwardNo={this.updateTxtInwardNo}
                        updateTxtInwardNo={this.updateTxtInwardNo}
                        updateInwardNoItemList={this.updateInwardNoItemList}
                        EditInwardDate={this.state.formData.dtpInwardDate}
                        ProductCategoryList={this.state.ProductCategoryList}
                        ProductNameList={this.state.ProductNameList}
                        EditMemberID={this.state.formData.MemberID} />
                }
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ ARD })
        })
        // }
    }

    DeleteInwardItemEntry = (InwardNo, InwardItemNo, InwardDate, ProductCode) => {
        if (InwardNo && InwardNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteInwardItemEntry',
                    bint_inwardno: InwardNo,
                    bint_inwarditemno: InwardItemNo,
                    dat_inwarddate: InwardDate,
                    vac_productcode: ProductCode
                }
                fetchInwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let rows = res.data.InwardItemEntryList
                            this.setState({
                                dataLoadStatus: true,
                                rows,
                                rowsCount: rows.length
                            }, () => {
                                // this.listSales()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteInwarditem. ' + error)
                    })
            })
        }
        return true
    }

    validateInwardEntry = () => {
        let result = false
        if (this.state.rowsCount === 0) {
            this.props.toastErrorMsg('First Enter Inward Item Then Try to Save Inward', 'dtpInwardDate')
        } else if (this.state.formData.dtpInwardDate.length <= 0) {
            this.props.toastErrorMsg('Enter Inward Date', 'dtpInwardDate')
        }

        else {
            result = true
        }
        return result
    }

    SaveInwardEntry = () => {
        if (this.validateInwardEntry()) {
            const dataLoadStatus = false
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus }, () => {
                const reqData = {
                    'Op': 'SaveInwardEntry',
                    'bint_inwardno': this.state.formData.txtInwardNo,
                    'dat_inwarddate': this.state.formData.dtpInwardDate,
                }
                fetchInwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({ dataLoadStatus: true }, () => {
                                this.newInwardEntry()
                            })
                        }
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in SaveInwardEntry.', error)
                    })
            })

        }

    }

    newInwardEntry = () => {
        this.setState({
            rows: [],
            rowsCount: 0,
            formData: {
                ...this.state.formData,
                txtInwardNo: '',
                dtpInwardDate: get_YMD_from_SYS(new Date()),
            },
        }, () => {
            this.navigateTo('InwardEntry')
            document.getElementById('dtpInwardDate').focus()
        })
    }

    onDeleteClick = () => {
        const InwardNo = this.state.formData.txtInwardNo
        if (Number(InwardNo) > 0) {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete InwardNo No. : ' + InwardNo + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.DeleteInwardEntry()
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg('error', 'No records to Delete')
        }
    }

    DeleteInwardEntry = () => {
        if (this.state.formData.txtInwardNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteInwardEntry',
                    'bint_inwardno': this.state.formData.txtInwardNo,
                    'dat_inwarddate': this.state.formData.dtpInwardDate
                }
                fetchInwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.newInwardEntry()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in DeleteInwardEntry. ' + error)
                    })
            })
        }
        return true
    }

    setSearchColumns = () => {
        let searchColumns = []
        //ProductNameCount = this.state.ProductNameList.length, SearchProductNameList = []
        // if (this.state.ProductNameList.length > 0) {
        //     ProductNameList.push({ 'ProductNameList': 'ALL' })
        //     this.state.ProductNameList.map((ProductName) => {
        //         SearchProductNameList.push({ 'ProductNameList': ProductName.ProductNameList })
        //     })
        //     console.log('SearchProductNameList', SearchProductNameList)
        // }
        searchColumns.push(
            {
                cType: 'Lbl',
                label: 'bint_inwardno',
                width: '100',
            },
            {
                cType: 'Lbl',
                label: 'bint_inwarditemno',
                width: '100',
            },
            {
                cType: 'Lbl',
                label: 'dat_inwarddate',
                width: '100',
            },
            {
                cType: 'CtCmb',
                id: 'txtProductName',
                label: 'Product Name',
                items: this.state.ProductNameList,
                value: this.state.searchData.vac_productname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                // colID:'vac_productname'
            },

            {
                cType: 'CtCmb',
                id: 'txtProductCategory',
                label: 'Product Category',
                value: this.state.searchData.vac_productcategory,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            }, {
            cType: 'CtTxt',
            id: 'txtNote',
            label: 'Note',
            value: this.state.searchData.vac_note,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        },
            {
                cType: 'CtTxt',
                id: 'txtQty',
                label: 'Qty',
                value: this.state.searchData.dec_qty,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },


        )
        this.setState({ searchColumns })
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let EditInwardno = 0, EditInwardDate = ''
            if (this.props.params.EditInwardno && (this.props.params.EditInwardno.substring(1).length) > 0) {
                var res = this.props.params.EditInwardno.split(":")
                EditInwardno = res[1]
                EditInwardDate = res[2]
            }
            let reqData = {
                Op: 'EditInwardEntry',
                bint_inwardno: EditInwardno,
                dat_inwarddate: EditInwardDate
            }
            fetchInwardList(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    let rowsCount = res.data.InwardItemEntryList.length
                    let rows = res.data.InwardItemEntryList
                    if (EditInwardDate.length === 0) {
                        EditInwardDate = get_YMD_from_SYS(new Date())
                    }
                    this.setState({
                        dataLoadStatus: true,
                        InwardEntryList: res.data.InwardEntryList,
                        ProductCategoryList: res.data.ProductCategoryList,
                        ProductNameList: res.data.ProductMasterList,
                        rows,
                        rowsCount,
                        formData: {
                            ...this.state.formData,
                            txtInwardNo: EditInwardno,
                            dtpInwardDate: get_YMD_from_SYS(EditInwardDate),
                        }
                    })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in onFormLoad. ' + error)
                })
        })
    }

    openProductQRCode = (ProductCode, ProductName, Qty) => {
        // console.log('topmargin:',topmargin);
        this.props.notifyProcessing()
        let reqData = [
            { name: 'Op', value: 'PrintProductQRCode' },
            { name: 'vac_productcode', value: ProductCode },
            { name: 'vac_productname', value: ProductName },
            { name: 'dec_qty', value: Qty },
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'inwardentrydetail.php');

        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');

        form.submit();
        this.props.updateProcessing('success', 'PDF Open in next Tab.')
    }
    //#endregion function

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Inward Entry</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0}>
                    <Grid item>
                        <CtTxt
                            id='txtInwardNo'
                            label='Inward No'
                            width='150'
                            handleOnChange={this.handleOnChange}
                            value={this.state.formData.txtInwardNo}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item>
                        <CtDtp
                            id='dtpInwardDate'
                            label='Inward Date'
                            width='150'
                            handleOnChange={this.handleOnChange}
                            value={this.state.formData.dtpInwardDate}
                            defaultValue={this.state.formData.dtpInwardDate}
                        // disabled={true}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Save' width={this.state.cWidth - 150} onClick={this.SaveInwardEntry} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='New' width={this.state.cWidth - 150} onClick={this.newInwardEntry} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='List' width={this.state.cWidth - 150} onClick={() => { this.navigateTo('InwardList') }} />
                    </Grid>
                    {localStorage.getItem('De') == 1 ?
                        <Grid item>
                            <CtBtn label='Delete' width={this.state.cWidth - 150} onClick={this.onDeleteClick} />
                        </Grid> : ""}
                </Grid>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                    toolTipNewEntry='New Inward Item Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>

            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(InwardEntry)))