import React, { Component } from 'react'
import { Grid, Box, Button, } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import CtDtp from './CustomTool/CtDtp'
import {
    get_DMY_from_YMD, get_YMD_from_SYS, get_90DaysBack_FromCurrentDate, //addPeriod 
} from './SystemUtility/SystemUtility'
import CtBtn from './CustomTool/CtBtn'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import { apiURL } from './API/API'

class RecalculateStockSelection extends Component {
    state = {
        formData: {
            dtpFromDate: get_90DaysBack_FromCurrentDate(),
            dtpToDate: get_YMD_from_SYS(new Date()),
        },
    }

    componentDidMount() {
        document.title = 'TED : Member List'
        this.props.setActiveMenu("MemberList")
        let dtpFromDate = get_90DaysBack_FromCurrentDate(),
            dtpToDate = get_YMD_from_SYS(new Date())
        this.setState({
            formData: {
                ...this.state.formData,
                dtpFromDate, dtpToDate
            }
        })
    }

    //#region handle
    handleOnChange = (e) => {
        console.log('e.target.name,e.target.value', e.target.name, e.target.value)
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            console.log('this.state.formData.dtpFromDate', this.state.formData.dtpFromDate)
        })
    }
    //#endregion handle

    render() {
        return (
            <div>
                <form>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                        <Grid item>
                            <CtDtp
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChange}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtp
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChange}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Recalculate' width={this.state.cWidth - 150} onClick={() => { this.props.recalculatePoints(this.props.bint_memberid, this.state.formData.dtpFromDate, this.state.formData.dtpToDate) }} />
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(RecalculateStockSelection)))