import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import Navigation from './Component/Navigation'
import Menu from './Component/Menu'
import { ToastContainer, Zoom } from 'react-toastify';
import { fetchRecaptcha, domainURL } from './Component/API/API'
import moment from 'moment'

function App() {
  
  const recaptchaFunction = () => {
    window.grecaptcha.ready(function () {
      //Site Key is writter in first argument
      //Action is writter in second argument
      window.grecaptcha.execute('6LcsQ2UiAAAAABwh7kukfhNbrVkksnZtqs5RWHC_', { action: 'onPageLoad' }).then(function (token) {
        const reqData = {
          "token": token
        }
        fetchRecaptcha(reqData)
          .then(res => {
            let isVerified = false
            if (res.data.success) {
              isVerified = true
            }
            // alert("isVerified : " + isVerified + "\nScore : " + res.data.score)
            if (res.data.score && Number(res.data.score) > 0.5) {

            } else {
              window.location.assign(domainURL + "404.php");
            }
          })
          .catch(error => {
            alert(error)
            console.log("Unknown Error occurred", error)
          })
      });
    });
  }

  useEffect(() => {
    // if (process.env.NODE_ENV === 'production') {
    recaptchaFunction()
    // }
  })

  return (
    <Container maxWidth={false}>
      <div className="App">
        <ToastContainer
          position="top-center"
          autoClose={4000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Zoom}
        />
        <HashRouter>
          <Menu />
          <br />
          <Navigation />
        </HashRouter>
      </div>
    </Container>
  );
}

export default App;
