import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { withRouter } from './HOC/withRouter'
import { Grid, Avatar, Button, Box } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import defaultImg from './images/default.png'
import CtDtp from './CustomTool/CtDtp'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import CtBtn from './CustomTool/CtBtn'
import TableList from './CustomTool/TableList'
import { Edit, Delete } from '@mui/icons-material';
import dayjs from 'dayjs';
import CtDtpTime from './CustomTool/CtDtpTime'
import MemberDashboardIcon from './images/memberdashboard.png'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import RentAtHomeItemEntry from './RentAtHomeItemEntry'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { fetchRentAtHome } from './API/API'
import { get_DMY_from_YMD, get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'
import HOCMemberInfo from './HOC/HOCMemberInfo'
import { getRowData } from './SystemUtility/SystemUtility'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

class RentAtHome extends Component {
    state = {
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_rentitemsrno', label: 'Item Sr No', hide: 'true' },
            { id: 'vac_productcode', label: 'Product Code' },
            { id: 'vac_productcategory', label: 'Product Category' },
            { id: 'vac_productname', label: 'Product Name' },
            //{ id: 'Description', label: 'Description' },
            { id: 'dat_rentstartdatetime_dmY', label: 'Rent Start Date Time' },
            { id: 'dec_rentmonths', label: 'Rent Weeks' },
            { id: 'dec_rentdays', label: 'Rent Days' },
            { id: 'dat_rentendatetime_dmY', label: 'Rent End Date Time' },
            { id: 'dec_totalpoint', label: 'Total Point' },
            { id: 'vac_rentremark', label: 'Remark' },
            { id: 'dat_rentstartdatetime', label: 'dat_rentstartdatetime', hide: 'true' },
            { id: 'dat_rentendatetime', label: 'dat_rentendatetime', hide: 'true' },
        ],
        rows: [],
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'RentAtHome:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'RentAtHome:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'bint_rentitemsrno', 'vac_productcode', 'vac_productcategory',
            'vac_productname', 'dat_rentstartdatetime_dmY', 'dec_rentmonths',
            'dec_rentdays', 'dat_rentendatetime_dmY', 'dec_totalpoint', 'vac_rentremark'
        ],
        searchData: {
            ProductCode: '',
            ProductCategory: '',
            ProductName: '',
            Description: '',
            RentStartDateTime: '',
            RentMonths: '',
            RentDays: '',
            RentEndDateTime: '',
            TotalPoint: '',
            Remark: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,
        formData: {
            dtpRentDate: get_YMD_from_SYS(new Date()),
            txtChallanNo: '',
            txtNetPoint: '',
            txtTotalRentProductCount: '',
            // MemberID: this.props.memberInfo.MemberID,
            // txtCardNo: this.props.memberInfo.CardNo,
            // txtKidName: this.props.memberInfo.KidName,
            // txtGuardianName: this.props.memberInfo.GuardianName,
            // txtAvailableCardPoints: this.props.memberInfo.CurrentPoints,
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'RentAtHomeItemEntry:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: '', actionType: 'AlertResponsive' },
        ],
        searchData: {
            vac_productcode: '',
            vac_productcategory: '',
            vac_productname: '',
            Description: '',
            Dat_rentstartdatetime: '',
            Dec_rentmonths: '',
            Dec_rentdays: '',
            Dat_rentendatetime: '',
            Dec_totalpoint: '',
            Vac_rentremark: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,
        ProductCategoryList: [],
        ProductNameList: [],
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Rent'
        this.setSearchColumns()
        this.onFormLoad()
        this.props.setActiveMenu("RentAtHome")
        document.getElementById('dtpRentDate').focus()
        this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo)
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }
    //#endregion component

    //#region handle
    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.vac_productcode === 'ALL' || row['vac_productcode'].toString().includes(this.state.searchData.vac_productcode)) &&
                        (this.state.searchData.vac_productcategory.length === 0 || row['vac_productcategory'].toString().includes(this.state.searchData.vac_productcategory)) &&
                        (this.state.searchData.vac_productname.length === 0 || row['vac_productname'].toString().includes(this.state.searchData.vac_productname))
                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        const ctrlName = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        const actionName = values[0].actionName,
            EditRentItemSrNo = values[0].value,
            ProductName = values[3].value,
            vac_productcode = values[1].value
        console.log("values", values);
        if (actionName === "Edit") {
            const curRowData = getRowData(this.state.rows, 'bint_rentitemsrno', EditRentItemSrNo)
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    // labelAgree='Delete'
                    labelDisagree='Close'
                    // alertTitle={'Do you want to Delete  : ' + txtInvoiceNo + ' ?'}
                    alertMessage={
                        <RentAtHomeItemEntry
                            Editrentsrno={this.state.formData.txtChallanNo}
                            EditCardNo={this.props.memberInfo.CardNo}
                            EditdtpRentDate={this.state.formData.dtpRentDate}
                            rentitemsrno={EditRentItemSrNo}
                            ProductCategoryList={this.state.ProductCategoryList}
                            rowItem={curRowData}
                            ProductNameList={this.state.ProductNameList}
                            EditMemberID={this.props.memberInfo.MemberID}
                            setRentAtHomeInfo={this.setRentAtHomeInfo} />
                    }                    // handleOnClickYes={() => {
                    //     return this.deletePeanuteDiseaseCalculator(intSrNo)
                    // }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />

                this.setState({ ARD }, () => {

                })
            })
        } else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Product : ' + ProductName + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteRentAtHomeItemEntry(this.state.formData.txtChallanNo, this.state.formData.dtpRentDate, EditRentItemSrNo, this.props.memberInfo.MemberID, vac_productcode)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    //#endregion

    //#region  Function
    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {}, {
            cType: 'CtTxt',
            id: 'vac_productcode',
            label: 'Product Code',
            value: this.state.searchData.vac_productcode,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',
            //disabled: true,

        }, {
            cType: 'CtTxt',
            id: 'vac_productcategory',
            label: 'Product Category',
            //items: productCategory,
            value: this.state.searchData.vac_productcategory,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',
            //disabled: true,

        }, {
            cType: 'CtTxt',
            id: 'vac_productname',
            label: 'Product Name',
            //items:productName,
            value: this.state.searchData.vac_productname,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            //disabled: true,
            maxLength: '100'
        }, {
            cType: 'Lbl',
            label: 'Rent Start Date Time',
            width: '100'
        }, {
            cType: 'CtTxt',
            id: 'dec_rentmonths',
            label: 'Rent Months',
            // disabled:{true},
            value: this.state.searchData.dec_rentmonths,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '5'
        }, {
            cType: 'CtTxt',
            id: 'dec_rentdays',
            label: 'Rent Days',
            // disabled:{true},
            value: this.state.searchData.dec_rentdays,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '5'
        }, {
            cType: 'Lbl',
            label: 'Rent End Date Time',
            width: '100'
        }, {
            cType: 'CtTxt',
            id: 'dec_totalpoint',
            label: 'Total Point',
            //disabled: true,
            value: this.state.searchData.dec_totalpoint,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'vac_rentremark',
            label: 'Rent Remark',
            //disabled: true,
            value: this.state.searchData.vac_rentremark,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        })
        this.setState({ searchColumns })
    }

    /* onFormLoad = () => {
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            const reqData = {
                'Op': 'LoadAllCmbRentatHomeDetails',
            }
            fetchRentAtHome(reqData)
                .then(res => {
                    const ProductCategoryList = res.data.ProductCategoryList
                    const ProductNameList = res.data.ProductMasterList
                    this.setState({ dataLoadStatus: true, ProductCategoryList, ProductNameList }, () => {
                        // console.log('this.state.ProductCategoryList', this.state.ProductCategoryList)
                        // console.log('this.state.ProductNameList', this.state.ProductNameList)
                    }
                    )
                })
                .catch(error => {
                    console.log('Unknown error occurred in onFormLoad.', error)
                })
        })
    } */

    onFormLoad = () => {
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            let reqData = {}
            let bint_rentsrno = 0, dat_rentdate = ''
            if (this.props.params.EditRentAtHome && (this.props.params.EditRentAtHome.substring(1).length) > 0) {
                var res = this.props.params.EditRentAtHome.split(":")
                bint_rentsrno = res[1]
                dat_rentdate = res[2]
                reqData = {
                    Op: 'EditRentAtHomeEntryList',
                    bint_rentsrno,
                    dat_rentdate
                }
            } else {
                reqData = {
                    'Op': 'LoadAllCmbRentatHomeDetails',
                }
            }
            fetchRentAtHome(reqData)
                .then(res => {
                    const ProductCategoryList = res.data.ProductCategoryList
                    const ProductNameList = res.data.ProductMasterList
                    let RentAtHomeEntryList = [], rows = [], rowsCount = 0
                    if (res.data.RentAtHomeEntryList) {
                        RentAtHomeEntryList = res.data.RentAtHomeEntryList
                        rows = res.data.RentAtHomeItemEntryList
                        rowsCount = rows.length
                        // console.log("RentAtHomeEntryList",RentAtHomeEntryList[0][0].dec_netpoints);
                        // console.log("RentAtHomeEntryList[0].dec_netpoints",RentAtHomeEntryList[0].dec_netpoints);
                        this.setState({
                            dataLoadStatus: true,
                            ProductCategoryList,
                            ProductNameList,
                            rows, rowsCount,
                            formData: {
                                ...this.state.formData,
                                dtpRentDate: dat_rentdate,
                                txtChallanNo: bint_rentsrno,
                                txtNetPoint: RentAtHomeEntryList[0][0].dec_netpoints,
                                txtTotalRentProductCount: rowsCount
                            },
                        })
                    } else {
                        this.setState({
                            dataLoadStatus: true,
                            ProductCategoryList,
                            ProductNameList
                        })
                    }
                })
                .catch(error => {
                    console.log('Unknown error occurred in onFormLoad.', error)
                })
        })
    }

    validateRentAtHome = () => {
        let validateRentAtHome = false
        if (Number(this.props.memberInfo.CurrentPoints) < Number(this.state.formData.txtNetPoint)) {
            this.props.toastErrorMsg('You Have Not Enough Points', 'txtNetPoint')
        } else {
            validateRentAtHome = true
        }
        return validateRentAtHome
    }

    validateItemCount = () => {
        let validateItemCount = false
        if (this.state.rowsCount <= 0) {
            this.props.toastMsg('error', 'Enter Item detail and then try Save Rent')
        } else {
            validateItemCount = true
        }
        return validateItemCount
    }

    saveRentAtHome = () => {
        if (this.validateRentAtHome() && this.validateItemCount()) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'SaveRentAtHomeEntry',
                    bint_rentsrno: this.state.formData.txtChallanNo,
                    dat_rentdate: this.state.formData.dtpRentDate,
                    vac_cardno: this.props.memberInfo.CardNo,
                    bint_memberid: this.props.memberInfo.MemberID,
                    dec_netpoints: this.state.formData.txtNetPoint,
                    vac_renttype: 'Rent at Home',
                }
                fetchRentAtHome(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                /* if (printInvoice == true) {
                                    this.printInvoice()
                                } */
                                this.newRentAtHome()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in save Rent At Home. ' + error)
                    })
            })
        }
    }

    setRentAtHomeInfo = (txtChallanNo, txtNetPoint, RentAtHomeItemList) => {
        this.setState({
            rows: RentAtHomeItemList,
            rowsCount: RentAtHomeItemList.length,
            formData: {
                ...this.state.formData,
                txtChallanNo,
                txtTotalRentProductCount: RentAtHomeItemList.length,
                txtNetPoint
            }
        }, () => {
            // console.log("this.state.formData",this.state.formData);
        })
    }

    onNewEntryClick = () => {
        // if (this.validateSales() === true) {
        let ARD = null
        this.setState({ ARD }, () => {
            ARD = <AlertResponsiveDialog
                labelDisagree='Close'
                // alertTitle={'Sales Item Entry'}
                alertMessage={
                    <RentAtHomeItemEntry Editrentsrno={this.state.formData.txtChallanNo} EditCardNo={this.props.memberInfo.CardNo}
                        EditdtpRentDate={this.state.formData.dtpRentDate} ProductCategoryList={this.state.ProductCategoryList}
                        ProductNameList={this.state.ProductNameList} EditMemberID={this.props.memberInfo.MemberID} rentitemsrno={this.props.rentitemsrno}
                        setRentAtHomeInfo={this.setRentAtHomeInfo}
                    />
                }
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ ARD })
        })
        // }
    }

    onDeleteClick = () => {
        const ChallanNo = this.state.formData.txtChallanNo,
            RentDate = this.state.formData.dtpRentDate
        if (Number(ChallanNo) > 0) {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Invoice No. : ' + ChallanNo + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteRentAtHome(ChallanNo, RentDate)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg('error', 'No records to Delete')
        }
    }

    deleteRentAtHome = (ChallanNo, RentDate) => {
        if (ChallanNo && ChallanNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteRentAtHomeEntry',
                    bint_rentsrno: ChallanNo,
                    dat_rentdate: RentDate
                }
                fetchRentAtHome(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.newRentAtHome()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteRentAtHome. ' + error)
                    })
            })
        }
        return true
    }

    deleteRentAtHomeItemEntry = (ChallanNo, RentDate, RentItemSrNo, bint_memberid, vac_productcode) => {
        if (ChallanNo && ChallanNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteRentAtHomeItemEntry',
                    bint_rentsrno: ChallanNo,
                    bint_rentitemsrno: RentItemSrNo,
                    dat_rentdate: RentDate,
                    bint_memberid,
                    vac_productcode
                }
                fetchRentAtHome(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            // console.log("res.data.RentInfo[0].dec_netpoint", res.data.RentInfo[0].dec_netpoints);
                            let RentAtHomeItemList = res.data.RentAtHomeItemEntryList,
                                txtNetPoint = res.data.RentInfo[0].dec_netpoints
                            this.setState({
                                dataLoadStatus: true,
                                rows: RentAtHomeItemList,
                                rowsCount: RentAtHomeItemList.length,
                                formData: {
                                    ...this.state.formData,
                                    txtTotalRentProductCount: RentAtHomeItemList.length,
                                    txtNetPoint
                                }
                            }, () => {
                                this.updateCurrentBalanceInRedux(res.data.dec_cardpointbalance)
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteRentAtHomeItem. ' + error)
                    })
            })
        }
        return true
    }

    WhatsApp = () => {
        const text1 = "Dear", text2 = ",", text3 = "\nThank you for using our Rent Service.", text4 = "\nAvailable Subscriptions Points are ", text5 = "\n\nThanks and Regards", text6 = "", text7 = ",", text8 = "\nTED", text9 = "\n(Toddlers Entertainment Den)";
        const url = text1.concat(" ", this.props.memberInfo.KidName, " ", text2, " ", text3, " ", "", text4, "", this.props.memberInfo.CurrentPoints, "", text5, "", text6, "", text7, "", text8, "", text9);
        window.open("https://api.whatsapp.com/send?phone=+91" + this.props.memberInfo.MobileNo + "&text=" + encodeURI(url))
    }

    newRentAtHome = () => {
        this.setState({
            rows: [],
            rowsCount: 0,
            formData: {
                ...this.state.formData,
                dtpRentDate: get_YMD_from_SYS(new Date()),
                txtChallanNo: '',
                txtNetPoint: '',
                txtTotalRentProductCount: '',
                // MemberID: this.props.memberInfo.MemberID,
                // txtCardNo: this.props.memberInfo.CardNo,
                // txtKidName: this.props.memberInfo.KidName,
                // txtGuardianName: this.props.memberInfo.GuardianName,
                // txtAvailableCardPoints: this.props.memberInfo.CurrentPoints,
            },
        }, () => {
            this.navigateTo('RentAtHome')
            document.getElementById('dtpRentDate').focus()
        })
    }

    updateCurrentBalanceInRedux = (CurrentPoints) => {
        this.props.setCurrentPoints({ CurrentPoints })
    }
    //#endregion Function

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Grid container direction="row" justifyContent="flext-start" alignItems="center" className="title" spacing={2} marginLeft={0}>
                    <Grid item xs={3} style={{ marginLeft: '50px' }}>
                        <Button
                            variant="outlined"
                            style={{ backgroundColor: '#d6ddf0', borderRadius: '10px' }}
                            startIcon={<Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />}
                            onClick={() => { this.navigateTo('MemberDashboard') }}>Member Dashboard
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <center><label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Rent</b></label></center>
                    </Grid>
                </Grid>

                <Grid container direction="row" spacing={2} marginTop={0} marginLeft={0} style={{ alignItems: 'center' }}>
                    <Grid item xs={4} md={2}>
                        <img src={this.props.memberInfo.Photo} style={{ width: '10vw', height: 'auto' }} />
                    </Grid>
                    <Grid item xs={8} md={10}>
                        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtChallanNo'
                                    label='Challan No.*'
                                    disabled={true}
                                    value={this.state.formData.txtChallanNo}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    // width={this.state.cWidth}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item>
                                <CtDtp
                                    id='dtpRentDate'
                                    label='Rent Date*'
                                    width='130'
                                    handleOnChange={this.handleOnChangeTrim}
                                    defaultValue={this.state.formData.dtpRentDate}
                                    value={this.state.formData.dtpRentDate}
                                    onKeyDown={this.props.onKeyDown}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxtAdornNum
                                    id='txtCardNo'
                                    label='Member ID*'
                                    adornment='TED'
                                    //value='123456'
                                    handleOnChange={this.handleOnChange}
                                    value={this.props.memberInfo.CardNo}
                                    readOnly={true}
                                    width='120'
                                    maxLength={6}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtKidName'
                                    label='Kid Name*'
                                    disabled={true}
                                    maxLength={100}
                                    handleOnChange={this.handleOnChange}
                                    value={this.props.memberInfo.KidName}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtGuardianName'
                                    label='Guardian Name*'
                                    disabled={true}
                                    maxLength={100}
                                    handleOnChange={this.handleOnChange}
                                    value={this.props.memberInfo.GuardianName}
                                />
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtTxt
                                        id='txtAvailableCardPoints'
                                        label='Available Card Points*'
                                        disabled={true}
                                        maxLength={10}
                                        handleOnChange={this.handleOnChange}
                                        value={this.props.memberInfo.CurrentPoints}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalRentProductCount'
                                        label='Total Rent Product Count*'
                                        disabled={true}
                                        maxLength={10}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalRentProductCount}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtNetPoint'
                                        label='Net Point*'
                                        disabled={true}
                                        maxLength={10}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtNetPoint}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='List' onClick={() => { this.navigateTo('RentAndReturnList') }} />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        startIcon={<WhatsAppIcon />}
                                        onClick={this.WhatsApp}>WhatsApp
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center" spacing={3} marginTop={0} style={{ paddingRight: '190px', display: 'none' }} >
                            <Grid item>
                                <CtBtn label='Save' onClick={this.saveRentAtHome} />
                            </Grid>
                            <Grid item style={{ display: 'none' }}>
                                <CtBtn label='New' onClick={this.newRentAtHome} />
                            </Grid>
                            {localStorage.getItem('De') == 1 ?
                                <Grid item style={{ display: 'none' }}>
                                    <CtBtn label='Delete' onClick={this.onDeleteClick} />
                                </Grid>
                                : ""}
                        </Grid>
                    </Grid>
                </Grid><br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={() => { this.onNewEntryClick() }}
                    toolTipNewEntry='New Item Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HOCVerifyIsUser(HOCMemberInfo(HoCtToastContainer(withRouter(RentAtHome)))))