import React, { Component } from 'react'
import { withRouter } from './HOC/withRouter'
import { Grid, Box } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import { Edit, Delete } from '@mui/icons-material';
import CtCmb from './CustomTool/CtCmb'
import CtBtn from './CustomTool/CtBtn'
import CtTxtNum from './CustomTool/CtTxtNum'
import TableList from './CustomTool/TableList'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import { fetchSubscriptionPlan } from './API/API'
import { fetchTaxList } from './API/API'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'

export class Subscription extends Component {

  state = {
    cWidth: 290,
    Tax: [],
    columns: [
      { id: 'Action', label: 'Search', name: 'Search' },
      { id: 'vac_planname', label: 'Plan Name' },
      { id: 'dec_year', label: 'Year' },
      { id: 'dec_month', label: 'Month' },
      { id: 'dec_days', label: 'Days' },
      { id: 'dec_planamount', label: 'Subscription Amount' },
      { id: 'vac_taxname', label: 'Tax %' },
      { id: 'dec_planamountwithgst', label: 'Subscription Amount With GST' },
      { id: 'dec_cardrechargepoint', label: 'Card Recharge Points' },
    ],
    row: [],
    actionList: [
      { name: 'Edit', icon: <Edit />, link: 'Subscription:', actionType: 'AlertResponsive' },
      { name: 'Delete', icon: <Delete />, link: 'Subscription:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
    ],

    keyColumn: [
      'vac_planname', 'dec_year', 'dec_month', 'dec_days', 'dec_planamount', 'vac_taxname', 'dec_planamountwithgst', 'dec_cardrechargepoint'
    ],
    searchData: {
      PlanName: '',
      Year: '',
      Month: '',
      Days: '',
      SubscriptionAmount: '',
      Tax: '',
      SubscriptionAmountGst: '',
      CardRechargePoints: '',
    },
    searchOpen: false,
    searchRows: [],
    searchRowsCount: 0,
    searchColumns: [],
    rowsCount: 0,
    rowsPerPage: 5,
    formData: {
      txtPlanName: '',
      txtYear: '',
      txtMonth: '',
      txtDays: '',
      txtSubscriptionAmount: '',
      txtSubscriptionAmountGst: '',
      cmbTax: '',
      txtTaxable: '',
      txtCardRechargePoints: '',
    },
  }

  //#region component
  componentDidMount() {
    document.title = 'TED : Subscription'
    this.props.setActiveMenu("Subscription")
    document.getElementById('txtPlanName').focus()
    // this.setSearchColumns()
    this.getInfo()
    this.getTaxList()
  }

  componentWillMount() {
    this.props.verifyUserID(localStorage.getItem('UI'))
  }
  //#endregion component

  //#region handle
  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage })
  }

  handleARDonActionClick = (values) => {
    console.log('values', values)
    const txtPlanName = values[0].value,
      txtYear = values[1].value,
      txtMonth = values[2].value,
      txtDays = values[3].value,
      txtSubscriptionAmount = values[4].value,
      cmbTax = values[5].value,
      txtSubscriptionAmountGst = values[6].value,
      txtCardRechargePoints = values[7].value,
      actionName = values[0].actionName

    if (actionName === "Edit") {
      this.setState({
        formData: {
          ...this.state.formData, txtPlanName, txtYear, txtMonth, txtDays, txtSubscriptionAmount, cmbTax, txtSubscriptionAmountGst, txtCardRechargePoints
        }
      })
    }
    else if (actionName === "Delete") {
      this.setState({ ARD: null }, () => {
        let ARD = <AlertResponsiveDialog
          labelAgree='Delete'
          labelDisagree='Cancel'
          alertTitle={'Do you want to Delete  : ' + txtPlanName + ' ?'}
          alertMessage=''
          handleOnClickYes={() => {
            return this.DeleteSubscriptionPlan(txtPlanName)
          }}
          defaultOpenDialog={true}
          onYesClickCloseIfExeSuccessfully={true}
        />

        this.setState({ ARD })
      })
    }

    else {
      let rows = this.state.rows.filter((row) => {
        if (row.PlanName !== txtPlanName && row.Year !== txtYear && row.Month !== txtMonth && row.Days !== txtDays &&
          row.txtSubscriptionAmount !== txtSubscriptionAmount && row.Tax !== cmbTax &&
          row.txtSubscriptionAmountGst !== txtSubscriptionAmountGst &&
          row.CardRechargePoints !== txtCardRechargePoints)
          return row
      })
      const rowsCount = rows.length
      this.setState({ rows, rowsCount })
    }
  }

  handleOnChange = (e) => {
    const ctrlname = e.target.name, ctrlValue = e.target.value
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value }
    }, () => {
      if (ctrlname === 'cmbTax') {
        if (Number(this.state.formData.txtSubscriptionAmount) > 0) {
          this.calculateSubscriptionAmountGst()
        } else if (Number(this.state.formData.txtSubscriptionAmountGst) > 0) {
          this.calculateSubscriptionAmount()
        }
      } else if (ctrlname === 'txtSubscriptionAmount' && Number(ctrlValue) > 0) {
        this.calculateSubscriptionAmountGst()
      } else if (ctrlname === 'txtSubscriptionAmountGst' && Number(ctrlValue) > 0) {
        this.calculateSubscriptionAmount()
      }
    })
  }

  //#endregion handle

  //#region function
  getTaxList = () => {
    let dataLoadStatus = false
    this.setState({ dataLoadStatus }, () => {
      const reqData = {
        'Op': 'TaxList',
      }
      fetchTaxList(reqData)
        .then(res => {
          dataLoadStatus = true
          let Tax = res.data.TaxList
          this.setState({
            dataLoadStatus, Tax
          })
        })
        .catch(error => {
          console.log('Unknown error occurred in getTaxList.', error)
        })
    })
  }

  getInfo = () => {
    let dataLoadStatus = false
    this.setState({ dataLoadStatus }, () => {
      const reqData = {
        'Op': 'SubscriptionPlanList',
      }
      fetchSubscriptionPlan(reqData)
        .then(res => {
          dataLoadStatus = true
          let rows = res.data.SubscriptionPlanList,
            rowsCount = rows.length
          this.setState({
            dataLoadStatus,
            rows, rowsCount
          })
        })
        .catch(error => {
          console.log('Unknown error occurred in onFormLoad.', error)
        })
    })
  }

  validateSubscriptionPlan = () => {
    let result = false
    console.log('tax : ', this.state.formData.cmbTax)
    if (this.state.formData.txtPlanName.length <= 0) {
      this.props.toastErrorMsg('Enter Plan Name', 'txtPlanName')
    } else if (
      Number(this.state.formData.txtYear) === 0 &&
      Number(this.state.formData.txtMonth) === 0 &&
      Number(this.state.formData.txtDays) === 0) {
      this.props.toastErrorMsg('Enter at least one Year, Month, or Day.', 'txtYear')
    }
    else if (Number(this.state.formData.txtSubscriptionAmount).length <= 0) {
      this.props.toastErrorMsg('Enter Subscription Amount', 'txtSubscriptionAmount')
    }
    else if (this.state.formData.cmbTax.trim().length <= 0) {
      this.props.toastErrorMsg('Enter Tax', 'cmbTax')
    }
    else if (Number(this.state.formData.txtSubscriptionAmountGst).length <= 0) {
      this.props.toastErrorMsg('Enter Subscription Amount With GST', 'txtSubscriptionAmountGst')
    }
    else if (Number(this.state.formData.txtCardRechargePoints).length <= 0) {
      this.props.toastErrorMsg('Enter Card Recharge Points', 'txtCardRechargePoints')
    } else {
      result = true
    }
    return result
  }

  clearInfo = (DefaultActive = false) => {
    const txtPlanName = '', txtYear = '', txtMonth = '', txtDays = '', txtSubscriptionAmount = '', cmbTax = '',
      txtTaxable = '', txtSubscriptionAmountGst = '', txtCardRechargePoints = ''
    this.setState({
      formData: {
        ...this.state.formData, txtPlanName, txtYear, txtMonth, txtDays, txtSubscriptionAmount, cmbTax, txtSubscriptionAmountGst, txtTaxable, txtCardRechargePoints
      }
    }, () => {
      document.getElementById('txtPlanName').focus()
    })
  }

  SaveSubscriptionPlan = () => {
    if (this.validateSubscriptionPlan()) {
      const dataLoadStatus = false
      this.props.notifyProcessing()
      this.setState({ dataLoadStatus }, () => {
        const reqData = {
          'Op': 'SaveSubscriptionPlan',
          'vac_planname': this.state.formData.txtPlanName,
          'dec_year': Number(this.state.formData.txtYear),
          'dec_month': Number(this.state.formData.txtMonth),
          'dec_days': Number(this.state.formData.txtDays),
          'dec_planamount': Number(this.state.formData.txtSubscriptionAmount),
          'vac_taxname': this.state.formData.cmbTax,
          'dec_planamountwithgst': Number(this.state.formData.txtSubscriptionAmountGst),
          'dec_cardrechargepoint': Number(this.state.formData.txtCardRechargePoints),
        }
        fetchSubscriptionPlan(reqData)
          .then(res => {
            console.log('res.data', res.data)
            this.props.updateProcessing(res.data.msgType, res.data.message)
            if (res.data.msgType === 'success') {
              this.getInfo()
              this.clearInfo(true)
            }
          })
          .catch(error => {
            console.log('Unknown error occurred in SaveSubscriptionplan.', error)
          })
      })

    }

  }

  DeleteSubscriptionPlan = (txtPlanName) => {
    this.props.notifyProcessing()
    this.setState({ dataLoadStatus: false }, () => {
      const reqData = {
        'Op': 'DeleteSubscriptionPlan',
        'vac_planname': txtPlanName
      }
      fetchSubscriptionPlan(reqData)
        .then(res => {
          this.props.updateProcessing(res.data.msgType, res.data.message)
          if (res.data.msgType === 'success') {
            this.setState({ ARD: null }, () => { this.getInfo() })
          }
        })
        .catch(error => {
          this.props.updateProcessing('error', 'Unknown error occurred in delete DeleteSubscriptionPlan. ' + error)
        })
    })
    return false
  }

  getTaxName = (taxValue) => {
    return Number(taxValue && taxValue.length > 0 ? taxValue.toString().replace("%", "").replace("Nil", "") : 0)
  }

  calculateSubscriptionAmount = () => {
    const txtSubscriptionAmount = Math.round(((this.state.formData.txtSubscriptionAmountGst / (100 + this.getTaxName(this.state.formData.cmbTax))) * 100), 2)
    this.setState({ formData: { ...this.state.formData, txtSubscriptionAmount } })

  }

  calculateSubscriptionAmountGst = () => {
    let tax = Number(this.getTaxName(this.state.formData.cmbTax))
    let taxamt = (Number(this.state.formData.txtSubscriptionAmount) * tax) / 100
    let amt = Number(Number(this.state.formData.txtSubscriptionAmount) + taxamt)
    const txtSubscriptionAmountGst = Math.round(amt)
    this.setState({ formData: { ...this.state.formData, txtSubscriptionAmountGst } })
  }
  //#endregion function

  render() {

    let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
    let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

    return (
      <div>
        <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
          <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Subscription Plan</b></label>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0} marginTop={0}>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
              <Grid item>
                <CtTxt
                  id='txtPlanName'
                  label='Plan Name*'
                  value={this.state.formData.txtPlanName}
                  handleOnChange={this.handleOnChange}
                  maxLength={100}
                />
              </Grid>
              <Grid item>
                <CtTxtNum
                  id='txtYear'
                  label='Year'
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtYear}
                  maxLength={10}
                />
              </Grid>
              <Grid item>
                <CtTxtNum
                  id='txtMonth'
                  label='Month'
                  value={this.state.formData.txtMonth}
                  handleOnChange={this.handleOnChange}
                  maxLength={10}
                />
              </Grid>
              <Grid item>
                <CtTxtNum
                  id='txtDays'
                  label='Days'
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtDays}
                  maxLength={10}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
              <Grid item>
                <CtTxt
                  id='txtSubscriptionAmount'
                  label='Subscription Amount*'
                  handleOnChange={this.handleOnChange}
                  width={170}
                  value={this.state.formData.txtSubscriptionAmount}
                  maxLength={20}
                />
              </Grid>

              <Grid item>
                <CtCmb
                  id='cmbTax'
                  label='Tax %*'
                  items={this.state.Tax}
                  value={this.state.formData.cmbTax}
                  // disabled={this.state.disabledProfileData.AccountTypeDS}
                  handleOnChange={this.handleOnChange}
                  width='100'
                  maxLength={50}
                  colID='TaxName'
                />
              </Grid>
              <Grid item>
                <CtTxt
                  id='txtSubscriptionAmountGst'
                  label='Subscription Amount With GST*'
                  handleOnChange={this.handleOnChange}
                  width={this.state.cWidth}
                  value={this.state.formData.txtSubscriptionAmountGst}
                  maxLength={20}
                />
              </Grid>
              <Grid item style={{ display: 'none' }}>
                <CtTxt
                  id='txtTaxable'
                  label='Taxable'
                  disabled={true}
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtTaxable}
                  maxLength={10}
                />
              </Grid>
              <Grid item>
                <CtTxt
                  id='txtCardRechargePoints'
                  label='Card Recharge Points*'
                  handleOnChange={this.handleOnChange}
                  value={this.state.formData.txtCardRechargePoints}
                  maxLength={20}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
              <Grid item>
                <CtBtn label='Save' onClick={() => { this.SaveSubscriptionPlan() }} />
              </Grid>
              <Grid item>
                <CtBtn label='New' onClick={this.clearInfo} />
              </Grid>
              <Grid item style={{ display: 'none' }}
              >
                <CtBtn label='Delete' onClick={this.onDeleteClick} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <TableList
          columns={this.state.columns}
          counter={this.state.counter}
          rows={rows}
          rowsCount={rowsCount}
          rowsPerPage={this.state.rowsPerPage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onActionClick={this.handleARDonActionClick}
          actionList={this.state.actionList}
          keyColumn={this.state.keyColumn}
          // handleOnSearchChange={this.handleOnSearchChange}
          searchColumns={this.state.searchColumns}
          searchData={this.state.searchData}
          provideSearch={true}
          onNewEntryClick={this.onNewEntryClick}
        // toolTipNewEntry='New Product Entry'
        />
        <Grid item>
          <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
            {this.state.ARD}
          </Box>
        </Grid>

      </div>

    )
  }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HoCtToastContainer(HOCVerifyIsUser(withRouter(Subscription))))