import React, { Component, createRef } from 'react'
import MemberDashboardIcon from './images/memberdashboard.png'
import { Grid, Avatar, Button, Box } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import { withRouter } from './HOC/withRouter'
import CtBtn from './CustomTool/CtBtn'
import CtDtp from './CustomTool/CtDtp'
import defaultImg from './images/default.png'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import TableList from './CustomTool/TableList'
import { Edit, Delete, WidthWideOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import CtDtpTime from './CustomTool/CtDtpTime'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { connect } from 'react-redux'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { fetchRentAtStore } from './API/API'
import Progress from './CustomTool/Progress'
import { get_30DaysBack_FromCurrentDate, get_YMD_from_SYS, get_YMD_from_DMY, formatDateTime } from './SystemUtility/SystemUtility'
import HOCMemberInfo from './HOC/HOCMemberInfo'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

class RentAtStore extends Component {

    state = {
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_rentstoresrno', label: 'Challan No' },
            { id: 'dat_rentdate_dmY', label: 'Play Date' },
            { id: 'vac_cardno', label: 'Member ID' },
            { id: 'dat_rentstartdatetime_dmY', label: 'Play Start Date Time' },
            { id: 'dec_renthours', label: 'Hours' },
            { id: 'dec_rentmintues', label: 'Minutes' },
            { id: 'dat_rentendatetime_dmY', label: 'Play End Date Time' },
            { id: 'dec_totalpoint', label: 'Total Points' },
            { id: 'dat_rentdate', label: '', hide: true },
            { id: 'dat_rentstartdatetime', label: '', hide: true },
            { id: 'dat_rentendatetime', label: '', hide: true },
            { id: 'dec_points', label: '', hide: true },
            { id: 'dec_cash', label: '', hide: true },
            { id: 'dec_other', label: '', hide: true },
            { id: 'bint_memberid', label: 'bint_memberid', hide: true },
            { id: 'vac_mobilenoWA', label: '', hide: true },
            { id: 'dec_cardpointbalance', label: '', hide: true },
            { id: 'vac_kidname', label: '', hide: true },
            { id: 'vac_guardianname', label: '', hide: true },


        ],
        rows: [],
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'RentAtStore:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'RentAtStore:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
            { name: 'WhatsApp', icon: <WhatsAppIcon />, link: 'RentAtStore:', actionType: 'AlertResponsive' },
        ],
        keyColumn: [
            'bint_rentstoresrno', 'dat_rentdate', 'vac_cardno',
            'dat_rentstartdatetime',
            'dec_renthours', 'dec_rentmintues',
            'dat_rentendatetime', 'dec_totalpoint', , 'dec_points', 'dec_cash', 'dec_other', 'bint_memberid', 'vac_mobilenoWA', 'dec_cardpointbalance', 'vac_kidname', 'vac_guardianname', 'dat_rentstartdatetime_dmY', 'dat_rentendatetime_dmY'
        ],
        searchData: {
            bint_rentstoresrno: '',
            dat_rentdate_dmY: '',
            vac_cardno: '',
            dat_rentstartdatetime_dmY: '',
            dec_renthours: '',
            dec_rentmintues: '',
            dat_rentendatetime_dmY: '',
            dec_totalpoint: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,

        formData: {
            txtChallanNo: '',
            dtpRentDate: get_YMD_from_SYS(new Date()),
            txtCardNo: this.props.memberInfo.CardNo,
            txtKidName: this.props.memberInfo.KidName,
            txtGuardianName: this.props.memberInfo.GuardianName,
            txtAvailableCardPoints: this.props.memberInfo.CurrentPoints,
            dtpRentStartDateTime: dayjs(),
            txtHours: 1,
            txtMinutes: '',
            dtpRentEndDateTime: '',
            txtTotalPoint: '',
            txtPoints: '',
            txtCash: '',
            txtOther: '',
            dtpFromDate: '',
            dtpToDate: '',
        }
    }

    //#region handle

    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rowsCount > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.bint_rentstoresrno.length === 0 || Number(row['bint_rentstoresrno']) === Number(this.state.searchData.bint_rentstoresrno)) &&
                        (this.state.searchData.dat_rentdate_dmY.length === 0 || row['dat_rentdate_dmY'].toString().includes(this.state.searchData.dat_rentdate_dmY)) &&
                        (this.state.searchData.vac_cardno.length === 0 || row['vac_cardno'].toString().includes(this.state.searchData.vac_cardno)) &&
                        (this.state.searchData.dat_rentstartdatetime_dmY.length === 0 || row['dat_rentstartdatetime_dmY'].toString().includes(this.state.searchData.dat_rentstartdatetime_dmY)) &&
                        (this.state.searchData.dec_renthours.length === 0 || Number(row['dec_renthours']) === Number(this.state.searchData.dec_renthours)) &&
                        (this.state.searchData.dec_rentmintues.length === 0 || Number(row['dec_rentmintues']) === Number(this.state.searchData.dec_rentmintues)) &&
                        (this.state.searchData.dat_rentendatetime_dmY.length === 0 || row['dat_rentendatetime_dmY'].toString().includes(this.state.searchData.dat_rentendatetime_dmY)) &&
                        (this.state.searchData.dec_totalpoint.length === 0 || Number(row['dec_totalpoint']) === Number(this.state.searchData.dec_totalpoint))
                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        const ctrlName = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            if (ctrlName === 'txtHours' || ctrlName === 'txtMinutes' || ctrlName === 'dtpRentStartDateTime' || ctrlName === 'txtTotalPoint' || ctrlName === 'txtPoints' || ctrlName === 'txtCash' || ctrlName === 'txtAvailableCardPoints') {
                this.setRentEndDateTime(this.setPointsCashOther)
            }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        // console.log('values', values)
        // console.log('this.state.formData.txtChallanNo', this.state.formData.txtChallanNo)
        const txtChallanNo = values[0].value,
            dtpRentDate = values[1].value,
            txtCardNo = values[2].value,
            dtpRentStartDateTime = values[3].value,
            txtHours = values[4].value,
            txtMinutes = values[5].value,
            dtpRentEndDateTime = values[6].value,
            txtTotalPoint = values[7].value,
            txtPoints = values[8].value,
            txtCash = values[9].value,
            txtOther = values[10].value,
            bint_memberid = values[11].value,
            txtMobileWA = values[12].value,
            txtAvailableCardPoints = values[13].value,
            txtKidName = values[14].value,
            txtGuardianName = values[15].value,
            dat_rentstartdatetime_dmY = values[16].value,
            dat_rentendatetime_dmY = values[17].value,
            actionName = values[0].actionName
        if (actionName === "Edit") {
            this.setState({
                formData: {
                    ...this.state.formData, txtChallanNo, dtpRentDate,
                    txtCardNo,
                    dtpRentStartDateTime, txtHours, txtMinutes, dtpRentEndDateTime, txtTotalPoint, txtPoints, txtCash, txtOther, txtMobileWA, txtAvailableCardPoints, txtKidName, txtGuardianName, dat_rentstartdatetime_dmY, dat_rentendatetime_dmY
                }
            })
        } else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Challan No : ' + txtChallanNo + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteRentAtStore(txtChallanNo, dtpRentDate, bint_memberid)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />

                this.setState({ ARD })
            })
        }
        else {
            let rows = this.state.rows.filter((row) => {
                if (row.ChallanNo !== txtChallanNo && row.RentDate !== dtpRentDate && row.CardNo !== txtCardNo &&
                    row.RentStartDateTime !== dtpRentStartDateTime && row.Hours !== txtHours && row.Minutes !== txtMinutes
                    && row.RentEndDateTime !== dtpRentEndDateTime && row.TotalPoint !== txtTotalPoint && row.Points !== txtPoints && row.Cash !== txtCash && row.Other !== txtOther)
                    return row
            })
            const rowsCount = rows.length
            this.setState({ rows, rowsCount })
        } if (actionName === "WhatsApp") {
            const text1 = "Dear", text2 = ",", text3 = "\nFor Playing of ", text4 = "from ", text5 = " to ", text6 = ", ", text7 = " has been deducted.", text8 = "\nAvailable Subscription points are ", text9 = ".", text10 = "\n\nThanks and Regards", text11 = "", text12 = ",", text13 = "\nTED", text14 = "\n(Toddlers Entertainment Den)";
            const url = text1.concat(" ", txtKidName, "", text2, "", text3, "",txtKidName," ", text4, "", dat_rentstartdatetime_dmY, "", text5, "", dat_rentendatetime_dmY, "", text6, "", txtTotalPoint, "", text7, "", text8, "", txtAvailableCardPoints, "", text9, "", text10, "", text11, "", text12, "", text13, "", text14, "");
            //console.log(url);
            //alert("https://api.whatsapp.com/send?phone=+91" + txtMobileWA + "&text=" + url)
            window.open("https://api.whatsapp.com/send?phone=+91" + txtMobileWA + "&text=" + encodeURI(url))

        }
    }

    //#endregion handle

    //#region component
    componentDidMount() {
        this.setSearchColumns()
        document.title = 'TED : Play'
        this.props.setActiveMenu("RentAtStore")
        this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo, () => {
            if (this.props.memberInfo.MemberID) {
                this.onFormLoad()
            }
        })
        document.getElementById('dtpRentDate').focus()
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }

    //#endregion component

    //#region Function
    getTotalPoint = (txtHours, txtMinutes) => {
        let txtTotalPoint = 250
        txtMinutes = Number(txtMinutes) + Number(txtHours) * 60
        const RemainMinutes = txtMinutes - 60
        if (RemainMinutes > 0) {
            const Divideby30 = RemainMinutes / 30
            let result = 0
            if ((Divideby30).toString().includes('.')) {
                const arr = Divideby30.toString().split(".")
                result = Number(arr[0]) + 1
            }
            else {
                result = Divideby30
            }
            if (result > 0) {
                txtTotalPoint += Number(result) * 100
            }
        }
        return txtTotalPoint
    }

    setPointsCashOther = () => {
        let txtTotalPoint = this.getTotalPoint(this.state.formData.txtHours, this.state.formData.txtMinutes)
        let txtPoints = Number(this.props.memberInfo.CurrentPoints) >= Number(txtTotalPoint) ? txtTotalPoint : ''
        let txtCash = Number(this.props.memberInfo.CurrentPoints) < Number(txtTotalPoint) ? txtTotalPoint : ''
        this.setState({ formData: { ...this.state.formData, txtTotalPoint, txtPoints, txtCash } })
    }

    onFormLoad = () => {
        let dtpFromDate = get_30DaysBack_FromCurrentDate(),
            dtpToDate = get_YMD_from_SYS(new Date())
        this.setState({
            formData: {
                ...this.state.formData,
                dtpFromDate,
                dtpToDate,
                txtChallanNo: '',
                dtpRentDate: get_YMD_from_SYS(new Date()),
                txtCardNo: this.props.memberInfo.CardNo,
                txtKidName: this.props.memberInfo.KidName,
                txtGuardianName: this.props.memberInfo.GuardianName,
                txtAvailableCardPoints: this.props.memberInfo.CurrentPoints,
                dtpRentStartDateTime: dayjs(),
                txtHours: 1,
                txtMinutes: '',
                dtpRentEndDateTime: '',
                txtTotalPoint: '',
                txtPoints: '',
                txtCash: '',
                txtOther: '',
            },
        }, () => {
            this.setPointsCashOther()
            this.loadPlayList()
        })
    }

    setRentEndDateTime = (nextFunctionCall) => {
        const dtpRentEndDateTime = window.addPeriod(this.state.formData.dtpRentStartDateTime, 0, Number(this.state.formData.txtHours), Number(this.state.formData.txtMinutes))
        this.setState({ formData: { ...this.state.formData, dtpRentEndDateTime } }, () => {
            if (nextFunctionCall) {
                nextFunctionCall()
            }
        })
    }

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    loadPlayList = () => {
        let dataLoadStatus = false
        this.setState({
            dataLoadStatus,
        }, () => {
            let FromDate = this.state.formData.dtpFromDate,
                ToDate = this.state.formData.dtpToDate
            const reqData = {
                'Op': 'RentAtStoreEntryList',
                bint_memberid: this.props.memberInfo.MemberID,
                FromDate,
                ToDate,
            }
            fetchRentAtStore(reqData)
                .then(res => {
                    const rows = res.data.RentAtStoreEntryList
                    const rowsCount = rows.length
                    this.setRentEndDateTime(() => {
                        let txtTotalPoint = this.getTotalPoint(this.state.formData.txtHours, this.state.formData.txtMinutes)
                        // console.log("this.state.formData", this.state.formData);
                        this.setState({
                            rows,
                            rowsCount, dataLoadStatus: true,
                            formData: {
                                ...this.state.formData,
                                txtTotalPoint
                            }
                        }, () => {
                            // console.log("this.state.formData", this.state.formData);
                        })
                    })
                    // this.setState({})
                })
                .catch(error => {
                    console.log('Unknown error occurred in loadPlayList.', error)
                })
        })
    }

    newRentAtStore = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                dat_rentdate: get_YMD_from_SYS(new Date()),
                vac_cardno: this.props.memberInfo.CardNo,
                bint_rentstoresrno: '',
                txtKidName: this.props.memberInfo.KidName,
                txtGuardianName: this.props.memberInfo.GuardianName,
                txtAvailableCardPoints: this.props.memberInfo.CurrentPoints,
                //imgKidPhoto: this.props.memberInfo.Photo,
                bint_memberid: this.props.memberInfo.MemberID,
                txtHours: 1,
                txtMinutes: '',
                txtTotalPoint: '',
                txtPoints: '',
                txtCash: '',
                txtOther: '',
                dtpRentEndDateTime: '',
                dtpRentStartDateTime: dayjs(),
            },
        }, () => {
            this.setRentEndDateTime(this.setPointsCashOther)
            document.getElementById('dtpRentDate').focus()
        })
    }

    saveRentAtStore = () => {
        if (this.validateRentAtStore()) {
            // console.log('this.state.formData.dtpRentEndDateTime', this.state.formData.dtpRentEndDateTime)
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'SaveRentAtStoreEntry',
                    bint_rentstoresrno: this.state.formData.txtChallanNo,
                    dat_rentdate: this.state.formData.dtpRentDate,
                    vac_cardno: this.props.memberInfo.CardNo,
                    bint_memberid: this.props.memberInfo.MemberID,
                    dec_renthours: this.state.formData.txtHours,
                    dec_rentmintues: this.state.formData.txtMinutes,
                    dat_rentendatetime: formatDateTime(this.state.formData.dtpRentEndDateTime),
                    dat_rentstartdatetime: formatDateTime(this.state.formData.dtpRentStartDateTime),
                    dec_totalpoint: this.state.formData.txtTotalPoint,
                    dec_points: this.state.formData.txtPoints,
                    dec_cash: this.state.formData.txtCash,
                    dec_other: this.state.formData.txtOther
                }
                fetchRentAtStore(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.updateCurrentBalanceInRedux(res.data.dec_cardpointbalance)
                                this.newRentAtStore()
                                this.loadPlayList()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in save Rent At store. ' + error)
                    })
            })
        }

    }

    updateCurrentBalanceInRedux = (CurrentPoints) => {
        this.props.setCurrentPoints({ CurrentPoints })
    }

    validateRentAtStore = () => {
        let validateRentAtStore = false
        if (this.state.formData.txtHours.length <= 0) {
            this.props.toastErrorMsg('Enter Hours', 'txtHours')
        } else if (this.state.formData.dtpRentStartDateTime.length <= 0) {
            this.props.toastErrorMsg('Enter Play Start Date Time', 'dtpRentStartDateTime')
        } else if (Number(this.state.formData.txtTotalPoint) !== Number(this.state.formData.txtPoints) + Number(this.state.formData.txtCash) + Number(this.state.formData.txtOther)) {
            this.props.toastErrorMsg('Sum of Points, Cash & Other must be equal to Total Points', 'saveRentAtStore')
        } else {
            validateRentAtStore = true
        }
        return validateRentAtStore
    }

    deleteRentAtStore = (txtChallanNo, dtpRentDate, bint_memberid) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            let reqData = {
                Op: 'DeleteRentAtStoreEntry',
                bint_rentstoresrno: txtChallanNo,
                dat_rentdate: dtpRentDate,
                bint_memberid
            }
            fetchRentAtStore(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.setState({ ARD: null }, () => { this.loadPlayList() })
                        this.updateCurrentBalanceInRedux(res.data.dec_cardpointbalance)
                    }
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in delete RentAtStore. ' + error)
                })
        })

        return true
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push({
            cType: 'CtTxt',
            id: 'bint_rentstoresrno',
            label: 'Challan No',
            value: this.state.searchData.bint_rentstoresrno,
            handleOnChange: this.handleOnSearchInputChange,
            width: '80',
            maxLength: '5',

        }, {
            cType: 'CtTxt',
            id: 'dat_rentdate_dmY',
            label: 'Play Date',
            value: this.state.searchData.dat_rentdate_dmY,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',

        }, {
            cType: 'CtTxt',
            id: 'vac_cardno',
            label: 'Card No',
            value: this.state.searchData.vac_cardno,
            handleOnChange: this.handleOnSearchInputChange,
            width: '80',
            maxLength: '6'
        }, {
            cType: 'CtTxt',
            id: 'dat_rentstartdatetime_dmY',
            label: 'Play Start Date Time',
            value: this.state.searchData.dat_rentstartdatetime_dmY,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'dec_renthours',
            label: 'Hours',
            // disabled:{true},
            value: this.state.searchData.dec_renthours,
            handleOnChange: this.handleOnSearchInputChange,
            width: '80',
            maxLength: '5'
        }, {
            cType: 'CtTxt',
            id: 'dec_rentmintues',
            label: 'Minutes',
            // disabled:{true},
            value: this.state.searchData.dec_rentmintues,
            handleOnChange: this.handleOnSearchInputChange,
            width: '80',
            maxLength: '5'
        }, {
            cType: 'CtTxt',
            id: 'dat_rentendatetime_dmY',
            label: 'Play End Date Time',
            value: this.state.searchData.dat_rentendatetime_dmY,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'dec_totalpoint',
            label: 'Total Point',
            value: this.state.searchData.dec_totalpoint,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '5'
        }, {
            cType: 'Lbl',
            id: 'dat_rentdate',
            label: 'Play Date',
        }, {
            cType: 'Lbl',
            id: 'dat_rentstartdatetime',
            label: 'Play Start Date',
        }, {
            cType: 'Lbl',
            id: 'dat_rentendatetime',
            label: 'Play End Date',
        }, {
            cType: 'Lbl',
            id: 'dec_points',
            label: 'Points',
        }, {
            cType: 'Lbl',
            id: 'dec_cash',
            label: 'Cash',
        }, {
            cType: 'Lbl',
            id: 'dec_other',
            label: 'Other',
        }, {
            cType: 'Lbl',
            id: 'bint_memberid',
            label: 'MemberID',
        })
        this.setState({ searchColumns })
    }

    //#endregion Function

    render() {
        //console.log('this.props.memberInfo', this.props.memberInfo)
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        // console.log('this.state.formData.txtPointss', this.state.formData.txtPoints)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="flext-start" alignItems="center" className="title" spacing={2} marginLeft={0}>
                    <Grid item xs={3} style={{ marginLeft: '50px' }}>
                        <Button
                            variant="outlined"
                            style={{ backgroundColor: '#d6ddf0', borderRadius: '10px' }}
                            startIcon={<Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />}
                            onClick={() => { this.navigateTo('MemberDashboard') }}>Member Dashboard
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <center><label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Play</b></label></center>
                    </Grid>
                </Grid>
                <form>
                    <Grid container direction="row" spacing={2} marginTop={0} marginLeft={0} style={{ alignItems: 'center' }}>
                        <Grid item xs={4} md={2}>
                            <img src={this.props.memberInfo.Photo} style={{ width: '10vw', height: 'auto' }} />
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtTxt
                                        id='txtChallanNo'
                                        label='Challan No.'
                                        disabled={true}
                                        width={90}
                                        maxLength={10}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtChallanNo}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtDtp
                                        id='dtpRentDate'
                                        label='Play Date *'
                                        width='130'
                                        handleOnChange={this.handleOnChangeTrim}
                                        defaultValue={this.state.formData.dtpRentDate}
                                        value={this.state.formData.dtpRentDate}
                                        onKeyDown={this.props.onKeyDown}
                                    // disabled={this.state.disabledProfileData.DOBDS}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxtAdornNum
                                        id='txtCardNo'
                                        label='Member ID'
                                        adornment='TED'
                                        readOnly={true}
                                        width='120'
                                        maxLength={6}
                                        handleOnChange={this.handleOnChange}
                                        value={this.props.memberInfo.CardNo}
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtKidName'
                                        label='Kid Name'
                                        disabled={true}
                                        maxLength={100}
                                        width={290}
                                        handleOnChange={this.handleOnChange}
                                        value={this.props.memberInfo.KidName}
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtGuardianName'
                                        label='Guardian Name'
                                        disabled={true}
                                        maxLength={100}
                                        width={295}
                                        handleOnChange={this.handleOnChange}
                                        value={this.props.memberInfo.GuardianName}
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtTxt
                                        id='txtAvailableCardPoints'
                                        label='Available Card Points'
                                        disabled={true}
                                        maxLength={100}
                                        handleOnChange={this.handleOnChange}
                                        value={this.props.memberInfo.CurrentPoints}
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtDtpTime
                                        id='dtpRentStartDateTime'
                                        label='Play Start Date Time'
                                        width='190'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.dtpRentStartDateTime}
                                        onKeyDown={this.props.onKeyDown}
                                        showToolbar={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtHours'
                                        label='Hours'
                                        maxLength={4}
                                        width='70'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtHours}
                                        onKeyDown={this.props.onKeyDown}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtMinutes'
                                        label='Minutes'
                                        maxLength={2}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtMinutes}
                                        width='70'
                                    />
                                </Grid>
                                <Grid item>
                                    <CtDtpTime
                                        id='dtpRentEndDateTime'
                                        label='Play End Date Time'
                                        width='190'
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.dtpRentEndDateTime}
                                        onKeyDown={this.props.onKeyDown}
                                        //showToolbar={true}
                                        readOnly={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtTotalPoint'
                                        label='Total Points'
                                        disabled={true}
                                        maxLength={100}
                                        handleOnChange={this.handleOnChange}
                                        value={this.state.formData.txtTotalPoint}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtTxt
                                        id='txtPoints'
                                        label='Points'
                                        //disabled={true}
                                        maxLength={100}
                                        value={this.state.formData.txtPoints}
                                        handleOnChange={this.handleOnChangeTrim}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtCash'
                                        label='Cash'
                                        //disabled={true}
                                        maxLength={100}
                                        value={this.state.formData.txtCash}
                                        handleOnChange={this.handleOnChangeTrim}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtOther'
                                        label='Other'
                                        //disabled={true}
                                        maxLength={100}
                                        value={this.state.formData.txtOther}
                                        handleOnChange={this.handleOnChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Save' onClick={() => { this.saveRentAtStore() }} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.newRentAtStore} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Challan Print' />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item>
                                <CtDtp
                                    id='dtpFromDate'
                                    label='From Date'
                                    width='150'
                                    handleOnChange={this.handleOnChangeTrim}
                                    defaultValue={this.state.formData.dtpFromDate}
                                    value={this.state.formData.dtpFromDate}
                                    onKeyDown={this.props.onKeyDown}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                            <Grid item>
                                <CtDtp
                                    id='dtpToDate'
                                    label='To Date'
                                    width='150'
                                    handleOnChange={this.handleOnChangeTrim}
                                    defaultValue={this.state.formData.dtpToDate}
                                    value={this.state.formData.dtpToDate}
                                    onKeyDown={this.props.onKeyDown}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                            <Grid item>
                                <CtBtn label='Load' onClick={this.loadPlayList} />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                // toolTipNewEntry='New Product Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HOCVerifyIsUser(HOCMemberInfo(HoCtToastContainer(withRouter(RentAtStore)))))
