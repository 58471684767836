import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import { fetchProductMaster } from './API/API'

class ProductInfo extends Component {

    state = {

    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Product Info'
        this.props.setActiveMenu("ProductInfo")
        console.log('this.props', this.props)
        //this.setSearchColumns()
        //this.getInfo()
    }
    //#endregion

    //#region handle

    handleOnChange = (e) => {
        const ctrlname = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }


    //#endregion function

    render() {
        const RentPerWeek = this.props.ProductInfo.dec_rentpointsperday * 7
        return (
            <div>
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Product Info</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item xs={12}>
                                Product Name: <b>{this.props.ProductInfo.vac_productname}</b>
                            </Grid>
                            <Grid item xs={12}>
                                Product Code: <b>{this.props.ProductInfo.vac_productcode}</b>
                            </Grid>
                            <Grid item xs={12}>
                                <b>{Number(this.props.ProductInfo.int_availforsales) === 1 ? 'Available' : 'Not Available'} for Sales</b>
                            </Grid>
                            <Grid item xs={12}>
                                <b>{Number(this.props.ProductInfo.int_availforhomerent) === 1 ? 'Available' : 'Not Available'} for Rent </b>
                            </Grid>
                            <Grid item xs={12}>
                                Current Stock: <b>{this.props.ProductInfo.dec_currentstock}</b>
                            </Grid>
                            <Grid item style={{ display: this.props.ProductInfo.vac_description.length > 0 ? 'block' : 'none' }} xs={12}>
                                Description: <b>{this.props.ProductInfo.vac_description}</b>
                            </Grid>
                            <Grid item xs={12}>
                                Rent Per Week: <b>{RentPerWeek}</b>
                            </Grid>
                            <Grid item xs={12}>
                                Sales MRP: <b>{this.props.ProductInfo.dec_mrp}</b>
                            </Grid>
                            <Grid item xs={12}>
                                TAX(%): <b>{this.props.ProductInfo.vac_taxname}</b>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div >
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(ProductInfo)))                                                                                                                                                                     