import React from 'react'
import Button from '@mui/material/Button';

const CtBtn = ({ label, disabled, onClick, width, variant }) => {

    // const useWidthStyle = width > 0 ? width + 'px' : 'auto'
    // const enableStyle = { backgroundColor: '#42AA46', color: '#ffffff', width: useWidthStyle }
    let buttonStyle = {}
    buttonStyle.width = width > 0 ? width + 'px' : 'auto'
    if ((disabled === undefined || disabled !== true) && (variant === undefined || variant === "contained")) {
        buttonStyle.backgroundColor = '#42AA46'
        buttonStyle.color = '#ffffff'
    }

    return (
        <Button
            variant={variant ? variant : "contained"}
            color='success'
            disabled={disabled}
            // style={disabled !== true ? enableStyle : { width: useWidthStyle }}
            style={buttonStyle}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

export default CtBtn