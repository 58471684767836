import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import CtDtp from './CustomTool/CtDtp'
import CtBtn from './CustomTool/CtBtn'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import CtTxt from './CustomTool/CtTxt'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { fetchInwardList, fetchProductMaster, apiURL } from './API/API'
import CtCmb from './CustomTool/CtCmb'
import CtMultiLineText from './CustomTool/CtMultiLineText'
import HOCProductInfo, { getProductInfo } from './HOC/HOCProductInfo'

class InwardItemEntry extends Component {

    state = {
        ProductNameList: [],
        ProductCategoryList: [],
        dataLoadStatus: false,
        formData: {
            EditInwardNo: '',
            EditInwardItemNo: '',
            txtProductCode: '',
            cmbProductCategory: '',
            cmbProductName: '',
            txtqty: '',
            txtnote: '',
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'InwardItemEntry:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'InwardItemEntry', actionType: 'AlertResponsive' },
        ],
        ARD: null,
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Inward item Entry'
        this.props.setActiveMenu("Inward item Entry")
        //this.setSearchColumns()
        //this.getInfo()
        this.onFormLoad()
    }
    //#endregion

    //#region handle

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            if (e.target.name === 'txtProductCode') {
                setTimeout(() => { this.props.getProductInfo(this.setDataLoadStatus, this.state.ProductNameList, this.setProductInfo, this.state.formData.txtProductCode) }, 500)
            } else if (
                (e.target.name === 'cmbProductCategory' || e.target.name === 'cmbProductName') &&
                this.state.formData.cmbProductCategory.trim().length > 0 &&
                this.state.formData.cmbProductName.trim().length > 0
            ) {
                this.props.getProductInfo(
                    this.setDataLoadStatus, this.state.ProductNameList, this.setProductInfo,
                    '', this.state.formData.cmbProductCategory, this.state.formData.cmbProductName
                )
            }
        })
    }

    //#region Function
    setProductInfo = (ProductInfo, dataLoadStatus) => {
        this.setState({
            dataLoadStatus,
            formData: {
                ...this.state.formData,
                txtProductCode: ProductInfo.vac_productcode ? ProductInfo.vac_productcode : '',
                cmbProductCategory: ProductInfo.vac_productcategory ? ProductInfo.vac_productcategory : '',
                cmbProductName: ProductInfo.vac_productname ? ProductInfo.vac_productname : '',
            }
        })
    }

    setDataLoadStatus = (dataLoadStatus, nextFunctionCall) => {
        this.setState({ dataLoadStatus }, () => {
            if (nextFunctionCall) {
                nextFunctionCall()
            }
        })
    }

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    ValidateInwardItemEntry = () => {
        let result = false
        if (this.state.formData.txtqty.length <= 0) {
            this.props.toastErrorMsg('Enter Quantity', 'txtqty')
        }
        else if (this.state.formData.txtProductCode.length <= 0) {
            this.props.toastErrorMsg('Enter Product Code', 'txtProductCode')
        } else if (this.state.formData.cmbProductCategory.length <= 0) {
            this.props.toastErrorMsg('Enter Product category', 'cmbProductCategory')
        } else if (this.state.formData.cmbProductName.length <= 0) {
            this.props.toastErrorMsg('Enter Product Name', 'cmbProductName')
        } /* else if (this.state.formData.txtnote.length <= 0) {
            this.props.toastErrorMsg('Enter Note', 'txtnote')
        }  */

        else {
            result = true
        }
        return result
    }

    SaveInwardItemEntry = (openQRCode = false) => {
        if (this.ValidateInwardItemEntry()) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'SaveInwardItemEntry',
                    bint_inwardno: this.state.formData.EditInwardNo,
                    bint_inwarditemno: this.state.formData.EditInwardItemNo,
                    vac_productcode: this.state.formData.txtProductCode,
                    vac_productcategory: this.state.formData.cmbProductCategory,
                    vac_productname: this.state.formData.cmbProductName,
                    dec_qty: this.state.formData.txtqty,
                    vac_note: this.state.formData.txtnote,
                    dat_inwarddate: this.props.EditInwardDate,
                }
                fetchInwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let EditInwardNo = res.data.bint_inwardno
                            this.props.updateInwardNoItemList(EditInwardNo, res.data.InwardItemEntryList)
                            this.setState({
                                dataLoadStatus: true,
                                formData: { ...this.state.formData, EditInwardNo }
                            }, () => {
                                if (openQRCode === true) {
                                    this.openProductQRCode();
                                }
                                this.onNewEntryClick()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in SaveInwardItemEntry. ' + error)
                    })
            })
        }
    }

    onNewEntryClick = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                EditInwardItemNo: '',
                txtProductCode: '',
                cmbProductCategory: '',
                cmbProductName: '',
                txtnote: '',
                txtqty: '',
            },
        }, () => {
            document.getElementById('txtqty').focus()
        })
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false,
            ProductCategoryList: this.props.ProductCategoryList,
            ProductNameList: this.props.ProductNameList,
        }, () => {
            let EditInwardNo = 0
            if (Number(this.props.EditInwardNo) > 0) {
                EditInwardNo = this.props.EditInwardNo
                this.setState({
                    dataLoadStatus: true,
                    formData: {
                        ...this.state.formData,
                        EditInwardNo,
                    }
                }, () => {
                    let EditInwardItemNo = 0
                    if (Number(this.props.EditItemSrNo) > 0) {
                        EditInwardItemNo = this.props.EditItemSrNo
                        this.setState({
                            dataLoadStatus: true,
                            formData: {
                                ...this.state.formData,
                                EditInwardItemNo: EditInwardItemNo, //this.props.rowItem.bint_itemsrno,
                                txtProductCode: this.props.rowItem.vac_productcode,
                                cmbProductCategory: this.props.rowItem.vac_productcategory,
                                cmbProductName: this.props.rowItem.vac_productname,
                                txtnote: this.props.rowItem.vac_note,
                                txtqty: Number(this.props.rowItem.dec_qty),
                            }
                        }, () => {
                            document.getElementById('txtqty').focus()
                        })
                    }
                    document.getElementById('txtqty').focus()
                })
            } else {
                this.setState({
                    dataLoadStatus: true,
                }, () => {
                    this.onNewEntryClick()
                })
            }
        })
    }

    onRemoveBtnClick = () => {
        if (this.state.formData.EditInwardItemNo && this.state.formData.EditInwardItemNo !== "") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Product : ' + this.state.formData.cmbProductName + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.DeleteInwardItemEntry()
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    DeleteInwardItemEntry = () => {
        // if (this.state.formData.EditInwardItemNo && this.state.formData.EditInwardItemNo !== "") {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            let reqData = {
                Op: 'DeleteInwardItemEntry',
                bint_inwardno: this.state.formData.EditInwardNo,
                bint_inwarditemno: this.state.formData.EditInwardItemNo,
                dat_inwarddate: this.props.EditInwardDate,
                vac_productcode: this.state.formData.txtProductCode
            }
            fetchInwardList(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.props.updateInwardNoItemList(this.state.formData.EditInwardNo, res.data.InwardItemEntryList)
                        this.setState({
                            dataLoadStatus: true,
                        }, () => {
                            this.onNewEntryClick()
                        })
                    }
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in deleteSalesItem. ' + error)
                })
        })
        return true
        // }
    }

    openProductQRCode = () => {
        // console.log('topmargin:',topmargin);
        this.props.notifyProcessing()
        let reqData = [
            { name: 'Op', value: 'PrintProductQRCode' },
            { name: 'vac_productcode', value: this.state.formData.txtProductCode },
            { name: 'vac_productname', value: this.state.formData.cmbProductName },
            { name: 'dec_qty', value: this.state.formData.txtqty },
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'inwardentrydetail.php');

        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');

        form.submit();
        this.props.updateProcessing('success', 'PDF Open in next Tab.')
    }
    //#endregion function

    render() {
        // console.log("this.state.dataLoadStatus", this.state.dataLoadStatus);
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Inward Item Entry</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <CtTxt
                            id='txtqty'
                            label='Qty'
                            value={this.state.formData.txtqty}
                            // disabled={this.state.disabledProfileData.AccountTypeDS}
                            handleOnChange={this.handleOnChange}
                            width={130}
                            maxLength={10}
                        />
                    </Grid>
                    <Grid item>
                        <CtTxt
                            id='txtProductCode'
                            label='Product Code'
                            value={this.state.formData.txtProductCode}
                            // disabled={this.state.disabledProfileData.AccountTypeDS}
                            handleOnChange={this.handleOnChange}
                            width={140}
                            maxLength={100}
                        // colID='vac_taxname' 
                        />
                    </Grid>
                    <Grid item>
                        <CtCmb
                            id='cmbProductCategory'
                            label='Product Category'
                            items={this.state.ProductCategoryList}
                            value={this.state.formData.cmbProductCategory}
                            onKeyDown={this.props.onKeyDown}
                            handleOnChange={this.handleOnChange}
                            width={180}
                            maxLength={100}
                            colID='vac_productcategory'
                        />

                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <CtCmb
                            id='cmbProductName'
                            label='Product Name'
                            items={this.state.ProductNameList}
                            value={this.state.formData.cmbProductName}
                            onKeyDown={this.props.onKeyDown}
                            handleOnChange={this.handleOnChange}
                            width={485}
                            maxLength={100}
                            colID='vac_productname'
                            dataFilter={[{ filterColID: 'vac_productcategory', value: this.state.formData.cmbProductCategory }]}

                        />

                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <CtMultiLineText
                            id='txtnote'
                            label='Note'
                            value={this.state.formData.txtnote}
                            // disabled={this.state.disabledProfileData.AccountTypeDS}
                            handleOnChange={this.handleOnChange}
                            width={485}
                            maxLength={2000}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <CtBtn label='Print QR Code' width={this.state.cWidth - 150} onClick={() => { this.SaveInwardItemEntry(true) }} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Add' width={this.state.cWidth - 150} onClick={() => { this.SaveInwardItemEntry() }} />
                    </Grid>
                    {localStorage.getItem('De') == 1 ?
                        <Grid item>
                            <CtBtn label='Remove' onClick={this.onRemoveBtnClick} />
                        </Grid>
                        : ""}
                    <Grid item>
                        <CtBtn label='New' width={this.state.cWidth - 150} onClick={this.onNewEntryClick} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(HOCProductInfo(withRouter(InwardItemEntry))))                                                                                                                                                                     