import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box } from '@mui/material'
import TableList from './CustomTool/TableList'
import { withRouter } from './HOC/withRouter'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { Edit, Delete } from '@mui/icons-material';
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import MemberID from './MemberID'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { getRowData } from './SystemUtility/SystemUtility'
import { fetchGroupMaster } from './API/API'
import Progress from './CustomTool/Progress'

class GroupMaster extends Component {
    state = {
        formData: {
            txtGroupName: '',
            txtGroupID: 0,
            txtPointsMemberID: 0,
            txtMemberID: this.props.memberInfo.CardNo,
        },
        columnsMember: [
            { id: 'Action', name: 'Search' },
            { id: 'bint_memberid', label: 'bint_memberid', hide: 'true' },
            { id: 'vac_cardno', label: 'Member ID' },
        ],
        columnsGroup: [
            { id: 'Action', label: '', name: '' },
            { id: 'vac_groupname', label: 'Group Name' },
            { id: 'memberCardNoList', label: 'Member ID List' },
            { id: 'bint_groupid', label: 'bint_groupid', hide: 'true' },
            { id: 'bint_pointsmemberid', label: 'bint_pointsmemberid', hide: 'true' }
        ],
        actionListMemberList: [
            { name: 'Delete', icon: <Delete />, link: 'ProductMaster:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 }
        ],
        actionListGroupList: [
            { name: 'Edit', icon: <Edit />, link: 'ProductMaster:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'ProductMaster:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 }
        ],
        keyColumnGroupList: [
            'vac_groupname', 'bint_groupid', 'bint_pointsmemberid'
        ],
        keyColumnMemberList: [
            'bint_memberid', 'vac_cardno'
        ],
        searchData: {
            vac_groupname: '',
            memberCardNoList: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsMember: [],
        rowsCountMember: 0,
        rowsPerPageMember: 5,
        rowsGroup: [],
        rowsCountGroup: 0,
        rowsPerPageGroup: 5,
        MemberCardNo: '',
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Group Master'
        this.props.setActiveMenu("GroupMaster")
        document.getElementById('txtGroupName').focus()
        this.getInfo()
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }
    //#endregion

    //#region handle
    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rowsGroup.length > 0) {
            this.state.rowsGroup.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.vac_groupname.length === 0 || row['vac_groupname'].toString().toLowerCase().includes(this.state.searchData.vac_groupname.toLowerCase())) &&
                        (this.state.searchData.memberCardNoList.length === 0 || row['memberCardNoList'].toString().toLowerCase().includes(this.state.searchData.memberCardNoList.toLowerCase()))

                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPageMember = (rowsPerPageMember) => {
        this.setState({ rowsPerPageMember })
    }

    handleChangeRowsPerPageGroup = (rowsPerPageGroup) => {
        this.setState({ rowsPerPageGroup })
    }

    handleARDonActionClick = (values) => {
        // console.log('values', values)
        const txtGroupName = values[0].value,
            txtGroupID = values[1].value,
            txtPointsMemberID = values[2].value,
            actionName = values[0].actionName
        if (actionName === "Edit") {
            const curRowData = getRowData(this.state.rowsGroup, 'bint_groupid', txtGroupID)
            this.setState({
                formData: {
                    ...this.state.formData,
                    txtGroupName: curRowData.vac_groupname,
                    txtGroupID: curRowData.bint_groupid,
                    txtPointsMemberID: curRowData.bint_pointsmemberid
                },
                rowsMember: curRowData.memberList,
                rowsCountMember: curRowData.memberList.length,
            })
        }
        else if (actionName === "Delete") {
            this.setState({ ARD: null },
                () => {
                    let ARD = <AlertResponsiveDialog
                        labelAgree='Delete'
                        labelDisagree='Cancel'
                        alertTitle={'Do you want to Delete  : ' + txtGroupName + ' ?'}
                        alertMessage=''
                        handleOnClickYes={() => {
                            return this.DeleteGroupMaster(txtGroupID)
                        }}
                        defaultOpenDialog={true}
                        onYesClickCloseIfExeSuccessfully={true}
                    />

                    this.setState({ ARD })
                })
        }
    }

    handleARDonActionClickMember = (values) => {
        // console.log('values', values)
        const vac_cardno = values[1].value,
            actionName = values[0].actionName,
            bint_memberid = values[0].value
        if (actionName === "Delete") {
            this.setState({ ARD: null },
                () => {
                    let ARD = <AlertResponsiveDialog
                        labelAgree='Delete'
                        labelDisagree='Cancel'
                        alertTitle={'Do you want to remove Member : ' + vac_cardno + ' from Group ?'}
                        alertMessage=''
                        handleOnClickYes={() => {
                            return this.deleteMemberID(vac_cardno, bint_memberid)
                        }}
                        defaultOpenDialog={true}
                        onYesClickCloseIfExeSuccessfully={true}
                    />

                    this.setState({ ARD })
                })
        } else {

        }
    }
    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    onNewEntryClick = () => {
        // if (this.validateSales() === true) {
        let ARD = null
        this.setState({ ARD }, () => {
            ARD = <AlertResponsiveDialog
                labelDisagree='Close'
                // alertTitle={'Sales Item Entry'}
                alertMessage={
                    <MemberID setMemberIDInfo={this.setMemberIDInfo} />
                }
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ ARD })
        })
        // }
    }

    getInfo = () => {
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            const reqData = {
                'Op': 'GroupList',
            }
            fetchGroupMaster(reqData)
                .then(res => {
                    dataLoadStatus = true
                    let rowsGroup = [],
                        rowsCountGroup = 0,
                        MemberCardNo = ''
                    if (res.data.GroupList) {
                        rowsGroup = res.data.GroupList
                        rowsCountGroup = rowsGroup.length
                        MemberCardNo = res.data.memberCardNoList
                    }
                    this.setState({
                        dataLoadStatus,
                        rowsGroup, rowsCountGroup, MemberCardNo
                    }, () => {
                        this.setSearchColumns()
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in onFormLoad.', error)
                })
        })
    }

    validateGroupMaster = () => {
        let result = false
        if (this.state.formData.txtGroupName.length <= 0) {
            this.props.toastErrorMsg('Enter Group Name', 'txtGroupName')
        } else if (this.state.rowsCountMember < 2) {
            this.props.toastErrorMsg('Group can be created of More than one Member', 'txtGroupName')
        } else {
            result = true
        }
        return result
    }

    SaveGroupMaster = () => {
        if (this.validateGroupMaster()) {
            const dataLoadStatus = false
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus }, () => {
                const reqData = {
                    'Op': 'SaveGroup',
                    'bint_groupid': this.state.formData.txtGroupID,
                    'vac_groupname': this.state.formData.txtGroupName,
                    'bint_pointsmemberid': this.state.formData.txtPointsMemberID,
                    'memberInfo': this.state.rowsMember
                }
                // console.log("reqData", reqData);
                fetchGroupMaster(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.getInfo()
                            this.clearInfo()
                        }
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in SaveGroupMaster.', error)
                    })
            })
        }
    }

    clearInfo = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                txtGroupName: '',
                txtGroupID: 0,
                txtPointsMemberID: 0,
                txtMemberID: this.props.memberInfo.CardNo,
            },
            rowsMember: [],
            rowsCountMember: 0,
        }, () => {
            this.navigateTo('GroupMaster')
            document.getElementById('txtGroupName').focus()
        })
    }

    onDeleteGroupBtnClick = () => {
        this.setState({ ARD: null },
            () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete  : ' + this.state.formData.txtGroupName + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.DeleteGroupMaster(this.state.formData.txtGroupID)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />

                this.setState({ ARD })
            })
    }

    DeleteGroupMaster = (txtGroupID) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'DeleteGroup',
                'bint_groupid': txtGroupID
            }
            fetchGroupMaster(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.setState({
                            ARD: null
                        }, () => {
                            this.getInfo()
                            this.clearInfo()
                        })
                    }
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in delete DeleteGroupMaster. ' + error)
                })
        })
        return false
    }

    setMemberIDInfo = (MemberID, CardNo) => {
        let memberList = this.state.rowsMember
        let bint_srno = 0
        memberList.map((Member) => {
            if (Member.bint_srno > bint_srno) {
                bint_srno = Member.bint_srno
            }
        })
        bint_srno += 1
        memberList.push({
            bint_srno,
            bint_memberid: MemberID,
            vac_cardno: CardNo
        })
        this.setState({
            rowsMember: memberList,
            rowsCountMember: memberList.length
        })
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'CtTxt',
                id: 'vac_groupname',
                label: 'Group Name',
                value: this.state.searchData.vac_groupname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'memberCardNoList',
                label: 'Member ID List',
                value: this.state.searchData.memberCardNoList,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '100'
            }
        )
        this.setState({ searchColumns })
    }

    deleteMemberID = (vac_cardno, bint_memberid) => {
        let rowsMember = this.state.rowsMember.filter((curRow) => {
            if (curRow.vac_cardno !== vac_cardno && curRow.bint_memberid !== bint_memberid)
                return curRow
        })
        const rowsCountMember = rowsMember.length
        this.setState({
            rowsMember,
            rowsCountMember
        })
        return true
    }
    //#endregion

    render() {
        let rowsMember = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rowsMember)
        let rowsCountMember = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCountMember)

        let rowsGroup = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rowsGroup)
        let rowsCountGroup = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCountGroup)
        return (
            <div>
                {/*  <Progress color='secondary' display={this.state.dataLoadStatus !== true} /> */}
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title">
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Group Master</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item>
                                <Grid container direction="column" justifyContent="center" alignItems="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                                    <Grid item>
                                        <CtTxt
                                            id='txtGroupName'
                                            label='Group Name'
                                            value={this.state.formData.txtGroupName}
                                            handleOnChange={this.handleOnChange}
                                            width='350'
                                            maxLength={100}
                                        />
                                    </Grid>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                                        <Grid item>
                                            <CtBtn label='Save' onClick={() => { this.SaveGroupMaster() }} />
                                        </Grid>
                                        <Grid item>
                                            <CtBtn label='New' onClick={() => { this.clearInfo() }} />
                                        </Grid>
                                        <Grid item>
                                            <CtBtn label='Delete' onClick={() => { this.DeleteGroupMaster() }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Grid item>
                                <TableList
                                    columns={this.state.columnsMember}
                                    counter={this.state.counter}
                                    rows={rowsMember}
                                    rowsCount={rowsCountMember}
                                    rowsPerPage={this.state.rowsPerPageMember}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPageMember}
                                    onActionClick={this.handleARDonActionClickMember}
                                    actionList={this.state.actionListMemberList}
                                    keyColumn={this.state.keyColumnMemberList}
                                    // handleOnSearchChange={this.handleOnSearchChange}
                                    // searchColumns={this.state.searchColumns}
                                    // searchData={this.state.searchData}
                                    provideSearch={false}
                                    onNewEntryClick={() => { this.onNewEntryClick() }}
                                    toolTipNewEntry='New Member ID'
                                />
                                <Grid item>
                                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                                        {this.state.ARD}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid><br />
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item>
                                <TableList
                                    columns={this.state.columnsGroup}
                                    counter={this.state.counter}
                                    rows={rowsGroup}
                                    rowsCount={rowsCountGroup}
                                    rowsPerPage={this.state.rowsPerPageGroup}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPageGroup}
                                    onActionClick={this.handleARDonActionClick}
                                    actionList={this.state.actionListGroupList}
                                    keyColumn={this.state.keyColumnGroupList}
                                    handleOnSearchChange={this.handleOnSearchChange}
                                    searchColumns={this.state.searchColumns}
                                    searchData={this.state.searchData}
                                    provideSearch={false}
                                //onNewEntryClick={this.onNewEntryClick}
                                // toolTipNewEntry='New Product Entry'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HoCtToastContainer(HOCVerifyIsUser(withRouter(GroupMaster))))