import React, { Component } from 'react'
import { Grid, Avatar, Box } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import CtCmb from './CustomTool/CtCmb'
import CtDtp from './CustomTool/CtDtp'
import CtDtpTime from './CustomTool/CtDtpTime'
import dayjs from 'dayjs';
import CtMultiLineText from './CustomTool/CtMultiLineText'
import CtBtn from './CustomTool/CtBtn'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { withRouter } from './HOC/withRouter'
import { Edit, Delete } from '@mui/icons-material';
import { fetchRentAtHome } from './API/API'
import TableList from './CustomTool/TableList'
import { fetchProductMaster } from './API/API'
import Progress from './CustomTool/Progress'
import HOCProductInfo, { getProductInfo } from './HOC/HOCProductInfo'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCMemberInfo from './HOC/HOCMemberInfo'
import { getRowData, formatDateTime } from './SystemUtility/SystemUtility'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
class RentAtHomeItemEntry extends Component {
    state = {
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'vac_productcode', label: 'Product Code' },
            { id: 'vac_productcategory', label: 'Product Category' },
            { id: 'vac_productname', label: 'Product Name' },
            { id: 'Description', label: 'Description' },
            { id: 'dat_rentstartdatetime', label: 'Rent Start Date Time' },
            { id: 'dec_rentmonths', label: 'Rent Weeks' },
            { id: 'dec_rentdays', label: 'Rent Days' },
            { id: 'dat_rentendatetime', label: 'Rent End Date Time' },
            { id: 'dec_totalpoint', label: 'Total Point' },
            { id: 'vac_rentremark', label: 'Remark' },
        ],
        rows: [],
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'RentAtHomeItemEntry:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'RentAtHomeItemEntry:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'vac_productcode', 'vac_productcategory', 'vac_productname', 'Description', 'dat_rentstartdatetime', 'dec_rentmonths', 'dec_rentdays',
            'dat_rentendatetime', 'dec_totalpoint', 'vac_rentremark',
        ],
        searchData: {
            vac_productcode: '',
            vac_productcategory: '',
            Vac_productname: '',
            Description: '',
            Dat_rentstartdatetime: '',
            Dec_rentmonths: '',
            Dec_rentdays: '',
            Dat_rentendatetime: '',
            Dec_totalpoint: '',
            Vac_rentremark: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,
        ProductNameList: [],
        ProductCategoryList: [],
        formData: {
            Editrentsrno: '',
            EditdtpRentDate: '',
            rentitemsrno: 0,
            txtProductCode: '',
            cmbProductCategory: '',
            cmbProductName: '',
            txtDescription: '',
            dtpRentStartDateTime: dayjs(),
            txtRentWeeks: 1,
            txtRentDays: 7,
            dtpRentEndDateTime: '',
            txtTotalPoint: '',
            txtRemark: '',
            dec_rentpointsperday: 0,
        },
        validateCardNetPoints: false,
    }

    //#region component
    componentDidMount() {
        document.title = 'TED : Rent Item Entry'
        // this.props.setActiveMenu("RentAtHomeItemEntry")
        this.onFormLoad()
        document.getElementById('txtProductCode').focus()
        this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo)
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }
    //#endregion component

    //#region handle
    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.vac_productcode.length === 0 || row['vac_productcode'].toString().includes(this.state.searchData.vac_productcode)) &&
                        (this.state.searchData.vac_productcategory === 'ALL' || this.state.searchData.vac_productcategory === row['vac_productcategory']) &&
                        (this.state.searchData.Vac_productname === 'ALL' || this.state.searchData.Vac_productname === row['Vac_productname']) &&
                        (this.state.searchData.Description.length === 0 || row['Description'].toString().includes(this.state.searchData.Description)) &&
                        (this.state.searchData.Dat_rentstartdatetime.length === 0 || row['Dat_rentstartdatetime'].toString().includes(this.state.searchData.Dat_rentstartdatetime)) &&
                        (this.state.searchData.Dec_rentmonths.length === 0 || row['Dec_rentmonths'].toString().includes(this.state.searchData.Dec_rentmonths)) &&
                        (this.state.searchData.Dec_rentdays.length === 0 || row['Dec_rentdays'].toString().includes(this.state.searchData.Dec_rentdays)) &&
                        (this.state.searchData.Dat_rentendatetime.length === 0 || row['Dat_rentendatetime'].toString().includes(this.state.searchData.Dat_rentendatetime)) &&
                        (this.state.searchData.Dec_totalpoint.length === 0 || row['Dec_totalpoint'].toString().includes(this.state.searchData.Dec_totalpoint)) &&
                        (this.state.searchData.Vac_rentremark.length === 0 || row['Vac_rentremark'].toString().includes(this.state.searchData.Vac_rentremark))

                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        const ctrlName = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            if (ctrlName === 'txtRentWeeks') {
                const txtRentDays = Number(this.state.formData.txtRentWeeks) * 7
                this.setState({
                    formData: { ...this.state.formData, txtRentDays }
                }, () => { this.setRentEndDateTime(this.CalculateTotalPoint) })
            } else if (ctrlName === 'txtRentDays') {
                const txtRentWeeks = 0
                this.setState({
                    formData: { ...this.state.formData, txtRentWeeks }
                }, () => { this.setRentEndDateTime(this.CalculateTotalPoint) })
                // this.setRentEndDateTime(this.CalculateTotalPoint)
            } else if (ctrlName === 'dtpRentStartDateTime') {
                this.setRentEndDateTime(this.CalculateTotalPoint)
            } else if (ctrlName === 'txtProductCode') {
                setTimeout(() => {
                    this.props.getProductInfo(this.setDataLoadStatus, this.state.ProductNameList,
                        this.setProductInfo, this.state.formData.txtProductCode)
                }, 500)
            } else if (
                (ctrlName === 'cmbProductCategory' || ctrlName === 'cmbProductName') &&
                this.state.formData.cmbProductCategory.trim().length > 0 &&
                this.state.formData.cmbProductName.trim().length > 0
            ) {
                this.props.getProductInfo(
                    this.setDataLoadStatus, this.state.ProductNameList, this.setProductInfo,
                    '', this.state.formData.cmbProductCategory, this.state.formData.cmbProductName
                )
            }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        const txtProductCode = values[0].value,
            cmbProductCategory = values[1].value,
            cmbProductName = values[2].value,
            txtDescription = values[3].value,
            dtpRentStartDateTime = values[4].value,
            txtRentWeeks = values[5].value,
            txtRentDays = values[6].value,
            dtpRentEndDateTime = values[7].value,
            txtTotalPoint = values[8].value,
            txtRemark = values[9].value,
            actionName = values[0].actionName
        if (actionName === "Edit") {
            this.setState({
                formData: {
                    ...this.state.formData, txtProductCode, cmbProductCategory, cmbProductName, txtDescription,
                    dtpRentStartDateTime, txtRentWeeks, txtRentDays, dtpRentEndDateTime, txtTotalPoint, txtRemark
                }
            })
        } else {
            let rows = this.state.rows.filter((row) => {
                if (row.ProductCode !== txtProductCode && row.ProductCategory !== cmbProductCategory && row.ProductName !== cmbProductName &&
                    row.Description !== txtDescription && row.RentStartDateTime !== dtpRentStartDateTime && row.RentMonths !== txtRentWeeks && row.RentDays !== txtRentDays
                    && row.RentEndDateTime !== dtpRentEndDateTime && row.TotalPoint !== txtTotalPoint && row.Remark !== txtRemark)
                    return row
            })
            const rowsCount = rows.length
            this.setState({ rows, rowsCount })
        }
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    //#endregion Handle

    //#region Function
    setProductInfo = (ProductInfo, dataLoadStatus) => {
        //console.log("ProductInfo",ProductInfo);
        //console.log("dataLoadStatus",dataLoadStatus);
        this.setState({
            dataLoadStatus,
            formData: {
                ...this.state.formData,
                txtProductCode: ProductInfo.vac_productcode ? ProductInfo.vac_productcode : '',
                cmbProductCategory: ProductInfo.vac_productcategory ? ProductInfo.vac_productcategory : '',
                cmbProductName: ProductInfo.vac_productname ? ProductInfo.vac_productname : '',
                dec_rentpointsperday: ProductInfo.dec_rentpointsperday ? ProductInfo.dec_rentpointsperday : ''
            }
        }, () => {
            this.setRentEndDateTime(this.CalculateTotalPoint)
        })
    }

    setDataLoadStatus = (dataLoadStatus, nextFunctionCall) => {
        this.setState({ dataLoadStatus }, () => {
            if (nextFunctionCall) {
                nextFunctionCall()
            }
        })
    }

    /*  getProductInfo = () => {
         if (this.state.formData.txtProductCode.length > 0 && this.state.formData.txtProductCode != "") {
             this.setState({ dataLoadStatus: false }, () => {
                 let reqData = {
                     Op: 'GetProductInfo',
                     vac_productcode: this.state.formData.txtProductCode
                 }
                 fetchProductMaster(reqData)
                     .then(res => {
                         let cmbProductCategory = '', cmbProductName = '', dec_rentpointsperday = ''
                         if (res.data.ProductInfo && res.data.ProductInfo.length > 0) {
                             cmbProductCategory = res.data.ProductInfo[0].vac_productcategory
                             cmbProductName = res.data.ProductInfo[0].vac_productname
                             dec_rentpointsperday = res.data.ProductInfo[0].dec_rentpointsperday
                         }
                         this.setState({
                             dataLoadStatus: true,
                             formData: {
                                 ...this.state.formData, cmbProductCategory, cmbProductName, dec_rentpointsperday
                             }
                         })
                     })
                     .catch(error => {
                         this.props.toastMsg('error', 'Unkonwn Error occured in getProductInfo. ' + error)
                     })
             })
         }
     } */

    /*  getProductInfoForProductCode = () => {
         if (this.state.formData.cmbProductCategory.length > 0 && this.state.formData.cmbProductCategory != ""
             && this.state.formData.cmbProductName.length > 0 && this.state.formData.cmbProductName != "") {
             this.setState({ dataLoadStatus: false }, () => {
                 let reqData = {
                     Op: 'GetProductCodeFromProductCategory_Name',
                     vac_productcategory: this.state.formData.cmbProductCategory,
                     vac_productname: this.state.formData.cmbProductName,
                 }
                 fetchProductMaster(reqData)
                     .then(res => {
                         let txtProductCode = ''
                         if (res.data.ProductCodeList && res.data.ProductCodeList.length > 0) {
                             txtProductCode = res.data.ProductCodeList[0].vac_productcode
                         }
                         this.setState({
                             dataLoadStatus: true,
                             formData: {
                                 ...this.state.formData, txtProductCode
                             }
                         })
                     })
                     .catch(error => {
                         this.props.toastMsg('error', 'Unkonwn Error occured in getProductInfoForProductCode. ' + error)
                     })
             })
         }
     } */

    CalculateTotalPoint = () => {
        let txtTotalPoint = Number(this.state.formData.txtRentDays) * Number(this.state.formData.dec_rentpointsperday)
        this.setState({ formData: { ...this.state.formData, txtTotalPoint } }, () => {
            // console.log('txtTotalPoint',txtTotalPoint)
        })

    }

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    setRentEndDateTime = (nextFunctionCall) => {
        const dtpRentEndDateTime = window.addPeriod(this.state.formData.dtpRentStartDateTime, Number(this.state.formData.txtRentDays))
        this.setState({ formData: { ...this.state.formData, dtpRentEndDateTime } }, () => {
            if (nextFunctionCall) {
                nextFunctionCall()
            }
        })
    }

    /* getInfo = () => {
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            const reqData = {
                'Op': 'RentAtHomeItemEntryList',
            }
            fetchRentAtHome(reqData)
                .then(res => {
                    const rows = res.data.RentAtHomeItemEntryList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    console.log('Unknown error occurred in getInfo.', error)
                })
        })
    } */

    onDeleteItemClick = () => {
        const Editrentsrno = this.state.formData.Editrentsrno,
            rentitemsrno = this.state.formData.rentitemsrno,
            EditdtpRentDate = this.state.formData.EditdtpRentDate,
            cmbProductName = this.state.formData.cmbProductName,
            txtProductCode = this.state.formData.txtProductCode
        if (Number(Editrentsrno) > 0 && Number(rentitemsrno) > 0) {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Product : ' + cmbProductName + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteRentAtHomeItem(Editrentsrno, rentitemsrno, EditdtpRentDate, this.props.EditMemberID, txtProductCode)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg('error', 'No records to Delete')
        }
    }

    onFormLoad = () => {
        // console.log("this.props", this.props);
        this.setState({
            dataLoadStatus: false,
            ProductCategoryList: this.props.ProductCategoryList,
            ProductNameList: this.props.ProductNameList
        }, () => {
            let Editrentsrno = 0, EditdtpRentDate = '', rentitemsrno = 0
            if (Number(this.props.Editrentsrno) > 0) {
                Editrentsrno = this.props.Editrentsrno
                EditdtpRentDate = this.props.EditdtpRentDate
            }
            this.setState({
                dataLoadStatus: true,
                formData: {
                    ...this.state.formData,
                    EditdtpRentDate,
                    Editrentsrno,
                }
            }, () => {
                if (Number(this.props.rentitemsrno) > 0) {
                    rentitemsrno = this.props.rentitemsrno
                    this.setState({
                        dataLoadStatus: true,
                        formData: {
                            ...this.state.formData,
                            EditdtpRentDate,
                            Editrentsrno,
                            rentitemsrno: this.props.rowItem.bint_rentitemsrno,
                            txtProductCode: this.props.rowItem.vac_productcode,
                            cmbProductCategory: this.props.rowItem.vac_productcategory,
                            cmbProductName: this.props.rowItem.vac_productname,
                            dtpRentStartDateTime: this.props.rowItem.dat_rentstartdatetime,
                            txtRentWeeks: this.props.rowItem.dec_rentmonths,
                            txtRentDays: this.props.rowItem.dec_rentdays,
                            dtpRentEndDateTime: this.props.rowItem.dat_rentendatetime,
                            txtTotalPoint: this.props.rowItem.dec_totalpoint,
                            txtRemark: this.props.rowItem.vac_rentremark,
                        }
                    }, () => {
                        document.getElementById('txtProductCode').focus()
                    })
                } else {
                    this.setState({
                        dataLoadStatus: true,
                    }, () => {
                        this.newRentAtHome()
                    })
                }
            })
        })
    }

    newRentAtHome = () => {
        this.setState({
            rows: [],
            rowsCount: 0,
            formData: {
                ...this.state.formData,
                rentitemsrno: 0,
                txtProductCode: '',
                cmbProductCategory: '',
                cmbProductName: '',
                txtDescription: '',
                dtpRentStartDateTime: dayjs(),
                txtRentWeeks: 1,
                txtRentDays: 7,
                dtpRentEndDateTime: dayjs(),
                txtTotalPoint: '',
                txtRemark: '',
            },
        }, () => {
            this.navigateTo('RentAtHome')
            document.getElementById('txtProductCode').focus()
        })
    }

    validateRentAtHome = () => {
        let validateRentAtHome = false
        if (this.state.formData.txtProductCode === undefined || Number(this.state.formData.txtProductCode) < 0) {
            this.props.toastErrorMsg('Enter Product Code', 'txtProductCode')
        } else if (this.state.formData.cmbProductCategory === undefined || this.state.formData.cmbProductCategory < 0) {
            this.props.toastErrorMsg('Select Product Category', 'cmbProductCategory')
        } else if (this.state.formData.cmbProductName === undefined || this.state.formData.cmbProductName < 0) {
            this.props.toastErrorMsg('Select Product Name', 'cmbProductName')
        } else if (this.state.formData.dtpRentStartDateTime.length <= 0) {
            this.props.toastErrorMsg('Enter Rent Start Date Time', 'dtpRentStartDateTime')
        } else if (this.state.formData.txtRemark === undefined || this.state.formData.txtRemark < 0) {
            this.props.toastErrorMsg('Enter Remark', 'txtRemark')
        } else {
            validateRentAtHome = true
        }
        return validateRentAtHome
    }

    validateCardNetPoints = () => {
        let validateCardNetPoints = false
        if (Number(this.props.memberInfo.CurrentPoints) < Number(this.state.formData.txtTotalPoint)) {
            // this.props.toastErrorMsg('Enter Remark', 'txtRemark')
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Yes'
                    labelDisagree='No'
                    alertTitle={'No Enough points available, Do you want to continue?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        this.setState({
                            validateCardNetPoints: true
                        }, () => {
                            this.saveRentAtHomeItems()
                        })
                        return true
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            return true
        }
        // return validateCardNetPoints
    }

    saveRentAtHome = (resetValidateCardNetPoints = true) => {
        // console.log("resetValidateCardNetPoints", resetValidateCardNetPoints);
        // console.log("this.state.validateCardNetPoints", this.state.validateCardNetPoints);
        if (resetValidateCardNetPoints) {
            this.setState({
                validateCardNetPoints: false
            }, () => {
                if (this.validateRentAtHome() && this.validateCardNetPoints()) {
                    this.saveRentAtHomeItems()
                }
            })
        } else {
            this.saveRentAtHomeItems()
        }
    }

    saveRentAtHomeItems = () => {
        // console.log("this.state.validateCardNetPoints", this.state.validateCardNetPoints);
        // if (this.validateRentAtHome() && this.validateCardNetPoints()) {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            let reqData = {
                Op: 'SaveRentAtHome_ItemEntry',
                bint_rentsrno: Number(this.state.formData.Editrentsrno),
                vac_renttype: 'Rent At Home',
                dat_rentdate: this.props.EditdtpRentDate,
                vac_cardno: this.props.EditCardNo,
                bint_memberid: this.props.EditMemberID,
                dec_netpoints: 0,
                bint_rentitemsrno: this.state.formData.rentitemsrno,
                vac_productcode: this.state.formData.txtProductCode,
                vac_productcategory: this.state.formData.cmbProductCategory,
                vac_productname: this.state.formData.cmbProductName,
                dat_rentstartdatetime: formatDateTime(this.state.formData.dtpRentStartDateTime),
                dec_rentmonths: this.state.formData.txtRentWeeks,
                dec_rentdays: this.state.formData.txtRentDays,
                dat_rentendatetime: formatDateTime(this.state.formData.dtpRentEndDateTime),
                dec_totalpoint: this.state.formData.txtTotalPoint,
                vac_rentremark: this.state.formData.txtRemark,
            }
            fetchRentAtHome(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        let txtChallanNo = res.data.RentInfo[0].bint_rentsrno,
                            RentAtHomeItemList = res.data.RentAtHomeItemEntryList,
                            txtNetPoint = res.data.RentInfo[0].dec_netpoints
                        this.setState({
                            dataLoadStatus: true,
                            formData: {
                                ...this.state.formData,
                                EditdtpRentDate: this.props.EditdtpRentDate,
                                Editrentsrno: txtChallanNo
                            },
                        }, () => {
                            this.props.setRentAtHomeInfo(txtChallanNo, txtNetPoint, RentAtHomeItemList)
                            this.newRentAtHome()
                            //this.getInfo()
                        })
                        this.updateCurrentBalanceInRedux(res.data.dec_cardpointbalance)
                    }
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in save Rent Item. ' + error)
                })
        })
        // }
    }

    deleteRentAtHomeItem = (ChallanNo, RentItemSrNo, RentDate, bint_memberid, vac_productcode) => {
        if (Number(ChallanNo) > 0 && Number(RentItemSrNo) > 0) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteRentAtHomeItemEntry',
                    bint_rentsrno: ChallanNo,
                    bint_rentitemsrno: RentItemSrNo,
                    dat_rentdate: RentDate,
                    bint_memberid,
                    vac_productcode
                }
                fetchRentAtHome(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let txtChallanNo = ChallanNo,
                                RentAtHomeItemList = res.data.RentAtHomeItemEntryList,
                                txtNetPoint = res.data.RentInfo[0].dec_netpoints
                            this.setState({
                                dataLoadStatus: true,
                                formData: {
                                    ...this.state.formData,
                                    EditdtpRentDate: this.props.EditdtpRentDate,
                                    Editrentsrno: txtChallanNo
                                },
                            }, () => {
                                this.props.setRentAtHomeInfo(txtChallanNo, txtNetPoint, RentAtHomeItemList)
                                this.newRentAtHome()
                            })
                            this.updateCurrentBalanceInRedux(res.data.dec_cardpointbalance)
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in delete RentAtHomeItemEntry. ' + error)
                    })
            })
            return true
        }
    }

    updateCurrentBalanceInRedux = (CurrentPoints) => {
        this.props.setCurrentPoints({ CurrentPoints })
    }
    //#endregion Function

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        // console.log('this.props.ProductNameList',this.props.ProductNameList)
        // console.log('this.props.ProductCategoryList',this.props.ProductCategoryList)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Rent Item Entry</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <CtTxt
                            id='txtProductCode'
                            label='Product Code'
                            value={this.state.formData.txtProductCode}
                            handleOnChange={this.handleOnChange}
                            // width={this.state.cWidth}
                            maxLength={100}
                            width='100'
                        />
                    </Grid>
                    <Grid item>
                        <CtCmb
                            id='cmbProductCategory'
                            label='Product Category'
                            items={this.props.ProductCategoryList}
                            value={this.state.formData.cmbProductCategory}
                            // disabled={this.state.disabledProfileData.AccountTypeDS}
                            handleOnChange={this.handleOnChange}
                            width='160'
                            colID='vac_productcategory'
                            maxLength={100}
                        />
                    </Grid>
                    <Grid item>
                        <CtCmb
                            id='cmbProductName'
                            label='Product Name'
                            items={this.props.ProductNameList}
                            value={this.state.formData.cmbProductName}
                            // disabled={this.state.disabledProfileData.AccountTypeDS}
                            handleOnChange={this.handleOnChange}
                            width='250'
                            colID='vac_productname'
                            maxLength={100}
                            dataFilter={[{ filterColID: 'vac_productcategory', value: this.state.formData.cmbProductCategory }]}
                        />
                    </Grid>
                    <Grid item style={{ display: this.state.formData.txtDescription.length > 0 ? 'block' : 'none' }}>
                        <CtTxt
                            id='txtDescription'
                            label='Description'
                            disabled={true}
                            maxLength={2000}
                            width='540'
                            value={this.state.formData.txtDescription}
                            handleOnChange={this.handleOnChange}
                        />
                    </Grid>
                    <Grid item>
                        <CtDtpTime
                            id='dtpRentStartDateTime'
                            label='Rent Start Date Time'
                            width='190'
                            handleOnChange={this.handleOnChange}
                            value={this.state.formData.dtpRentStartDateTime}
                            onKeyDown={this.props.onKeyDown}
                            showToolbar={true}
                        />
                    </Grid>
                    <Grid item>
                        <CtTxt
                            id='txtRentWeeks'
                            label='Rent Week'
                            maxLength={10}
                            width='150'
                            value={this.state.formData.txtRentWeeks}
                            handleOnChange={this.handleOnChange}
                        />
                    </Grid>
                    <Grid item>
                        <CtTxt
                            id='txtRentDays'
                            label='Rent Days'
                            maxLength={10}
                            width='150'
                            value={this.state.formData.txtRentDays}
                            handleOnChange={this.handleOnChange}
                        />
                    </Grid>
                    <Grid item>
                        <CtDtpTime
                            id='dtpRentEndDateTime'
                            label='Rent End Date Time'
                            width='190'
                            handleOnChange={this.handleOnChange}
                            value={this.state.formData.dtpRentEndDateTime}
                            onKeyDown={this.props.onKeyDown}
                            showToolbar={true}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item>
                        <CtTxt
                            id='txtTotalPoint'
                            label='Total Point'
                            disabled={true}
                            maxLength={10}
                            width='210'
                            value={this.state.formData.txtTotalPoint}
                            handleOnChange={this.handleOnChange}
                        />
                    </Grid>
                    <Grid item>
                        <CtMultiLineText
                            id='txtRemark'
                            label='Remark'
                            width='540'
                            maxLength={2000}
                            value={this.state.formData.txtRemark}
                            handleOnChange={this.handleOnChange}
                        />
                    </Grid>
                    <Grid container direction="row" justifyContent="center" spacing={3} marginTop={0} style={{ paddingRight: '190px' }} >
                        <Grid item>
                            <CtBtn label='Save' onClick={this.saveRentAtHome} />
                        </Grid>
                        <Grid item>
                            <CtBtn label='New' onClick={this.newRentAtHome} />
                        </Grid>
                        {localStorage.getItem('De') == 1 ?
                            <Grid item>
                                <CtBtn label='Delete' onClick={this.onDeleteItemClick} />
                            </Grid> : ""}
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(HOCVerifyIsUser(HOCMemberInfo(HOCProductInfo(withRouter(RentAtHomeItemEntry))))))