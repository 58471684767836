import React, { Component } from 'react'
import { withRouter } from './HOC/withRouter'
import Logo from './images/logo.png'
import {
    Grid, Avatar, Box, Typography, Paper, Card, CardActionArea,
    CardContent, CardMedia, CardActions, Button, Stack
} from '@mui/material'
import CtTxtPWD from './CustomTool/CtTxtPWD'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ProcessingImg from './images/Processing_1.gif'
import Timer from './Timer'
import { fetchUserDetail } from './API/API'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
class Login extends Component {
    state = {
        formData: {
            txtUserId: '',
            txtPasswrod: '',
        },
        res: [],
    }

    //#region Component
    componentDidMount() {
        this.clearStorage()
        document.title = 'TED : Login'
        this.props.setActiveMenu("")
    }
    //#endregion

    //#region handle
    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }
    //#endregion

    //#region Function
    clearStorage = () => {
        localStorage.removeItem('UI')
        localStorage.removeItem('De')
        localStorage.removeItem('Me')
        localStorage.removeItem('Um')
        localStorage.removeItem('Pc')
        localStorage.removeItem('Pm')
        localStorage.removeItem('Sp')
        localStorage.removeItem('Se')
        localStorage.removeItem('Rh')
        localStorage.removeItem('Rs')
        localStorage.removeItem('Sa')
        localStorage.removeItem('In')
        localStorage.removeItem('Ou')
    }

    validateInput = (index = null) => {
        let result = false
        if ((index === null || index === 0) && this.state.formData.txtUserId.length <= 0) {
            this.props.toastErrorMsg('Enter Login ID', 'txtUserId')
        } else if ((index === null || index === 1) && this.state.formData.txtPasswrod.length <= 0) {
            this.props.toastErrorMsg('Enter Password', 'txtPasswrod')
        } else if ((index === null || index === 1) && this.state.formData.txtPasswrod.length < 4) {
            this.props.toastErrorMsg('Enter Valid Password', 'txtPasswrod')
        }
        else {
            result = true
        }
        return result
    }

    splitPrivileges = (privileges, message, msgType) => {
        let result = false
        let arrprivileges = privileges.split("")
        if (arrprivileges.length > 11) {
            let index = 0
            if (Number(arrprivileges[index]) === 0) {
                if (localStorage.getItem('AD') && Number(localStorage.getItem('AD')) === 1) {
                    result = true
                }
            } else {
                result = true
            }

            if (result === true) {
                localStorage.setItem('De', arrprivileges[index])
                index++
                localStorage.setItem('Me', arrprivileges[index])
                index++
                localStorage.setItem('Um', arrprivileges[index])
                index++
                localStorage.setItem('Pc', arrprivileges[index])
                index++
                localStorage.setItem('Pm', arrprivileges[index])
                index++
                localStorage.setItem('Sp', arrprivileges[index])
                index++
                localStorage.setItem('Se', arrprivileges[index])
                index++
                localStorage.setItem('Rh', arrprivileges[index])
                index++
                localStorage.setItem('Rs', arrprivileges[index])
                index++
                localStorage.setItem('Sa', arrprivileges[index])
                index++
                localStorage.setItem('In', arrprivileges[index])
                index++
                localStorage.setItem('Ou', arrprivileges[index])
                // console.log("arrprivileges", arrprivileges);
            } else {
                message = 'Try to login on Authorized Device Only.'
                msgType = 'error'
            }
            this.props.updateProcessing(msgType, message)
            return result
        }
    }

    verifyCredential = () => {
        if (this.validateInput()) {
            this.props.notifyProcessing()
            const reqData = {
                vac_userid: this.state.formData.txtUserId,
                vac_password: this.state.formData.txtPasswrod,
                Op: 'VerifyUser',
            }
            fetchUserDetail(reqData)
                .then(res => {
                    this.setState({ res: res.data }, () => {
                        // console.log(this.state.res);
                        // this.props.updateProcessing(this.state.res.msgType, this.state.res.message)
                        if (this.state.res.msgType === 'success') {
                            let result = this.splitPrivileges(res.data.Privileges, this.state.res.message, this.state.res.msgType)
                            if (result === true) {
                                localStorage.setItem('UI', this.state.formData.txtUserId)
                                this.navigateTo('UserDashboard')
                            }
                        } else {
                            this.props.updateProcessing(this.state.res.msgType, this.state.res.message)
                        }
                    })
                })
                .catch(err => {
                    this.props.updateProcessing('error', 'Unknown error occured in Verify Credential.\n\n' + err)
                })
        }
    }

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }
    //#endregion

    render() {

        return (
            <Timer
                initialMiliSeconds={700}
                timerPreContent={<center><img src={ProcessingImg} style={{
                    display: 'block',
                    height: 'auto',
                    width: '75vw',
                    maxHeight: '80vh',
                    margin: '0 auto'
                }} /></center>}
                timerPostContent={
                    <form>
                        <Grid container direction="row" alignItems="center" justifyContent="flex-start" className="title" spacing={2} marginLeft={0} marginTop={0} marginRight={0}>
                            <Grid item marginLeft={0} marginTop={0} marginRight={0} xs={12} sm={6} md={5} style={{ width: '100%' }}>
                                <center><img src={Logo} alt='Logo' style={{ width: '333px', height: 'auto', borderRadius: '166px' }} /></center>
                            </Grid>
                            <Grid item marginTop={0} marginRight={0} xs={12} sm={6} md={6} lg={3}
                            // style={{backgroundColor:'white'}}
                            >
                                <Grid container direction="column" justifyContent="flext-start" alignItems='center' spacing={2} marginLeft={0} marginTop={0} marginRight={0}>
                                    <Grid item>
                                        <center><label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Login</b></label></center>
                                    </Grid>
                                    <Grid item >
                                        <CtTxt
                                            id='txtUserId'
                                            label='User Id'
                                            value={this.state.formData.txtUserId}
                                            handleOnChange={this.handleOnChange}
                                            width={200}
                                            maxLength='100'
                                            onKeyDown={this.props.onKeyDown}
                                            validateInput={this.validateInput}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CtTxtPWD
                                            id='txtPasswrod'
                                            label='Password'
                                            value={this.state.formData.txtPasswrod}
                                            width={200}
                                            maxLength='100'
                                            handleOnChange={this.handleOnChange}
                                            onKeyDown={this.props.onKeyDown}
                                            validateInput={this.validateInput}
                                            defaultAction={this.verifyCredential}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CtBtn label='Login' onClick={this.verifyCredential} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                }
                timerPostFunctionCall={() => { document.getElementById('txtUserId').focus() }}
            />
        )
    }
}

export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HOCVerifyIsUser(HoCtToastContainer(withRouter(Login))))