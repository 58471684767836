import React from 'react'
import TextField from '@mui/material/TextField';

const CtTextField = ({
    id, label, disabled, readOnly, value, width, handleOnChange, maxLength, isUpperCase, variant,
    textAlign, display, 
    onKeyDown, defaultAction, validateInput
}) => {

    let curVariant = variant ? variant : 'standard'

    // const useWidthStyle = { width: width + 'px' }
    let style = {}
    if (Number(width) > 0) {
        style.width = width + 'px'
    }
    if (display !== undefined && (display === false || display === 'false')) {
        style.display = 'none'
    }
    // const useStyle = { ext}
    const applyMaxLength = { maxLength: maxLength, readOnly: disabled }
    const applyMaxLengthMissing = { maxLength: 1, readOnly: disabled }
    let inputPropsStyle = {}
    if (maxLength > 0) {
        inputPropsStyle.maxLength = maxLength
    } else {
        inputPropsStyle.maxLength = 1
    }
    /* if (disabled !== undefined && disabled === true) {
        inputPropsStyle.disabled = disabled
    } */
    if (readOnly !== undefined && readOnly === true) {
        inputPropsStyle.readOnly = readOnly
    }
    if (isUpperCase !== undefined && isUpperCase === true) {
        inputPropsStyle.style = { textTransform: 'uppercase' }
    }
    if (textAlign !== undefined) {
        inputPropsStyle.style = { textAlign }
    }
    
    const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, 1, defaultAction, validateInput) }) : (null)
    return (
        <TextField
            id={id}
            name={id}
            label={label}
            disabled={disabled}
            value={value}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            style={style}
            inputProps={inputPropsStyle}
            variant={curVariant}
        />
    )
}

export default CtTextField