import { Grid, Box, Button, } from '@mui/material'
import React, { Component, createRef } from 'react'
import Logo from './images/logo.png'
import ImageUploadIcon from './CustomTool/ImageUploadIcon'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import PH_KID from '../Component/images/kidphoto.png'
import PH_GUARDIANADHARCARD from '../Component/images/AadharCardPhoto.png'
import PH_GUARDIAN from '../Component/images/GuardianPhoto.png'
import CtTxt from './CustomTool/CtTxt'
import CtCmb from './CustomTool/CtCmb'
import TextAreaAutoSize from "./CustomTool/TextAreaAutoSize";
import CtTxtAdornNumWhatsApp from "./CustomTool/CtTxtAdornNumWhatsApp";
import CtBtn from './CustomTool/CtBtn'
import CtDtp from './CustomTool/CtDtp'
import { connect } from 'react-redux'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { WhatsApp } from '@mui/icons-material';
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { withRouter } from './HOC/withRouter'
import { fetchMember, apiURL } from './API/API'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { get_DMY_from_YMD, get_YMD_from_SYS, get_YMD_from_DMY, getString_or_Blank4Null } from './SystemUtility/SystemUtility'
import Progress from './CustomTool/Progress'
import Dashboard from '@mui/icons-material/Dashboard'
import TransactionHistory from './TransactionHistory'

class MemberMaster extends Component {
  state = {
    cWidth: 220,
    GuardianRelation: [
      { GuardianRelation: 'Mother' },
      { GuardianRelation: 'Father' },
      { GuardianRelation: 'Uncle' },
      { GuardianRelation: 'Aunty' },
      { GuardianRelation: ' Grand Father' },
      { GuardianRelation: 'Grand Mother' },
      { GuardianRelation: 'Brother' },
      { GuardianRelation: 'Sister' },
    ],
    Status: [
      { Status: 'Pending', disabled: false },
      { Status: 'Active', disabled: false },
      { Status: 'Block', disabled: Number(localStorage.getItem('De')) === 0 },
      { Status: 'Close', disabled: Number(localStorage.getItem('De')) === 0 },
    ],
    Gender: [
      { 'gender': 'Male' },
      { 'gender': 'Female' },
    ],
    formData: {
      txtMemberID: '',
      txtKidName: '',
      cmbGender: '',
      txtGuardianName: '',
      cmbGuardianRelation: '',
      txtMobileNoForWhatsApp: '',
      txtAlternateMobileNo: '',
      txtCardNo: '',
      txtEmailID: '',
      txtGuardianAdharCard: '',
      txtAddress: '',
      txtCardBalance: '',
      cmbStatus: 'Pending',
      txtReasonofStatus: '',
      dtpDepositeReturnDate: '',
      txtDepositeReturnAmount: '',
      // Kidphoto: '',
      // Guardianphoto: '',
      // GuardianAdharCardImg: '',
      PhotoOfKid: '',
      KidImgLocation: '',
      KidImgPath: '',
      PhotoOfGuardian: '',
      GuardianImgLocation: '',
      GuardianImpPath: '',
      GuardianAdharCardPhoto: '',
      AdharCardImgLocation: '',
      AdharCardImgPath: '',
      cmbKidGender: '',
      dtpDOB: '',
      txtBloodGroup: '',
    },
    cmbStatevisible: 'none',
    dataLoadStatus: true,
    disableFields: false,
  }

  constructor(props) {
    super(props)
    this.Kidphoto = createRef()
    this.GuardianAdharCardImg = createRef()
    this.Guardianphoto = createRef()
  }

  //#region  component
  componentDidMount() {
    document.title = 'TED : Member Master'
    this.props.setActiveMenu("MemberMaster")
    this.onFormLoad()
    document.getElementById('txtKidName').focus()
  }

  componentWillMount() {
    this.props.verifyUserID(localStorage.getItem('UI'))
  }

  //#endregion component

  //#region  Handle
  handleOnChange = (e) => {
    const ctrlName = e.target.name
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value }
    }, () => {
      if (ctrlName === 'cmbStatus') {
        let cmbStatevisible = this.state.formData.cmbStatus === 'Block' || this.state.formData.cmbStatus === 'Close' ? 'block' : 'none'
        this.setState({ cmbStatevisible })
      }
    }
    )
  }

  onKidImageChange = (PhotoOfKid, KidImgLocation, KidImgPath) => {
    this.setState({
      formData: { ...this.state.formData, PhotoOfKid, KidImgLocation, KidImgPath }
    }, () => {
      let path = this.state.formData.KidImgLocation + this.state.formData.PhotoOfKid,
        infoType = "KID"
      console.log("path", path);
      this.uploadMemberPhotos(path, infoType)
    })
  }

  onGuardianImageChange = (PhotoOfGuardian, GuardianImgLocation, GuardianImpPath, skipVerifyOTP = false) => {
    this.setState({
      formData: { ...this.state.formData, PhotoOfGuardian, GuardianImgLocation, GuardianImpPath }
    }, () => {
      let path = this.state.formData.GuardianImgLocation + this.state.formData.PhotoOfGuardian,
        infoType = "GUARDIANPHOTO"
      this.uploadMemberPhotos(path, infoType)
    })
  }

  onGuardianAdharCardImageChange = (GuardianAdharCardPhoto, AdharCardImgLocation, AdharCardImgPath, skipVerifyOTP = false) => {
    this.setState({
      formData: { ...this.state.formData, GuardianAdharCardPhoto, AdharCardImgLocation, AdharCardImgPath }
    }, () => {
      let path = this.state.formData.AdharCardImgLocation + this.state.formData.GuardianAdharCardPhoto,
        infoType = "GUARDIANAADHARPHOTO"
      this.uploadMemberPhotos(path, infoType)
    })
  }

  handleOnClickYesRemoveKidImage = () => {
    let result = this.uploadMemberPhotos('', 'KID', this.state.formData.PhotoOfKid)
    return result
  }
  //#endregion Handle

  //#region Function
  navigateTo = (link) => {
    this.props.navigate('/' + link)
  }

  validateMember = () => {
    let validateMember = false
    /* if (this.state.formData.txtMemberID.length <= 0) {
      this.props.toastErrorMsg('Enter Sr No.', 'txtMemberID')
    } else */ if (this.state.formData.txtKidName.length <= 0) {
      this.props.toastErrorMsg('Enter Kid Name', 'txtKidName')
    } else if (this.state.formData.txtGuardianName.length <= 0) {
      this.props.toastErrorMsg('Enter Guardian Name', 'txtGuardianName')
    } else if (this.state.formData.cmbGuardianRelation.length <= 0) {
      this.props.toastErrorMsg('Enter Guardian Relation', 'cmbGuardianRelation')
    } else if (Number(this.state.formData.txtMobileNoForWhatsApp) <= 0) {
      this.props.toastErrorMsg('Enter MobileNo For WhatsApp', 'txtMobileNoForWhatsApp')
      // } else if (isNaN(Number(this.state.formData.txtAlternateMobileNo))) {
      //   this.props.toastErrorMsg('Enter Alternate Mobile No', 'txtAlternateMobileNo')
      /*  } else if (this.state.formData.txtCardNo.length <= 0) {
         this.props.toastErrorMsg('Enter Member ID', 'txtCardNo') */
      // } else if (this.state.formData.txtEmailID.length <= 0) {
      //   this.props.toastErrorMsg('Enter Email ID', 'txtEmailID')
    } else if (this.state.formData.txtAddress.length <= 0) {
      this.props.toastErrorMsg('Enter Address', 'txtAddress')
      /*  } else if (this.state.formData.txtCardBalance.length <= 0) {
         this.props.toastErrorMsg('Enter Card Balance', 'txtCardBalance') */
    } else if (this.state.formData.cmbStatus.length <= 0) {
      this.props.toastErrorMsg('Enter  Status', 'cmbStatus')
      // } else if (this.state.formData.txtReasonofStatus.length <= 0) {
      //   this.props.toastErrorMsg('Enter Reason of Status', 'txtReasonofStatus')
    } else {
      validateMember = true
    }
    return validateMember
  }

  saveMember = () => {
    // console.log("this.state.fromData", this.state.formData);
    if (this.validateMember()) {
      this.props.notifyProcessing()
      this.setState({ dataLoadStatus: false }, () => {
        let reqData = {
          Op: 'SaveMember',
          bint_memberid: this.state.formData.txtMemberID,
          vac_kidname: this.state.formData.txtKidName,
          // vac_kidphoto: this.state.formData.KidImgLocation,// + this.state.formData.PhotoOfKid,
          vac_guardianname: this.state.formData.txtGuardianName,
          vac_guardianrelation: this.state.formData.cmbGuardianRelation,
          // vac_guardianphoto: this.state.formData.GuardianImgLocation,// + this.state.formData.PhotoOfGuardian,
          vac_mobileno: this.state.formData.txtMobileNoForWhatsApp,
          vac_mobileno2: this.state.formData.txtAlternateMobileNo,
          vac_cardno: this.state.formData.txtCardNo,
          vac_emailid: this.state.formData.txtEmailID,
          vac_address: this.state.formData.txtAddress,
          vac_Guardianadharcardno: this.state.formData.txtGuardianAdharCard,
          // vac_Guardianadharcardphoto: this.state.formData.AdharCardImgLocation,// + this.state.formData.GuardianAdharCardPhoto,
          dec_cardpointbalance: this.state.formData.txtCardBalance,
          vac_status: this.state.formData.cmbStatus,
          vac_reason_of_status: this.state.formData.txtReasonofStatus,
          dat_depositreturndate: (this.state.formData.dtpDepositeReturnDate !== null && this.state.formData.dtpDepositeReturnDate !== undefined ? this.state.formData.dtpDepositeReturnDate : ""),
          dec_depositereturnamount: Number(this.state.formData.txtDepositeReturnAmount),
          vac_kidgender: this.state.formData.cmbKidGender,
          vac_bloodgroup: this.state.formData.txtBloodGroup,
          dat_kiddob: getString_or_Blank4Null(this.state.formData.dtpDOB)
        }
        // console.log("reqData",reqData);
        fetchMember(reqData)
          .then(res => {
            this.props.updateProcessing(res.data.msgType, res.data.message)
            if (res.data.msgType === 'success') {
              let memberID = '',
                CardNo = ''
              if (res.data.MemberID && res.data.CardNo) {
                memberID = res.data.MemberID
                CardNo = res.data.CardNo
              } else {
                memberID = this.state.formData.txtMemberID
                CardNo = this.state.formData.txtCardNo
              }

              this.setState({
                dataLoadStatus: true, disableFields: true,
                formData: {
                  ...this.state.formData,
                  txtMemberID: memberID,
                  txtCardNo: CardNo
                }
              }, () => {
                // this.newMemberEntry()
                // console.log("this.state.formData", this.state.formData);
              })
            }
          })
          .catch(error => {
            this.props.toastMsg('error', 'Unkonwn Error occured in saveMember. ' + error)
          })
      })
    }
  }

  uploadMemberPhotos = (photopath, InfoType, unlink = '') => {
    // console.log("this.state.fromData", this.state.formData);
    if (this.validateMember()) {
      this.props.notifyProcessing()
      this.setState({ dataLoadStatus: false }, () => {
        let reqData = {
          Op: 'UploadMemberPhotos',
          bint_memberid: this.state.formData.txtMemberID,
          FilePath: photopath,
          InfoType,
          Unlink: unlink
        }
        fetchMember(reqData)
          .then(res => {
            this.props.updateProcessing(res.data.msgType, res.data.message)
            if (res.data.msgType === 'success') {
              this.setState({
                dataLoadStatus: true,
              }, () => {
                if (photopath == "" && InfoType == 'KID') {
                  this.Kidphoto.current.removeImage()
                } else if (photopath == "" && InfoType == 'GUARDIANPHOTO') {
                  this.Guardianphoto.current.removeImage()
                } else if (photopath == "" && InfoType == 'GUARDIANAADHARPHOTO') {
                  this.GuardianAdharCardImg.current.removeImage()
                }
                return true
              })
            }
          })
          .catch(error => {
            this.props.toastMsg('error', 'Unkonwn Error occured in saveMember. ' + error)
          })
      })
    }
  }

  newMemberEntry = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        txtMemberID: '',
        txtKidName: '',
        txtGuardianName: '',
        cmbGuardianRelation: '',
        txtMobileNoForWhatsApp: '',
        txtAlternateMobileNo: '',
        txtCardNo: '',
        txtEmailID: '',
        txtGuardianAdharCard: '',
        txtAddress: '',
        txtCardBalance: '',
        cmbStatus: 'Pending',
        txtReasonofStatus: '',
        dtpDepositeReturnDate: '',
        txtDepositeReturnAmount: '',
        PhotoOfKid: '',
        KidImgLocation: '',
        KidImgPath: '',
        PhotoOfGuardian: '',
        GuardianImgLocation: '',
        GuardianImpPath: '',
        GuardianAdharCardPhoto: '',
        AdharCardImgLocation: '',
        AdharCardImgPath: '',
        cmbKidGender: '',
        dtpDOB: '',
        txtBloodGroup: '',
      },
      cmbStatevisible: 'none',
      dataLoadStatus: true,
    }, () => {
      this.navigateTo('MemberMaster')
    })
  }

  onFormLoad = () => {
    // this.props.notifyProcessing()
    this.setState({
      dataLoadStatus: false
    }, () => {
      let EditMemberID = 0
      if (this.props.params.EditMemberID && (this.props.params.EditMemberID.substring(1).length) > 0) {
        var res = this.props.params.EditMemberID.split(":")
        EditMemberID = res[1]
        let reqData = {
          Op: 'EditMemberInfo',
          bint_memberid: EditMemberID
        }
        fetchMember(reqData)
          .then(res => {
            this.props.updateProcessing(res.data.msgType, res.data.message)
            let cmbStatevisible = res.data.MemberList[0].vac_status === 'Block' || res.data.MemberList[0].vac_status === 'Close' ? 'block' : 'none'
            // this.setState({ cmbStatevisible })
            let kidpath = (res.data.MemberList[0].vac_kidphoto != "" ? apiURL + res.data.MemberList[0].vac_kidphoto : PH_KID),
              guardianpath = (res.data.MemberList[0].vac_guardianphoto != "" ? apiURL + res.data.MemberList[0].vac_guardianphoto : PH_GUARDIAN),
              aadharpath = (res.data.MemberList[0].vac_Guardianadharcardphoto != "" ? apiURL + res.data.MemberList[0].vac_Guardianadharcardphoto : PH_GUARDIANADHARCARD)
            this.setState({
              dataLoadStatus: true,
              formData: {
                ...this.state.formData,
                txtMemberID: res.data.MemberList[0].bint_memberid,
                txtKidName: res.data.MemberList[0].vac_kidname,
                txtGuardianName: res.data.MemberList[0].vac_guardianname,
                cmbGuardianRelation: res.data.MemberList[0].vac_guardianrelation,
                txtMobileNoForWhatsApp: res.data.MemberList[0].vac_mobileno,
                txtAlternateMobileNo: res.data.MemberList[0].vac_mobileno2,
                txtCardNo: res.data.MemberList[0].vac_cardno,
                txtEmailID: res.data.MemberList[0].vac_emailid,
                txtGuardianAdharCard: res.data.MemberList[0].vac_Guardianadharcardno,
                txtAddress: res.data.MemberList[0].vac_address,
                txtCardBalance: res.data.MemberList[0].dec_cardpointbalance,
                cmbStatus: res.data.MemberList[0].vac_status,
                txtReasonofStatus: res.data.MemberList[0].vac_reason_of_status,
                dtpDepositeReturnDate: ((res.data.MemberList[0].dat_depositreturndate != null || res.data.MemberList[0].dat_depositreturndate != '') ? get_YMD_from_DMY(res.data.MemberList[0].dat_depositreturndate) : ""),
                txtDepositeReturnAmount: res.data.MemberList[0].dec_depositereturnamount,
                // Kidphoto: res.data.MemberList[0].vac_kidphoto,
                // Guardianphoto: res.data.MemberList[0].vac_guardianphoto,
                // GuardianAdharCardImg: res.data.MemberList[0].vac_Guardianadharcardphoto,

                PhotoOfKid: res.data.MemberList[0].vac_kidphotoname,
                KidImgLocation: kidpath,//apiURL + res.data.MemberList[0].vac_kidphoto,
                KidImgPath: kidpath, //apiURL + res.data.MemberList[0].vac_kidphoto,
                PhotoOfGuardian: res.data.MemberList[0].vac_guardianphotoname,
                GuardianImgLocation: guardianpath, //apiURL + res.data.MemberList[0].vac_guardianphoto,
                GuardianImpPath: guardianpath, //apiURL + res.data.MemberList[0].vac_guardianphoto,
                GuardianAdharCardPhoto: res.data.MemberList[0].vac_Guardianadharcardphotoname,
                AdharCardImgLocation: aadharpath, //apiURL + res.data.MemberList[0].vac_Guardianadharcardphoto,
                AdharCardImgPath: aadharpath, //apiURL + res.data.MemberList[0].vac_Guardianadharcardphoto,

                cmbStatevisible: cmbStatevisible,
                cmbKidGender: res.data.MemberList[0].vac_kidgender,
                dtpDOB: ((res.data.MemberList[0].dat_kiddob != null || res.data.MemberList[0].dat_kiddob != '') ? get_YMD_from_DMY(res.data.MemberList[0].dat_kiddob) : ""),
                txtBloodGroup: res.data.MemberList[0].vac_bloodgroup,
              }
            })
          })
          .catch(error => {
            this.props.toastMsg('error', 'Unkonwn Error occured in onFormLoad. ' + error)
          })
      } else {
        this.setState({
          dataLoadStatus: true
        })
      }
    })
  }

  removeKidImage = () => {
    let ARD = null
    this.setState({
      ARD
    }, () => {
      let ARD = <AlertResponsiveDialog
        labelAgree='Delete'
        labelDisagree='Cancel'
        alertTitle={'Do you want to Delete Kid Photo?'}
        // alertMessage={ }
        handleOnClickYes={() => {
          return this.handleOnClickYesRemoveKidImage()
        }}
        defaultOpenDialog={true}
        onYesClickCloseIfExeSuccessfully={true}
      />
      this.setState({ ARD })
    })
  }

  navigateToPurchaseSubscription = () => {
    localStorage.setItem('MI', this.state.formData.txtMemberID)
    this.navigateTo('PurchaseSubscription:' + this.state.formData.txtCardNo)
  }

  generateMemberCard = () => {
    this.props.notifyProcessing()
    let reqData = [
      { name: 'Op', value: 'MemberCardPrint' },
      { name: 'MemberID', value: this.state.formData.txtMemberID },
    ]

    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", apiURL + 'reportdetail.php');
    form.setAttribute("target", "view");

    reqData.forEach((data) => {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", data.name);
      hiddenField.setAttribute("value", data.value);
      form.appendChild(hiddenField);
      document.body.appendChild(form);
    })

    window.open('', 'view');
    form.submit();

    this.props.updateProcessing('success', 'PDF Open In Next Tab.')
  }
  //#endregion Function

  render() {
    let fixKidFileName = 'Kidphoto_' + this.state.formData.txtMemberID
    let fixGuardianAdharCardFileName = 'GuardianAdharCardImg_' + this.state.formData.txtMemberID
    let fixGuardianFileName = 'Guardianphoto_' + this.state.formData.txtMemberID
    let txtMembershipFeesPaymentDate_Dispalay = this.state.formData.txtMembershipFeesPaymentDate && this.state.formData.txtMembershipFeesPaymentDate !== '' ?
      'block' : 'none'
    let txtMembershipBlockOrCloseDate_Dispalay = this.state.formData.txtMembershipBlockOrCloseDate && this.state.formData.txtMembershipBlockOrCloseDate !== '' ?
      'block' : 'none'
    return (
      <div>
        <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
          <Grid item style={{ marginTop: '-5px' }}>
            <img src={Logo} alt='Logo' style={{ width: '250px', height: 'auto', borderRadius: '166px' }} />
          </Grid>
          <Grid item >
            <ImageUploadIcon
              id='Kidphoto'
              ref={this.Kidphoto}
              default={PH_KID}
              curImageName={this.state.formData.PhotoOfKid}
              curImageLocation={this.state.formData.KidImgLocation}
              curImageFullPath={this.state.formData.KidImgPath}
              onCurImageChange={this.onKidImageChange}
              notResetImageOnUpload={true}
              fixFileName={fixKidFileName}
              uploadLocation='KidPhotos'
              showDeleteIcon={true}
              maxSizeInKB={2000}
              //changeImagePreValidation={this.validateProfiledata}
              displayFileUploadedMsg={false}
              removeImage={this.removeKidImage}
              toastMsg={this.props.toastMsg}
              toastErrorMsg={this.props.toastErrorMsg}
              disableAction={!(this.state.formData.txtCardNo && this.state.formData.txtCardNo.length > 0)}
            />
          </Grid>
          <Grid item>
            <ImageUploadIcon
              id='Guardianphoto'
              ref={this.Guardianphoto}
              default={PH_GUARDIAN}
              curImageName={this.state.formData.PhotoOfGuardian}
              curImageLocation={this.state.formData.GuardianImgLocation}
              curImageFullPath={this.state.formData.GuardianImpPath}
              onCurImageChange={this.onGuardianImageChange}
              notResetImageOnUpload={true}
              fixFileName={fixGuardianFileName}
              uploadLocation='GuardianPhotos'
              showDeleteIcon={true}
              maxSizeInKB={2000}
              //changeImagePreValidation={this.validateProfiledata}
              displayFileUploadedMsg={false}
              //removeImage={this.removePancardImage}
              toastMsg={this.props.toastMsg}
              toastErrorMsg={this.props.toastErrorMsg}
              disableAction={!(this.state.formData.txtCardNo && this.state.formData.txtCardNo.length > 0)}
            />
          </Grid>
          <Grid item>
            <ImageUploadIcon
              id='GuardianAdharCardImg'
              ref={this.GuardianAdharCardImg}
              default={PH_GUARDIANADHARCARD}
              curImageName={this.state.formData.GuardianAdharCardPhoto}
              curImageLocation={this.state.formData.AdharCardImgLocation}
              curImageFullPath={this.state.formData.AdharCardImgPath}
              onCurImageChange={this.onGuardianAdharCardImageChange}
              notResetImageOnUpload={true}
              fixFileName={fixGuardianAdharCardFileName}
              uploadLocation='GuardianAdharCardPhotos'
              showDeleteIcon={true}
              maxSizeInKB={2000}
              //changeImagePreValidation={this.validateProfiledata}
              displayFileUploadedMsg={false}
              //removeImage={this.removePancardImage}
              toastMsg={this.props.toastMsg}
              toastErrorMsg={this.props.toastErrorMsg}
              disableAction={!(this.state.formData.txtCardNo && this.state.formData.txtCardNo.length > 0)}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
          <Grid item>
            <CtTxt
              id='txtMemberID'
              label='Sr No.'
              maxLength={10}
              disabled={true}
              width='130'
              value={this.state.formData.txtMemberID}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='txtKidName'
              label='Kid Name*'
              maxLength={100}
              width='350'
              value={this.state.formData.txtKidName}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='txtGuardianName'
              label='Guardian Name*'
              maxLength={100}
              width='350'
              value={this.state.formData.txtGuardianName}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id='cmbGuardianRelation'
              label='Guardian Relation*'
              items={this.state.GuardianRelation}
              value={this.state.formData.cmbGuardianRelation}
              // disabled={this.state.disabledProfileData.AccountTypeDS}
              handleOnChange={this.handleOnChange}
              width='180'
              maxLength={100}
              colID='GuardianRelation'
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
          <Grid item>
            <CtCmb
              id='cmbKidGender'
              label='Kid Gender'
              items={this.state.Gender}
              value={this.state.formData.cmbKidGender}
              handleOnChange={this.handleOnChange}
              width='120'
              maxLength={50}
              colID='gender'
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtDtp
              id='dtpDOB'
              label='Date of Birth'
              width='130'
              defaultValue={this.state.formData.dtpDOB}
              value={this.state.formData.dtpDOB}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='txtBloodGroup'
              label='Blood Group'
              maxLength={50}
              width='130'
              value={this.state.formData.txtBloodGroup}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
          <Grid item>
            <CtTxtAdornNumWhatsApp
              id='txtMobileNoForWhatsApp'
              label='Mobile No.*'
              width='160'
              maxLength={10}
              value={this.state.formData.txtMobileNoForWhatsApp}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='txtAlternateMobileNo'
              label='Alternate Mob. No'
              maxLength={10}
              width='160'
              value={this.state.formData.txtAlternateMobileNo}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='txtCardNo'
              label='Member ID.'
              maxLength={6}
              // value=''
              disabled={true}
              width='160'
              value={this.state.formData.txtCardNo}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='txtEmailID'
              label='Email ID'
              maxLength={100}
              width='335'
              value={this.state.formData.txtEmailID}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='txtGuardianAdharCard'
              label='Guardian Adhar Card No'
              maxLength={12}
              width='180'
              value={this.state.formData.txtGuardianAdharCard}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
          <Grid item>
            <TextAreaAutoSize
              id='txtAddress'
              label='Address*'
              maxLength={2000}
              width='400'
              value={this.state.formData.txtAddress}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtTxt
              id='txtCardBalance'
              label='Card Balance'
              maxLength={10}
              disabled={true}
              width='150'
              value={this.state.formData.txtCardBalance}
              handleOnChange={this.handleOnChange}
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item>
            <CtCmb
              id='cmbStatus'
              label='Status'
              items={this.state.Status}
              value={this.state.formData.cmbStatus}
              disabled={
                Number(localStorage.getItem('De')) === 0 &&
                (this.state.formData.cmbStatus === 'Close' || this.state.formData.cmbStatus === 'Block')}
              handleOnChange={this.handleOnChange}
              width='110'
              maxLength={50}
              colID='Status'
              onKeyDown={this.props.onKeyDown}
            />
          </Grid>
          <Grid item style={{ display: this.state.cmbStatevisible }}>
            <TextAreaAutoSize
              id='txtReasonofStatus'
              label='Reason of Status'
              maxLength={2000}
              width='340'
              value={this.state.formData.txtReasonofStatus}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
          <Grid item style={{ display: this.state.cmbStatevisible }}>
            <CtDtp
              id='dtpDepositeReturnDate'
              label='Deposite Return Date'
              width='150'
              defaultValue={this.state.formData.dtpDepositeReturnDate}
              value={this.state.formData.dtpDepositeReturnDate}
              onKeyDown={this.props.onKeyDown}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item style={{ display: this.state.cmbStatevisible }}>
            <CtTxt
              id='txtDepositeReturnAmount'
              label='Deposite Return Amount'
              maxLength={10}
              width='200'
              value={this.state.formData.txtDepositeReturnAmount}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0} marginBottom={2}>
          <Grid item style={{ display: 'none' }}>
            <CtBtn label='Transaction History' />
          </Grid>
          <Grid item>
            <CtBtn
              label='Member Card'
              onClick={this.generateMemberCard}
              disabled={(this.state.formData.txtCardNo && this.state.formData.txtCardNo !== '' &&
                (this.state.formData.cmbStatus === 'Pending' || this.state.formData.cmbStatus === 'Active')) ? false : true}
            />
          </Grid>
          <Grid item>
            <CtBtn
              label='Buy Subscription'
              onClick={this.navigateToPurchaseSubscription}
              disabled={(this.state.formData.txtCardNo && this.state.formData.txtCardNo !== '' &&
                (this.state.formData.cmbStatus === 'Pending' || this.state.formData.cmbStatus === 'Active')) ? false : true}
            />
          </Grid>
          <Grid item>
            <CtBtn label='Save' onClick={this.saveMember} />
          </Grid>
          <Grid item>
            <CtBtn label='List' onClick={() => { this.navigateTo('MemberList') }} />
          </Grid>
          <Grid item>
            <CtBtn label='New Member' onClick={this.newMemberEntry} />
          </Grid>
          <Grid item>
            <TransactionHistory />
          </Grid>

          <Grid item style={{ display: txtMembershipFeesPaymentDate_Dispalay }}>
            <CtTxt
              id='txtMembershipFeesPaymentDate'
              label='Membership Fees Payment Date'
              maxLength={10}
              width='245'
              disabled={true}
              value={this.state.formData.txtMembershipFeesPaymentDate}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
          <Grid item style={{ display: txtMembershipBlockOrCloseDate_Dispalay }}>
            <CtTxt
              id='txtMembershipBlockOrCloseDate'
              label='Membership Block or Close Date'
              maxLength={10}
              width='245'
              disabled={true}
              value={this.state.formData.txtMembershipBlockOrCloseDate}
              handleOnChange={this.handleOnChange}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
            {this.state.ARD}
          </Box>
        </Grid>
      </div>
    )
  }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HoCtToastContainer(HOCVerifyIsUser(withRouter(MemberMaster))))