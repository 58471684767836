import React, { Component, createRef } from 'react'
import { Grid, Box } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import { withRouter } from './HOC/withRouter'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import CtBtn from './CustomTool/CtBtn'
import TableList from './CustomTool/TableList'
import { Edit, Delete } from '@mui/icons-material';
import HoCtToastContainer from './HOC/HoCtToastContainer'
import CtTextFieldPWD from './CustomTool/CtTxtPWD'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import { connect } from 'react-redux'
import CtCheckBox from './CustomTool/CtCheckBox'
import { fetchUserDetail } from './API/API'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import CtCmb from './CustomTool/CtCmb'

class UserManagement extends Component {

    state = {
        Designations: [
            { Designation: 'Admin' },
            { Designation: 'User' }
        ],
        columns: [
            { id: 'Action', label: 'Action', name: 'Search' },
            { id: 'bint_srno', label: 'bint_srno', hide: 'true' },
            { id: 'vac_password', label: 'vac_password', hide: 'true' },
            { id: 'vac_privileges', label: 'vac_privileges', hide: 'true' },
            { id: 'vac_userid', label: 'User ID' },
            { id: 'vac_personname', label: 'Person Name' },
            { id: 'vac_mobileno', label: 'Mobile No' },
            { id: 'vac_email', label: 'Email' },
            { id: 'vac_designation', label: 'Designation' },
        ],
        rows: [],
        actionList: [],
        keyColumn: [
            'vac_userid', 'vac_personname', 'vac_mobileno', 'vac_email', 'vac_designation',
            'bint_srno', 'vac_password', 'vac_privileges'
        ],
        searchData: {
            UserID: '',
            PersonName: '',
            MobileNo: '',
            Email: '',
            Designation: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,
        formData: {
            bint_srno: 0,
            txtUserID: '',
            txtPassword: '',
            txtConfirmPassword: '',
            txtPersonName: '',
            txtMobileNo: '',
            txtEmail: '',
            cmbDesignation: '',
            chkMember: false,
            chkUserManagement: false,
            chkProductCategoryMaster: false,
            chkProductMaster: false,
            chkSubscriptionPlan: false,
            chkSubscriptionEntry: false,
            chkRentAtHomeEntry: false,
            chkRentAtShopEntry: false,
            chkSalesEntry: false,
            chkInwardEntry: false,
            chkOutwardEntry: false,

        }
    }

    constructor(props) {
        super(props)
        this.Memberphoto = createRef()
    }

    //#region component
    componentDidMount() {
        this.setActionList()
        document.title = 'TED : User Management'
        this.props.setActiveMenu("UserManagement")
        document.getElementById('txtUserID').focus()
        this.setSearchColumns()
        this.getInfo()
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }

    //#endregion component

    //#region function

    setActionList = () => {
        let actionEdit = { name: 'Edit', icon: <Edit />, link: 'RentAtStore:', actionType: 'AlertResponsive' },
            actionDelete = {
                name: 'Delete', icon: <Delete />, link: 'RentAtStore:', actionType: 'AlertResponsive',
                hide: Number(localStorage.getItem('De')) === 0
            }

        if (Number(localStorage.getItem('De')) === 0) {
            actionEdit.condition = [{ colID: 'vac_designation', value: 'Admin', relationalOperator: '!=' }]
        }

        const actionList = [
            actionEdit,
            actionDelete,
        ]
        this.setState({ actionList })
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push({
            cType: 'CtTxt',
            id: 'txtUserID',
            label: 'User ID',
            value: this.state.searchData.UserID,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',

        }, {
            cType: 'CtTxt',
            id: 'txtPersonName',
            label: 'Person Name',
            value: this.state.searchData.PersonName,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'txtMobileNo',
            label: 'Mobile No',
            value: this.state.searchData.MobileNo,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'txtEmail',
            label: 'Email',
            value: this.state.searchData.Email,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtCmb',
            id: 'cmbDesignation',
            label: 'Designation',
            value: this.state.searchData.Designation,
            items: this.state.Designations,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        })
        this.setState({ searchColumns })
    }

    getInfo = () => {
        let dataLoadStatus = false,
            cmbDesignation = (localStorage.getItem('De') == 0 ? 'User' : '')
        this.setState({ dataLoadStatus }, () => {
            const reqData = {
                'Op': 'UserList',
            }
            fetchUserDetail(reqData)
                .then(res => {
                    dataLoadStatus = true
                    let rows = res.data.UserList,
                        rowsCount = rows.length
                    this.setState({
                        dataLoadStatus,
                        rows, rowsCount,
                        formData: {
                            ...this.state.formData,
                            cmbDesignation,
                        }
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in onFormLoad.', error)
                })
        })
    }

    validateUserManagement = () => {
        let result = false
        if (this.state.formData.txtUserID.length <= 0) {
            this.props.toastErrorMsg('Enter User ID', 'txtUserID')
        } else if (this.state.formData.bint_srno === 0 && this.state.formData.txtPassword.length <= 0) {
            this.props.toastErrorMsg('Enter Password', 'txtPassword')
        } else if (this.state.formData.bint_srno === 0 && this.state.formData.txtConfirmPassword.length <= 0) {
            this.props.toastErrorMsg('Enter Confirm Password', 'txtConfirmPassword')
        } else if (this.state.formData.txtPassword !== this.state.formData.txtConfirmPassword) {
            this.props.toastErrorMsg('Password and Confirm Password Must Be Same', 'txtPassword')
        } else if (this.state.formData.txtPersonName.length <= 0) {
            this.props.toastErrorMsg('Enter Person Name', 'txtPersonName')
        } else if (this.state.formData.txtMobileNo.length <= 0) {
            this.props.toastErrorMsg('Enter Mobile No', 'txtMobileNo')
        } else {
            result = true
        }

        /* else if (this.state.formData.txtEmail.length <= 0) {
            this.props.toastErrorMsg('Enter Email', 'txtEmail')
        } else if (this.state.formData.cmbDesignation.length <= 0) {
            this.props.toastErrorMsg('Enter Designation', 'cmbDesignation')
        } */

        return result
    }

    clearInfo = () => {
        const txtUserID = '', txtPassword = '', txtConfirmPassword = '', txtPersonName = '', txtMobileNo = '', txtEmail = '', cmbDesignation = '', bint_srno = 0

        this.setState({
            formData: {
                ...this.state.formData, txtUserID, txtPassword, txtConfirmPassword,
                txtPersonName, txtMobileNo, txtEmail, cmbDesignation, bint_srno
            }
        }, () => {
            // this.checkUncheckAllPrivileges(false)
            document.getElementById('txtUserID').focus()
        })
    }

    getStrPrivilegesFromCheckBox = (arrPrivileges) => {
        if (arrPrivileges && arrPrivileges.length >= 11) {
            let strPrivileges = ''
            arrPrivileges.forEach(curPrivilege => {
                if (curPrivilege === true) {
                    strPrivileges += '1'
                } else {
                    strPrivileges += '0'
                }
            });
            return strPrivileges
        } else {
            return ''
        }
    }

    setChkPrivilegesFormDataFromString = (strPrivileges) => {
        let i = 0,
            chkMember = false, // 0
            chkUserManagement = false, // 1
            chkProductCategoryMaster = false, // 2
            chkProductMaster = false, // 3
            chkSubscriptionPlan = false, // 4
            chkSubscriptionEntry = false, // 5
            chkRentAtHomeEntry = false, // 6
            chkRentAtShopEntry = false, // 7
            chkSalesEntry = false, // 8
            chkInwardEntry = false, // 9
            chkOutwardEntry = false // 10
        // console.log('array of priv (' + strPrivileges + ') : ', strPrivileges.split(''))
        if (strPrivileges.length >= 11) {
            strPrivileges.split('').forEach((curPrivilege, index) => {
                let curValue = curPrivilege === '1' ? true : false
                switch (index) {
                    case 1:
                        chkMember = curValue
                        break;
                    case 2:
                        chkUserManagement = curValue
                        break;
                    case 3:
                        chkProductCategoryMaster = curValue
                        break;
                    case 4:
                        chkProductMaster = curValue
                        break;
                    case 5:
                        chkSubscriptionPlan = curValue
                        break;
                    case 6:
                        chkSubscriptionEntry = curValue
                        break;
                    case 7:
                        chkRentAtHomeEntry = curValue
                        break;
                    case 8:
                        chkRentAtShopEntry = curValue
                        break;
                    case 9:
                        chkSalesEntry = curValue
                        break;
                    case 10:
                        chkInwardEntry = curValue
                        break;
                    case 11:
                        chkOutwardEntry = curValue
                        break;
                }
            });
        }

        this.setState({
            formData: {
                ...this.state.formData,
                chkMember,
                chkUserManagement,
                chkProductCategoryMaster,
                chkProductMaster,
                chkSubscriptionPlan,
                chkSubscriptionEntry,
                chkRentAtHomeEntry,
                chkRentAtShopEntry,
                chkSalesEntry,
                chkInwardEntry,
                chkOutwardEntry
            }
        })
    }

    checkUncheckAllPrivileges = (checkStatus) => {
        let chkMember = checkStatus,
            chkUserManagement = checkStatus,
            chkProductCategoryMaster = checkStatus,
            chkProductMaster = checkStatus,
            chkSubscriptionPlan = checkStatus,
            chkSubscriptionEntry = checkStatus,
            chkRentAtHomeEntry = checkStatus,
            chkRentAtShopEntry = checkStatus,
            chkSalesEntry = checkStatus,
            chkInwardEntry = checkStatus,
            chkOutwardEntry = checkStatus
        this.setState({
            formData: {
                ...this.state.formData, chkMember,
                chkUserManagement,
                chkProductCategoryMaster,
                chkProductMaster,
                chkSubscriptionPlan,
                chkSubscriptionEntry,
                chkRentAtHomeEntry,
                chkRentAtShopEntry,
                chkSalesEntry,
                chkInwardEntry,
                chkOutwardEntry
            }
        })
    }

    SaveUserManageMent = () => {
        let vac_privileges = this.getStrPrivilegesFromCheckBox([
            this.state.formData.chkMember,
            this.state.formData.chkUserManagement,
            this.state.formData.chkProductCategoryMaster,
            this.state.formData.chkProductMaster,
            this.state.formData.chkSubscriptionPlan,
            this.state.formData.chkSubscriptionEntry,
            this.state.formData.chkRentAtHomeEntry,
            this.state.formData.chkRentAtShopEntry,
            this.state.formData.chkSalesEntry,
            this.state.formData.chkInwardEntry,
            this.state.formData.chkOutwardEntry
        ])
        // console.log('vac_privileges', vac_privileges)

        if (this.validateUserManagement()) {
            const dataLoadStatus = false
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus }, () => {
                let desig = ""
                if (this.state.formData.cmbDesignation && this.state.formData.cmbDesignation.length > 0) {
                    desig = (this.state.formData.cmbDesignation === 'Admin' ? "1" : "0")
                }
                const reqData = {
                    'Op': 'SaveUser',
                    'bint_srno': this.state.formData.bint_srno,
                    'vac_userid': this.state.formData.txtUserID,
                    'vac_password': this.state.formData.txtPassword,
                    'vac_personname': this.state.formData.txtPersonName,
                    'vac_mobileno': this.state.formData.txtMobileNo,
                    'vac_email': this.state.formData.txtEmail,
                    'vac_designation': this.state.formData.cmbDesignation,
                    'vac_privileges': desig + vac_privileges
                }
                fetchUserDetail(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.getInfo()
                            this.clearInfo()
                        }
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in Save User Management.', error)
                    })
            })

        }

    }

    DeleteUserManagement = (bint_srno) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'DeleteUser',
                'bint_srno': bint_srno

            }
            fetchUserDetail(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.setState({ ARD: null }, () => { this.getInfo() })
                    }
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in delete DeleteUserMAnagement. ' + error)
                })
        })
        return false
    }

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }
    //endregion function

    //#region handle
    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.UserID.length === 0 || row['UserID'].toString().includes(this.state.searchData.UserID)) &&
                        (this.state.searchData.PersonName.length === 0 || row['PersonName'].toString().includes(this.state.searchData.PersonName)) &&
                        (this.state.searchData.MobileNo.length === 0 || row['MobileNo'].toString().includes(this.state.searchData.MobileNo)) &&
                        (this.state.searchData.Email.length === 0 || row['Email'].toString().includes(this.state.searchData.Email)) &&
                        (this.state.searchData.Designation === 'ALL' || this.state.searchData.Designation === row['Designation'])

                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleCheckChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.checked
            }
        })
    }

    handleOnChangeNotNullUnDefined = (e) => {
        if (e.target.value !== null && e.target.value !== undefined) {
            this.setState({
                formData: { ...this.state.formData, [e.target.name]: e.target.value }
            })
        }
    }

    handleARDonActionClick = (values) => {
        //      0               1               2               3           4
        // 'vac_userid', 'vac_personname', 'vac_mobileno', 'vac_email', 'vac_designation',
        //      5           6                   7
        // 'bint_srno', 'vac_password', 'vac_privileges'
        const txtUserID = values[0].value,
            txtPersonName = values[1].value,
            txtMobileNo = values[2].value,
            txtEmail = values[3].value,
            cmbDesignation = values[4].value,
            actionName = values[0].actionName,
            bint_srno = values[5].value,
            // txtPassword = values[6].value,
            // txtConfirmPassword = values[6].value,
            strPrivileges = values[7].value
        if (actionName === "Edit") {
            this.setState({
                formData: {
                    ...this.state.formData, txtUserID, txtPersonName, txtMobileNo, txtEmail, cmbDesignation,
                    bint_srno//, txtPassword, txtConfirmPassword
                }
            }, () => {
                console.log('state.formData', this.state.formData)
                this.setChkPrivilegesFormDataFromString(strPrivileges)
            })
        }
        else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete  : ' + txtUserID + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.DeleteUserManagement(bint_srno)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />

                this.setState({ ARD })
            })
        }
        else {
            let rows = this.state.rows.filter((row) => {
                if (row.UserID !== txtUserID && row.PersonName !== txtPersonName &&
                    row.MobileNo !== txtMobileNo && row.Email !== txtEmail && row.Designation !== cmbDesignation)
                    return row
            })
            const rowsCount = rows.length
            this.setState({ rows, rowsCount })
        }
    }
    //#endregion handle

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div>
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title">
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>User Management</b></label>
                </Grid>
                <form>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0} marginTop={0}>
                        <Grid item>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtTxt
                                        id='txtUserID'
                                        label='User ID*'
                                        maxLength={100}
                                        width='130'
                                        value={this.state.formData.txtUserID}
                                        onKeyDown={this.props.onKeyDown}
                                        handleOnChange={this.handleOnChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTextFieldPWD
                                        id='txtPassword'
                                        label='Password*'
                                        maxLength={100}
                                        width='190'
                                        value={this.state.formData.txtPassword}
                                        onKeyDown={this.props.onKeyDown}
                                        handleOnChange={this.handleOnChange}
                                        autoFillPassword={false}
                                    />

                                </Grid>
                                <Grid item>
                                    <CtTextFieldPWD
                                        id='txtConfirmPassword'
                                        label='Confirm Password*'
                                        maxLength={100}
                                        width='190'
                                        value={this.state.formData.txtConfirmPassword}
                                        onKeyDown={this.props.onKeyDown}
                                        handleOnChange={this.handleOnChange}
                                        autoFillPassword={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtPersonName'
                                        label='Person Name*'
                                        maxLength={100}
                                        width='220'
                                        value={this.state.formData.txtPersonName}
                                        onKeyDown={this.props.onKeyDown}
                                        handleOnChange={this.handleOnChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtTxt
                                        id='txtMobileNo'
                                        label='Mobile No*'
                                        maxLength={10}
                                        width='130'
                                        value={this.state.formData.txtMobileNo}
                                        onKeyDown={this.props.onKeyDown}
                                        handleOnChange={this.handleOnChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtTxt
                                        id='txtEmail'
                                        label='Email'
                                        maxLength={100}
                                        width='400'
                                        value={this.state.formData.txtEmail}
                                        onKeyDown={this.props.onKeyDown}
                                        handleOnChange={this.handleOnChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCmb
                                        id='cmbDesignation'
                                        label='Designation*'
                                        items={this.state.Designations}
                                        value={this.state.formData.cmbDesignation}
                                        disabled={(localStorage.getItem('De') == 1 ? false : true)}
                                        handleOnChange={this.handleOnChange}
                                        width={220}
                                        maxLength={50}
                                        colID='Designation'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    Privileges &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <CtBtn label='Selct All Privileges' onClick={() => { this.checkUncheckAllPrivileges(true) }} variant={'outlined'} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <CtBtn label='Clear All Privileges' onClick={() => { this.checkUncheckAllPrivileges(false) }} variant={'outlined'} />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkMember'
                                        label='Member'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkMember}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkUserManagement'
                                        label='User Management'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkUserManagement}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkProductCategoryMaster'
                                        label='Product Category Master'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkProductCategoryMaster}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkProductMaster'
                                        label='Product Master'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkProductMaster}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>

                                <Grid item>
                                    <CtCheckBox
                                        id='chkSubscriptionPlan'
                                        label='Subscription Plan'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkSubscriptionPlan}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkSubscriptionEntry'
                                        label='Subscription Entry'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkSubscriptionEntry}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkRentAtHomeEntry'
                                        label='Rent Entry'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkRentAtHomeEntry}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkRentAtShopEntry'
                                        label='Rent At Shop Entry'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkRentAtShopEntry}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkSalesEntry'
                                        label='Sales Entry'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkSalesEntry}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkInwardEntry'
                                        label='Inward Entry'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkInwardEntry}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtCheckBox
                                        id='chkOutwardEntry'
                                        label='Outward Entry'
                                        // value={this.state.formData.}
                                        checked={this.state.formData.chkOutwardEntry}
                                        handleCheckChange={this.handleCheckChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='Save' onClick={this.SaveUserManageMent} />
                                </Grid>
                                <Grid item>
                                    <CtBtn label='New' onClick={this.clearInfo} />
                                </Grid>
                                {/* <Grid item>
                                    <CtBtn label='Delete' />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </form> <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    // provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                // toolTipNewEntry='New Product Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>


            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HOCVerifyIsUser(HoCtToastContainer(withRouter(UserManagement))))
