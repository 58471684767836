import React, { useState } from 'react'
import { fetchMember } from '../API/API'
import { toast } from 'react-toastify'

const HOCMemberInfo = (ReduxMemberInfoValidator) => {

    const loadMemberDashboardInfo = (setMemberInfo, nextFunctionCall) => {
        let reqData = {
            Op: 'GetKidsInfo',
            vac_cardno: '',
            vac_mobileno: '',
            bint_memberid: Number(localStorage.getItem('MI')),
            vac_kidsname: ''
        }
        fetchMember(reqData)
            .then(res => {
                if (res.data.msgType === 'success') {
                    let memberInfo = {
                        Photo: res.data.KidsInfo[0].vac_kidphoto,
                        KidName: res.data.KidsInfo[0].vac_kidname,
                        GuardianName: res.data.KidsInfo[0].vac_guardianname,
                        CurrentPoints: res.data.KidsInfo[0].dec_cardpointbalance,
                        MobileNo: res.data.KidsInfo[0].vac_mobileno,
                        CardNo: res.data.KidsInfo[0].vac_cardno,
                        MemberID: res.data.KidsInfo[0].bint_memberid,
                        RecentFromDate: res.data.KidsInfo[0].dat_recentfrom,
                        RecentToDate: res.data.KidsInfo[0].dat_recentto,
                        Status: res.data.KidsInfo[0].vac_status
                    }
                    setMemberInfo(memberInfo)
                    localStorage.setItem('MI', res.data.KidsInfo[0].bint_memberid)
                    if (nextFunctionCall) {
                        nextFunctionCall()
                    }
                } else {
                    this.props.toastMsg(res.data.msgType, res.data.message)
                }
            })
            .catch(error => {
                // this.props.toastMsg('error', 'Unkonwn Error occured in onMemberDashboardBtnClick. ' + error)
                console.log('error', 'Unkonwn Error occured in onMemberDashboardBtnClick. ' + error)
            })
    }

    const verifyMemberInfo = (memberInfo, setMemberInfo, nextFunctionCall) => {
        if (
            localStorage.getItem('MI') &&
            Number(localStorage.getItem('MI')) > 0 &&
            Number(memberInfo.MemberID) !== Number(localStorage.getItem('MI'))) {
            loadMemberDashboardInfo(setMemberInfo, nextFunctionCall)
        } else {
            if (nextFunctionCall) {
                nextFunctionCall()
            }
        }
    }

    return (props) => {
        return (
            <div className='ReduxMemberInfoValidator'>
                <ReduxMemberInfoValidator
                    {...props}
                    verifyMemberInfo={verifyMemberInfo}
                    loadMemberDashboardInfo={loadMemberDashboardInfo}
                />
            </div>
        )
    }
}
export default HOCMemberInfo