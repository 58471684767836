import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

class CtCmbEditable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: []
        };
        this.onTagsChange = this.onTagsChange.bind(this);
        this.onTagsInputChange = this.onTagsInputChange.bind(this);
    }

    onTagsChange = (event, values) => {
        const value = values && values[this.props.colID] ? values[this.props.colID] : values
        this.setState({
            tags: value
        }, () => {
            let curValue = value == null ? '' : value
            this.props.handleOnChange({ target: { name: this.props.id, value: curValue } })
        });
    }

    onTagsInputChange = (e, value) => {
        let curValue = value == null ? '' : value
        this.props.handleOnChange({ target: { name: this.props.id, value: curValue } })
    }

    render() {
        const {
            id, label, items, value, width, handleOnChange, handleOnCmbInputChange, disabled, maxLength,
            freeSolo, colID,
            onKeyDown, defaultAction, validateInput
        } = this.props
        const valueFreeSolo = freeSolo && freeSolo === true ? true : false
        let inc = 3
        if (valueFreeSolo) {
            inc = 2
        }
        const useWidthStyle = { width: width + 'px' }
        const valueMaxLength = maxLength && Number(maxLength) > 0 ? Number(maxLength) : 15
        const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, inc, defaultAction, validateInput) }) : (null)
        const defaultProps = {
            options: items,
            getOptionLabel: (option) => option[colID] || option,//option.[this.props.colID] || option,
        };

        return (
            <div >
                <Autocomplete
                    freeSolo={valueFreeSolo}
                    {...defaultProps}
                    autoHighlight
                    id={id}
                    name={id}
                    value={value}
                    disabled={disabled ? true : false}
                    onChange={this.onTagsChange}
                    onInputChange={this.onTagsInputChange}
                    renderInput={(params) => <TextField
                        {...params}
                        label={label}
                        margin="normal"
                        onKeyDown={handleOnKeyDown}
                        inputProps={{ ...params.inputProps, maxLength: valueMaxLength, form: { autocomplete: 'off' } }}
                        style={{ marginTop: '8px', marginBottom: '0px' }}
                        variant='standard'
                    />}
                    style={width > 0 ? useWidthStyle : {}}
                />
            </div >
        );
    }
}
export default CtCmbEditable