import React, { Component } from 'react'
import { Grid, Box, Button, } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import CtDtp from './CustomTool/CtDtp'
import {
    get_DMY_from_YMD, get_YMD_from_SYS, get_YMD_from_DMY, //addPeriod 
} from './SystemUtility/SystemUtility'
import CtBtn from './CustomTool/CtBtn'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import { apiURL } from './API/API'

class TransactionHistorySelection extends Component {
    state = {
        formData: {
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: get_YMD_from_SYS(new Date()),
        },
    }

    //#region handle
    handleOnChange = (e) => {
        console.log('e.target.name,e.target.value', e.target.name, e.target.value)
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            console.log('this.state.formData.dtpFromDate', this.state.formData.dtpFromDate)
        })
    }
    //#endregion handle

    //#region Function
    generateTransactionHistory = () => {
        this.props.notifyProcessing()
        let reqData = [
            { name: 'Op', value: 'TransactionHistory' },
            { name: 'dat_fromdate', value: this.state.formData.dtpFromDate },
            { name: 'dat_todate', value: this.state.formData.dtpToDate },
            { name: 'bint_memberid', value: this.props.memberInfo.MemberID },
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'reportdetail.php');
        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');
        form.submit();

        this.props.updateProcessing('success', 'PDF Open In Next Tab.')
    }
    //#endregion Function
    render() {
        return (
            <div>
                <form>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                        <Grid item>
                            <CtDtp
                                id='dtpFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChange}
                                defaultValue={this.state.formData.dtpFromDate}
                                value={this.state.formData.dtpFromDate}
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtp
                                id='dtpToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChange}
                                defaultValue={this.state.formData.dtpToDate}
                                value={this.state.formData.dtpToDate}
                                onKeyDown={this.props.onKeyDown}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='View Report' width={this.state.cWidth - 150} onClick={this.generateTransactionHistory} />
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(TransactionHistorySelection)))