import React, { Component } from 'react'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { withRouter } from './HOC/withRouter'
import { fetchRentAtHome } from './API/API'
import TableList from './CustomTool/TableList'
import { Grid, Avatar, Box, Button, InputLabel, Alert } from '@mui/material'
import MemberDashboardIcon from './images/memberdashboard.png'
import { Diversity3, Diversity3Rounded } from '@mui/icons-material';
class MemberTable extends Component {

    state = {
        columns: [
            { id: 'Action', label: 'Action', name: 'Search' },
            { id: 'vac_cardno', label: 'Member ID' },
            { id: 'vac_kidname', label: 'Kid Name' },
            { id: 'vac_kidphoto', hide: true },
            { id: 'vac_guardianname', hide: true },
            { id: 'dec_cardpointbalance', hide: true },
            { id: 'vac_mobileno', hide: true },
            { id: 'bint_memberid', hide: true },
            { id: 'dat_recentfrom', hide: true },
            { id: 'dat_recentto', hide: true },
            { id: 'vac_status', hide: true },
        ],
        keyColumn: [
            'vac_cardno', 'vac_kidname', 'vac_kidphoto', 'vac_guardianname', 'dec_cardpointbalance',
            'vac_mobileno', 'bint_memberid', 'dat_recentfrom', 'dat_recentto', 'vac_status',
        ],
        rows: [],
        actionList: [
            {
                name: 'MemberDashboard',
                icon: <Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />,
                actionType: 'AlertResponsive'
            },
            {
                name: 'Group Member',
                icon: <Diversity3 />,
                actionType: 'AlertResponsive'
            },
        ],
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,
        ARD: null,
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
    }

    //#region  component
    componentDidMount() {
        document.title = 'TED : Member Table'
        this.setActionList()
        let rows = this.props.rows,
            rowsCount = rows.length
        this.setState({ rows, rowsCount })
    }
    //#endregion component

    //#region Handle
    handleARDonActionClick = (values) => {
        const actionName = values[0].actionName
        console.log('actionName'+actionName)
        if (actionName === "MemberDashboard") {
            this.props.setReduxMemberInfo_Navigate(
                {
                    data: {
                        msgType: 'success',
                        KidsInfo: [{
                            'vac_cardno': values[0].value,
                            'vac_kidsname': values[1].value,
                            'vac_kidphoto': values[2].value,
                            'vac_guardianname': values[3].value,
                            'dec_cardpointbalance': values[4].value,
                            'vac_mobileno': values[5].value,
                            'bint_memberid': values[6].value,
                            'dat_recentfrom': values[7].value,
                            'dat_recentto': values[8].value,
                            'vac_status': values[9].value
                        }]
                    }
                }
            )
        } else if (actionName === "Group Member") {
            const txtMemberID = values[0].value,
                txtKidName = values[1].value
            this.props.setReduxMemberInfo_Navigate(txtMemberID, txtKidName)
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    setActionList = () => {
        if (this.props.openerForMemberTable == 'MemberDashboard') {
            this.setState({
                actionList: [
                    {
                        name: 'MemberDashboard',
                        icon: <Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />,
                        actionType: 'AlertResponsive'
                    },
                ]
            })
        } else if (this.props.openerForMemberTable == 'GroupMaster') {
            this.setState({
                actionList: [
                    {
                        name: 'Group Member',
                        icon: <Diversity3 />,
                        actionType: 'AlertResponsive'
                    },
                ]
            })
        }
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div>
                <Grid item>
                    <TableList
                        columns={this.state.columns}
                        counter={this.state.counter}
                        rows={rows}
                        rowsCount={rowsCount}
                        rowsPerPage={this.state.rowsPerPage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        onActionClick={this.handleARDonActionClick}
                        actionList={this.state.actionList}
                        keyColumn={this.state.keyColumn}
                    />
                </Grid>
            </div>
        )
    }

}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HOCVerifyIsUser(withRouter(MemberTable)))