import moment from 'moment'

//#region Constants
export const PROCESSING = 'Processing';
export const NO_DATA_FOUND = 'No Data Found';
export const SUCCESS = 'success';
export const ERROR = 'error';
//#endregion

//#region Function
export function getAgeOfDOB(date) {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function validatePANNo(panNo) {
    var result = false;
    if (panNo.length == 10) {
        result = true;
    }
    return result;
}

export function validateIFSCCode(ifscCode) {
    var result = false;
    if (ifscCode.length == 11) {
        result = true;
    }
    return result;
}

export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function getIndianFormatedNumber(number, decimalPlace = 2) {
    number = Number(number)
    return number.toLocaleString('en-IN', {
        maximumFractionDigits: decimalPlace,
        style: 'currency',
        currency: 'INR'
    });
}

export function get_YMD_from_SYS(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function get_DMY_from_YMD(date) {
    var datePart = date.match(/\d+/g), year = datePart[0], month = datePart[1], day = datePart[2];
    return `${day}/${month}/${year}`;
}

export function get_YMD_from_DMY(strDate, separator = '/') {
    var datePart = strDate.toString().split(separator);
    var day = datePart[0], month = datePart[1], year = datePart[2];
    return `${year}-${month}-${day}`;
}

export function getDateAcYearfromDate(strDate) {
    var result = "", month = "", year = "";
    if (strDate.length > 0) {
        // console.log("strDate",strDate);
        var res = strDate.split("-");
        month = res[1];
        // console.log("month:",month);
        year = res[0];
        // console.log("year:",year);
        if (Number(month) >= 1 && Number(month) <= 3) {
            result = Number(year) - Number(1);
        } else if (Number(month) >= 4 && Number(month) <= 12) {
            result = year;
        }
    }
    return result;
}

export function getLastDayofMonthofGivenDate(dateYMD_Dash) {
    let arraySplitFromDt = dateYMD_Dash.split("-"),
        month = Number(arraySplitFromDt[1]),
        year = Number(arraySplitFromDt[0]),
        day = 0,
        newDate = convertStrDate_To_YMD(new Date(year, month, day)),
        arrnewDate = newDate.split("-")
    day = Number(arrnewDate[2])
    if (month < 10)
        month = '0' + month;
    if (day < 10)
        day = '0' + day;
    // return new Date(year, month, 0);
    return [year, month, day].join('-');

}

export function getTowDigits_DayORMonth(intDay_Month) {
    if (Number(intDay_Month) < 10) {
        return '0' + intDay_Month
    } else {
        return intDay_Month
    }
}

export function convertStrDate_To_YMD(strDate) {
    var date = new Date(strDate),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join("-");
}

export function get_DayMonthYear_of_Given_YMD_Date(dateYMD_Dash) {
    let arrDtParts = dateYMD_Dash.split("-"),
        intYear = Number(arrDtParts[0]),
        intMonth = Number(arrDtParts[1]),
        intDay = Number(arrDtParts[2])
    return { intYear, intMonth, intDay }
}

export function get_NextDate_of_Given_YMD_Date(dateYMD_Dash) {
    // console.log("function call get_NextDate_of_Given_YMD_Date");
    // console.log("dateYMD_Dash",dateYMD_Dash);
    var tomorrow = new Date(dateYMD_Dash);
    // console.log("tomorrow",tomorrow);
    tomorrow.setDate(tomorrow.getDate() + 1);
    // console.log("tomorrow",tomorrow);
    return convertStrDate_To_YMD(tomorrow);
}

export function get_FirstDate_of_Given_YMD_Date(dateYMD_Dash) {
    var date = new Date(dateYMD_Dash),
        month = '' + (date.getMonth() + 1),
        day = '' + 1,
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function get_30DaysBack_FromCurrentDate() {
    let n = new Date(), d = new Date();
    d.setDate(n.getDate() - 30);

    var month = '' + (d.getMonth() + (1)),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function get_90DaysBack_FromCurrentDate() {
    let n = new Date(), d = new Date();
    d.setDate(n.getDate() - 90);

    var month = '' + (d.getMonth() + (1)),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function getRowData(Rows, ColID, Data) {
    let resultRow = '';
    Rows.filter((row) => {
        if (row[ColID] == Data) {
            resultRow = row
        }
    });
    return resultRow;
}

export function getTextSummary(strData, startIndex = 0, lastIndex = 5) {
    return strData.substring(startIndex, lastIndex) + (strData.length > 5 ? '...' : '');
}

export function getString_or_Blank4Null(strData) {
    return strData !== null && strData !== undefined ? strData : '';
}

export function formatDateTime(dateVal) {
    // console.log('dateVal', dateVal)
    dateVal = new Date(dateVal)
    const tzoffset = (((5 * 60) + 30) * 60000)//dateVal.getTimezoneOffset() * 60000
    const localTime = new Date(dateVal.getTime() + tzoffset)
    return localTime

    // dateVal = new Date(dateVal).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' })

    /* var newDate = new Date(dateVal);

    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    /* var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
        sAMPM = "PM";
        sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
        sHour = "12";
    } *//*

    sHour = padValue(sHour);

    return sYear + "-" + sMonth + "-" + sDay + " " + sHour + ":" + sMinute;// + " " + sAMPM; */
}

function padValue(value) {
    return (value < 10) ? "0" + value : value;
}
//#endregion