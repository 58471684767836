import React, { Component } from 'react'
import { withRouter } from './HOC/withRouter'
import Logo from './images/logo.png'
import defaultImg from './images/default.png'
import info from './images/info.png'
import saleboard from './images/saleboard.png'
import rent1 from './images/rent1.png'
import returnsale from './images/return.png'
import RentAtHome from './images/RentAtHome.png'
import RentReturn from './images/RentReturn.png'
// import RentAtStore from './images/RentAtStore.png'
import Play from './images/Play.png'
import { Grid, Avatar, Box, Typography, Paper, Card, CardActionArea, CardContent, CardMedia, CardActions, Button, Stack } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Dashboard from '@mui/icons-material/Dashboard'
import TouchApp from '@mui/icons-material/TouchApp'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import Progress from './CustomTool/Progress'
import { fetchMember } from './API/API'
import CtDtp from './CustomTool/CtDtp'
import ArticleIcon from '@mui/icons-material/Article';
import TransactionHistory from './TransactionHistory'

class MemberDashboard extends Component {

    state = {
        dataLoadStatus: true,
        RentAtStore: 'none',
        Sales: 'none',
        RentAtHome: 'none',
        Return: 'none',
        card: {
            ButtonTextColor: '#0A5078',
            Opacity: '0.8'
        },
        formData: {
            dtpFromDate: this.props.memberInfo.RecentFromDate,
            dtpToDate: this.props.memberInfo.RecentToDate,
        }
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Member Dashboard'
        this.props.setActiveMenu("MemberDashboard")
        console.log("Number(localStorage.getItem('MI'))", Number(localStorage.getItem('MI')));
        if (
            localStorage.getItem('MI') &&
            Number(localStorage.getItem('MI')) > 0 &&
            Number(this.props.memberInfo.MemberID) !== Number(localStorage.getItem('MI'))) {
            this.loadMemberDashboardInfo()
        }
        //document.getElementById('txtMobileNo').focus()
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    setDisplayCardState = (ctrlName, value) => {
        this.setState({ [ctrlName]: value })
    }

    loadMemberDashboardInfo = () => {
        this.setState({ dataLoadStatus: false }, () => {
            let reqData = {
                Op: 'GetKidsInfo',
                vac_cardno: '',
                vac_mobileno: '',
                bint_memberid: Number(localStorage.getItem('MI')),
                vac_kidsname: ''
            }
            fetchMember(reqData)
                .then(res => {
                    if (res.data.msgType === 'success') {
                        let memberInfo = {
                            Photo: res.data.KidsInfo[0].vac_kidphoto,
                            KidName: res.data.KidsInfo[0].vac_kidname,
                            GuardianName: res.data.KidsInfo[0].vac_guardianname,
                            CurrentPoints: res.data.KidsInfo[0].dec_cardpointbalance,
                            MobileNo: res.data.KidsInfo[0].vac_mobileno,
                            CardNo: res.data.KidsInfo[0].vac_cardno,
                            MemberID: res.data.KidsInfo[0].bint_memberid,
                            RecentFromDate: res.data.KidsInfo[0].dat_recentfrom,
                            RecentToDate: res.data.KidsInfo[0].dat_recentto,
                            Status: res.data.KidsInfo[0].vac_status
                        }
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            this.props.setMemberInfo(memberInfo)
                            // console.log('res.data.KidsInfo[0].vac_status', res.data.KidsInfo[0].vac_status)
                            localStorage.setItem('MI', res.data.KidsInfo[0].bint_memberid)
                        })
                    } else {
                        this.props.toastMsg(res.data.msgType, res.data.message)
                    }
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in onMemberDashboardBtnClick. ' + error)
                })
        })
    }
    //#endregion

    //#region  Handle

    handleOnChangeTrim = (e) => {
        const ctrlName = e.target.name
        console.log('e.target.name, e.target.checked', e.target.name, e.target.checked)
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        }, () => {
            if (ctrlName === 'rdbSalesListType') {
                // alert(this.state.formData.rdbSalesListType)
                this.listSales()
            }
            console.log(this.state.formData.rdbSalesListType)
            console.log(this.state.checkItemsSalesListType)
        })
    }


    //#endregion Handle

    render() {

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="flex-start" spacing={3} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <img src={Logo} alt='Logo' style={{ width: '250px', height: 'auto', borderRadius: '166px' }} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={3} marginTop={-2} marginLeft={0}>
                            <Grid item>
                                <img src={this.props.memberInfo.Photo} style={{ width: '10vw', height: 'auto' }} />
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={2} marginTop={-2} marginLeft={0}>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                                        <Grid item>
                                            <CtTxt
                                                id='txtMobileNo'
                                                label='Mobile No'
                                                value={this.props.memberInfo.MobileNo}
                                                // handleOnChange={this.handleOnChange}
                                                disabled={true}
                                                width={105}
                                                maxLength='10'
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CtTxtAdornNum
                                                id='txtCardNo'
                                                label='Member ID'
                                                adornment='TED'
                                                value={this.props.memberInfo.CardNo}
                                                readOnly={true}
                                                width='120'
                                                maxLength={11}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CtTxt
                                                id='txtCurrentPoints'
                                                label='Current Points'
                                                width={115}
                                                disabled={true}
                                                value={this.props.memberInfo.CurrentPoints}
                                                // handleOnChange={this.handleOnChange}
                                                // width={this.CtWidth}
                                                maxLength={10}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                                        <Grid item>
                                            <CtTxt
                                                id='txtKidName'
                                                label='Kid Name'
                                                width='450'
                                                disabled={true}
                                                value={this.props.memberInfo.KidName}
                                                maxLength='100'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                                        <Grid item>
                                            <CtTxt
                                                id='txtGuardianName'
                                                label='Guardian Name'
                                                value={this.props.memberInfo.GuardianName}
                                                width='450'
                                                disabled={true}
                                                maxLength='100'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item marginLeft={4}>
                                <Grid container direction="column" spacing={2} marginTop={-2} marginLeft={0}>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0} marginTop={0}>
                                        <Grid item>
                                            <CtDtp
                                                id='dtpFromDate'
                                                label='From Date'
                                                width='120'
                                                handleOnChange={this.handleOnChangeTrim}
                                                defaultValue={this.state.formData.dtpFromDate}
                                                value={this.state.formData.dtpFromDate}
                                                onKeyDown={this.props.onKeyDown}
                                            //disabled={true}
                                            // disabled={this.state.disabledProfileData.DOBDS}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <CtDtp
                                                id='dtpToDate'
                                                label='To Date'
                                                width='120'
                                                handleOnChange={this.handleOnChangeTrim}
                                                defaultValue={this.state.formData.dtpToDate}
                                                value={this.state.formData.dtpToDate}
                                                onKeyDown={this.props.onKeyDown}
                                            //disabled={true}
                                            // disabled={this.state.disabledProfileData.DOBDS}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0} marginTop={1}>
                                        <Grid item style={{ display: this.props.memberInfo.Status === 'Block' || this.props.memberInfo.Status === 'Close' ? 'none' : 'block' }}>
                                            <Button
                                                variant="outlined"
                                                style={{ backgroundColor: '#d6ddf0', borderRadius: '10px' }}
                                                startIcon={<TouchApp />}
                                                onClick={() => { this.navigateTo('PurchaseSubscription') }}>Purchase Subscription
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0} marginTop={1}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                style={{ backgroundColor: '#d6ddf0', borderRadius: '10px', width: '250px', justifyContent: 'flex-start' }}
                                                startIcon={<Dashboard />}
                                                onClick={() => { this.navigateTo('UserDashboard') }}>User Dashboard
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0} marginTop={1}>
                                        <Grid item>
                                            <TransactionHistory />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0} marginTop={1}>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                style={{ backgroundColor: '#d6ddf0', borderRadius: '10px', width: '250px', justifyContent: 'flex-start' }}
                                                startIcon={<Dashboard />}
                                                onClick={() => { this.navigateTo('MemberList') }}>Member List
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item>
                                <Stack m={1} gap={3}>
                                    <Card
                                        sx={{
                                            minWidth: '205px',
                                            maxWidth: '205px',
                                            height: '180px',
                                            ':hover': {
                                                boxShadow: 20,
                                                height: 'auto',
                                                cursor: 'pointer',
                                            },
                                        }}
                                        style={{ backgroundColor: '#c9947a' }}
                                        onMouseOver={() => { this.setDisplayCardState('RentAtStore', 'block') }}
                                        onMouseOut={() => { this.setDisplayCardState('RentAtStore', 'none') }}
                                    >
                                        <Grid container direction={'column'} justifyContent='center' alignItems={'center'} margin={0}>
                                            <Grid item>
                                                <CardMedia
                                                    component="img"
                                                    // height="auto"
                                                    // minWidth={180}
                                                    // height={180}
                                                    sx={{ width: 180 }}
                                                    image={Play}
                                                    onClick={() => { this.navigateTo('RentAtStore') }}
                                                // alt="green iguana"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CardContent
                                                    sx={{
                                                        fontSize: '22px', fontWeight: '800', marginTop: '-37px',
                                                        display: this.state.RentAtStore,
                                                        backgroundColor: '#eeeeff',
                                                        opacity: this.state.card.Opacity,
                                                        color: this.state.card.ButtonTextColor,
                                                        width: '100%',
                                                    }}
                                                    style={{ paddingBottom: '0px', paddingTop: '0px' }}
                                                ><center>Play</center></CardContent>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Stack>
                            </Grid>
                            <Grid item style={{ display: this.props.memberInfo.Status === 'Block' || this.props.memberInfo.Status === 'Close' ? 'none' : 'block' }}>
                                <Stack m={1} gap={3}>
                                    <Card
                                        sx={{
                                            minWidth: '205px',
                                            maxWidth: '205px',
                                            height: '180px',
                                            ':hover': {
                                                boxShadow: 20,
                                                height: 'auto',
                                                cursor: 'pointer',
                                            },
                                        }}
                                        style={{ backgroundColor: '#f9bac4' }}
                                        onMouseOver={() => { this.setDisplayCardState('Sales', 'block') }}
                                        onMouseOut={() => { this.setDisplayCardState('Sales', 'none') }}>
                                        <Grid container direction={'column'} justifyContent='center' alignItems={'center'}>
                                            <Grid item>
                                                <CardMedia
                                                    component="img"
                                                    // height="auto"
                                                    // minWidth={180}
                                                    // height={180}
                                                    sx={{ width: 180 }}
                                                    image={saleboard}
                                                    onClick={() => { this.navigateTo('SalesEntry') }}
                                                // alt="green iguana"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CardContent sx={{
                                                    fontSize: '22px', fontWeight: '800', marginTop: '-37px',
                                                    display: this.state.Sales,
                                                    backgroundColor: '#eeeeff',
                                                    opacity: this.state.card.Opacity,
                                                    color: this.state.card.ButtonTextColor,
                                                    width: '100%',
                                                }}
                                                    style={{ paddingBottom: '0px', paddingTop: '0px', }}><center>Sales</center></CardContent>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Stack>
                            </Grid>
                            <Grid item style={{ display: this.props.memberInfo.Status !== 'Active' ? 'none' : 'block' }}>
                                <Stack m={1} gap={3}>
                                    <Card
                                        sx={{
                                            minWidth: '205px',
                                            maxWidth: '205px',
                                            height: '180px',
                                            ':hover': {
                                                boxShadow: 20,
                                                height: 'auto',
                                                cursor: 'pointer',
                                            },
                                        }}
                                        style={{ backgroundColor: '#B5D793' }}
                                        onMouseOver={() => { this.setDisplayCardState('RentAtHome', 'block') }}
                                        onMouseOut={() => { this.setDisplayCardState('RentAtHome', 'none') }}
                                    >
                                        <Grid container direction={'column'} justifyContent='center' alignItems={'center'}>
                                            <Grid item>
                                                <CardMedia
                                                    component="img"
                                                    // height="auto"
                                                    // minWidth={180}
                                                    // height={180}
                                                    sx={{ width: 180 }}
                                                    image={RentAtHome}
                                                    onClick={() => { this.navigateTo('RentAtHome') }}
                                                // alt="green iguana"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CardContent sx={{
                                                    fontSize: '22px', fontWeight: '800', marginTop: '-37px',
                                                    display: this.state.RentAtHome,
                                                    backgroundColor: '#eeeeff',
                                                    opacity: this.state.card.Opacity,
                                                    color: this.state.card.ButtonTextColor,
                                                    width: '100%',
                                                }}
                                                    style={{ paddingBottom: '0px', paddingTop: '0px', }}><center>Rent</center></CardContent>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Stack>
                            </Grid>
                            <Grid item style={{ display: this.props.memberInfo.Status !== 'Active' ? 'none' : 'block' }}>
                                <Stack m={1} gap={3}>
                                    <Card
                                        sx={{
                                            minWidth: '205px',
                                            maxWidth: '205px',
                                            height: '180px',
                                            ':hover': {
                                                boxShadow: 20,
                                                height: 'auto',
                                                cursor: 'pointer',
                                            },
                                        }}
                                        style={{ backgroundColor: '#B9C5E5' }}
                                        onMouseOver={() => { this.setDisplayCardState('Return', 'block') }}
                                        onMouseOut={() => { this.setDisplayCardState('Return', 'none') }}
                                    >
                                        <Grid container direction={'column'} justifyContent='center' alignItems={'center'}>
                                            <Grid item>
                                                <CardMedia
                                                    component="img"
                                                    // height="auto"
                                                    // minWidth={180}
                                                    // height={180}
                                                    sx={{ width: 180 }}
                                                    image={RentReturn}
                                                    onClick={() => { this.navigateTo('ReturnEntry') }}

                                                // alt="green iguana"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CardContent sx={{
                                                    fontSize: '22px', fontWeight: '800', marginTop: '-37px',
                                                    display: this.state.Return,
                                                    backgroundColor: '#eeeeff',
                                                    opacity: this.state.card.Opacity,
                                                    color: this.state.card.ButtonTextColor,
                                                    width: '100%',
                                                }}
                                                    style={{ paddingBottom: '0px', paddingTop: '0px', }}><center>Return</center></CardContent>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(HOCVerifyIsUser(withRouter(MemberDashboard))))