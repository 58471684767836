import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button, Radio, FormControlLabel } from '@mui/material'
import MemberDashboardIcon from './images/memberdashboard.png'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import CtDtp from './CustomTool/CtDtp'
import CtRadioButtonsGroup from './CustomTool/CtRadioButtonsGroup'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import HOCMemberInfo from './HOC/HOCMemberInfo'
import Progress from './CustomTool/Progress'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { get_30DaysBack_FromCurrentDate, get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'
import { fetchRentAtHome } from './API/API'
import EditRent from './images/RentEdit.png'
import EditReturn from './images/ReturnEdit.png'

class RentAndReturnList extends Component {
    state = {
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_rentsrno', label: 'Challan No' },
            { id: 'dat_rentdateDMY', label: 'Rent Date' },
            { id: 'vac_productcode', label: 'Product Code' },
            { id: 'vac_productcategory', label: 'Product Category' },
            { id: 'vac_productname', label: 'Product Name' },
            { id: 'dat_rentstartdatetime', label: 'Rent Start Date' },
            { id: 'dat_rentendatetime', label: 'Rent End Date' },
            { id: 'dec_penaltyamount', label: 'Penalty Amount' },
            { id: 'dat_actualreturndatetimeDMY', label: 'Actual Return DateTime' },
            { id: 'bint_rentitemsrno', label: 'Rent Item SrNo', hide: 'true' },
            { id: 'dat_rentdate', label: 'dat_rentdate', hide: 'true' },
            { id: 'dat_actualreturndatetime', label: 'dat_actualreturndatetime', hide: 'true' },
        ],
        rows: [],
        formData: {
            dtpFromDate: '',
            dtpToDate: '',
        },
        actionList: [
            {
                name: 'Edit Rent',
                icon: <Avatar src={EditRent} sx={{ width: 40, height: 40 }} />,
                actionType: 'AlertResponsive'
            },
            {
                name: 'Edit Return',
                icon: <Avatar src={EditReturn} sx={{ width: 40, height: 40 }} />,
                actionType: 'Conditional',
                condition: [
                    { colID: 'dat_actualreturndatetimeDMY', value: null, relationalOperator: '!=' },
                    // { colID: 'Rate', value: 0, relationalOperator: '!=' },
                    // { colID: 'FreeMRP', value: 0, relationalOperator: '!=', logicalOperator: '||' }
                ],
            },
            { name: 'Delete', icon: <Delete />, link: 'RentAndReturnList', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'bint_rentsrno', 'bint_rentitemsrno', 'dat_rentdate', 'dat_actualreturndatetime', 'vac_productname','vac_productcode'
        ],
        searchData: {
            bint_rentsrno: '',
            dat_rentdateDMY: '',
            vac_productcode: '',
            vac_productcategory: '',
            vac_productname: '',
            dat_rentstartdatetime: '',
            dat_rentendatetime: '',
            dec_penaltyamount: '',
            dat_actualreturndatetimeDMY: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 10,
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Rent And Return List'
        this.props.setActiveMenu("RentAndReturnList")
        this.setSearchColumns()
        this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo, () => {
            this.onFormLoad()
        })
    }
    //#endregion

    //#region handle
    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rowsCount > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.bint_rentsrno.length === 0 || Number(row['bint_rentsrno']) === Number(this.state.searchData.bint_rentsrno)) &&
                        (this.state.searchData.vac_productcode.length === 0 || row['vac_productcode'].toString().includes(this.state.searchData.vac_productcode)) &&
                        (this.state.searchData.vac_productcategory.length === 0 || row['vac_productcategory'].toString().toLowerCase().includes(this.state.searchData.vac_productcategory.toLowerCase())) &&
                        (this.state.searchData.vac_productname.length === 0 || row['vac_productname'].toString().toLowerCase().includes(this.state.searchData.vac_productname.toLowerCase())) &&
                        (this.state.searchData.dat_rentstartdatetime.length === 0 || row['dat_rentstartdatetime'].toString().includes(this.state.searchData.dat_rentstartdatetime)) &&
                        (this.state.searchData.dat_rentendatetime.length === 0 || row['dat_rentendatetime'].toString().includes(this.state.searchData.dat_rentendatetime)) &&
                        (this.state.searchData.dec_penaltyamount.length === 0 || Number(row['dec_penaltyamount']) === Number(this.state.searchData.dec_penaltyamount)) &&
                        (this.state.searchData.dat_actualreturndatetimeDMY.length === 0 || row['dat_actualreturndatetimeDMY'].toString().includes(this.state.searchData.dat_actualreturndatetimeDMY))

                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        let bint_rentsrno = values[0].value,
            actionName = values[0].actionName,
            bint_rentitemsrno = values[1].value,
            dat_rentdate = values[2].value,
            dat_actualreturndate = values[3].value,
            vac_productname = values[4].value,
            bint_memberid = this.props.memberInfo.MemberID,
            vac_productcode = values[5].value

        if (actionName === "Edit Rent") {
            this.navigateTo('RentAtHome:' + bint_rentsrno + ":" + dat_rentdate)
        } else if (actionName === "Edit Return") {
            dat_actualreturndate = dat_actualreturndate.replaceAll(":","+")
            this.navigateTo('ReturnEntry:' + bint_memberid + ":" + bint_rentsrno + ":" + dat_actualreturndate + ":" + dat_rentdate)
        } else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={"Do you want to Delete Product: '" + vac_productname + "' of Challan No: '" + bint_rentsrno + "' ?"}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteRentAtHomeItemEntry(bint_rentsrno, dat_rentdate, bint_rentitemsrno, bint_memberid, vac_productcode)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }
    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    onFormLoad = () => {
        let dtpFromDate = get_30DaysBack_FromCurrentDate(),
            dtpToDate = get_YMD_from_SYS(new Date())
        this.setState({
            formData: {
                ...this.state.formData,
                dtpFromDate, dtpToDate
            }
        }, () => {
            this.RentAndReturnList()
        })
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'CtTxt',
                id: 'bint_rentsrno',
                label: 'Challan No',
                value: this.state.searchData.bint_rentsrno,
                handleOnChange: this.handleOnSearchInputChange,
                width: '80',
                maxLength: '5'
            },
            {
                cType: 'CtTxt',
                id: 'dat_rentdateDMY',
                label: 'Rent Date',
                value: this.state.searchData.dat_rentdateDMY,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '10'
            },
            {
                cType: 'CtTxt',
                id: 'vac_productcode',
                label: 'Product Code',
                value: this.state.searchData.vac_productcode,
                handleOnChange: this.handleOnSearchInputChange,
                width: '80',
                maxLength: '5'
            },
            {
                cType: 'CtTxt',
                id: 'vac_productcategory',
                label: 'Category',
                value: this.state.searchData.vac_productcategory,
                handleOnChange: this.handleOnSearchInputChange,
                width: '80',
                maxLength: '50'
            },
            {
                cType: 'CtTxt',
                id: 'vac_productname',
                label: 'Product Name',
                value: this.state.searchData.vac_productname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'dat_rentstartdatetime',
                label: 'Rent Start Date',
                value: this.state.searchData.dat_rentstartdatetime,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '50'
            },
            {
                cType: 'CtTxt',
                id: 'dat_rentendatetime',
                label: 'Ret End Date',
                value: this.state.searchData.dat_rentendatetime,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '50'
            },
            {
                cType: 'CtTxt',
                id: 'dec_penaltyamount',
                label: 'Penalty Amount',
                value: this.state.searchData.dec_penaltyamount,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '5'
            },
            {
                cType: 'CtTxt',
                id: 'dat_actualreturndatetimeDMY',
                label: 'Actual Return Date',
                value: this.state.searchData.dat_actualreturndatetimeDMY,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '100'
            },)
        this.setState({ searchColumns })
    }

    RentAndReturnList = () => {
        this.setState({ dataLoadStatus: false }, () => {
            let FromDate = this.state.formData.dtpFromDate,
                ToDate = this.state.formData.dtpToDate
            const reqData = {
                Op: 'RentAndReturnList',
                dat_fromdate: FromDate,
                dat_todate: ToDate,
                bint_memberid: this.props.memberInfo.MemberID
            }
            fetchRentAtHome(reqData)
                .then(res => {
                    const rows = res.data.RentAndReturnList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in RentAndReturnList. ' + error)
                })
        })
    }

    deleteRentAtHomeItemEntry = (ChallanNo, RentDate, RentItemSrNo, bint_memberid, vac_productcode) => {
        if (ChallanNo && ChallanNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteRentAtHomeItemEntry',
                    bint_rentsrno: ChallanNo,
                    bint_rentitemsrno: RentItemSrNo,
                    dat_rentdate: RentDate,
                    bint_memberid,
                    vac_productcode
                }
                fetchRentAtHome(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.RentAndReturnList()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteRentAtHomeItem. ' + error)
                    })
            })
        }
        return true
    }
    //#endregion

    render() {
        // console.log('this.props.memberInfo.MemberID', this.props.memberInfo.MemberID)

        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="flext-start" alignItems="center" className="title" spacing={2} marginLeft={0}>
                    <Grid item xs={4} style={{ marginLeft: '50px' }}>
                        <Button
                            variant="outlined"
                            style={{ backgroundColor: '#d6ddf0', borderRadius: '10px' }}
                            startIcon={<Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />}
                            onClick={() => { this.navigateTo('MemberDashboard') }}>Member Dashboard
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Rent And Return List</b></label>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0}>
                    <Grid item>
                        <CtDtp
                            id='dtpFromDate'
                            label='From Date'
                            width='150'
                            handleOnChange={this.handleOnChangeTrim}
                            defaultValue={this.state.formData.dtpFromDate}
                            value={this.state.formData.dtpFromDate}
                            onKeyDown={this.props.onKeyDown}
                        // disabled={this.state.disabledProfileData.DOBDS}
                        />
                    </Grid>
                    <Grid item>
                        <CtDtp
                            id='dtpToDate'
                            label='To Date'
                            width='150'
                            handleOnChange={this.handleOnChangeTrim}
                            defaultValue={this.state.formData.dtpToDate}
                            value={this.state.formData.dtpToDate}
                            onKeyDown={this.props.onKeyDown}
                        // disabled={this.state.disabledProfileData.DOBDS}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Load' width={this.state.cWidth - 150} onClick={this.RentAndReturnList} />
                    </Grid>
                </Grid>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HOCMemberInfo(HoCtToastContainer(withRouter(RentAndReturnList))))