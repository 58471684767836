export const setMemberInfo = (MemberInfo) => {
    return {
        type: 'SET_MemberInfo',
        MemberInfo
    }
}

export const setSubscriptionInfo = (MemberInfo) => {
    return {
        type: 'SET_SubscriptionInfo',
        MemberInfo
    }
}

export const setCurrentPoints = (MemberInfo) => {
    return {
        type: 'SET_CurrentPoints',
        MemberInfo
    }
}