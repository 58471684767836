export const localBackEndURL = "http://127.0.0.1:8080/Development/TED/ted-kids-management-backend/"
export const onlineTestingBackEndURL = "https://ravitechworld.com/LiveTesting/Ted/BackEndAPI/"
export const domainURL = 'https://toddlersentertainmentden.com/'
export const onlineBackEndURL = domainURL + 'BackEndAPI/'
export const loginPath = './'
// export const apiURL = localBackEndURL
export const apiURL = process.env.NODE_ENV === 'production' ?
    onlineBackEndURL : onlineTestingBackEndURL //localBackEndURL //onlineBackEndURL

const axios = require("axios");
const axiosInstance = axios.create({
    baseURL: apiURL
});

export function fetchUserDetail(data) {
    return axiosInstance.post("userdetail.php", data);
}
export function fetchProductCategoryMaster(data) {
    return axiosInstance.post("productcategorymasterdetail.php", data);
}
export function fetchProductMaster(data) {
    return axiosInstance.post("productmasterdetail.php", data);
}

export function fetchGroupMaster(data) {
    return axiosInstance.post("membergroupdetail.php", data);
}

export function fetchSubscriptionPlan(data) {
    return axiosInstance.post("subscriptionplandetail.php", data);
}
export function fetchSubscriptionEntry(data) {
    return axiosInstance.post("subscriptionentrydetail.php", data);
}

export function fetchSales(data) {
    return axiosInstance.post("salesdetail.php", data);
}

export function fetchMember(data) {
    return axiosInstance.post("memberdetail.php", data);
}
export function fetchTaxList(data) {
    return axiosInstance.post("taxdetail.php", data);
}

export function fetchRentAtStore(data) {
    return axiosInstance.post("rentatstoreentrydetail.php", data);
}

export function fetchInwardList(data) {
    return axiosInstance.post("inwardentrydetail.php", data);
}
export function fetchOutwardList(data) {
    return axiosInstance.post("outwardentrydetail.php", data);
}

export function fetchRentAtHome(data) {
    return axiosInstance.post("rentathomeentrydetail.php", data);
}

export function imageFrontEndBaseURL() {
    return apiURL
}

export function imageBackEndBaseURL() {
    return 'images/'
}

export function removeImage(formData) {
    return axiosInstance.post("ImageUpload.php", formData);
}

export function imageUpload(formData, config) {
    return axiosInstance.post("ImageUpload.php", formData, config)
}
//#region Image API Links & Functions End

export function VerifyIsUser(userID) {
    const reqData = {
        'Op': 'VerifyUserID',
        'vac_userid': userID,
    }
    return fetchUserDetail(reqData)
}

const axiosGRCVInstance = axios.create({
    baseURL: domainURL
});
export function fetchRecaptcha(data) {
    return axiosGRCVInstance.post("recaptcha.verify.php", data);
}