import { useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    let param = useParams();

    return (
      <Component
        navigate={navigate}
        params={param}
        {...props}
      />
    );
  };

  return Wrapper;
};