import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button, Radio, FormControlLabel } from '@mui/material'
import MemberDashboardIcon from './images/memberdashboard.png'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import CtDtp from './CustomTool/CtDtp'
import CtRadioButtonsGroup from './CustomTool/CtRadioButtonsGroup'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { get_30DaysBack_FromCurrentDate, get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'
import { fetchInwardList } from './API/API'

class InwardList extends Component {
    state = {
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'dat_inwarddate', label: 'Inward Date' },
            { id: 'bint_inwardno', label: 'Inward No' },
            { id: 'productinfo', label: 'Product Info' },
        ],
        rows: [],
        formData: {
            dtpFromDate: '',
            dtpToDate: '',
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'InwardList:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'InwardList', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'dat_inwarddate', 'bint_inwardno'
        ],
        searchData: {
            dat_inwarddate: '',
            bint_inwardno: '',
            productinfo: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 10,
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Inward List'
        this.props.setActiveMenu("InwardList")
        this.setSearchColumns()
        this.onFormLoad()
    }
    //#endregion

    //#region handle
    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (searchOpen === true && (
                    (this.state.searchData.dat_inwarddate.length === 0 || row['dat_inwarddate'].toString().includes(this.state.searchData.dat_inwarddate)) &&
                    (this.state.searchData.bint_inwardno.length === 0 || row['bint_inwardno'].toString().includes(this.state.searchData.bint_inwardno)) &&
                    (this.state.searchData.productinfo.length === 0 || row['productinfo'].toString().includes(this.state.searchData.productinfo)))
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        console.log('values', values)
        const txtInwardNo = values[1].value,
            actionName = values[0].actionName,
            dtpInwardDate = get_YMD_from_DMY(values[0].value)

        if (actionName === "Edit") {
            this.navigateTo('InwardEntry:' + txtInwardNo + ":" + dtpInwardDate)
        }
        else if (actionName === "Delete") {
            console.log('dtpInwardDate', dtpInwardDate)
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Inward No: ' + txtInwardNo + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.DeleteInwardList(txtInwardNo, dtpInwardDate)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }
    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    onFormLoad = () => {
        let dtpFromDate = get_30DaysBack_FromCurrentDate(),
            dtpToDate = get_YMD_from_SYS(new Date())
        this.setState({
            formData: {
                ...this.state.formData,
                dtpFromDate, dtpToDate
            }
        }, () => {
            this.getInfo()
        })
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push({
            cType: 'CtTxt',
            id: 'txtInwardDate',
            label: 'InwardDate',
            value: this.state.searchData.InwardDate,
            handleOnChange: this.handleOnSearchInputChange,
            width: '150',
            maxLength: '100'
        },
            {
                cType: 'CtTxt',
                id: 'txtInwardNo',
                label: 'Inward No',
                value: this.state.searchData.InwardNo,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            }, {
            cType: 'CtTxt',
            id: 'txtProductInfo',
            label: 'ProductInfo',
            value: this.state.searchData.ProductInfo,
            handleOnChange: this.handleOnSearchInputChange,
            width: '120',
            maxLength: '100'
        },)
        this.setState({ searchColumns })
    }


    getInfo = () => {
        this.setState({ dataLoadStatus: false }, () => {
            let FromDate = this.state.formData.dtpFromDate,
                ToDate = this.state.formData.dtpToDate
            const reqData = {
                Op: 'InwardEntryList',
                FromDate,
                ToDate,
            }
            fetchInwardList(reqData)
                .then(res => {
                    const rows = res.data.InwardEntryList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in getInfo. ' + error)
                })
        })
    }

    DeleteInwardList = (txtInwardNo, dtpInwardDate) => {
        if (txtInwardNo && txtInwardNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteInwardEntry',
                    'bint_inwardno': txtInwardNo,
                    'dat_inwarddate': dtpInwardDate
                }
                fetchInwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.getInfo()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in DeleteInwardList. ' + error)
                    })
            })
        }
        return true
    }

    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Inward List</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0}>
                    <Grid item>
                        <CtDtp
                            id='dtpFromDate'
                            label='From Date'
                            width='150'
                            handleOnChange={this.handleOnChangeTrim}
                            defaultValue={this.state.formData.dtpFromDate}
                            value={this.state.formData.dtpFromDate}
                            onKeyDown={this.props.onKeyDown}
                        // disabled={this.state.disabledProfileData.DOBDS}
                        />
                    </Grid>
                    <Grid item>
                        <CtDtp
                            id='dtpToDate'
                            label='To Date'
                            width='150'
                            handleOnChange={this.handleOnChangeTrim}
                            defaultValue={this.state.formData.dtpToDate}
                            value={this.state.formData.dtpToDate}
                            onKeyDown={this.props.onKeyDown}
                        // disabled={this.state.disabledProfileData.DOBDS}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Load' width={this.state.cWidth - 150} onClick={this.getInfo} />
                    </Grid>
                </Grid>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={() => { this.navigateTo('InwardEntry') }}
                    toolTipNewEntry='New Inward Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(InwardList)))