import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import CtDtp from './CustomTool/CtDtp'
import CtBtn from './CustomTool/CtBtn'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import CtTxt from './CustomTool/CtTxt'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { fetchInwardList, fetchOutwardList } from './API/API'
import CtCmb from './CustomTool/CtCmb'
import CtMultiLineText from './CustomTool/CtMultiLineText'

class OutwardItemEntry extends Component {

    state = {
        ProductCode: [],
        ProductName: [],
        ProductCategory: [],

        formDataItem: {
            EditOutwardNo: '',
            EditOutwardItemNo: '',
            txtProductCode: '',
            cmbProductCategory: '',
            cmbProductName: '',
            txtqty: '',
            txtnote: '',
            EditOutwardDate: '',

        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'OutwardItemEntry:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'OutwardItemEntry', actionType: 'AlertResponsive' },
        ],
        dataLoadStatus: true,
        ARD: null,
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Outward Item Entry'
        this.props.setActiveMenu("OutwardItemEntry")
        //  this.setSearchColumns()
        // this.getInfo()
    }
    //#endregion

    //#region handle
    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }
    handleOnChangeSales = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    //#endregion handle

    //#region Function
    ValidateOutwardItemEntry = () => {
        let result = false
        if (this.state.formDataItem.txtProductCode.length <= 0) {
            this.props.toastErrorMsg('Enter Product Code', 'txtProductCode')
        } else if (this.state.formDataItem.cmbProductCategory.length <= 0) {
            this.props.toastErrorMsg('Enter Product category', 'cmbProductCategory')
        } else if (this.state.formDataItem.cmbProductName.length <= 0) {
            this.props.toastErrorMsg('Enter Product Name', 'cmbProductName')
        } else if (this.state.formDataItem.txtnote.length <= 0) {
            this.props.toastErrorMsg('Enter Note', 'txtnote')
        } else if (this.state.formDataItem.txtqty.length <= 0) {
            this.props.toastErrorMsg('Enter Quantity', 'txtqty')
        }

        else {
            result = true
        }
        return result
    }

    SaveOutwardItemEntry = () => {
        if (this.ValidateOutwardItemEntry()) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'SaveOutwardItemEntry',
                    bint_outwardno: this.state.formDataItem.EditInwardNo,
                    bint_outwarditemno: this.state.formDataItem.EditInwardItemNo,
                    vac_productcode: this.state.formDataItem.txtProductCode,
                    vac_productcategory: this.state.formDataItem.cmbProductCategory,
                    vac_productname: this.state.formDataItem.cmbProductName,
                    dec_qty: this.state.formDataItem.txtqty,
                    vac_note: this.state.formDataItem.txtnote,
                    dat_outwarddate: this.state.formDataItem.EditInwardDate,
                }
                fetchOutwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let txtOutwardNo = res.data.OutwardInfo[0].bint_outwardno,
                                OutwardItemEntryList = res.data.OutwardItemEntryList
                            this.setState({
                                dataLoadStatus: true,
                                formDataItem: {
                                    ...this.state.formData,
                                    EditOutwardDate: this.props.EditOutwardDate,
                                    EditOutwardNo: txtOutwardNo
                                },
                            }, () => {
                                //  this.props.setSalesInfo(txtInwardNo, InwardItemEntryList)
                                this.onNewEntryClick()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in saveOutwardItem. ' + error)
                    })
            })
        }
    }
    onNewEntryClick = () => {
        this.setState({
            formDataItem: {
                ...this.state.formData,
                txtProductCode: '',
                cmbProductCategory: '',
                cmbProductName: '',
                txtnote: '',
                txtqty: '',
            },
        }, () => {
            document.getElementById('txtProductCode').focus()
        })
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false,
            ProductCategoryList: this.props.ProductCategoryList,
            ProductNameList: this.props.ProductNameList,
        }, () => {
            let EditOutwardNo = 0, EditOutwardDate = '', EditItemSrNo = 0
            if (Number(this.props.EditOutwardNo) > 0) {
                EditOutwardNo = this.props.EditOutwardNo
                EditOutwardDate = this.props.EditOutwardDate
            }
            if (Number(this.props.EditItemSrNo) > 0) {
                EditItemSrNo = this.props.EditItemSrNo
                this.setState({
                    dataLoadStatus: true, EditOutwardNo, EditOutwardDate, EditItemSrNo,
                    formDataItem: {
                        ...this.state.formDataItem,
                        EditOutwardDate,
                        EditOutwardNo,
                        EditItemSrNo: this.props.rowItem.bint_itemsrno,
                        txtProductCode: this.props.rowItem.vac_productcode,
                        cmbProductCategory: this.props.rowItem.vac_productcategory,
                        cmbProductName: this.props.rowItem.vac_productname,
                        txtnote: this.props.rowItem.vac_note,
                        txtqty: this.props.rowItem.dec_qty,
                    }
                }, () => {
                    document.getElementById('txtProductCode').focus()
                })
            } else {
                this.setState({
                    dataLoadStatus: true,
                }, () => {
                    this.onNewEntryClick()
                })
            }
        })
    }

    DeleteOutwardItemEntry = (OutwardNo, OutwardItemNo, OutwardDate) => {
        if (OutwardNo && OutwardNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteOutwardItemEntry',
                    bint_outwardno: OutwardNo,
                    bint_outwarditemno: OutwardItemNo,
                    dat_outwarddate: OutwardDate,
                }
                fetchOutwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let txtOutwardNo = OutwardNo,
                                OutwardItemEntryList = res.data.OutwardItemEntryList
                            this.setState({
                                dataLoadStatus: true,
                                formDataItem: {
                                    ...this.state.formData,
                                    EditOutwardDate: this.props.EditOutwardDate,
                                    EditOutwardNo: txtOutwardNo
                                },
                            }, () => {
                                this.props.setSalesInfo(txtOutwardNo, OutwardItemEntryList)
                                this.onNewEntryClick()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteOutwardItem. ' + error)
                    })
            })
            return true
        }
    }


    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    // getInfo = () => {
    //     this.setState({ dataLoadStatus: false }, () => {
    //         let FromDate = this.state.formData.dtpFromDate,
    //             ToDate = this.state.formData.dtpToDate
    //         const reqData = {
    //             Op: 'InwardEntryList',
    //             FromDate,
    //             ToDate,
    //         }
    //         fetchInwardList(reqData)
    //             .then(res => {
    //                 const rows = res.data.ProductMasterList
    //                 const rowsCount = rows.length
    //                 this.setState({ rows, rowsCount, dataLoadStatus: true })
    //             })
    //             .catch(error => {
    //                 this.props.toastMsg('error', 'Unkonwn Error occured in getInfo. ' + error)
    //             })
    //     })
    // }
    //#endregion function

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Outward Item Entry</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtProductCode'
                                    label='Product Code'
                                    value={this.state.formDataItem.txtProductCode}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    width={140}
                                    maxLength={100}
                                // colID='vac_taxname' 
                                />
                            </Grid>
                            <Grid item>
                                <CtCmb
                                    id='cmbProductCategory'
                                    label='Product Category'
                                    items={this.state.ProductCategory}
                                    value={this.state.formDataItem.cmbProductCategory}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    width={180}
                                    maxLength={100}
                                    colID='vac_productcategory'
                                />

                            </Grid>
                            <Grid item>
                                <CtCmb
                                    id='cmbProductName'
                                    label='Product Name'
                                    items={this.state.ProductName}
                                    value={this.state.formDataItem.cmbProductName}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    width={150}
                                    maxLength={100}
                                    colID='vac_productname'
                                />

                            </Grid>
                            <Grid item>
                                <CtMultiLineText
                                    id='txtNote'
                                    label='Note'
                                    value={this.state.formDataItem.txtnote}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    width={485}
                                    maxLength={2000}
                                />

                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtQty'
                                    label='Qty'
                                    value={this.state.formDataItem.txtnote}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    width={130}
                                    maxLength={10}
                                // colID='vac_taxname' 
                                />

                            </Grid>

                            <Grid item>
                                <CtBtn label='Add' width={this.state.cWidth - 150} onClick={this.SaveOutwardItemEntry} />
                            </Grid>
                            {localStorage.getItem('De') == 1 ?
                                <Grid item>
                                    <CtBtn label='Remove' width={this.state.cWidth - 150} onClick={this.DeleteOutwardItemEntry} />
                                </Grid>
                                : ""}
                            <Grid item>
                                <CtBtn label='New' width={this.state.cWidth - 150} onClick={this.onNewEntryClick} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>

            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(OutwardItemEntry)))                                                                                                                                                                     