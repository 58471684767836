import React, { Component } from 'react'
import { Grid, Avatar, Button } from '@mui/material'
import MemberDashboardIcon from './images/memberdashboard.png'
import CtDtp from './CustomTool/CtDtp'
import CtTxt from './CustomTool/CtTxt'
import CtCmb from './CustomTool/CtCmb'
import CtBtn from './CustomTool/CtBtn'
import { connect } from 'react-redux'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import HOCMemberInfo from './HOC/HOCMemberInfo'
import { get_YMD_from_SYS, convertStrDate_To_YMD } from './SystemUtility/SystemUtility'
import { fetchSubscriptionPlan, fetchSubscriptionEntry, apiURL } from './API/API'
import { getRowData } from './SystemUtility/SystemUtility'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import { Edit, Delete } from '@mui/icons-material';
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

class PurchaseSubscription extends Component {

    state = {
        SubscriptionPlan: [],
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_subscriptionid', label: 'Subscription ID' },
            { id: 'dat_dateDMY', label: 'Date' },
            { id: 'vac_planname', label: 'Subscription Plan' },
            { id: 'dat_startdateDMY', label: 'Start Date' },
            { id: 'dat_expirydateDMY', label: 'Expiry Date' },
            { id: 'dec_planamountwithgst', label: 'Plan Amount' },
            { id: 'dec_planpoints', label: 'Subscription Points' },
            { id: 'dec_curpointsbalance', label: 'Current Card Points' },
            { id: 'dec_newpointsbalance', label: 'New Card Points' },
            { id: 'dec_membershipwithgst', label: 'Membership' },
            { id: 'dec_deposite', label: 'Membership Deposite' },
            { id: 'dec_totalamt', label: 'Total Points' },
            { id: 'vac_mobilenoWA', label: 'Mobile No', hide: 'true' },
            { id: 'dat_date', label: 'Date', hide: 'true' },
            { id: 'dat_startdate', label: 'Start Date', hide: 'true' },
            { id: 'dat_expirydate', label: 'Expiry Date', hide: 'true' },
            { id: 'dec_membership', label: 'Membership', hide: 'true' },
        ],
        rows: [],
        formData: {
            dtpFromDate: this.props.memberInfo.RecentFromDate,
            dtpToDate: this.props.memberInfo.RecentToDate,
            lstFromDate: '',
            lstFToDate: '',
            txtSubscriptionID: '',
            dtpDate: get_YMD_from_SYS(new Date()),
            txtCardNo: this.props.memberInfo.CardNo,
            txtKidName: this.props.memberInfo.KidName,
            txtMobileNo: this.props.memberInfo.MobileNo,
            cmbSubscriptionPlan: '',
            dtpStartDate: get_YMD_from_SYS(new Date()),
            dtpExpiryDate: '',
            txtPlanAmountIncludingGST: '',
            txtPoints: '',
            txtCurrentCardPoints: 0,
            txtNewCardPoints: '',
            txtMemberShipChargeWithGst: '',
            txtMemberShipCharge: '',
            txtMemberShipTax: '',
            txtMemberShipDeposit: '',
            txtTotalAmount: '',
            dec_planamount: 0,
        },
        count: 0,
        txtMemberShipTaxSetCount: 0,
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'PurchaseSubscription:', actionType: 'AlertResponsive' },
            // { name: 'Delete', icon: <Delete />, link: 'PurchaseSubscription:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 }, 
            /* *** On deletion first system need to check for Recent subscription of the Member or any of the Group. 
            Option 1: If found, Set that period as a member subscription period. Else set member subscription period as a blank/NULL and status='Pending'.
            Option 2: If found, nothing to do. Else set status='Pending'. */
            { name: 'WhatsAppIcon', icon: <WhatsAppIcon />, link: 'PurchaseSubscription:', actionType: 'AlertResponsive' },
        ],
        keyColumn: [
            'bint_subscriptionid', 'dat_date', 'vac_planname', 'dat_startdate', 'dat_expirydate',
            'dec_planpoints', 'dec_planamountwithgst', 'dec_curpointsbalance',
            'dec_newpointsbalance', 'dec_membershipwithgst', 'dec_deposite', 'dec_totalamt', 'vac_mobilenoWA',
            'dec_membership'
        ],
        searchData: {
            bint_subscriptionid: '',
            dat_dateDMY: '',
            vac_planname: '',
            dat_startdateDMY: '',
            dat_expirydateDMY: '',
            dec_planamountwithgst: '',
            dec_planpoints: '',
            dec_curpointsbalance: '',
            dec_newpointsbalance: '',
            dec_membership: '',
            dec_deposite: '',
            dec_totalamt: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,

    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Purchase Subscription'
        this.props.setActiveMenu("PurchaseSubscription")
        this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo, () => {
            if (this.props.memberInfo.MemberID) {
                this.onFormLoad()
            }
        })
        this.setSearchColumns()
        this.LoadDataIntoCmb()
    }
    //#endregion

    //#region Handle
    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rowsCount > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.bint_subscriptionid.length === 0 || Number(row['bint_subscriptionid']) === Number(this.state.searchData.bint_subscriptionid)) &&
                        (this.state.searchData.vac_planname.length === 0 || row['vac_planname'].toString().toLowerCase().includes(this.state.searchData.vac_planname.toLowerCase())) &&
                        (this.state.searchData.dec_planpoints.length === 0 || Number(row['dec_planpoints']) === Number(this.state.searchData.dec_planpoints)) &&
                        (this.state.searchData.dec_planamountwithgst.length === 0 || Number(row['dec_planamountwithgst']) === Number(this.state.searchData.dec_planamountwithgst)) &&
                        (this.state.searchData.dec_curpointsbalance.length === 0 || Number(row['dec_curpointsbalance']) === Number(this.state.searchData.dec_curpointsbalance)) &&
                        (this.state.searchData.dec_newpointsbalance.length === 0 || Number(row['dec_newpointsbalance']) === Number(this.state.searchData.dec_newpointsbalance)) &&
                        (this.state.searchData.dec_membership.length === 0 || Number(row['dec_membership']) === Number(this.state.searchData.dec_membership)) &&
                        (this.state.searchData.dec_deposite.length === 0 || Number(row['dec_deposite']) === Number(this.state.searchData.dec_deposite)) &&
                        (this.state.searchData.dec_totalamt.length === 0 || Number(row['dec_totalamt']) === Number(this.state.searchData.dec_totalamt))

                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleARDonActionClick = (values) => {
        const txtSubscriptionID = values[0].value,
            dtpDate = values[1].value,
            cmbSubscriptionPlan = values[2].value,
            dtpStartDate = values[3].value,
            dtpExpiryDate = values[4].value,
            txtPoints = values[5].value,
            txtPlanAmountIncludingGST = values[6].value,
            txtCurrentCardPoints = values[7].value,
            txtNewCardPoints = values[8].value,
            txtMemberShipChargeWithGst = values[9].value,
            txtMemberShipDeposit = values[10].value,
            txtTotalAmount = values[11].value,
            txtMobileNo = values[12].value,
            txtMemberShipCharge = values[13].value,
            actionName = values[0].actionName
        if (actionName === "Edit") {
            this.setState({
                formData: {
                    ...this.state.formData, txtSubscriptionID, dtpDate, cmbSubscriptionPlan,
                    dtpStartDate, dtpExpiryDate, txtPoints, txtPlanAmountIncludingGST, txtCurrentCardPoints,
                    txtNewCardPoints, txtMemberShipCharge, txtMemberShipDeposit, txtTotalAmount, txtMobileNo,
                    txtMemberShipChargeWithGst

                }
            })
        }
        /*   else if (actionName === "Delete") {
              this.setState({ ARD: null },
                  () => {
                      let ARD = <AlertResponsiveDialog
                          labelAgree='Delete'
                          labelDisagree='Cancel'
                          alertTitle={'Do you want to Delete  : ' + txtProductName + ' ?'}
                          alertMessage=''
                          handleOnClickYes={() => {
                              return this.DeleteProductMaster(txtId)
                          }}
                          defaultOpenDialog={true}
                          onYesClickCloseIfExeSuccessfully={true}
                      />
  
                      this.setState({ ARD })
                  })
          } */
        else if (actionName === "WhatsAppIcon") {
            const text1 = "Dear", text2 = ",", text3 = "\nSubsription For", text4 = "has been \nActivited as per below details.", text5 = "\nCard No :",
                text6 = "\nSubscription Name :", text7 = "\nStart Date :", text8 = "\nExpiry Date :", text9 = "\nAmount Paid :", text10 = "\nAvailabel Points :", text11 = "\n\nThanks and Regrads,", text12 = "\nTED", text13 = "\n(Toddlers Entertainment Den)";
            const url = text1.concat(" ", this.props.memberInfo.GuardianName, text2, " ", text3, " ", this.props.memberInfo.KidName, " ", text4, " ", text5, " ", this.props.memberInfo.CardNo, " ", text6, " ", cmbSubscriptionPlan, " ", text7, " ", dtpStartDate, " ", text8, " ", dtpExpiryDate, " ", text9, " ", txtTotalAmount, " ", text10, " ", this.props.memberInfo.CurrentPoints, text11, text12, text13);
            window.open("https://api.whatsapp.com/send?phone=+91" + txtMobileNo + "&text=" + encodeURI(url))

        }
        /* else {
            let rows = this.state.rows.filter((row) => {
                if (row.bint_subscriptionid !== txtSubscriptionID &&
                    row.dat_date !== dtpDate &&
                    row.vac_planname !== cmbSubscriptionPlan &&
                    row.dat_startdate !== dtpStartDate,
                    row.dat_expirydate !== dtpExpiryDate &&
                    row.dec_planpoints !== txtPoints &&
                    row.dec_planamountwithgst !== txtPlanAmountIncludingGST &&
                    row.dec_curpointsbalance !== txtCurrentCardPoints &&
                    row.dec_newpointsbalance !== txtNewCardPoints &&
                    row.dec_membership !== txtMemberShipCharge &&
                    row.dec_deposite !== txtMemberShipDeposit &&
                    row.dec_totalamt !== txtTotalAmount &&
                    row.vac_mobilenoWA !== txtMobileNo


                )

                    return row
            })
            const rowsCount = rows.length
            this.setState({ rows, rowsCount })
        } */
    }

    handleOnChange = (e) => {
        const ctrlName = e.target.name,
            ctrlValue = e.target.value
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            // console.log("this.state.SubscriptionPlan", this.state.SubscriptionPlan);
            if (ctrlName === 'cmbSubscriptionPlan') {
                const curSubscriptionPlan = getRowData(this.state.SubscriptionPlan, 'vac_planname', ctrlValue)
                // console.log('curSubscriptionPlan', curSubscriptionPlan)
                let txtPoints = curSubscriptionPlan.dec_cardrechargepoint,
                    txtNewCardPoints = txtPoints + this.state.formData.txtCurrentCardPoints,
                    txtPlanAmountIncludingGST = curSubscriptionPlan.dec_planamountwithgst,
                    dec_planamount = curSubscriptionPlan.dec_planamount
                const dtpExpiryDate = this.getDtpExpiryDate(curSubscriptionPlan)
                this.setState({
                    formData: { ...this.state.formData, txtPoints, txtNewCardPoints, txtPlanAmountIncludingGST, dtpExpiryDate, dec_planamount }
                }, () => {
                    this.calculateTotalAmount()
                })
            } else if (ctrlName === 'dtpStartDate') {
                let curdtpStartDate = getRowData(this.state.SubscriptionPlan, 'vac_planname', this.state.formData.cmbSubscriptionPlan)
                const dtpExpiryDate = this.getDtpExpiryDate(curdtpStartDate)
                this.setState({
                    formData: { ...this.state.formData, dtpExpiryDate }
                })
            } else if (ctrlName === 'lstFromDate' || ctrlName === 'lstFToDate') {
                this.setState({
                    count: 0
                })
            } else if (ctrlName === 'txtMemberShipChargeWithGst' || ctrlName === 'txtMemberShipDeposit' || ctrlName === 'txtPlanAmountIncludingGST') {
                this.calculateTotalAmount()
            }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    //#endregion Handle

    //#region function

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    getCurrentFinancialYear = () => {
        let lstFromDate = '', lstFToDate = ''
        let today = new Date();
        if ((today.getMonth() + 1) <= 3) {
            lstFromDate = ((today.getFullYear()) - 1) + "-04-01"
            lstFToDate = (today.getFullYear()) + "-03-31"
            //  fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
        } else {
            lstFromDate = today.getFullYear() + "-04-01"
            lstFToDate = (today.getFullYear() + 1) + "-03-31"
            // fiscalyear = "01-04-" + today.getFullYear() + " To " + "31-03-" + (today.getFullYear() + 1)
        }
        return { lstFromDate, lstFToDate }

    }

    getDtpExpiryDate = (curdtpStartDate) => {
        let Year = Number(curdtpStartDate.dec_year) * Number(365),
            Months = Number(curdtpStartDate.dec_month) * Number(30),
            Days = curdtpStartDate.dec_days,
            daysValue = Number(Year) + Number(Months) + Number(Days)
        const dtpExpiryDate = convertStrDate_To_YMD(window.addPeriod(this.state.formData.dtpStartDate, Number(daysValue), 0, 0))
        return dtpExpiryDate
    }

    LoadDataIntoCmb = () => {
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            const reqData = {
                'Op': 'SubscriptionPlanList',
            }
            fetchSubscriptionPlan(reqData)
                .then(res => {
                    dataLoadStatus = true
                    let SubscriptionPlan = res.data.SubscriptionPlanList
                    this.setState({
                        dataLoadStatus,
                        SubscriptionPlan
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in LoadDataIntoCmb.', error)
                })
        })
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'CtTxt',
                id: 'bint_subscriptionid',
                label: 'ID',
                value: this.state.searchData.bint_subscriptionid,
                handleOnChange: this.handleOnSearchInputChange,
                width: '60',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'Date',
                width: '100'
            },
            // {
            //     cType: 'CtTxt',
            //     id: 'dat_dateDMY',
            //     label: 'Date',
            //     value: this.state.searchData.dat_dateDMY,
            //     handleOnChange: this.handleOnSearchInputChange,
            //     width: '200',
            //     maxLength: '100'
            // },
            {
                cType: 'CtTxt',
                id: 'vac_planname',
                label: 'Subscription Plan',
                value: this.state.searchData.vac_planname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'Start Date',
                width: '100'
            },
            // {
            //     cType: 'CtTxt',
            //     id: 'dat_startdateDMY',
            //     label: 'Start DateDMY',
            //     value: this.state.searchData.dat_startdateDMY,
            //     handleOnChange: this.handleOnSearchInputChange,
            //     width: '120',
            //     maxLength: '100'
            // },
            {
                cType: 'Lbl',
                label: 'Expiry Date',
                width: '100'
            },
            // {
            //     cType: 'CtTxt',
            //     id: 'dat_expirydateDMY',
            //     label: 'Expiry DateDMY',
            //     value: this.state.searchData.dat_expirydateDMY,
            //     handleOnChange: this.handleOnSearchInputChange,
            //     width: '120',
            //     maxLength: '100'
            // },
            {
                cType: 'CtTxt',
                id: 'dec_planamountwithgst',
                label: 'Plan Amount',
                value: this.state.searchData.dec_planamountwithgst,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'dec_planpoints',
                label: 'Subscription Points',
                value: this.state.searchData.dec_planpoints,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'dec_curpointsbalance',
                label: 'Current Card Points',
                value: this.state.searchData.dec_curpointsbalance,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'dec_newpointsbalance',
                label: 'New Card Points',
                value: this.state.searchData.dec_newpointsbalance,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'dec_membership',
                label: 'Membership',
                value: this.state.searchData.dec_membership,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'dec_deposit',
                label: 'Membership Deposite',
                value: this.state.searchData.dec_deposit,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'dec_totalamt',
                label: 'Total Amount',
                value: this.state.searchData.dec_totalamt,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
        )
        this.setState({ searchColumns })
    }

    getTaxName = (taxValue) => {
        return Number(taxValue && taxValue.length > 0 ? taxValue.toString().replace("%", "").replace("Nil", "") : 0)
    }

    calculateTotalAmount = () => {
        const NumberTax = this.getTaxName(this.state.formData.txtMemberShipTax),
            txtMemberShipCharge = Math.round(((Number(this.state.formData.txtMemberShipChargeWithGst) / (100 + Number(NumberTax))) * 100) * 100) / 100
        const txtTotalAmount = Number(this.state.formData.txtPlanAmountIncludingGST) + Number(this.state.formData.txtMemberShipChargeWithGst) + Number(this.state.formData.txtMemberShipDeposit)
        this.setState({
            formData: { ...this.state.formData, txtTotalAmount, txtMemberShipCharge }
        })
    }

    CalculateMemberShipChargeDepositeAmount = () => {
        // console.log("this.props.memberInfo.Status", this.props.memberInfo.Status);
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            const reqData = {
                'Op': 'GetMembershipChargeTax',
            }
            fetchSubscriptionEntry(reqData)
                .then(res => {
                    dataLoadStatus = true
                    let MembershipChargeTax = res.data.GetMembershipChargeTax[0].vac_taxname
                    // console.log("MembershipChargeTax", MembershipChargeTax);
                    this.setState({
                        dataLoadStatus,
                    }, () => {
                        if (this.props.memberInfo.Status === 'Pending') {
                            let txtMemberShipChargeWithGst = 1000,
                                txtMemberShipCharge = 1000,
                                txtMemberShipTax = 'Nil',
                                txtMemberShipDeposit = 1500,
                                NumberTax = 0
                            if (MembershipChargeTax !== 'Nil') {
                                txtMemberShipTax = MembershipChargeTax
                                NumberTax = this.getTaxName(MembershipChargeTax)
                                txtMemberShipChargeWithGst = txtMemberShipCharge + Math.round(txtMemberShipCharge * (NumberTax / 100))
                            }
                            let txtTotalAmount = Number(this.state.formData.txtPlanAmountIncludingGST) + txtMemberShipChargeWithGst + txtMemberShipDeposit
                            this.setState({
                                formData: { ...this.state.formData, txtMemberShipChargeWithGst, txtMemberShipDeposit, txtTotalAmount, txtMemberShipTax, txtMemberShipCharge }
                            }, () => {
                                // console.log("this.state.formData",this.state.formData);
                            })
                        } else {
                            const txtMemberShipChargeWithGst = 0, txtMemberShipDeposit = 0,
                                txtTotalAmount = 0, txtMemberShipTax = MembershipChargeTax,
                                txtMemberShipCharge = 0
                            this.setState({
                                formData: { ...this.state.formData, txtMemberShipChargeWithGst, txtMemberShipDeposit, txtTotalAmount, txtMemberShipTax, txtMemberShipCharge }
                            })
                        }
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in GetMembershipChargeTax.', error)
                })
        })
    }

    validateSubscriptionEntry = () => {
        let validateSubscriptionEntry = false
        // console.log('this.props.memberInfo.KidName', this.props.memberInfo.KidName)
        if (this.props.memberInfo.CardNo.length <= 0) {
            this.props.toastErrorMsg('Enter Member ID', 'txtCardNo')
        }
        // else if (this.props.memberInfo.KidName.length <= 0) {
        //     this.props.toastErrorMsg('Enter Kid Name', 'txtKidName')
        // } 
        else if (this.props.memberInfo.MobileNo.length <= 0) {
            this.props.toastErrorMsg('Enter Mobile No', 'txtMobileNo')
        } else if (this.state.formData.dtpDate.length <= 0) {
            this.props.toastErrorMsg('Enter Date', 'dtpDate')
        } else if (Number(this.state.formData.txtMemberShipChargeWithGst) <= 0 && this.state.formData.cmbSubscriptionPlan !== undefined && this.state.formData.cmbSubscriptionPlan.length <= 0) {
            this.props.toastErrorMsg('Enter Subscription Plan', 'cmbSubscriptionPlan')
        } else if (this.state.formData.dtpStartDate.length <= 0) {
            this.props.toastErrorMsg('Enter Start Date', 'dtpStartDate')
        } else if (this.state.formData.cmbSubscriptionPlan !== undefined && this.state.formData.cmbSubscriptionPlan.length > 0 && this.state.formData.dtpExpiryDate.length <= 0) {
            this.props.toastErrorMsg('Enter Expiry Date', 'dtpExpiryDate')
        } else if (this.state.formData.cmbSubscriptionPlan !== undefined && this.state.formData.cmbSubscriptionPlan.length > 0 && this.state.formData.txtPoints.length <= 0) {
            this.props.toastErrorMsg('Enter Subscription Points', 'txtPoints')
        } else if (this.state.formData.cmbSubscriptionPlan !== undefined && this.state.formData.cmbSubscriptionPlan.length > 0 && this.state.formData.txtNewCardPoints.length <= 0) {
            this.props.toastErrorMsg('Enter New Card Points', 'txtNewCardPoints')
        } else if (this.state.formData.cmbSubscriptionPlan !== undefined && this.state.formData.cmbSubscriptionPlan.length > 0 && this.state.formData.txtPlanAmountIncludingGST.length <= 0) {
            this.props.toastErrorMsg('Enter Plan Amount Including GST', 'txtPlanAmountIncludingGST')
        }
        // else if (this.state.formData.txtMemberShipDeposit.length <= 0) {
        //     this.props.toastErrorMsg('Enter MemberShip Deposite', 'txtMemberShipDeposit')
        // }
        else {
            validateSubscriptionEntry = true
        }
        /*else if (this.state.formData.txtSubscriptionID.length <= 0) {
            this.props.toastErrorMsg('Enter Subscription ID', 'txtSubscriptionID')
        }else if (this.state.formData.txtCurrentCardPoints.length <= 0) {
            this.props.toastErrorMsg('Enter Current Card Points', 'txtCurrentCardPoints')
        } */
        return validateSubscriptionEntry
    }

    SaveSubscriptionEntry = (printInvoice = false) => {
        if (this.validateSubscriptionEntry()) {

            // console.log('console', console)

            // console.log("this.props.memberInfo.MemberID", this.props.memberInfo);
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'SaveSubscriptionEntry',
                    bint_subscriptionid: this.state.formData.txtSubscriptionID,
                    dat_date: this.state.formData.dtpDate,
                    vac_cardno: this.props.memberInfo.CardNo,
                    bint_memberid: this.props.memberInfo.MemberID,
                    vac_planname: this.state.formData.cmbSubscriptionPlan,
                    dat_startdate: this.state.formData.dtpStartDate,
                    dat_expirydate: this.state.formData.dtpExpiryDate,
                    dec_planamountwithgst: this.state.formData.txtPlanAmountIncludingGST,
                    dec_planamount: this.state.formData.dec_planamount,
                    vac_plantaxname: '18%',
                    dec_deposite: this.state.formData.txtMemberShipDeposit,
                    dec_depositewithgst: this.state.formData.txtMemberShipDeposit,
                    vac_depositetaxname: 'Nil',
                    dec_membership: this.state.formData.txtMemberShipCharge,
                    vac_membershiptaxname: this.state.formData.txtMemberShipTax,
                    dec_membershipwithgst: this.state.formData.txtMemberShipChargeWithGst,
                    dec_totalamt: Number(this.state.formData.txtPlanAmountIncludingGST) + Number(this.state.formData.txtMemberShipDeposit) + Number(this.state.formData.txtMemberShipChargeWithGst),
                    dec_planpoints: Number(this.state.formData.txtPoints),
                    dec_curpointsbalance: Number(this.state.formData.txtCurrentCardPoints),
                    dec_newpointsbalance: Number(this.state.formData.txtNewCardPoints),
                    MemberStatus: this.props.memberInfo.Status
                }
                fetchSubscriptionEntry(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                count: 0
                            }, () => {
                                this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo)
                                if (printInvoice == true) {
                                    this.printInvoice(res.data.bint_subscriptionid, this.state.formData.dtpDate)
                                }
                                this.SubscriptionList()
                                this.newSubscription()
                                this.props.setSubscriptionInfo({
                                    CurrentPoints: res.data.dec_cardpointbalance,
                                    RecentFromDate: res.data.dat_recentfrom,
                                    RecentToDate: res.data.dat_recentto,
                                    Status: res.data.vac_status
                                })
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in saveSubscription. ' + error)
                    })
            })
        }
    }

    SubscriptionList = () => {
        if (Number(this.props.memberInfo.MemberID) > 0 && Number(this.state.count) === 0) {
            let count = Number(this.state.count) + 1
            this.setState({ count })
            let dataLoadStatus = false
            this.setState({ dataLoadStatus }, () => {
                const reqData = {
                    'Op': 'SubscriptionList',
                    dat_fromdate: this.state.formData.lstFromDate,
                    dat_todate: this.state.formData.lstFToDate,
                    bint_memberid: this.props.memberInfo.MemberID
                }
                fetchSubscriptionEntry(reqData)
                    .then(res => {
                        dataLoadStatus = true
                        let rows = res.data.SubscriptionList,
                            rowsCount = rows.length
                        this.setState({
                            dataLoadStatus,
                            rows, rowsCount,
                        })
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in SubscriptionList.', error)
                    })
            })
        }
    }

    newSubscription = () => {
        this.props.loadMemberDashboardInfo(this.props.setMemberInfo)
        let txtSubscriptionID = '',
            dtpDate = get_YMD_from_SYS(new Date()),
            cmbSubscriptionPlan = '',
            dtpStartDate = get_YMD_from_SYS(new Date()),
            dtpExpiryDate = '',
            txtPlanAmountIncludingGST = '',
            dec_planamount = 0,
            txtPoints = '',
            txtCurrentCardPoints = 0,
            txtNewCardPoints = '',
            txtMemberShipCharge = '',
            txtMemberShipTax = '',
            txtMemberShipChargeWithGst = '',
            txtMemberShipDeposit = '',
            txtTotalAmount = ''
        this.setState({
            dataLoadStatus: true,
            formData: {
                ...this.state.formData,
                txtSubscriptionID,
                dtpDate,
                cmbSubscriptionPlan,
                dtpStartDate,
                dtpExpiryDate,
                txtMemberShipCharge,
                txtMemberShipTax,
                txtPlanAmountIncludingGST,
                dec_planamount,
                txtPoints,
                txtCurrentCardPoints,
                txtNewCardPoints,
                txtMemberShipChargeWithGst,
                txtMemberShipDeposit,
                txtTotalAmount
            }
        })
    }

    printInvoice = (SubscriptionID, dtpDate) => {
        this.props.notifyProcessing()
        let reqData = [
            { name: 'Op', value: 'SubscriptionInvoiceReport' },
            { name: 'invoice_no', value: SubscriptionID },
            { name: 'date', value: dtpDate },
            { name: 'header_footer', value: false },
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'reportdetail.php');

        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');

        form.submit();
        this.newSubscription()
        this.props.updateProcessing('success', 'PDF Open in next Tab.')
    }

    onFormLoad = () => {
        this.setState({
            count: 0,
            formData: {
                ...this.state.formData,
                dtpFromDate: this.props.memberInfo.RecentFromDate,
                dtpToDate: this.props.memberInfo.RecentToDate,
                txtSubscriptionID: '',
                dtpDate: '',
                txtCardNo: this.props.memberInfo.CardNo,
                txtKidName: this.props.memberInfo.KidName,
                txtMobileNo: this.props.memberInfo.MobileNo,
                cmbSubscriptionPlan: '',
                dtpStartDate: get_YMD_from_SYS(new Date()),
                dtpExpiryDate: '',
                txtPlanAmountIncludingGST: '',
                txtPoints: '',
                txtCurrentCardPoints: 0,
                txtNewCardPoints: '',
                txtPlanAmountIncludingGST: '',
                txtMemberShipChargeWithGst: '',
                txtMemberShipDeposit: '',
                txtTotalAmount: ''
            },
        }, () => {
            let { lstFromDate, lstFToDate } = this.getCurrentFinancialYear()
            this.setState({
                formData: { ...this.state.formData, lstFromDate, lstFToDate }
            }, () => {
                this.SubscriptionList()
            })
        })
    }
    //#endregion function

    render() {
        // this.SubscriptionList()
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        if (this.state.txtMemberShipTaxSetCount === 0 &&
            (this.props.memberInfo.CardNo !== this.state.formData.txtCardNo ||
                (
                    this.state.formData.txtCardNo && this.state.formData.txtCardNo.length > 0 &&
                    this.state.formData.txtMemberShipTax === ''
                ))
        ) {
            this.setState({ txtMemberShipTaxSetCount: (Number(this.state.txtMemberShipTaxSetCount) + 1) }, () => {
                if (Number(this.state.txtMemberShipTaxSetCount) === 1) {
                    const txtCardNo = this.props.memberInfo.CardNo
                    this.setState({ formData: { ...this.state.formData, txtCardNo } }, () => {
                        if (this.state.formData.txtCardNo && Number(this.state.formData.txtCardNo) > 0) {
                            this.CalculateMemberShipChargeDepositeAmount()
                        }
                    })
                }
            })
        }

        return (
            <div>
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title">
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Subscription Entry</b></label>
                </Grid>
                <Grid container direction="row" justifyContent={'center'} alignItems={'center'} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0} marginTop={0}>
                            <Grid item>
                                Current Subscription
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtDtp
                                    id='dtpFromDate'
                                    label='From Date'
                                    width='150'
                                    handleOnChange={this.handleOnChange}
                                    defaultValue={this.props.memberInfo.RecentFromDate}
                                    value={this.props.memberInfo.RecentFromDate}
                                    onKeyDown={this.props.onKeyDown}
                                    disabled={true}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                            <Grid item>
                                <CtDtp
                                    id='dtpToDate'
                                    label='To Date'
                                    width='150'
                                    handleOnChange={this.handleOnChange}
                                    defaultValue={this.props.memberInfo.RecentToDate}
                                    value={this.props.memberInfo.RecentToDate}
                                    onKeyDown={this.props.onKeyDown}
                                    disabled={true}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtCardNo'
                                    label='Member ID'
                                    width='155'
                                    maxLength={100}
                                    value={this.props.memberInfo.CardNo}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtKidName'
                                    label='Kid Name'
                                    disabled={true}
                                    maxLength={100}
                                    width='350'
                                    value={this.props.memberInfo.KidName}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtMobileNo'
                                    label='Mobile No'
                                    disabled={true}
                                    width='120'
                                    maxLength={10}
                                    value={this.props.memberInfo.MobileNo}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0} marginTop={5}>
                            <Grid item>
                                New Subscription Details
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtSubscriptionID'
                                    label='Subscription ID'
                                    width='120'
                                    disabled={true}
                                    value={this.state.formData.txtSubscriptionID}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtDtp
                                    id='dtpDate'
                                    label='Date*'
                                    width='150'
                                    handleOnChange={this.handleOnChange}
                                    defaultValue={this.state.formData.dtpDate}
                                    value={this.state.formData.dtpDate}
                                    onKeyDown={this.props.onKeyDown}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                            <Grid item>
                                <CtCmb
                                    id='cmbSubscriptionPlan'
                                    label='Subscription Plan*'
                                    items={this.state.SubscriptionPlan}
                                    value={this.state.formData.cmbSubscriptionPlan}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    width='190'
                                    maxLength={100}
                                    colID='vac_planname'
                                />
                            </Grid>
                            <Grid item>
                                <CtDtp
                                    id='dtpStartDate'
                                    label='Start Date*'
                                    width='150'
                                    handleOnChange={this.handleOnChange}
                                    defaultValue={this.state.formData.dtpStartDate}
                                    value={this.state.formData.dtpStartDate}
                                    onKeyDown={this.props.onKeyDown}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                            <Grid item>
                                <CtDtp
                                    id='dtpExpiryDate'
                                    label='Expiry Date'
                                    width='150'
                                    handleOnChange={this.handleOnChange}
                                    defaultValue={this.state.formData.dtpExpiryDate}
                                    value={this.state.formData.dtpExpiryDate}
                                    onKeyDown={this.props.onKeyDown}
                                    disabled={true}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtPoints'
                                    label='Subscription Points'
                                    disabled={true}
                                    width='150'
                                    value={this.state.formData.txtPoints}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtCurrentCardPoints'
                                    label='Current Card Points'
                                    disabled={true}
                                    width='150'
                                    value={this.state.formData.txtCurrentCardPoints}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtNewCardPoints'
                                    label='New Card Points'
                                    disabled={true}
                                    width='130'
                                    value={this.state.formData.txtNewCardPoints}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtPlanAmountIncludingGST'
                                    label='Plan Amount Including GST'
                                    disabled={true}
                                    width='210'
                                    value={this.state.formData.txtPlanAmountIncludingGST}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtMemberShipChargeWithGst'
                                    label='Member Ship Charge'
                                    // disabled={true}
                                    width='160'
                                    maxLength='10'
                                    value={this.state.formData.txtMemberShipChargeWithGst}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtMemberShipDeposit'
                                    label='Membership Deposit'
                                    width='160'
                                    maxLength='10'
                                    value={this.state.formData.txtMemberShipDeposit}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtTotalAmount'
                                    label='Total Amount'
                                    disabled={true}
                                    width='110'
                                    value={this.state.formData.txtTotalAmount}
                                    handleOnChange={this.handleOnChange}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtBtn label='Subscribe' onClick={() => { this.SaveSubscriptionEntry(true) }} />
                            </Grid>
                            <Grid item style={{ marginLeft: '50px' }}>
                                <Button
                                    variant="outlined"
                                    style={{ backgroundColor: '#d6ddf0', borderRadius: '10px' }}
                                    startIcon={<Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />}
                                    onClick={() => { this.navigateTo('MemberDashboard') }}>Member Dashboard
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                        <Grid item>
                            <CtDtp
                                id='lstFromDate'
                                label='From Date'
                                width='150'
                                handleOnChange={this.handleOnChange}
                                defaultValue={this.state.formData.lstFromDate}
                                value={this.state.formData.lstFromDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtDtp
                                id='lstFToDate'
                                label='To Date'
                                width='150'
                                handleOnChange={this.handleOnChange}
                                defaultValue={this.state.formData.lstFToDate}
                                value={this.state.formData.lstFToDate}
                                onKeyDown={this.props.onKeyDown}
                            // disabled={this.state.disabledProfileData.DOBDS}
                            />
                        </Grid>
                        <Grid item>
                            <CtBtn label='Load' onClick={this.SubscriptionList} />
                        </Grid>
                    </Grid>
                </Grid> <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                // toolTipNewEntry='New Product Entry'
                />

            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HOCMemberInfo(HoCtToastContainer(withRouter(PurchaseSubscription))))