import React, { Component } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { Search, Close, Add } from '@mui/icons-material'
import { Tooltip, IconButton, Checkbox } from '@mui/material'
import CtCheckBox from './CtCheckBox'
// import CtTxtAdornNum from './CtTxtAdornNum'
import CtCmb from './CtCmb'
import CtTxt from './CtTxt'
import CtPhoneNumber from './CtPhoneNumber'
import CtMultiLineText from './CtMultiLineText'

class TableList extends Component {

  state = {
    page: 0,
    rows: [
      {
        ID: '',
        Scheme: '',
        ReferenceCode: '',
        SponsorId: '',
        Name: '',
        MobileNo: '',
      }
    ],
    rowsCount: 0,
    columns: [],
    searchColumns: [],
    searchOpen: false,
    searchData: {},
    provideSearch: this.props.provideSearch,
    autoEnableDisalbe_Col_Info: [],
  }

  listResult = "Processing the List"

  handleChangeColumnsRows = () => {
    const columns = this.props.columns
    const rows = this.props.rows
    const rowsCount = this.props.rowsCount
    this.setState({ columns, rows, rowsCount })
  }

  setPage = (pageNumber) => {
    this.setState({ page: pageNumber })
  }

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage }, () => { console.log('rowsPerPage in TableList.js ', rowsPerPage) })
  }

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = (event, tblIndex) => {
    this.setRowsPerPage(+event.target.value);
    this.setPage(0);
    if (this.props.handleChangeRowsPerPage) {
      this.props.handleChangeRowsPerPage(event.target.value, tblIndex)
    }
  };

  componentDidMount() {
    this.handleChangeColumnsRows()
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      rows: newProps.rows,
      rowsCount: newProps.rowsCount,
      searchColumns: newProps.searchColumns,
      searchData: newProps.searchData,
      provideSearch: newProps.provideSearch
    })
  }

  displayCurrentCell = (colIndex, column, value, row, rowIndex) => {
    let cellValue = ''
    if (colIndex === 0 && this.props.actionList && this.props.actionList.length > 0) {
      cellValue = this.props.actionList.map((action, actionIndex) => {

        let result = null
        if (
          (action.actionType && action.actionType === 'Conditional' && action.condition && action.condition.length > 0) ||
          (action.condition && action.condition.length > 0)
        ) {
          let conditionFinalResult = false, iterationResults = []

          action.condition.forEach((curcondition) => {
            let conditionResult = false
            if (curcondition.relationalOperator === undefined || curcondition.relationalOperator == '==') {
              if (row[curcondition.colID] == curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '===') {
              if (row[curcondition.colID] === curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '!=') {
              if (row[curcondition.colID] != curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '!==') {
              if (row[curcondition.colID] !== curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '<=') {
              if (row[curcondition.colID] <= curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '<') {
              if (row[curcondition.colID] < curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '>=') {
              if (row[curcondition.colID] >= curcondition.value)
                conditionResult = true
            } else if (curcondition.relationalOperator && curcondition.relationalOperator == '>') {
              if (row[curcondition.colID] > curcondition.value)
                conditionResult = true
            }
            iterationResults.push({
              conditionResult,
              logicalOperator: curcondition.logicalOperator && curcondition.logicalOperator.length > 0 ? curcondition.logicalOperator : null
            })
          })

          if (iterationResults.length === 1) {
            conditionFinalResult = iterationResults[0].conditionResult
          } else {
            iterationResults.forEach((curResult) => {
              if (curResult.logicalOperator === null) {
                conditionFinalResult = curResult.conditionResult
              } else {
                if (curResult.logicalOperator === '&&') {
                  conditionFinalResult = (conditionFinalResult && curResult.conditionResult)
                } else {
                  conditionFinalResult = (conditionFinalResult || curResult.conditionResult)
                }
              }
            })
          }

          // console.log('conditionFinalResult @ colIndex', colIndex, conditionFinalResult)
          // console.log('iterationResults @ colIndex', colIndex, iterationResults);
          // console.log('action.condition', action.condition)
          // console.log('row', row)

          if (conditionFinalResult === true) {
            result = this.getActionIcon(action, row)
          }
        } else if (action.hide !== undefined) {
          if (!(action.hide === true || action.hide === 'true')) {
            result = this.getActionIcon(action, row)
          }
        } else if (action.actionType && action.actionType === 'Chk') {
          if (this.props.handleOnTableCellCtrlValueChange) {
            // this.props.handleOnTableCellCtrlValueChange(row, { colID })
            let actionCtrlID = this.getCtrlID(rowIndex, colIndex, actionIndex)
            if (row[actionCtrlID] === undefined && action.CtrlProps && action.CtrlProps.checked !== undefined) {
              this.props.handleOnTableCellCtrlValueChange('Chk', row, { target: { name: actionCtrlID, checked: action.CtrlProps.checked } }, rowIndex, colIndex, actionIndex)
            }
            result = <CtCheckBox
              label={action.CtrlProps && action.CtrlProps.label !== undefined ? action.CtrlProps.label : ''}
              checked={row[actionCtrlID] !== undefined ?
                row[actionCtrlID] :
                (action.CtrlProps && action.CtrlProps.checked !== undefined ? action.CtrlProps.checked : false)
              }
              id={actionCtrlID}
              handleCheckChange={(e) => { this.props.handleOnTableCellCtrlValueChange('Chk', row, e, rowIndex, colIndex, actionIndex) }} />
          } else {
            alert('handleOnTableCellCtrlValueChange is missing for actionIndex:' + actionIndex)
          }
        } else {
          result = this.getActionIcon(action, row)
        }

        return result
      })
      if (cellValue.length > 0) {
        cellValue = <div style={{ display: 'flex' }}>{cellValue}</div>
      }
    }
    else if (column.format && value) {
      // alert('before format cellValue : ' + cellValue)
      cellValue = column.format(value)
      // alert('after format cellValue : ' + cellValue)
    } else if (column.type && column.type === "CtTxt") {
      if (column.CtrlProps) {
        let rowCtrlID = this.getCtrlID(rowIndex, colIndex)
        if (row[rowCtrlID] === undefined && column.CtrlProps && column.CtrlProps.value !== undefined) {
          this.props.handleOnTableCellCtrlValueChange(
            'CtTxt', row, { target: { name: rowCtrlID, value: column.CtrlProps.value } }, rowIndex, colIndex)
        }

        if (
          row[rowCtrlID] !== undefined &&
          Number(row[rowCtrlID + '_ASVC']) === 1 &&
          column.CtrlProps.defaultValueColID !== undefined &&
          row[column.CtrlProps.defaultValueColID] !== undefined &&
          column.CtrlProps.value !== row[column.CtrlProps.defaultValueColID]
        ) {
          this.props.handleOnTableCellCtrlValueChange(
            'CtTxt', row, { target: { name: rowCtrlID, value: row[column.CtrlProps.defaultValueColID] } }, rowIndex, colIndex)
        }

        if (row[rowCtrlID] !== undefined && Number(row[rowCtrlID + '_ASVC']) > 0 && row[rowCtrlID] === null) {
          let defaultValue = ''
          if (row[rowCtrlID] === undefined && column.CtrlProps && column.CtrlProps.value !== undefined) {
            defaultValue = column.CtrlProps.value
          }
          this.props.handleOnTableCellCtrlValueChange(
            'CtTxt', row, { target: { name: rowCtrlID, value: defaultValue } }, rowIndex, colIndex)
        }

        cellValue = <CtTxt
          id={rowCtrlID}//  + (rowIndex + 1)}
          label={column.CtrlProps && column.CtrlProps.label !== undefined ? column.CtrlProps.label : ''}
          value={row[rowCtrlID] !== undefined ?
            row[rowCtrlID] :
            (column.CtrlProps && column.CtrlProps.value !== undefined ? column.CtrlProps.value : '')
          }
          handleOnChange={(e) => this.props.handleOnTableCellCtrlValueChange('CtTxt', row, e, rowIndex, colIndex)}
          width={column.CtrlProps && column.CtrlProps.width !== undefined ? column.CtrlProps.width : 50}
          maxLength={column.CtrlProps && column.CtrlProps.maxLength !== undefined ? column.CtrlProps.maxLength : 1}
          disabled={row[this.getCtrlEDSID(rowIndex, colIndex)] !== undefined ? row[this.getCtrlEDSID(rowIndex, colIndex)] : false}
        // onKeyDown={this.props.onKeyDown}
        />
      } else {
        cellValue = value
        alert('CtrlProps is missing for (rowIndex, colIndex) : (' + rowIndex + ', ' + colIndex)
      }
    } else if (column.type && column.type === "CtMultiLineText") {
      if (column.CtrlProps) {
        let rowCtrlID = this.getCtrlID(rowIndex, colIndex)
        if (row[rowCtrlID] === undefined && column.CtrlProps && column.CtrlProps.value !== undefined) {
          this.props.handleOnTableCellCtrlValueChange('CtMultiLineText', row, { target: { name: rowCtrlID, value: column.CtrlProps.value } }, rowIndex, colIndex)
          // this.add_autoEnableDisalbe_ColIdxs(column.CtrlProps, colIndex)
        }

        if (
          row[rowCtrlID] !== undefined &&
          Number(row[rowCtrlID + '_ASVC']) === 1 &&
          column.CtrlProps.defaultValueColID !== undefined &&
          row[column.CtrlProps.defaultValueColID] !== undefined &&
          column.CtrlProps.value !== row[column.CtrlProps.defaultValueColID]
        ) {
          this.props.handleOnTableCellCtrlValueChange(
            'CtMultiLineText', row, { target: { name: rowCtrlID, value: row[column.CtrlProps.defaultValueColID] } }, rowIndex, colIndex)
          // this.add_autoEnableDisalbe_ColIdxs(column.CtrlProps, colIndex)
        }

        if (row[rowCtrlID] !== undefined && Number(row[rowCtrlID + '_ASVC']) > 0 && row[rowCtrlID] === null) {
          let defaultValue = ''
          if (row[rowCtrlID] === undefined && column.CtrlProps && column.CtrlProps.value !== undefined) {
            defaultValue = column.CtrlProps.value
          }
          this.props.handleOnTableCellCtrlValueChange(
            'CtMultiLineText', row, { target: { name: rowCtrlID, value: defaultValue } }, rowIndex, colIndex)
        }

        cellValue = <CtMultiLineText
          id={rowCtrlID}
          label={column.CtrlProps && column.CtrlProps.label !== undefined ? column.CtrlProps.label : ''}
          value={row[rowCtrlID] !== undefined ?
            row[rowCtrlID] :
            (column.CtrlProps && column.CtrlProps.value !== undefined ? column.CtrlProps.value : '')
          }
          handleOnChange={(e) => this.props.handleOnTableCellCtrlValueChange('CtMultiLineText', row, e, rowIndex, colIndex)}
          width={column.CtrlProps && column.CtrlProps.width !== undefined ? column.CtrlProps.width : 100}
          maxLength={column.CtrlProps && column.CtrlProps.maxLength !== undefined ? column.CtrlProps.maxLength : 1}
          disabled={row[this.getCtrlEDSID(rowIndex, colIndex)] !== undefined ? row[this.getCtrlEDSID(rowIndex, colIndex)] : false}
        />
      } else {
        cellValue = value
        alert('CtrlProps is missing for (rowIndex, colIndex) : (' + rowIndex + ', ' + colIndex)
      }
    } else {
      cellValue = value
    }
    return (cellValue)
  }

  add_autoEnableDisalbe_ColIdxs = (CtrlProps, colIndex) => {
    if (CtrlProps && CtrlProps.autoEnableDisable && !(this.state.autoEnableDisalbe_Col_Info.length > 0 && this.state.autoEnableDisalbe_Col_Info.find(col => col.index === colIndex))) {
      if (this.props.handleOnChangeAutoEnableDisalbe_ColIdxs) {
        const autoEnableDisalbe_Col_Info = this.state.autoEnableDisalbe_Col_Info
        const blankIfDisabled = CtrlProps.blankIfDisabled
        autoEnableDisalbe_Col_Info.push({ index: colIndex, blankIfDisabled })
        this.setState({ autoEnableDisalbe_Col_Info }, () => {
          // console.log('autoEnableDisalbe_Col_Info', this.state.autoEnableDisalbe_Col_Info)
          this.props.handleOnChangeAutoEnableDisalbe_ColIdxs(this.state.autoEnableDisalbe_Col_Info)
        })
      } else {
        alert('Remain to set handleOnChangeAutoEnableDisalbe_ColIdxs for TableList')
      }
    }
  }

  getCtrlID = (rowIndex, colIndex, actionIndex) => {
    return actionIndex !== undefined ?
      rowIndex.toString() + '_' + colIndex.toString() + '_' + actionIndex.toString() :
      rowIndex.toString() + '_' + colIndex.toString()
  }

  getCtrlEDSID = (rowIndex, colIndex, actionIndex) => {
    return actionIndex !== undefined ?
      rowIndex.toString() + '_' + colIndex.toString() + '_' + actionIndex.toString() + '_EDS' :
      rowIndex.toString() + '_' + colIndex.toString() + '_EDS'
  }

  checkCondition = (action, row) => {
    let result = false
    if (
      (action.actionType && action.actionType === 'Conditional') ||
      (action.condition && action.condition.length > 0)
    ) {
      let conditionFinalResult = false, iterationResults = []

      action.condition.forEach((curcondition) => {
        // console.log("curcondition.relationalOperator", curcondition.relationalOperator);
        // console.log("row[curcondition.colID]", row[curcondition.colID]);
        // console.log("curcondition.value", curcondition.value);
        let conditionResult = false
        if (curcondition.relationalOperator === undefined || curcondition.relationalOperator == '==') {
          if (row[curcondition.colID] == curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '===') {
          if (row[curcondition.colID] === curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '!=') {
          if (row[curcondition.colID] != curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '!==') {
          if (row[curcondition.colID] !== curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '<=') {
          if (row[curcondition.colID] <= curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '<') {
          if (row[curcondition.colID] < curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '>=') {
          if (row[curcondition.colID] >= curcondition.value)
            conditionResult = true
        } else if (curcondition.relationalOperator && curcondition.relationalOperator == '>') {
          if (row[curcondition.colID] > curcondition.value)
            conditionResult = true
        }
        iterationResults.push({
          conditionResult,
          logicalOperator: curcondition.logicalOperator && curcondition.logicalOperator.length > 0 ? curcondition.logicalOperator : null
        })
      })

      if (iterationResults.length === 1) {
        conditionFinalResult = iterationResults[0].conditionResult
      } else {
        iterationResults.forEach((curResult) => {
          if (curResult.logicalOperator === null) {
            conditionFinalResult = curResult.conditionResult
          } else {
            if (curResult.logicalOperator === '&&') {
              conditionFinalResult = (conditionFinalResult && curResult.conditionResult)
            } else {
              conditionFinalResult = (conditionFinalResult || curResult.conditionResult)
            }
          }
        })
      }

      if (conditionFinalResult === true) {
        result = true
      } else {
        result = false
      }
    } else {
      result = true
    }
    return result
  }

  getActionIcon = (action, row) => {
    const actionIcon = <Tooltip title={action.name}>
      <IconButton
        style={{ padding: '0px', margin: '0px 5px' }}
        onClick={() => {
          if (this.props.keyColumn && this.props.keyColumn.length > 0) {
            if (action.actionType && action.actionType === 'AlertResponsive') {
              let values = []
              this.props.keyColumn.map((keyCol) => {
                let value = row[keyCol]
                values.push({ 'value': value, actionName: action.name })
              })
              this.props.onActionClick(values)
            } else {
              let keyValue = row[this.props.keyColumn[0]]
              this.props.onActionClick(action.link + keyValue)
            }
          } else {
            this.props.toastMsg('error', 'Technical info loss to apply action')
          }
        }}
      >
        {action.icon}
      </IconButton>
    </Tooltip>
    return actionIcon
  }

  onSearchClick = () => {
    this.setState({
      searchOpen: (!(this.state.searchOpen))
    }, () => {
      this.props.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
    })
  }

  render() {
    const {
      classes, keyColumn, width, height, maxHeight, minHeight, rowsPerPage,
      dataRowBgColor, dataRowBgColorCondition } = this.props
    const fullWidth = { width: '100%' }
    const fixWidthHeight = { width: width + 'px', height: height + 'px' }
    const defaultMaxMinHeight = { maxHeight: '440px', minHeight: '440px' }
    const maxMinHeight = { maxHeight: maxHeight + 'px', minHeight: minHeight + 'px' }

    let rowStyle = {}

    // console.log('style', width && width > 0 && height && height > 0 ? { fixWidthHeight } : { fullWidth })
    const tableHeadingBgColor = '#b3bcd5' // '#bfc7dc'// '#b3bcd5'//'#a6b1ce'
    return (
      <Paper style={width && width > 0 && height && height > 0 ? { fixWidthHeight } : { fullWidth }}>
        <TableContainer style={minHeight && maxHeight && minHeight > 0 && maxHeight > 0 ?
          { maxMinHeight } : { defaultMaxMinHeight }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {this.props.columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    //Red Shade ED1C24, f44336, ef9a9a, ff8a80
                    //Violage Shade 8556A6
                    style={
                      column.hide == true || column.hide == 'true' ?
                        { display: 'none', minWidth: column.minWidth, width: column.width, backgroundColor: tableHeadingBgColor } :
                        { minWidth: column.minWidth, width: column.width, backgroundColor: tableHeadingBgColor }
                    }
                  // { minWidth: column.minWidth, backgroundColor: '#ff8a80' },
                  >
                    {
                      (index === 0 && this.props.onNewEntryClick) ?
                        <Tooltip title={this.props.toolTipNewEntry}>
                          <IconButton
                            onClick={this.props.onNewEntryClick}
                            style={{ padding: '0px', margin: '0px 5px' }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                        : ''
                    }
                    {
                      (index === 0 && this.state.provideSearch && this.state.provideSearch === true) ? (
                        this.state.searchOpen === true ?
                          <Tooltip title={'Close Search'}>
                            <IconButton
                              onClick={this.onSearchClick}
                              style={{ padding: '0px', margin: '0px 5px' }}
                            >
                              <Close />
                            </IconButton>
                          </Tooltip> :
                          <Tooltip title={'Search'}>
                            <IconButton
                              onClick={this.onSearchClick}
                              style={{ padding: '0px', margin: '0px 5px' }}
                            >
                              <Search />
                            </IconButton>
                          </Tooltip>
                      ) : (
                        (
                          index > 0 && this.state.searchOpen === true &&
                          this.state.provideSearch && this.state.provideSearch === true
                        ) ? (
                          this.state.searchColumns.map((col, scIndex) => {
                            if (col.cType === 'CtCmb' && index === (scIndex + 1)) {
                              console.log('col.items', col.items)
                              console.log('col.colID', col.colID)
                            }
                            return (
                              (col.cType === 'CtTxtAdornNum' && index === (scIndex + 1)) ? (
                                <span />
                              ) : ((col.cType === 'CtCmb' && index === (scIndex + 1)) ? (
                                <CtCmb
                                  id={col.id}
                                  label={col.label}
                                  items={col.items}
                                  value={this.state.searchData[col.id]}
                                  handleOnChange={col.handleOnChange}
                                  width={col.width}
                                  colID={col.colID}
                                />
                              ) : ((col.cType === 'CtTxt' && index === (scIndex + 1)) ? (
                                <CtTxt
                                  id={col.id}
                                  label={col.label}
                                  value={this.state.searchData[col.id]}
                                  handleOnChange={col.handleOnChange}
                                  width={col.width}
                                  maxLength={col.maxLength}
                                />
                              ) : ((col.cType === 'CtPhoneNumber' && index === (scIndex + 1)) ? (
                                <span />
                              ) : ((col.cType === 'Lbl' && index === (scIndex + 1)) ? col.label : ('')))))
                            )
                          })
                        ) : (column.label)
                      )
                    }
                    {column.CtrlProps && column.CtrlProps.autoEnableDisable && !(this.state.autoEnableDisalbe_Col_Info.length > 0 && this.state.autoEnableDisalbe_Col_Info.find(col => col.index === index)) ? this.add_autoEnableDisalbe_ColIdxs(column.CtrlProps, index) : ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.rows ?
                  this.state.rows.slice(this.state.page * rowsPerPage, this.state.page * rowsPerPage + rowsPerPage).map((row, rowIndex) => {

                    if (dataRowBgColor && dataRowBgColor.length > 0) {

                      let applyBgColor = false
                      rowStyle = {}

                      if (
                        dataRowBgColorCondition
                        //&& dataRowBgColorCondition.length > 0
                      ) {
                        if (this.checkCondition(dataRowBgColorCondition, row)) {
                          applyBgColor = true
                          {/* console.log('if checkcondition returns true') */ }
                        } else {
                          applyBgColor = false
                          {/* console.log('if checkcondition returns false') */ }
                        }
                      } else {
                        applyBgColor = true
                        {/* console.log('dataRowBgColorCondition not found') */ }
                      }

                      {/* console.log('applyBgColor', applyBgColor) */ }

                      if (applyBgColor === true) {
                        rowStyle.backgroundColor = dataRowBgColor
                      }

                      {/* console.log('rowStyle',rowStyle) */ }
                    }

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.ID} style={rowStyle} >
                        {
                          this.props.columns.map((column, index) => {
                            let value = row[column.id], dynamicRowDataID
                            const keyValue = row[keyColumn]

                            if (
                              column.dynamicRowDataID && column.dynamicRowDataID.length > 0
                            ) {
                              if (row.dynamicRowDataID) {
                                value = row.dynamicRowDataID[column.dynamicRowDataID]
                              } else {
                                value = ''
                              }
                            }

                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={
                                  column.hide == true || column.hide == 'true' ? { display: 'none' } : {}
                                }
                              >
                                {this.displayCurrentCell(index, column, value, row, rowIndex)}
                                {/* {this.displayCurrentCell(index, column.format, value, row, rowIndex)} */}
                                {/* {column.type && column.type === "CtTxt" ? (
                                  <CtTxt
                                    id={column.dynamicRowDataID}//  + (rowIndex + 1)}
                                    label={column.inputLabel}
                                    value={this.displayCurrentCell(index, column.format, value, row, rowIndex)}
                                    handleOnChange={(e) => column.handleOnChange(e, rowIndex)}
                                    width={150}
                                    maxLength='20'
                                    paddingZero={true}
                                  // onKeyDown={this.props.onKeyDown}
                                  />
                                ) : this.displayCurrentCell(index, column.format, value, row, rowIndex)} */}
                              </TableCell>
                            )
                          })
                        }
                      </TableRow>
                    )
                  })
                  : ''
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={this.state.rowsCount}
          rowsPerPage={rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={(e, tblIndex) => { this.handleChangeRowsPerPage(e, tblIndex) }}
        />
      </Paper>
    );
  }
}

export default TableList


/*
Library Notes:

CtrlProps in ActionList:
While action contains inputs, so, don't forget to set CtrlProps with default value of input control. 
If you don't provide default value in CtrlProps, then all row will not contains key='RowIndex'+'ColumnIndex' with default value
*/