import { Grid, Box } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import React, { Component } from 'react'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { Edit, Delete, Print } from '@mui/icons-material';
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import CtCheckBox from './CustomTool/CtCheckBox'
import CtMultiLineText from './CustomTool/CtMultiLineText'
import CtCmbEditable from './CustomTool/CtCmbEditable'
import { apiURL } from './API/API'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'
import { fetchProductMaster } from './API/API'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import { getRowData } from './SystemUtility/SystemUtility'

export class ProductMaster extends Component {

    state = {
        cWidth: 290,
        ProductCategory: [],
        HSNCode: [{ 'vac_hsncode': '' }],
        TaxName: [],
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_id', label: 'ID', hide: 'true' },
            { id: 'vac_productcode', label: 'Product Code' },
            { id: 'vac_productcategory', label: 'Product Category' },
            { id: 'vac_productname', label: 'Product Name' },
            { id: 'vac_description', label: 'Description' },
            { id: 'dec_rentpointsperminure', label: 'RentPoints/Minute', hide: 'true' },
            { id: 'dec_rentpointsperday', label: 'RentPoints/Day' },
            { id: 'dec_salesprice', label: 'Sales Price' },
            { id: 'CurrentStock', label: 'Current Stock' },
        ],
        rows: [],
        formData: {
            txtId: '',
            txtProductCode: '',
            cmbProductCategory: '',
            txtProductName: '',
            chkSale: true,
            chkRentAtStore: false,
            chkRentAtHome: true,
            txtDescription: '',
            cmbHSNCode: '',
            cmbTaxName: '',
            txtSalesPrice: '',
            txtSalesTaxable: '',
            txtMRP: '',
            txtRentPointsPerMinute: '',
            txtRentPointsPerDay: '',
            lblCurrentStock: '',
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'ProductMaster:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'ProductMaster:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
            { name: 'Print QR Code', icon: <Print />, link: 'ProductMaster', actionType: 'AlertResponsive' },
        ],
        keyColumn: [
            'bint_id', 'vac_productcode', 'vac_productcategory', 'vac_productname', 'vac_description', 'dec_rentpointsperminure', 'dec_rentpointsperday', 'dec_salesprice', 'CurrentStock'
        ],
        searchData: {
            bint_id: '',
            vac_productcode: '',
            vac_productcategory: 'ALL',
            vac_productname: '',
            vac_description: '',
            dec_rentpointsperminure: '',
            dec_rentpointsperday: '',
            dec_salesprice: '',
            CurrentStock: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,


    }

    //#region handle
    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                console.log('this.state.searchData', this.state.searchData)
                console.log('row', row)
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.bint_id.length === 0 || row['bint_id'].toString().includes(this.state.searchData.bint_id)) &&
                        (this.state.searchData.vac_productcode.length === 0 || row['vac_productcode'].toString().includes(this.state.searchData.vac_productcode)) &&
                        (this.state.searchData.vac_productname.length === 0 || row['vac_productname'].toString().includes(this.state.searchData.vac_productname)) &&
                        (this.state.searchData.vac_description.length === 0 || row['vac_description'].toString().includes(this.state.searchData.vac_description)) &&
                        (this.state.searchData.dec_rentpointsperminure.length === 0 || row['dec_rentpointsperminure'].toString().includes(this.state.searchData.dec_rentpointsperminure)) &&
                        (this.state.searchData.dec_rentpointsperday.length === 0 || row['dec_rentpointsperday'].toString().includes(this.state.searchData.dec_rentpointsperday)) &&
                        (this.state.searchData.dec_salesprice.length === 0 || row['dec_salesprice'].toString().includes(this.state.searchData.dec_salesprice)) &&
                        (this.state.searchData.CurrentStock.length === 0 || row['CurrentStock'].toString().includes(this.state.searchData.CurrentStock)) &&
                        (this.state.searchData.vac_productcategory === 'ALL' || this.state.searchData.vac_productcategory === row['vac_productcategory'])

                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        const ctrlname = e.target.name, ctrlValue = e.target.value
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            if (ctrlname === 'cmbTaxName') {
                if (Number(this.state.formData.txtSalesTaxable) > 0) {
                    this.calculateSalesPrice()
                } else if (Number(this.state.formData.txtSalesPrice) > 0) {
                    this.calculateSalesTaxaabel()
                }
            } else if (ctrlname === 'txtSalesTaxable' && Number(ctrlValue) > 0) {
                this.calculateSalesPrice()
            } else if (ctrlname === 'txtSalesPrice' && Number(ctrlValue) > 0) {
                this.calculateSalesTaxaabel()
            } else if (ctrlname === 'txtMRP') {
                this.calculateRentPerDay()
            }
            // console.log(this.getTaxName(this.state.formData.cmbTaxName))
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }

    handleARDonActionClick = (values) => {
        // console.log('values', values)
        const txtId = values[0].value,
            txtProductCode = values[1].value,
            cmbProductCategory = values[2].value,
            txtProductName = values[3].value,
            txtDescription = values[4].value,
            txtRentPointsPerMinute = values[5].value,
            txtRentPointsPerDay = values[6].value,
            txtSalesPrice = values[7].value,
            lblCurrentStock = values[8].value,
            actionName = values[0].actionName
        if (actionName === "Edit") {
            const curRowData = getRowData(this.state.rows, 'bint_id', txtId)
            this.setState({
                formData: {
                    ...this.state.formData,
                    txtId: curRowData.bint_id,
                    txtProductCode: curRowData.vac_productcode,
                    cmbProductCategory: curRowData.vac_productcategory,
                    txtProductName: curRowData.vac_productname,
                    txtDescription: curRowData.vac_description,
                    txtRentPointsPerMinute: curRowData.dec_rentpointsperminure,
                    txtRentPointsPerDay: curRowData.dec_rentpointsperday,
                    txtSalesPrice: curRowData.dec_salesprice,
                    lblCurrentStock: curRowData.dec_salesprice,
                    chkSale: (curRowData.int_availforsales == 0 ? false : true),
                    chkRentAtStore: (curRowData.int_availforstorerent == 0 ? false : true),
                    chkRentAtHome: (curRowData.int_availforhomerent == 0 ? false : true),
                    cmbHSNCode: curRowData.vac_HSNCode,
                    cmbTaxName: curRowData.vac_taxname,
                    txtSalesTaxable: curRowData.dec_salestaxable,
                    txtMRP: curRowData.dec_mrp,
                }
            })
        }
        else if (actionName === "Delete") {
            this.setState({ ARD: null },
                () => {
                    let ARD = <AlertResponsiveDialog
                        labelAgree='Delete'
                        labelDisagree='Cancel'
                        alertTitle={'Do you want to Delete  : ' + txtProductName + ' ?'}
                        alertMessage=''
                        handleOnClickYes={() => {
                            return this.DeleteProductMaster(txtId)
                        }}
                        defaultOpenDialog={true}
                        onYesClickCloseIfExeSuccessfully={true}
                    />

                    this.setState({ ARD })
                })
        } else if (actionName === "Print QR Code") {
            this.openProductQRCode(txtProductCode, txtProductName)
        }
        else {
            let rows = this.state.rows.filter((row) => {
                if (row.Id !== txtId && row.ProductCode !== txtProductCode && row.ProductCategory !== cmbProductCategory && row.ProductName !== txtProductName && row.Description !== txtDescription &&
                    row.RentPointsPerMinute !== txtRentPointsPerMinute && row.RentPointsPerDay !== txtRentPointsPerDay && row.SalesPrice !== txtSalesPrice && row.CurrentStock !== lblCurrentStock)
                    return row
            })
            const rowsCount = rows.length
            this.setState({ rows, rowsCount })
        }
    }

    handleCheckChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.checked
            }
        })
    }
    //#endregion handle

    //#region Component
    componentDidMount() {
        document.title = 'TED : Product Master'
        this.props.setActiveMenu("Products")
        document.getElementById('txtId').focus()
        this.getInfo()
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }

    //#endregion

    //#region Function

    calculateSalesTaxaabel = () => {
        const txtSalesTaxable = Math.round(((this.state.formData.txtSalesPrice / (100 + this.getTaxName(this.state.formData.cmbTaxName))) * 100), 2)
        this.setState({ formData: { ...this.state.formData, txtSalesTaxable } })
    }

    calculateSalesPrice = () => {
        let tax = Number(this.getTaxName(this.state.formData.cmbTaxName))
        let taxamt = (Number(this.state.formData.txtSalesTaxable) * tax) / 100
        let amt = Number(Number(this.state.formData.txtSalesTaxable) + taxamt)
        const txtSalesPrice = Math.round(amt)
        this.setState({ formData: { ...this.state.formData, txtSalesPrice } })
    }

    calculateRentPerDay = () => {
        const txtRentPointsPerDay = Math.round((this.state.formData.txtMRP * 0.20) / 7, 2)
        this.setState({ formData: { ...this.state.formData, txtRentPointsPerDay } })
    }

    getTaxName = (taxValue) => {
        return Number(taxValue && taxValue.length > 0 ? taxValue.toString().replace("%", "").replace("Nil", "") : 0)
    }

    openProductQRCode = (ProductCode, ProductName) => {
        // console.log('topmargin:',topmargin);
        this.props.notifyProcessing()
        let reqData = [
            { name: 'Op', value: 'PrintProductQRCode' },
            { name: 'vac_productcode', value: ProductCode },
            { name: 'vac_productname', value: ProductName },
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'productmasterdetail.php');

        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');

        form.submit();
        this.props.updateProcessing('success', 'PDF Open in next Tab.')
    }

    getInfo = () => {
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            const reqData = {
                'Op': 'ProductMasterList',
            }
            fetchProductMaster(reqData)
                .then(res => {
                    dataLoadStatus = true
                    let rows = res.data.ProductMasterList, HSNCode = res.data.HSNList,
                        TaxName = res.data.TaxList,
                        rowsCount = rows.length,
                        ProductCategory = res.data.ProductCategoryList
                    this.setState({
                        dataLoadStatus,
                        rows, HSNCode, TaxName, rowsCount, ProductCategory
                    }, () => {
                        this.setSearchColumns()
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in onFormLoad.', error)
                })
        })
    }

    validateProductMaster = () => {
        let result = false
        // console.log("this.state.formData.lblCurrentStock", this.state.formData.lblCurrentStock);
        /* if (Number(this.state.formData.txtId)>0 && this.state.formData.txtProductCode.length <= 0) {
            this.props.toastErrorMsg('Enter Product Code', 'txtProductCode')
        } else */ if (this.state.formData.cmbProductCategory.length <= 0) {
            this.props.toastErrorMsg('Enter Product Category', 'cmbProductCategory')
        } else if (this.state.formData.txtProductName.length <= 0) {
            this.props.toastErrorMsg('Enter Product Name', 'txtProductName')
        } /* else if (this.state.formData.txtDescription.length <= 0) {
            this.props.toastErrorMsg('Enter Description', 'txtDescription')
        } */
        // else if (this.state.formData.cmbHSNCode.length <= 0) {
        //     this.props.toastErrorMsg('Enter HSN Code', 'cmbHSNCode')
        // }
        else if (this.state.formData.cmbTaxName.length <= 0) {
            this.props.toastErrorMsg('Enter Tax Name', 'cmbTaxName')
        } else if (this.state.formData.txtSalesPrice.length <= 0) {
            this.props.toastErrorMsg('Enter Sales Price', 'txtSalesPrice')
        } else if (this.state.formData.txtSalesTaxable.length <= 0) {
            this.props.toastErrorMsg('Enter Sales Taxaable', 'txtSalesTaxable')
        } else if (this.state.formData.txtMRP.length <= 0) {
            this.props.toastErrorMsg('Enter MRP', 'txtMRP')
        } /* else if (this.state.formData.txtRentPointsPerMinute.length <= 0) {
            this.props.toastErrorMsg('Enter Rent Points Per Minute', 'txtRentPointsPerMinute')
        } */ else if (this.state.formData.txtRentPointsPerDay.length <= 0) {
            this.props.toastErrorMsg('Enter Rent Points Per Day', 'txtRentPointsPerDay')
        } /* else if (Number(this.state.formData.lblCurrentStock) <= 0) {
            this.props.toastErrorMsg('Enter Current Stock', 'lblCurrentStock')
        } */

        else {
            result = true
        }
        return result
    }

    clearInfo = () => {
        const txtProductCode = '',
            cmbProductCategory = '', txtProductName = '',
            txtDescription = '', cmbHSNCode = '', cmbTaxName = '', txtSalesPrice = '', txtSalesTaxable = '', txtMRP = '',
            txtRentPointsPerMinute = '', txtRentPointsPerDay = '', lblCurrentStock = '',
            chkSale = true, chkRentAtHome = true, chkRentAtStore = false

        this.setState({
            formData: {
                ...this.state.formData, txtProductCode, cmbProductCategory, txtProductName, txtDescription, cmbHSNCode,
                cmbTaxName, txtSalesPrice, txtSalesTaxable, txtMRP, txtRentPointsPerMinute, txtRentPointsPerDay, lblCurrentStock,
                chkSale, chkRentAtHome, chkRentAtStore
            }
        }, () => {
            document.getElementById('txtProductCode').focus()
        })
    }

    SaveProductMaster = (openQRCode = false) => {
        if (this.validateProductMaster()) {
            const dataLoadStatus = false
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus }, () => {
                const reqData = {
                    'Op': 'SaveProductMaster',
                    'bint_id': this.state.formData.txtId,
                    'vac_productcode': this.state.formData.txtProductCode,
                    'vac_productcategory': this.state.formData.cmbProductCategory,
                    'vac_productname': this.state.formData.txtProductName,
                    'vac_description': this.state.formData.txtDescription,
                    'vac_HSNCode': this.state.formData.cmbHSNCode,
                    'vac_taxname': this.state.formData.cmbTaxName,
                    'dec_mrp': this.state.formData.txtMRP,
                    'dec_rentpointsperminure': this.state.formData.txtRentPointsPerMinute,
                    'dec_rentpointsperday': this.state.formData.txtRentPointsPerDay,
                    'dec_salesprice': this.state.formData.txtSalesPrice,
                    'dec_salestaxable': this.state.formData.txtSalesTaxable,
                    'int_availforsales': this.state.formData.chkSale,
                    'int_availforhomerent': this.state.formData.chkRentAtHome,
                    'int_availforstorerent': this.state.formData.chkRentAtStore,
                }
                // console.log("reqData", reqData);
                fetchProductMaster(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            if (openQRCode == true) {
                                this.openProductQRCode(this.state.formData.txtProductCode, this.state.formData.txtProductName)
                            }
                            this.getInfo()
                            this.clearInfo()
                        }
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in SaveProductMaster.', error)
                    })
            })

        }

    }

    DeleteProductMaster = (txtId) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'DeleteProductMaster',
                'bint_id': txtId
            }
            fetchProductMaster(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.setState({ ARD: null }, () => { this.getInfo() })
                    }
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in delete DeleteProductMaster. ' + error)
                })
        })
        return false
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push({
            cType: 'CtTxt',
            id: 'bint_id',
            label: 'Id',
            value: this.state.searchData.bint_id,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'vac_productcode',
            label: 'Product Code',
            value: this.state.searchData.vac_productcode,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }
            , {
                cType: 'CtCmb',
                id: 'vac_productcategory',
                label: 'Product Category',
                value: this.state.searchData.vac_productcategory,
                items: this.state.ProductCategory,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100',
                colID: 'vac_productcategory'
            }, {
            cType: 'CtTxt',
            id: 'vac_productname',
            label: 'Product Name',
            value: this.state.searchData.vac_productname,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'vac_description',
            label: 'Description',
            value: this.state.searchData.vac_description,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'dec_rentpointsperminure',
            label: 'Rent Points Per Minute',
            value: this.state.searchData.dec_rentpointsperminure,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'dec_rentpointsperday',
            label: 'Rent Points Per Day',
            value: this.state.searchData.dec_rentpointsperday,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'dec_salesprice',
            label: 'SalesPrice',
            value: this.state.searchData.dec_salesprice,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'CurrentStock',
            label: 'Current Stock',
            // disabled:{true},
            value: this.state.searchData.CurrentStock,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }
        )
        this.setState({ searchColumns })
    }

    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title">
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Product Master</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0} marginTop={0}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item style={{ display: 'none' }}>
                                <CtTxt
                                    id='txtId'
                                    label='Id'
                                    value={this.state.formData.txtId}
                                    disabled={true}
                                    handleOnChange={this.handleOnChange}
                                    width={100}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxtAdornNum
                                    id='txtProductCode'
                                    value={this.state.formData.txtProductCode}
                                    label='Product Code'
                                    adornment='TED'
                                    readOnly={true}
                                    width='120'
                                />
                            </Grid>
                            <Grid item>
                                <CtCmb
                                    id='cmbProductCategory'
                                    label='Product Category*'
                                    items={this.state.ProductCategory}
                                    value={this.state.formData.cmbProductCategory}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    width={170}
                                    maxLength={100}
                                    colID='vac_productcategory'

                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtProductName'
                                    label='Product Name*'
                                    value={this.state.formData.txtProductName}
                                    handleOnChange={this.handleOnChange}
                                    width={280}
                                    maxLength={100}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtCheckBox
                                    id='chkSale'
                                    label='Sale'
                                    checked={this.state.formData.chkSale}
                                    handleCheckChange={this.handleCheckChange}
                                />
                            </Grid>
                            <Grid item>
                                <CtCheckBox
                                    id='chkRentAtHome'
                                    label='Rent'
                                    checked={this.state.formData.chkRentAtHome}
                                    handleCheckChange={this.handleCheckChange}
                                />
                            </Grid>
                            <Grid item display='none'>
                                <CtCheckBox
                                    id='chkRentAtStore'
                                    label='Play*'
                                    checked={this.state.formData.chkRentAtStore}
                                    handleCheckChange={this.handleCheckChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" marginTop={0}>
                            <Grid item>
                                <CtMultiLineText
                                    id='txtDescription'
                                    label='Description'
                                    value={this.state.formData.txtDescription}
                                    handleOnChange={this.handleOnChange}
                                    width={600}
                                    maxLength={2000}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='cmbHSNCode'
                                    label='HSN Code'
                                    // items={this.state.HSNCode}
                                    value={this.state.formData.cmbHSNCode}
                                    handleOnChange={this.handleOnChange}
                                    width={150}
                                    maxLength={12}
                                // colID='vac_hsncode'
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtSalesTaxable'
                                    label='Sales Taxable*'
                                    // disabled={true}
                                    value={this.state.formData.txtSalesTaxable}
                                    handleOnChange={this.handleOnChange}
                                    width={160}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item>
                                <CtCmb
                                    id='cmbTaxName'
                                    label='Tax Name*'
                                    items={this.state.TaxName}
                                    value={this.state.formData.cmbTaxName}
                                    // disabled={this.state.disabledProfileData.AccountTypeDS}
                                    handleOnChange={this.handleOnChange}
                                    width={130}
                                    maxLength={50}
                                    colID='vac_taxname'
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtSalesPrice'
                                    label='Sales Price*'
                                    value={this.state.formData.txtSalesPrice}
                                    handleOnChange={this.handleOnChange}
                                    width={110}
                                    maxLength={10}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtMRP'
                                    label='MRP*'
                                    value={this.state.formData.txtMRP}
                                    handleOnChange={this.handleOnChange}
                                    width={50}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item display='none'>
                                <CtTxt
                                    id='txtRentPointsPerMinute'
                                    label='Rent Points Per Minute'
                                    value={this.state.formData.txtRentPointsPerMinute}
                                    handleOnChange={this.handleOnChange}
                                    width={170}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtRentPointsPerDay'
                                    label='Rent Points Per Day'
                                    value={this.state.formData.txtRentPointsPerDay}
                                    handleOnChange={this.handleOnChange}
                                    width={150}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='lblCurrentStock'
                                    label='Current Stock'
                                    disabled={true}
                                    value={this.state.formData.lblCurrentStock}
                                    handleOnChange={this.handleOnChange}
                                    width={178}
                                    maxLength={10}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0}>
                            <Grid item>
                                <CtBtn label='Save' onClick={() => { this.SaveProductMaster() }} />
                            </Grid>
                            <Grid item>
                                <CtBtn label='New' onClick={this.clearInfo} />
                            </Grid>
                            <Grid item>
                                <CtBtn label='Print QR Code' onClick={() => { this.SaveProductMaster(true) }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                // toolTipNewEntry='New Product Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>

            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HoCtToastContainer(HOCVerifyIsUser(withRouter(ProductMaster))))