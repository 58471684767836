import React, { Component } from 'react'
import { withRouter } from './HOC/withRouter'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import CtBtn from './CustomTool/CtBtn'
import { Grid, Box, Button, } from '@mui/material'
import ArticleIcon from '@mui/icons-material/Article';
import CtDtp from './CustomTool/CtDtp'
import { fetchReport, apiURL } from './API/API'
import {
    get_DMY_from_YMD, get_YMD_from_SYS, get_YMD_from_DMY, //addPeriod 
} from './SystemUtility/SystemUtility'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import TransactionHistorySelection from './TrasactionHistorySelection'

class TransactionHistory extends Component {
    
state={

}
    
    /* handleOnChange = (e) => {
        this.setState({
          formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }
        )
      } */

    handleARDonActionClick = () => {
        let ARD = null
        this.setState({ ARD }, () => {
            ARD = <AlertResponsiveDialog
                labelDisagree='Close'
                // alertTitle={'Sales Item Entry'}
                alertMessage={
                    <TransactionHistorySelection/>

                }
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ ARD })
        })
    }
    
    render() {
        return (
            <div>
                <Button
                    variant="outlined"
                    style={{ backgroundColor: '#d6ddf0', borderRadius: '10px', width: '250px', justifyContent: 'flex-start' }}
                    startIcon={<ArticleIcon />}
                    onClick={this.handleARDonActionClick}>Transaction History
                </Button>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }

}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(TransactionHistory)))

