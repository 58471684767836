import React, { Component } from 'react'
import { withRouter } from './HOC/withRouter'
import { Grid, Box, Avatar } from '@mui/material'
import TableList from './CustomTool/TableList'
import { Edit, Delete, Replay } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { fetchMember } from './API/API'
import { getTextSummary } from './SystemUtility/SystemUtility'
import MemberDashboardIcon from './images/memberdashboard.png'
import CtDtp from './CustomTool/CtDtp'
import CtBtn from './CustomTool/CtBtn'
import { get_90DaysBack_FromCurrentDate, get_YMD_from_SYS } from './SystemUtility/SystemUtility'
import RecalculateStockSelection from './RecalculateStockSelection'
class MemberList extends Component {
    state = {
        status: [],
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_memberid', label: 'Sr No.' },
            { id: 'vac_cardno', label: 'Member ID' },
            { id: 'vac_kidname', label: 'Kid Name' },
            { id: 'vac_guardianname', label: 'Guardian Name' },
            { id: 'vac_guardianrelation', label: 'Guardian Relation' },
            { id: 'vac_mobileno', label: 'Mobile No' },
            { id: 'vac_mobileno2', label: 'Alternate Mobile No' },
            { id: 'vac_emailid', label: 'Email ID' },
            { id: 'vac_Guardianadharcardno', label: 'Guardian Adhar Card No' },
            { id: 'vac_address', label: 'Address', format: getTextSummary },
            { id: 'dec_cardpointbalance', label: 'Card Balance' },
            { id: 'vac_status', label: 'Status' },
            { id: 'vac_kidphoto', label: '', hide: true },
            { id: 'dat_recentfrom', label: '', hide: true },
            { id: 'dat_recentto', label: '', hide: true },
        ],
        searchData: {
            vac_cardno: '',
            vac_kidname: '',
            vac_guardianname: '',
            vac_mobileno: '',
            vac_mobileno2: '',
        },
        formData: {
            dtpFromDate: '',
            dtpToDate: '',
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'MemberMaster', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: '', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
            {
                name: 'MemberDashboard',
                icon: <Avatar src={MemberDashboardIcon} sx={{ width: 40, height: 40 }} />,
                actionType: 'AlertResponsive'
            },
            { name: 'RecalculatePoints', icon: <Replay />, link: 'RecalculateStockSelection', actionType: 'AlertResponsive' },
        ],
        keyColumn: [
            'bint_memberid', 'vac_cardno', 'vac_kidname', 'vac_guardianname', 'vac_guardianrelation', 'vac_mobileno', 'vac_mobileno2', 'vac_emailid', 'vac_Guardianadharcardno', 'vac_address', 'dec_cardpointbalance', 'vac_status', 'vac_kidphoto', 'dat_recentfrom', 'dat_recentto'
        ],
        rows: [],
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 10,
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Member List'
        this.props.setActiveMenu("MemberList")
        // document.getElementById('txtProductCategoryName').focus()
        this.setSearchColumns()
        this.listMember()

    }
    //#endregion

    //#endregion handle
    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.vac_cardno.length === 0 || row['vac_cardno'].toString().toLowerCase().includes(this.state.searchData.vac_cardno.toLowerCase())) &&
                        (this.state.searchData.vac_kidname.length === 0 || row['vac_kidname'].toString().toLowerCase().includes(this.state.searchData.vac_kidname.toLowerCase())) &&
                        (this.state.searchData.vac_guardianname.length === 0 || row['vac_guardianname'].toString().toLowerCase().includes(this.state.searchData.vac_guardianname.toLowerCase())) &&
                        (this.state.searchData.vac_mobileno.length === 0 || row['vac_mobileno'].toString().includes(this.state.searchData.vac_mobileno)) &&
                        (this.state.searchData.vac_mobileno2.length === 0 || row['vac_mobileno2'].toString().includes(this.state.searchData.vac_mobileno2))
                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        console.log('e.target.name,e.target.value', e.target.name, e.target.value)
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            console.log('this.state.formData.dtpFromDate', this.state.formData.dtpFromDate)
        })
    }

    handleARDonActionClick = (values) => {
        const bint_memberid = values[0].value,
            vac_kidname = values[1].value,
            actionName = values[0].actionName
        if (actionName === "Edit") {
            this.navigateTo('MemberMaster:' + bint_memberid)
        } else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Kid Name : \'' + vac_kidname + '\' With Sr No. : \'' + bint_memberid + '\'?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteMember(bint_memberid)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })

        } else if (actionName === "MemberDashboard") {
            // this.props.navigateToMemberDashboard(values[0].value)
            // console.log('values', values)
            this.setReduxMemberInfo_Navigate(
                {
                    data: {
                        msgType: 'success',
                        KidsInfo: [{
                            'vac_cardno': values[1].value,
                            'vac_kidsname': values[2].value,
                            'vac_kidphoto': values[12].value,
                            'vac_guardianname': values[3].value,
                            'dec_cardpointbalance': values[10].value,
                            'vac_mobileno': values[5].value,
                            'bint_memberid': values[0].value,
                            'dat_recentfrom': values[13].value,
                            'dat_recentto': values[14].value,
                            'vac_status': values[11].value
                        }]
                    }
                }
            )
        } else if (actionName === "RecalculatePoints") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    // labelAgree='Recalculate Stock'
                    labelDisagree='Cancel'
                    alertTitle={'Reclculate Stock'}
                    alertMessage={
                        <RecalculateStockSelection recalculatePoints={this.recalculatePoints} bint_memberid={bint_memberid} />
                    }
                    handleOnClickYes={true}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }
    //#endregion

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push({
            cType: 'Lbl',
            label: 'Sr No.',
            width: '100'
        }, {
            cType: 'CtTxt',
            id: 'vac_cardno',
            label: 'Member ID',
            value: this.state.searchData.vac_cardno,
            handleOnChange: this.handleOnSearchInputChange,
            width: '150',
            maxLength: '100'
        },
            {
                cType: 'CtTxt',
                id: 'vac_kidname',
                label: 'Kid Name',
                value: this.state.searchData.vac_kidname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'vac_guardianname',
                label: 'Guardian Name',
                value: this.state.searchData.vac_guardianname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'Guardian Relation',
                width: '100'
            }, {
            cType: 'CtTxt',
            id: 'vac_mobileno',
            label: 'Mobile No',
            value: this.state.searchData.vac_mobileno,
            handleOnChange: this.handleOnSearchInputChange,
            width: '120',
            maxLength: '100'
        },
            {
                cType: 'CtTxt',
                id: 'vac_mobileno2',
                label: 'Mobile No',
                value: this.state.searchData.vac_mobileno2,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'Email ID',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'Guardian Adhar Card No',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'Address',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'Card Balance',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'Status',
                width: '100'
            },
        )
        this.setState({ searchColumns })
    }

    listMember = () => {
        this.setState({
            dataLoadStatus: false,
        }, () => {
            const reqData = {
                Op: 'MemberList',
            }
            fetchMember(reqData)
                .then(res => {
                    const rows = res.data.MemberList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in listMember. ' + error)
                })
        })
    }

    deleteMember = (MemberID) => {
        if (MemberID && MemberID != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteMember',
                    bint_memberid: MemberID
                }
                fetchMember(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.listMember()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteMember. ' + error)
                    })
            })
        }
        return true
    }

    setReduxMemberInfo_Navigate = (res) => {
        if (res.data.msgType === 'success') {
            let memberInfo = {
                Photo: res.data.KidsInfo[0].vac_kidphoto,
                KidName: res.data.KidsInfo[0].vac_kidsname,
                GuardianName: res.data.KidsInfo[0].vac_guardianname,
                CurrentPoints: res.data.KidsInfo[0].dec_cardpointbalance,
                MobileNo: res.data.KidsInfo[0].vac_mobileno,
                CardNo: res.data.KidsInfo[0].vac_cardno,
                MemberID: res.data.KidsInfo[0].bint_memberid,
                RecentFromDate: res.data.KidsInfo[0].dat_recentfrom,
                RecentToDate: res.data.KidsInfo[0].dat_recentto,
                Status: res.data.KidsInfo[0].vac_status
            }
            // console.log("memberInfo", memberInfo);
            this.setState({
                dataLoadStatus: true
            }, () => {
                this.props.setMemberInfo(memberInfo)
                localStorage.setItem('MI', res.data.KidsInfo[0].bint_memberid)
                this.navigateTo('MemberDashboard')
            })
        }
    }

    recalculatePoints = (bint_memberid, dat_fromdate, dat_todate) => {
        if (bint_memberid > 0 && dat_fromdate != undefined && dat_todate != undefined) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                const reqData = {
                    Op: 'RecalculateCurPoints',
                    bint_memberid,
                    dat_fromdate,
                    dat_todate
                }
                fetchMember(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({ dataLoadStatus: true, ARD: null })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in recalculatePoints. ' + error)
                    })
            })
        } else {
            this.props.toastMsg('error', 'Required fields are undefined')
        }
        return true
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Member List</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                    <Grid item>
                        <TableList
                            columns={this.state.columns}
                            counter={this.state.counter}
                            rows={rows}
                            rowsCount={rowsCount}
                            rowsPerPage={this.state.rowsPerPage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                            onActionClick={this.handleARDonActionClick}
                            actionList={this.state.actionList}
                            keyColumn={this.state.keyColumn}
                            handleOnSearchChange={this.handleOnSearchChange}
                            searchColumns={this.state.searchColumns}
                            searchData={this.state.searchData}
                            provideSearch={true}
                            onNewEntryClick={() => { this.navigateTo('MemberMaster') }}
                            toolTipNewEntry='New Member Entry'
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(MemberList)))