import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box, Drawer, Toolbar, List, Divider, IconButton, ListItem,
    ListItemButton, ListItemIcon, ListItemText, Collapse, CssBaseline
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
    ExpandLess, ExpandMore, Store, PendingActions, Assignment, AssignmentInd,
    ChevronLeft, ChevronRight, TouchApp, TrendingDown, TrendingUp, People,
    LibraryAdd, Category, Dashboard, LockOpen, Settings,Diversity3
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const MenuListItemIcon = ({ ItemName, LinkSlug, ItemIcon, activeMenu, handleDrawerClose, activeStyle, setActiveMenu, OnlyForAdmin, IsAdmin, subMenuItems, handleOnSubMenuClick }) => {
    const [subMenuOpenState, setSubMenuOpenState] = useState(false)
    let result = (
        <div>
            <Link
                to={LinkSlug}
                className='menulink'
                style={activeMenu === LinkSlug ? activeStyle : {}}
                onClick={() => { subMenuItems ? setSubMenuOpenState(!subMenuOpenState) : setActiveMenu({ LinkSlug }) }}
            >
                <ListItem
                    button
                    key={ItemName}
                    onClick={handleDrawerClose ? handleDrawerClose : null}
                >
                    <ListItemIcon>
                        {<ItemIcon className='muicon' />}
                    </ListItemIcon>
                    <ListItemText primary={ItemName} />
                </ListItem>
            </Link>
        </div>
    )

    if (subMenuItems && OnlyForAdmin !== undefined) {
        if (IsAdmin !== undefined && IsAdmin() === true) {
            result = (
                <div>
                    <div
                        className='menulink'
                        style={activeMenu === LinkSlug ? activeStyle : {}}
                        onClick={() => { subMenuItems ? setSubMenuOpenState(!subMenuOpenState) : setActiveMenu({ LinkSlug }) }}
                    >
                        <ListItem
                            button
                            key={ItemName}
                            onClick={handleOnSubMenuClick ? handleOnSubMenuClick : null}
                        >
                            <ListItemIcon>
                                {<ItemIcon className='muicon' />}
                            </ListItemIcon>
                            <ListItemText primary={ItemName} />
                            {subMenuItems ? (subMenuOpenState ? <ExpandLess /> : <ExpandMore />) : null}
                        </ListItem>
                    </div>
                    {
                        subMenuItems ?
                            <Collapse
                                in={subMenuOpenState}
                                timeout="auto" unmountOnExit
                            >
                                {
                                    subMenuItems.map((curSubMenu) => {
                                        if (curSubMenu.privilege === 'A' || curSubMenu.privilege === 'R' || curSubMenu.privilege === 'W' || curSubMenu.privilege === 'M') {
                                            return (
                                                <List
                                                    component="div" disablePadding
                                                    style={{ backgroundColor: '#ECF6FA' }}
                                                >
                                                    <Link
                                                        to={curSubMenu.LinkSlug}
                                                        className='menulink'
                                                        style={activeMenu === curSubMenu.LinkSlug ? activeStyle : {}}
                                                    >
                                                        <ListItem
                                                            button
                                                            onClick={handleDrawerClose}
                                                        >
                                                            <ListItemIcon>
                                                                {/* {curSubMenu.menuIcon} */}
                                                                {<curSubMenu.menuIcon className='muicon' />}
                                                            </ListItemIcon>
                                                            <ListItemText primary={curSubMenu.menuText} />
                                                        </ListItem>
                                                    </Link>
                                                </List>
                                            )
                                        } else {
                                            return ''
                                        }

                                    })
                                }
                            </Collapse>
                            : ''
                    }
                </div>
            )
        } else {
            result = ''
        }
    }
    return (
        result
    )
}

const DrawerMenuList = ({ ItemIcon, ItemName, subMenuItems, setActiveMenu, LinkSlug, activeStyle, activeMenu, handleDrawerClose }) => {
    const [openCurSubMenu, setOpenCurSubMenu] = useState(false);
    let result = <div>
        <List
            sx={{
                width: '100%', maxWidth: 360,
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            style={{ color: '#1976d2' }}>
            <ListItemButton onClick={() => { subMenuItems ? setOpenCurSubMenu(!openCurSubMenu) : setActiveMenu(LinkSlug) }}>
                <ListItemIcon>
                    <ItemIcon className="muicon" />
                </ListItemIcon>
                <ListItemText primary={ItemName} />
                {subMenuItems ? (openCurSubMenu ? <ExpandLess /> : <ExpandMore />) : ''}
            </ListItemButton>
            {
                subMenuItems ?
                    <Collapse in={openCurSubMenu} timeout="auto" unmountOnExit>
                        {
                            subMenuItems.map((curSubMenu) => {
                                return (
                                    curSubMenu.privilege == 1 ?
                                        <List component="div" disablePadding>
                                            <Link
                                                to={curSubMenu.LinkSlug ? curSubMenu.LinkSlug : '#'}
                                                className='menulink'
                                                style={activeMenu === curSubMenu.LinkSlug ? activeStyle : {}}
                                            >
                                                <ListItemButton sx={{ pl: 4 }}
                                                    onClick={handleDrawerClose ? () => { handleDrawerClose(setActiveMenu(curSubMenu.LinkSlug)) } : null}
                                                >
                                                    <ListItemIcon>
                                                        <curSubMenu.menuIcon className="muicon" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={curSubMenu.menuText} />
                                                </ListItemButton>
                                            </Link>
                                        </List> : ""
                                )
                            })
                        }
                    </Collapse> : ''
            }
        </List>
    </div>
    return (
        result
    )
}

const Menu = (props) => {

    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = (nextCall) => {
        setOpen(false);
        if (nextCall) {
            nextCall()
        }
    };

    const activeStyle = { color: 'red', 'fontWeight': '600' }

    const submenuStoreOperation = [
        { menuIcon: TrendingDown, menuText: 'Inward', LinkSlug: 'InwardList', privilege: localStorage.getItem('In') },
        // { menuIcon: TrendingUp, menuText: 'Outward', LinkSlug: 'OutwardList', privilege: localStorage.getItem('Ou') },
    ]
    const submenuMaster = [
        { menuIcon: People, menuText: 'Members', LinkSlug: 'MemberMaster', privilege: localStorage.getItem('Me') },
        { menuIcon: Category, menuText: 'Product Category', LinkSlug: 'ProductCategoryMaster', privilege: localStorage.getItem('Pc') },
        { menuIcon: LibraryAdd, menuText: 'Products', LinkSlug: 'Products', privilege: localStorage.getItem('Pm') },
        { menuIcon: TouchApp, menuText: 'Subscription Plan', LinkSlug: 'SubscriptionPlan', privilege: localStorage.getItem('Sp') },
        { menuIcon: Diversity3, menuText: 'Group Master', LinkSlug: 'GroupMaster', privilege: localStorage.getItem('Sp') },
    ]

    const isLoggedIn = () => {
        return localStorage.getItem('UI') && localStorage.getItem('UI').length > 0 ? true : false
    }

    return (
        <div style={{ display: "block", position: 'absolute' }}>
            <Box sx={{ display: 'flex' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <CssBaseline />
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            backgroundColor: '#EBEEF8'
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader style={{ backgroundColor: '#EBEEF8' }}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {
                        isLoggedIn() ?
                            <MenuListItemIcon ItemName={'Dashboard'} LinkSlug={'UserDashboard'} ItemIcon={Dashboard} activeMenu={props.navInfo.activeMenu} handleDrawerClose={handleDrawerClose} activeStyle={activeStyle} setActiveMenu={props.setActiveMenu} />
                            : ''
                    }
                    {
                        (isLoggedIn() && (localStorage.getItem('In') == 1 || localStorage.getItem('Ou') == 1)) ?
                            <DrawerMenuList ItemIcon={Store} ItemName={'Store Operations'} subMenuItems={submenuStoreOperation} setActiveMenu={props.setActiveMenu} LinkSlug={'StoreOperations'} activeStyle={activeStyle} activeMenu={props.navInfo.activeMenu} handleDrawerClose={handleDrawerClose} />
                            : ''
                    }
                    {
                        isLoggedIn() ?
                            <MenuListItemIcon ItemName={'Return List'} LinkSlug={'ReturnDueList'} ItemIcon={PendingActions} activeMenu={props.navInfo.activeMenu} handleDrawerClose={handleDrawerClose} activeStyle={activeStyle} setActiveMenu={props.setActiveMenu} />
                            : ''
                    }
                    {
                        (isLoggedIn() && (localStorage.getItem('Me') == 1 || localStorage.getItem('Pc') == 1 || localStorage.getItem('Pm') == 1 || localStorage.getItem('Sp') == 1)) ?
                            <DrawerMenuList ItemIcon={Assignment} ItemName={'Masters'} subMenuItems={submenuMaster} setActiveMenu={props.setActiveMenu} LinkSlug={'Masters'} activeStyle={activeStyle} activeMenu={props.navInfo.activeMenu} handleDrawerClose={handleDrawerClose} />
                            : ''
                    }
                    {
                        (isLoggedIn() && localStorage.getItem('Um') == 1) ?
                            <MenuListItemIcon ItemName={'User Management'} LinkSlug={'UserManagement'} ItemIcon={AssignmentInd} activeMenu={props.navInfo.activeMenu} handleDrawerClose={handleDrawerClose} activeStyle={activeStyle} setActiveMenu={props.setActiveMenu} />
                            : ''
                    }
                    {
                        (isLoggedIn() && Number(localStorage.getItem('De')) === 1) ?
                            <MenuListItemIcon ItemName={'Settings'} LinkSlug={'Settings'} ItemIcon={Settings} activeMenu={props.navInfo.activeMenu} handleDrawerClose={handleDrawerClose} activeStyle={activeStyle} setActiveMenu={props.setActiveMenu} />
                            : ''
                    }
                    <MenuListItemIcon ItemName={isLoggedIn() ? 'Log Out ' + localStorage.getItem('UI') : 'Log In'}
                        LinkSlug={'/'} ItemIcon={LockOpen} activeMenu={props.navInfo.activeMenu} handleDrawerClose={handleDrawerClose} activeStyle={activeStyle} setActiveMenu={props.setActiveMenu} />
                    {/* <Divider />                     */}
                </Drawer>
                <Main open={open}>
                    <DrawerHeader />
                </Main>
            </Box>
        </div>
    );
}

export default connect(mapStatetoProps, mapSetActiveMenuToProps)(Menu)