import React from 'react'
import { useState, useEffect } from 'react';

const Timer = (props) => {
    const { initialMinute = 0, initialSeconds = 0, initialMiliSeconds = 0, timerPreContent = false, timerPostContent = false, timerPostFunctionCall = undefined } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [postFunctionCallCount,setPostFunctionCallCount] = useState(0);
    const [miliSeconds, setMiliSeconds] = useState(initialMiliSeconds);
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (miliSeconds > 0) {
                setMiliSeconds(miliSeconds - 1)
            }
            if (miliSeconds === 0) {
                if (seconds === 0) {
                    if (minutes === 0) {
                        if(timerPostFunctionCall && postFunctionCallCount===0){
                            setPostFunctionCallCount(Number(postFunctionCallCount)+1)
                            timerPostFunctionCall()
                        }
                        clearInterval(myInterval)
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                        setMiliSeconds(999)
                    }
                } else {
                    setSeconds(seconds - 1);
                    setMiliSeconds(999)
                }
            }

        }, 1)
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <div>
            {minutes === 0 && seconds === 0 && miliSeconds === 0
                ? (timerPostContent ? timerPostContent : <h2>Timer Over</h2>)
                : (timerPreContent ? timerPreContent : <h1> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}:{miliSeconds}</h1>)
            }
        </div>
    )
}

export default Timer;