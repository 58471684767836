import React from 'react'
import TextField from '@mui/material/TextField';

const CtMultiLineText = ({
    id, label, disabled, value, width, handleOnChange, rows, maxLength, foreColor, paddingZero,
    onKeyDown, defaultAction, validateInput, variant
}) => {

    let curVariant = variant ? variant : 'standard'

    const useWidthStyle = { width: width + 'px' }
    // const custStyle = { color: foreColor }
    const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, 1, defaultAction, validateInput) }) : (null)
    let inputPropsStyle = {}
    if (maxLength > 0) {
        inputPropsStyle.maxLength = maxLength
    } else {
        inputPropsStyle.maxLength = 1
    }
    if (disabled && disabled === true) {
        inputPropsStyle.readOnly = disabled
    }
    
    let style = {}
    if (Number(width) > 0) {
        style.width = width + 'px'
    }
    
    return (
        <TextField
            id={id}
            name={id}
            label={label}
            disabled={disabled}
            value={value}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            multiline
            rows={rows}
            variant={curVariant}
            style={style}
            inputProps={inputPropsStyle}
        />
    )
}

export default CtMultiLineText