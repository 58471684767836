import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button } from '@mui/material'
import TableList from './CustomTool/TableList'
import CtDtp from './CustomTool/CtDtp'
import CtBtn from './CustomTool/CtBtn'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import SalesItemEntry from './SalesItemEntry'
import CtTxt from './CustomTool/CtTxt'
import CtTxtNum from './CustomTool/CtTxtNum'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import TouchApp from '@mui/icons-material/TouchApp'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import Dashboard from '@mui/icons-material/Dashboard'
import { get_DMY_from_YMD, get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'
import { fetchSales, fetchMember, apiURL } from './API/API'
import { getRowData } from './SystemUtility/SystemUtility'
import HOCMemberInfo from './HOC/HOCMemberInfo'
import { withRouter } from './HOC/withRouter'

class GuestSales extends Component {

    state = {
        status: [],
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_invoiceno', label: 'Invoice No', hide: 'true' },
            { id: 'bint_itemsrno', label: 'ItemSrNo', hide: 'true' },
            { id: 'vac_productcode', label: 'Product Code' },
            { id: 'vac_productcategory', label: 'Product Category' },
            { id: 'vac_productname', label: 'Product Name' },
            // { id: 'vac_description', label: 'Product Name' },
            { id: 'dec_mrp', label: 'MRP' },
            { id: 'dec_salesrate', label: 'Sales Rate' },
            { id: 'dec_quantity', label: 'Quantity' },
            { id: 'dec_amount', label: 'Amount' },
            { id: 'dec_discountper', label: 'Dis. %' },
            { id: 'dec_discountamt', label: 'Dis. Amount' },
            { id: 'dec_taxable', label: 'Taxable' },
            { id: 'vac_taxname', label: 'Tax' },
            { id: 'dec_cgst', label: 'CGST' },
            { id: 'dec_sgst', label: 'SGST' },
            { id: 'dec_igst', label: 'IGST' },
            { id: 'dec_totalamount', label: 'Total Amount' },
        ],
        formData: {
            txtInvoiceNo: '',
            dtpSalesDate: get_YMD_from_SYS(new Date()),
            txtCardNo: '',
            txtKidName: '',
            txtGuardianName: '',
            txtAvailableCardPoints: 0,
            imgKidPhoto: '',
            txtTotalProductCount: '',
            txtNetAmount: '',
            txtCashPayment: '',
            txtOtherPayment: '',
            MemberID: this.props.memberInfo.MemberID,
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'GuestSales:', actionType: 'AlertResponsive', },
            { name: 'Delete', icon: <Delete />, link: '', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'bint_invoiceno', 'bint_itemsrno', 'vac_productname', 'vac_productcode'
        ],
        searchData: {
            Action: '',
            bint_invoiceno: '',
            bint_itemsrno: '',
            vac_productcode: '',
            vac_productcategory: '',
            vac_productname: '',
            vac_description: '',
            dec_mrp: '',
            dec_salesrate: '',
            dec_quantity: '',
            dec_amount: '',
            dec_discountper: '',
            dec_discountamt: '',
            dec_taxable: '',
            vac_taxname: '',
            dec_cgst: '',
            dec_sgst: '',
            dec_igst: '',
            dec_totalamount: '',
            ProductCode: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
        ProductCategoryList: [],
        TaxNameList: [],
        ProductNameList: [],
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Guest Sales'
        this.props.setActiveMenu("GuestSales")
        //this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo)
        document.getElementById('dtpSalesDate').focus()
        this.setSearchColumns()
        this.onFormLoad()
    }
    //#endregion

    //#region handle
    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rowsCount > 0) {
            this.state.rows.map((row) => {
                if (searchOpen === true && (
                    (this.state.searchData.vac_productcode.length === 0 || row['vac_productcode'].toString().includes(this.state.searchData.vac_productcode)) &&
                    (this.state.searchData.vac_productcategory.length === 0 || row['vac_productcategory'].toString().toLowerCase().includes(this.state.searchData.vac_productcategory.toLowerCase())) &&
                    (this.state.searchData.vac_productname.length === 0 || row['vac_productname'].toString().toLowerCase().includes(this.state.searchData.vac_productname.toLowerCase())) &&
                    (this.state.searchData.vac_taxname.length === 0 || row['vac_taxname'].toString().toLowerCase().includes(this.state.searchData.vac_taxname.toLowerCase()))
                )) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })

    }

    handleARDonActionClick = (values) => {
        // console.log('values', values)
        const txtInvoiceNo = values[0].value,
            actionName = values[0].actionName,
            EditItemSrNo = values[1].value,
            vac_productname = values[2].value,
            vac_productcode = values[3].value
        if (actionName === "Edit") {
            // console.log("this.state.rows",this.state.rows);
            // console.log("EditItemSrNo",EditItemSrNo);
            const curRowData = getRowData(this.state.rows, 'bint_itemsrno', EditItemSrNo)
            // console.log("curRowData",curRowData);
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    // labelAgree='Delete'
                    labelDisagree='Close'
                    // alertTitle={'Do you want to Delete  : ' + txtInvoiceNo + ' ?'}
                    alertMessage={
                        <SalesItemEntry EditInvoiceNo={txtInvoiceNo}
                            EditCardNo={this.state.formData.txtCardNo}
                            EditItemSrNo={EditItemSrNo}
                            EditSalesDate={this.state.formData.dtpSalesDate}
                            EditKidName={this.state.formData.txtKidName}
                            EditGuardianName={this.state.formData.txtGuardianName}
                            rowItem={curRowData} ProductCategoryList={this.state.ProductCategoryList}
                            TaxNameList={this.state.TaxNameList} ProductNameList={this.state.ProductNameList}
                            setSalesInfo={this.setSalesInfo} EditMemberID={this.state.formData.MemberID} SalesMode='GuestSales' />
                    }
                    // handleOnClickYes={() => {
                    //     return this.deletePeanuteDiseaseCalculator(intSrNo)
                    // }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Product : ' + vac_productname + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteSalesItem(txtInvoiceNo, this.state.formData.dtpSalesDate, EditItemSrNo, vac_productcode)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }
    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'Lbl',
                label: 'bint_invoiceno',
                width: '100',
            },
            {
                cType: 'Lbl',
                label: 'bint_itemsrno',
                width: '100',
            },
            {
                cType: 'CtTxt',
                id: 'vac_productcode',
                label: 'Product Code',
                value: this.state.searchData.vac_productcode,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'vac_productcategory',
                label: 'Product Category',
                value: this.state.searchData.vac_productcategory,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'vac_productname',
                label: 'Product Name',
                value: this.state.searchData.vac_productname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'MRP',
                width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Sales Rate',
                width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Quantity',
                width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Amount',
                width: '100',
            },
            {
                cType: 'Lbl',
                label: 'Dis. %',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'Dis. Amt',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'Taxable',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'Tax',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'CGST',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'SGST',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'IGST',
                width: '100'
            },
            {
                cType: 'Lbl',
                label: 'Total Amount',
                width: '100'
            },
        )
        this.setState({ searchColumns })
    }

    onNewEntryClick = () => {
        if (this.validateNewItem() === true) {
            let ARD = null
            this.setState({ ARD }, () => {
                ARD = <AlertResponsiveDialog
                    labelDisagree='Close'
                    // alertTitle={'Sales Item Entry'}
                    alertMessage={
                        <SalesItemEntry
                            EditInvoiceNo={this.state.formData.txtInvoiceNo}
                            EditCardNo={this.state.formData.txtCardNo}
                            EditSalesDate={this.state.formData.dtpSalesDate}
                            EditKidName={this.state.formData.txtKidName}
                            EditGuardianName={this.state.formData.txtGuardianName}
                            ProductCategoryList={this.state.ProductCategoryList}
                            TaxNameList={this.state.TaxNameList}
                            ProductNameList={this.state.ProductNameList} setSalesInfo={this.setSalesInfo}
                            EditMemberID={this.state.formData.MemberID}
                            SalesMode='GuestSales' />
                    }
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    onDeleteClick = () => {
        const InvoiceNo = this.state.formData.txtInvoiceNo,
            SalesDate = this.state.formData.dtpSalesDate
        if (Number(InvoiceNo) > 0) {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Invoice No. : ' + InvoiceNo + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteSales(InvoiceNo, SalesDate)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else {
            this.props.toastMsg('error', 'No records to Delete')
        }
    }

    deleteSales = (InvoiceNo, SalesDate) => {
        if (InvoiceNo && InvoiceNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteSales',
                    bint_invoiceno: InvoiceNo,
                    dat_salesdate: SalesDate
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.newSalesEntry()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteSales. ' + error)
                    })
            })
        }
        return true
    }

    deleteSalesItem = (InvoiceNo, SalesDate, ItemSrNo, ProductCode) => {
        if (InvoiceNo && InvoiceNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteSalesItemEntry',
                    bint_invoiceno: InvoiceNo,
                    dat_salesdate: SalesDate,
                    bint_itemsrno: ItemSrNo,
                    vac_productcode: ProductCode
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let SalesItemList = res.data.SalesItemEntryList
                            this.setState({
                                dataLoadStatus: true,
                                rows: SalesItemList,
                                rowsCount: SalesItemList.length
                            }, () => {
                                // this.listSales()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteSales. ' + error)
                    })
            })
        }
        return true
    }

    newSalesEntry = () => {
        this.setState({
            rows: [],
            rowsCount: 0,
            formData: {
                ...this.state.formData,
                txtInvoiceNo: '',
                dtpSalesDate: get_YMD_from_SYS(new Date()),
                txtCardNo: '',
                txtKidName: '',
                txtGuardianName: '',
                txtAvailableCardPoints: 0,
                imgKidPhoto: '',
                MemberID: '',
                txtTotalProductCount: '',
                txtNetAmount: '',
                txtCashPayment: '',
                txtOtherPayment: '',
            },
        }, () => {
            this.navigateTo('GuestSales')
            document.getElementById('dtpSalesDate').focus()
        })
    }

    validateSales = () => {
        let validateSales = false,
            TotalPayment = Number(this.state.formData.txtCashPayment) + Number(this.state.formData.txtOtherPayment)
        if (Number(this.state.formData.txtNetAmount) !== TotalPayment) {
            this.props.toastErrorMsg('Total Payment is not valid', 'txtCashPayment')
        } else if (this.state.formData.txtKidName.length <= 0) {
            this.props.toastErrorMsg('Enter Kid Name', 'txtKidName')
        } else if (this.state.formData.txtGuardianName.length <= 0) {
            this.props.toastErrorMsg('Enter Guardian Name', 'txtGuardianName')
        }

        else {
            validateSales = true
        }
        return validateSales
    }

    validateNewItem = () => {
        let validateNewItem = false
        if (this.state.formData.txtKidName.length <= 0) {
            this.props.toastErrorMsg('Enter Kid Name', 'txtKidName')
        } else if (this.state.formData.txtGuardianName.length <= 0) {
            this.props.toastErrorMsg('Enter Guardian Name', 'txtGuardianName')
        }
        else {
            validateNewItem = true
        }
        return validateNewItem
    }


    validateItemCount = () => {
        let validateItemCount = false
        if (this.state.rowsCount <= 0) {
            this.props.toastMsg('error', 'Enter Item detail and then try Guest Save Sales')
        } else {
            validateItemCount = true
        }
        return validateItemCount
    }

    saveSales = (printInvoice = false) => {
        if (this.validateSales() && this.validateItemCount()) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'GuestSaveSales',
                    bint_invoiceno: this.state.formData.txtInvoiceNo,
                    dat_salesdate: this.state.formData.dtpSalesDate,
                    vac_kidname: this.state.formData.txtKidName,
                    vac_guardianname: this.state.formData.txtGuardianName,
                    dec_netamount: this.state.formData.txtNetAmount,
                    dec_cashpayment: this.state.formData.txtCashPayment,
                    dec_otherpayment: this.state.formData.txtOtherPayment,
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                if (printInvoice == true) {
                                    this.printInvoice()
                                }
                                // this.newSalesEntry()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in saveSales. ' + error)
                    })
            })
        }
    }

    calculateNetAmount = () => {
        let txtNetAmount = 0
        if (Number(this.state.formData.txtCashPayment) > 0 || Number(this.state.formData.txtOtherPayment) > 0) {
            txtNetAmount = Number(this.state.formData.txtCashPayment) + Number(this.state.formData.txtOtherPayment)
        }
        this.setState({
            formData: {
                ...this.state.formData,
                txtNetAmount
            }
        })
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let EditInvoiceNo = 0, EditSalesDate = ''
            if (this.props.params.EditInvoiceNo && (this.props.params.EditInvoiceNo.substring(1).length) > 0) {
                //  console.log('this.props.params.EditInvoiceNo',this.props.params.EditInvoiceNo)
                var res = this.props.params.EditInvoiceNo.split(":")
                EditInvoiceNo = res[1]
                EditSalesDate = res[2]
                let reqData = {
                    Op: 'GuestSalesEditInfo',
                    bint_invoiceno: EditInvoiceNo,
                    dat_salesdate: EditSalesDate
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        let rowsCount = (res.data.SalesItemEntryList ? res.data.SalesItemEntryList.length : 0)
                        let rows = (res.data.SalesItemEntryList ? res.data.SalesItemEntryList : [])
                        this.setState({
                            dataLoadStatus: true,
                            ProductCategoryList: res.data.ProductCategoryList,
                            TaxNameList: res.data.TaxList,
                            ProductNameList: res.data.ProductMasterList,
                            rows,
                            rowsCount,
                            formData: {
                                ...this.state.formData,
                                txtInvoiceNo: EditInvoiceNo,
                                dtpSalesDate: get_YMD_from_SYS(EditSalesDate),
                                txtCardNo: res.data.SalesList[0].vac_cardno,
                                txtKidName: res.data.SalesList[0].vac_kidname,
                                txtGuardianName: res.data.SalesList[0].vac_guardianname,
                                txtAvailableCardPoints: res.data.SalesList[0].dec_cardpointbalance,
                                txtTotalProductCount: rowsCount,
                                txtNetAmount: res.data.SalesList[0].dec_netamount,
                                txtCashPayment: res.data.SalesList[0].dec_cashpayment,
                                txtOtherPayment: res.data.SalesList[0].dec_otherpayment,
                                // imgKidPhoto: defaultImg,
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in onFormLoad. ' + error)
                    })
            } else {
                let reqData = {
                    Op: 'LoadAllCmbSalesDetails'
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true,
                            ProductCategoryList: res.data.ProductCategoryList,
                            TaxNameList: res.data.TaxList,
                            ProductNameList: res.data.ProductMasterList,
                        }, () => {
                            this.newSalesEntry()
                        })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in onFormLoad. ' + error)
                    })
            }
        })
    }

    setSalesInfo = (txtInvoiceNo, txtNetAmount, SalesItemList) => {
        this.setState({
            rows: SalesItemList,
            rowsCount: SalesItemList.length,
            formData: {
                ...this.state.formData,
                txtInvoiceNo,
                txtTotalProductCount: SalesItemList.length,
                txtNetAmount
            }
        })
    }

    printInvoice = () => {
        this.props.notifyProcessing()
        let reqData = [
            { name: 'Op', value: 'GuestSalesInvoiceReport' },
            { name: 'invoice_no', value: this.state.formData.txtInvoiceNo },
            { name: 'date', value: this.state.formData.dtpSalesDate },
            { name: 'header_footer', value: true },
        ]

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", apiURL + 'reportdetail.php');

        form.setAttribute("target", "view");

        reqData.forEach((data) => {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", data.name);
            hiddenField.setAttribute("value", data.value);
            form.appendChild(hiddenField);
            document.body.appendChild(form);
        })

        window.open('', 'view');

        form.submit();

        this.props.updateProcessing('success', 'PDF Open In Next Tab.')
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" className="title" spacing={2} marginLeft={0}>
                    <Grid item xs={3} style={{ marginLeft: '50px' }}>
                        <Button
                            variant="outlined"
                            style={{ backgroundColor: '#d6ddf0', borderRadius: '10px', width: '250px', justifyContent: 'flex-start' }}
                            startIcon={<Dashboard />}
                            onClick={() => { this.navigateTo('UserDashboard') }}>User Dashboard
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <center>
                            <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}>
                                <b>Guest Sales Entry</b>
                            </label>
                        </center>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtInvoiceNo'
                                    label='Invoice No*'
                                    maxLength={10}
                                    disabled={true}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formData.txtInvoiceNo}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtDtp
                                    id='dtpSalesDate'
                                    label='Sales Date*'
                                    width='150'
                                    handleOnChange={this.handleOnChange}
                                    defaultValue={this.state.formData.dtpSalesDate}
                                    value={this.state.formData.dtpSalesDate}
                                    onKeyDown={this.props.onKeyDown}
                                // disabled={this.state.disabledProfileData.DOBDS}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtTotalProductCount'
                                    label='Total Product Count*'
                                    maxLength={10}
                                    disabled={true}
                                    width='160'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formData.txtTotalProductCount}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>

                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtKidName'
                                    label='Kid Name*'
                                    maxLength={100}
                                    width='200'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formData.txtKidName}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtGuardianName'
                                    label='Guardian Name*'
                                    maxLength={100}
                                    width='265'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formData.txtGuardianName}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                            <Grid item>
                                <CtTxt
                                    id='txtNetAmount'
                                    label='Net Amount*'
                                    maxLength={10}
                                    disabled={true}
                                    width='135'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formData.txtNetAmount}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtCashPayment'
                                    label='Cash Payment*'
                                    maxLength={10}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formData.txtCashPayment}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtOtherPayment'
                                    label='Other Payment*'
                                    maxLength={10}
                                    width='130'
                                    handleOnChange={this.handleOnChange}
                                    value={this.state.formData.txtOtherPayment}
                                    onKeyDown={this.props.onKeyDown}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} marginTop={0}>
                            <Grid item>
                                <CtBtn label='Save' onClick={() => { this.saveSales() }} />
                            </Grid>
                            <Grid item>
                                <CtBtn label='New' onClick={this.newSalesEntry} />
                            </Grid>
                            {localStorage.getItem('De') == 1 ?
                                <Grid item>
                                    <CtBtn label='Delete' onClick={() => {
                                        this.onDeleteClick()
                                    }} />
                                </Grid> : ""}
                            <Grid item>
                                <CtBtn label='Invoice' onClick={() => { this.saveSales(true) }} />
                            </Grid>
                            <Grid item>
                                <CtBtn label='List' onClick={() => { localStorage.setItem('SM', 'GS'); this.navigateTo('SalesList'); }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={() => { this.onNewEntryClick() }}
                    toolTipNewEntry='New Sales Item Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HOCMemberInfo(HoCtToastContainer(withRouter(GuestSales))))