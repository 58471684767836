import { connect } from 'react-redux'
import { setActiveMenu } from '../actions/navAction'
import { setMemberInfo, setSubscriptionInfo, setCurrentPoints } from '../actions/memberAction'

export const mapStatetoProps = (state) => {
    return {
        navInfo: state.navInfo,
        memberInfo: state.memberInfo,
    }
}

export const mapSetActiveMenuToProps = (dispatch) => {
    return {
        setActiveMenu: (ActiveMenu) => { dispatch(setActiveMenu(ActiveMenu)) }
    }
}

export const mapSetMemberInfoSetActiveMenuToProps = (dispatch) => {
    return {
        setActiveMenu: (ActiveMenu) => { dispatch(setActiveMenu(ActiveMenu)) },
        setMemberInfo: (MemberInfo) => { dispatch(setMemberInfo(MemberInfo)) },
        setSubscriptionInfo: (MemberInfo) => { dispatch(setSubscriptionInfo(MemberInfo)) },
        setCurrentPoints: (MemberInfo) => { dispatch(setCurrentPoints(MemberInfo)) },
    }
}