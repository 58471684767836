import React, { useState } from 'react'
import { VerifyIsUser, loginPath } from '../API/API'
import { toast } from 'react-toastify'

const HOCVerifyIsUser = (UserArea) => {

    const verifyUserID = (userID, funCallForValidUser) => {
        if (userID && userID.length > 0) {
            VerifyIsUser(userID)
                .then(res => {
                    if (res.data.status && Number(res.data.status)===1) {
                        if (funCallForValidUser) {
                            funCallForValidUser()
                        }
                    } else {
                        navigateToLogin()
                    }
                })
        } else {
            navigateToLogin()
        }
    }

    const navigateToLogin = () => {
        clearLocalStorage()
        window.location.assign(loginPath)
    }

    const clearLocalStorage = () => {
        localStorage.removeItem('UI');
    }

    return (props) => {
        return (
            <div className='UserArea'>
                <UserArea
                    {...props}
                    verifyUserID={verifyUserID}
                />
            </div>
        )
    }
}
export default HOCVerifyIsUser