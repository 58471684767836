import React, { Component } from 'react'
import { withRouter } from './HOC/withRouter'
import Logo from './images/logo.png'
import MemberDashboardIcon from './images/memberdashboard.png'
import return1 from './images/return1.png'
import info from './images/info.png'
import { Grid, Avatar, Box, Button, InputLabel, Alert } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import { connect } from 'react-redux'
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { fetchMember } from './API/API'
import { fetchProductMaster } from './API/API'
import ProductInfo from './ProductInfoOnUserDashboard'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import MemberTable from './MemberTable'
import MemberIcon from './images/default.png'
import sales from './images/sales.png'
//import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

class UserDashboard extends Component {

    state = {
        isLoading: true,
        dataLoadStatus: true,
        formData: {
            txtAnMemberCardNo: '',
            txtMobileNo: '',
            txtProductCode: '',
            txtKidName: ''
        },
        rows: [],
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 10,
        ARD: null,
    }

    constructor(props) {
        super(props)
        // this.state = { isLoading: true }
        this.state = {
            isLoading: true,
            dataLoadStatus: true,
            formData: {
                txtAnMemberCardNo: '',
                txtMobileNo: '',
                txtProductCode: '',
                txtKidName: ''
            }
        }
    }

    //#region Component
    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }

    componentDidMount() {
        localStorage.removeItem('MI')
        this.setState({ isLoading: false }, () => {
            this.props.setActiveMenu("UserDashboard")
            document.title = 'TED : User Dashboard'
            document.getElementById('txtAnMemberCardNo').focus()
        })

    }
    //#endregion component

    //#region handle
    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }
    //#endregion handle

    //#region Function

    listMember = () => {
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                Op: 'MemberList',
            }
            fetchMember(reqData)
                .then(res => {
                    const rows = res.data.MemberList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in listMember. ' + error)
                })
        })
    }

    ValidateProductCode = () => {
        // console.log('this.state.formData.txtProductCode', this.state.formData.txtProductCode)
        let result = false
        if (Number(this.state.formData.txtProductCode) <= 0) {
            this.props.toastErrorMsg('Enter Product Code And Try Again', 'txtProductCode')
        }
        else {
            result = true
        }
        return result
    }

    ValidateMemberInfo = () => {
        let result = false
        if (Number(this.state.formData.txtAnMemberCardNo) <= 0 && this.state.formData.txtMobileNo <= 0 && this.state.formData.txtKidName <= 0) {
            this.props.toastErrorMsg('Enter Member ID OR Mobile No OR Kid Name and Try Again', 'txtAnMemberCardNo')
        } else if (Number(this.state.formData.txtAnMemberCardNo) > 0 && Number(this.state.formData.txtAnMemberCardNo).toString().length < 6) {
            this.props.toastErrorMsg('Enter Valid Member ID', 'txtAnMemberCardNo')
        } else if (this.state.formData.txtMobileNo.trim().length > 0 && this.state.formData.txtMobileNo.trim().length < 10) {
            this.props.toastErrorMsg('Enter Valid Mobile No', 'txtMobileNo')
        }
        else {
            result = true
        }
        return result
    }

    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }
    
    getInfo = () => {
        if (this.ValidateProductCode()) {
            let dataLoadStatus = false
            this.setState({ dataLoadStatus }, () => {
                const reqData = {
                    'Op': 'ProductMasterList',
                    'vac_productcode': Number(this.state.formData.txtProductCode)
                }
                fetchProductMaster(reqData)
                    .then(res => {
                        dataLoadStatus = true
                        console.log('res.data.ProductMasterList.length', res.data.ProductMasterList.length)
                        if (res.data.ProductMasterList.length > 0) {
                            let ARD = null
                            this.setState({ ARD }, () => {
                                ARD = <AlertResponsiveDialog
                                    labelDisagree='Close'
                                    alertMessage={
                                        <ProductInfo
                                            ProductCode={Number(this.state.formData.txtProductCode)}
                                            ProductInfo={res.data.ProductMasterList[0]} />
                                    }
                                    defaultOpenDialog={true}
                                    onYesClickCloseIfExeSuccessfully={true}
                                />
                                this.setState({ ARD })
                            })
                        }
                        else {
                            this.props.toastMsg('error', 'Invalid Product Code')
                        }
                        this.setState({
                            dataLoadStatus,
                        })
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in getInfo.', error)
                    })
            })
        }
    }

    onMemberDashboardBtnClick = () => {
        if (this.ValidateMemberInfo()) {
            if (this.state.formData.txtAnMemberCardNo.length > 0 && this.state.formData.txtAnMemberCardNo != "") {
                this.navigateToMemberDashboard(this.state.formData.txtAnMemberCardNo)
            } else {
                this.props.notifyProcessing()
                let reqData = {
                    Op: 'GetKidsInfo',
                    vac_cardno: this.state.formData.txtAnMemberCardNo,
                    vac_mobileno: this.state.formData.txtMobileNo,
                    vac_kidsname: this.state.formData.txtKidName,
                }
                fetchMember(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        // console.log('res.data.KidsInfo', res.data.KidsInfo)
                        if (res.data.KidsInfo.length > 1) {
                            let ARD = null
                            this.setState({ ARD }, () => {
                                ARD = <AlertResponsiveDialog
                                    labelDisagree='Close'
                                    alertMessage={
                                        <MemberTable rows={res.data.KidsInfo}
                                            navigateToMemberDashboard={this.navigateToMemberDashboard}
                                            setReduxMemberInfo_Navigate={this.setReduxMemberInfo_Navigate}
                                            openerForMemberTable='MemberDashboard'
                                        />
                                    }
                                    defaultOpenDialog={true}
                                    onYesClickCloseIfExeSuccessfully={true}
                                />
                                this.setState({ ARD })
                            })
                        } else {
                            this.setReduxMemberInfo_Navigate(res)
                        }
                    })
            }
        }
    }

    navigateToMemberDashboard = (vac_cardno = '', vac_mobileno = '', vac_kidsname = '') => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            let reqData = {
                Op: 'GetKidsInfo',
                vac_cardno,
                vac_mobileno,
                vac_kidsname
            }
            fetchMember(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    this.setReduxMemberInfo_Navigate(res)
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in onMemberDashboardBtnClick. ' + error)
                })
        })
    }

    setReduxMemberInfo_Navigate = (res) => {
        if (res.data.msgType === 'success') {
            let memberInfo = {
                Photo: res.data.KidsInfo[0].vac_kidphoto,
                KidName: res.data.KidsInfo[0].vac_kidname,
                GuardianName: res.data.KidsInfo[0].vac_guardianname,
                CurrentPoints: res.data.KidsInfo[0].dec_cardpointbalance,
                MobileNo: res.data.KidsInfo[0].vac_mobileno,
                CardNo: res.data.KidsInfo[0].vac_cardno,
                MemberID: res.data.KidsInfo[0].bint_memberid,
                RecentFromDate: res.data.KidsInfo[0].dat_recentfrom,
                RecentToDate: res.data.KidsInfo[0].dat_recentto,
                Status: res.data.KidsInfo[0].vac_status
            }
            this.setState({
                dataLoadStatus: true
            }, () => {
                this.props.setMemberInfo(memberInfo)
                localStorage.setItem('MI', res.data.KidsInfo[0].bint_memberid)
                this.navigateTo('MemberDashboard')
            })
        }
    }

    onMemberMasterClick = () => {
        this.navigateTo('MemberList')
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        //console.log('this.state.formData.txtMobileNo',this.state.formData.txtMobileNo)
        return (
            <div>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <img src={Logo} alt='Logo' style={{ width: '333px', height: 'auto', borderRadius: '166px' }} />
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item>
                                <CtTxtAdornNum
                                    id='txtAnMemberCardNo'
                                    label='Member ID'
                                    adornment='TED'
                                    value={this.state.formData.txtAnMemberCardNo}
                                    handleOnChange={this.handleOnChange}
                                    width='120'
                                    maxLength={7}
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>OR</InputLabel>
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtMobileNo'
                                    label='Mobile No'
                                    value={this.state.formData.txtMobileNo}
                                    handleOnChange={this.handleOnChange}
                                    width='130'
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>OR</InputLabel>
                            </Grid>
                            <Grid item>
                                <CtTxt
                                    id='txtKidName'
                                    label='Kid Name'
                                    value={this.state.formData.txtKidName}
                                    handleOnChange={this.handleOnChange}
                                    width={this.CtWidth}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        backgroundColor: '#d6ddf0', borderRadius: '10px', width: '360px', width: '260px',
                                        justifyContent: "flex-start"
                                    }}
                                    startIcon={<Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />}
                                    onClick={this.onMemberDashboardBtnClick}><b>Member Dashboard</b>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0}>
                            <Grid item>
                                <CtTxtAdornNum
                                    id='txtProductCode'
                                    label='Product Code'
                                    adornment='TED'
                                    value={this.state.formData.txtProductCode}
                                    handleOnChange={this.handleOnChange}
                                    width='120'
                                    maxLength={7}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    style={{
                                        backgroundColor: '#d2e7be',
                                        // backgroundColor: '#b5d793',
                                        borderRadius: '10px',
                                    }}
                                    startIcon={<Avatar src={info} sx={{ width: 56, height: 56 }} />}
                                    onClick={this.getInfo}
                                ><b>Product Info </b>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginTop={0} marginLeft={0} marginBottom={2}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color='error'
                                    style={{
                                        color: '#ed3652',
                                        backgroundColor: '#e5dbdd',
                                        borderRadius: '10px',
                                        BorderColor: '#f58d9d',
                                        width: '260px',
                                        justifyContent: "flex-start"
                                    }}
                                    fullWidth={true}
                                    onClick={() => { this.navigateTo('ReturnDueList') }}
                                    startIcon={<Avatar src={return1} sx={{ width: 56, height: 56, }} />}
                                ><b>Return Due List</b>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    style={{
                                        backgroundColor: '#d6ddf0', borderRadius: '10px', width: '360px', width: '260px',
                                        justifyContent: "flex-start"
                                    }}
                                    startIcon={<Avatar src={MemberIcon} sx={{ width: 56, height: 56 }} />}
                                    onClick={this.onMemberMasterClick}><b>Member List</b>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    style={{
                                        backgroundColor: '#d6ddf0', borderRadius: '10px', width: '360px', width: '260px',
                                        justifyContent: "flex-start"
                                    }}
                                    startIcon={<Avatar src={sales} sx={{ width: 56, height: 56 }} />}
                                    onClick={() => { this.navigateTo('GuestSales') }}
                                    ><b>Guest Sales</b>
                                </Button>
                            </Grid>

                            <Grid item>
                                <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                                    {this.state.ARD}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(HOCVerifyIsUser(withRouter(UserDashboard))))