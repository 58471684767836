import React, { Component, createRef } from 'react'
import MemberDashboardIcon from './images/memberdashboard.png'
import { Grid, Avatar, Button } from '@mui/material'
import CtTxt from './CustomTool/CtTxt'
import { withRouter } from './HOC/withRouter'
import ImageUploadIcon from './CustomTool/ImageUploadIcon'
import CtBtn from './CustomTool/CtBtn'
import CtDtp from './CustomTool/CtDtp'
import defaultImg from './images/default.png'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import TableList from './CustomTool/TableList'
import { Edit, Delete } from '@mui/icons-material';
import dayjs from 'dayjs';
import CtDtpTime from './CustomTool/CtDtpTime'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import Dashboard from '@mui/icons-material/Dashboard'
import Progress from './CustomTool/Progress'
import { connect } from 'react-redux'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { get_NextDate_of_Given_YMD_Date, get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'

class ReturnDueList extends Component {

    state = {
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'KidName', label: 'Kid Name' },
            { id: 'MobileNo', label: 'Mobile No' },
            { id: 'ReturnDueDateTime', label: 'Return Due Date Time' },
            { id: 'ProductName', label: 'Product Name' },
            { id: 'RentIssueDateTime', label: 'Rent Issue Date Time' },
            { id: 'ChallanNo', label: 'Challan No' },
            { id: 'RentDate', label: 'Rent Date' },
        ],
        rows: [],
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'ReturnDueList:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'ReturnDueList:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'KidName', 'MobileNo', 'ReturnDueDateTime', 'ProductName', 'RentIssueDateTime', 'ChallanNo', 'RentDate'
        ],
        searchData: {
            KidName: '',
            MobileNo: '',
            ReturnDueDateTime: '',
            ProductName: '',
            RentIssueDateTime: '',
            ChallanNo: '',
            RentDate: '',
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,
        formData: {
            txtKidName: '',
            txtMobileNo: '',
            DtpReturnDueDateTime: '',
            DtpRentIssueDateTime: '',
            txtChallanNo: '',
            DtpRentDate: '',
            dtpFromDate: get_YMD_from_SYS(new Date()),
            dtpToDate: ''
        }
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push({
            cType: 'CtTxt',
            id: 'txtKidName',
            label: 'Kid Name',
            value: this.state.searchData.KidName,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',

        }, {
            cType: 'CtTxt',
            id: 'txtMobileNo',
            label: 'Mobile No',
            value: this.state.searchData.MobileNo,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',

        }, {
            cType: 'CtTxt',
            id: 'DtpReturnDueDateTime',
            label: 'Return Due Date Time',
            value: this.state.searchData.ReturnDueDateTime,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'txtProductName',
            label: 'Product Name',
            value: this.state.searchData.ProductName,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',

        }, {
            cType: 'CtTxt',
            id: 'DtpRentIssueDateTime',
            label: 'Rent Issue Date Time',
            value: this.state.searchData.RentIssueDateTime,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtTxt',
            id: 'txtChallanNo',
            label: 'Challan No',
            value: this.state.searchData.ChallanNo,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',
        }, {
            cType: 'CtTxt',
            id: 'DtpRentDate',
            label: 'Rent Date',
            // disabled:{true},
            value: this.state.searchData.RentDate,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100',

        },)
        this.setState({ searchColumns })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.KidName.length === 0 || row['KidName'].toString().includes(this.state.searchData.KidName)) &&
                        (this.state.searchData.MobileNo.length === 0 || row['MobileNo'].toString().includes(this.state.searchData.MobileNo)) &&
                        (this.state.searchData.ReturnDueDateTime.length === 0 || row['ReturnDueDateTime'].toString().includes(this.state.searchData.ReturnDueDateTime)) &&
                        (this.state.searchData.ProductName.length === 0 || row['ProductName'].toString().includes(this.state.searchData.ProductName)) &&
                        (this.state.searchData.RentIssueDateTime.length === 0 || row['RentIssueDateTime'].toString().includes(this.state.searchData.RentIssueDateTime)) &&
                        (this.state.searchData.ChallanNo.length === 0 || row['ChallanNo'].toString().includes(this.state.searchData.ChallanNo)) &&
                        (this.state.searchData.RentDate.length === 0 || row['RentDate'].toString().includes(this.state.searchData.RentDate))

                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }


    //#region handle

    handleARDonActionClick = (values) => {
        console.log('values', values)
        const txtKidName = values[0].value,
            txtMobileNo = values[1].value,
            DtpReturnDueDateTime = values[2].value,
            txtProductName = values[3].value,
            DtpRentIssueDateTime = values[4].value,
            txtChallanNo = values[5].value,
            DtpRentDate = values[6].value,
            actionName = values[0].actionName
        if (actionName === "Edit") {
            this.setState({
                formData: {
                    ...this.state.formData, txtKidName, txtMobileNo, DtpReturnDueDateTime, txtProductName, DtpRentIssueDateTime, txtChallanNo, DtpRentDate
                }
            })
        }
        //  else if (actionName === "Delete") {
        //      this.setState({ ARD: null }, () => {
        //          let ARD = <AlertResponsiveDialog
        //             labelAgree='Delete'
        //              labelDisagree='Cancel'
        //              alertTitle={'Do you want to Delete  : ' + txtMedicineName + ' ?'}
        //             alertMessage=''
        //             handleOnClickYes={() => {
        //              return this.deletePeanuteDiseaseCalculator(intSrNo)
        //              }}
        //             defaultOpenDialog={true}
        //              onYesClickCloseIfExeSuccessfully={true}
        //          />

        //          this.setState({ ARD })
        //  })
        // } 
        else {
            let rows = this.state.rows.filter((row) => {
                if (row.KidName !== txtKidName && row.MobileNo !== txtMobileNo
                    && row.ReturnDueDateTime !== DtpReturnDueDateTime && row.ProductName !== txtProductName && row.DtpRentIssueDateTime !== DtpRentIssueDateTime &&
                    row.ChallanNo !== txtChallanNo && row.RentDate !== DtpRentDate)
                    return row
            })
            const rowsCount = rows.length
            this.setState({ rows, rowsCount })
        }
    }


    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        const ctrlName = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            if (ctrlName === 'dtpFromDate') {
                this.setDtpToDate()
            }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    // handleCheckedChange = () => {
    //     handleCheckChange(!checked)
    // }


    //#endregion handle

    //#region Component

    componentDidMount() {
        this.setSearchColumns()
        document.title = 'TED : Return Due List'
        this.props.setActiveMenu("ReturnDueList")
        document.getElementById('dtpFromDate').focus()
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }


    //#endregion component

    setDtpToDate = () => {
        console.log('before add period FromDt, ToDt', this.state.formData.dtpFromDate, this.state.formData.dtpToDate)
        console.log('this.state.formData', this.state.formData)
        let dtpToDate = this.state.formData.dtpFromDate
        if (this.state.formData.dtpFromDate.length > 0) {
            dtpToDate = window.addPeriod(this.state.formData.dtpFromDate, 2)
        }
        this.setState({ formData: { ...this.state.formData, dtpToDate } }, () => {
            console.log('new FromDt, ToDt', this.state.formData.dtpFromDate, this.state.formData.dtpToDate)
        })
    }



    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Return Due List</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                    <Grid item>
                        <Button
                            variant="outlined"
                            style={{ backgroundColor: '#d6ddf0', borderRadius: '10px' }}
                            startIcon={<Dashboard />}
                            onClick={() => { this.navigateTo('UserDashboard') }}>User Dashboard
                        </Button>
                    </Grid>
                    <Grid item>
                        <CtDtp
                            id='dtpFromDate'
                            label='From Date'
                            width='150'
                            handleOnChange={this.handleOnChange}
                            defaultValue={this.state.formData.dtpFromDate}
                            value={this.state.formData.dtpFromDate}
                            onKeyDown={this.props.onKeyDown}
                        // disabled={this.state.disabledProfileData.DOBDS}
                        />
                    </Grid>
                    <Grid item>
                        <CtDtp
                            id='dtpToDate'
                            label='To Date'
                            width='150'
                            handleOnChange={this.handleOnChange}
                            defaultValue={this.state.formData.dtpToDate}
                            value={this.state.formData.dtpToDate}
                            onKeyDown={this.props.onKeyDown}
                        // disabled={this.state.disabledProfileData.DOBDS}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Load' width={this.state.cWidth - 150} onClick={this.listInward} />
                    </Grid>
                </Grid><br />

                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    // provideSearch={true}
                    onNewEntryClick={this.onNewEntryClick}
                // toolTipNewEntry='New Product Entry'
                />
            </div >
        )
    }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HOCVerifyIsUser(HoCtToastContainer(withRouter(ReturnDueList))))
