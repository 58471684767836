import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import CtDtp from './CustomTool/CtDtp'
import CtBtn from './CustomTool/CtBtn'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import CtTxt from './CustomTool/CtTxt'
import CtTxtAdornNum from './CustomTool/CtTxtAdornNum'
import CtCmb from './CustomTool/CtCmb'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { fetchInwardList, fetchOutwardList } from './API/API'
import OutwardItemEntry from './OutwardItemEntry'
import { get_DMY_from_YMD, get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'
import { getRowData } from './SystemUtility/SystemUtility'

class OutwardEntry extends Component {

    state = {
        OutwardType: [{ Type: 'Member' },
        { Type: 'Supplier' }],
        Supplier: [],
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'ProductCode.', label: 'Product Code.' },
            { id: 'ProductCategory', label: 'Product Category' },
            { id: 'ProductName', label: 'Product Name' },
            { id: 'Qty', label: 'Qty' },
            { id: 'Note', label: 'Note' },
        ],
        formData: {
            txtOutwardNo: '',
            dtpOutwradDate: get_YMD_from_SYS(new Date()),
            MemberID: this.props.memberInfo.MemberID,
            cmbOutwardType: '',
            txtCardNo: '',
            txtMobileno: '',
            cmbSupplier: '',
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'OutwardEntry:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'OutwardEntry', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'ProductCode', 'ProductCategory', 'ProductName', 'Qty', 'Note'
        ],
        searchData: {
            ProductCode: '',
            ProductCategory: '',
            ProductName: '',
            Qty: '',
            Note: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rows: [],
        rowsCount: 0,
        rowsPerPage: 10,
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Outward Entry'
        this.props.setActiveMenu("OutwardEntry")
        this.setSearchColumns()
        this.onFormLoad()
    }
    //#endregion

    //#region handle
    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (searchOpen === true && (
                    (this.state.searchData.ProductCode.length === 0 || row['ProductCode'].toString().includes(this.state.searchData.ProductCode)) &&
                    (this.state.searchData.ProductCategory.length === 0 || row['ProductCategory'].toString().includes(this.state.searchData.ProductCategory))
                        (this.state.searchData.ProductName.length === 0 || row['ProductName'].toString().includes(this.state.searchData.ProductName)) &&
                    (this.state.searchData.Qty.length === 0 || row['Qty'].toString().includes(this.state.searchData.Qty))
                        (this.state.searchData.Note.length === 0 || row['Note'].toString().includes(this.state.searchData.Note))

                )) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    // handleOnChangeTrim = (e) => {
    //     this.setState({
    //         formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
    //     })
    // }

    handleOnChangeSales = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    handleARDonActionClick = (values) => {
        // console.log('values', values)
        const txtOutwarddNo = values[0].value,
            actionName = values[0].actionName,
            EditItemSrNo = values[1].value
        if (actionName === "Edit") {
            const curRowData = getRowData(this.state.rows, 'bint_outwarditemno', EditItemSrNo)
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    // labelAgree='Delete'
                    labelDisagree='Close'
                    // alertTitle={'Do you want to Delete  : ' + txtInvoiceNo + ' ?'}
                    alertMessage={
                        <OutwardItemEntry EditOutwardNo={txtOutwarddNo} EditItemSrNo={EditItemSrNo}
                            EditOutwardDate={this.state.formData.dtpOutwardDate} rowItem={curRowData}
                            ProductCategoryList={this.state.ProductCategoryList}
                            ProductNameList={this.state.ProductNameList}
                        //  setSalesInfo={this.setSalesInfo} EditMemberID={this.state.formData.MemberID} 
                        />
                    }
                    // handleOnClickYes={() => {
                    //     return this.deletePeanuteDiseaseCalculator(intSrNo)
                    // }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        } else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete Item : ' + txtOutwarddNo + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.DeleteOutwardItemEntry(txtOutwarddNo, this.state.formData.dtpOutwradDate, EditItemSrNo)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }

    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }
    onNewEntryClick = () => {
        // if (this.validateSales() === true) {
        let ARD = null
        this.setState({ ARD }, () => {
            ARD = <AlertResponsiveDialog
                labelDisagree='Close'
                // alertTitle={'Sales Item Entry'}
                alertMessage={
                    <OutwardItemEntry />
                }
                defaultOpenDialog={true}
                onYesClickCloseIfExeSuccessfully={true}
            />
            this.setState({ ARD })
        })
        // }
    }
    setOutwardInfo = (txtOutwardNo, OutwardItemEntryList) => {
        this.setState({
            rows: OutwardItemEntryList,
            rowsCount: OutwardItemEntryList.length,
            formData: {
                ...this.state.formData,
                txtOutwardNo, OutwardItemEntryList
            }
        })
    }

    DeleteInwardItemEntry = (InwardNo, InwardItemNo, InwardDate) => {
        if (InwardNo && InwardNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteInwardItemEntry',
                    bint_inwardno: InwardNo,
                    bint_inwarditemno: InwardItemNo,
                    dat_inwarddate: InwardDate,
                }
                fetchInwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let txtInwardNo = InwardNo,
                                InwardItemEntryList = res.data.InwardItemEntryList
                            this.setState({
                                dataLoadStatus: true,
                                formDataItem: {
                                    ...this.state.formData,
                                    EditInwardDate: this.props.EditInwardDate,
                                    EditInwardNo: txtInwardNo
                                },
                            }, () => {
                                this.props.setSalesInfo(txtInwardNo, InwardItemEntryList)
                                this.onNewEntryClick()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteSalesItem. ' + error)
                    })
            })
            return true
        }
    }
    validateOutwardEntry = () => {
        let result = false
        if (this.state.formData.txtOutwardNo.length <= 0) {
            this.props.toastErrorMsg('Enter Outward No', 'txtOutwardNo')
        } else if (this.state.formData.dtpOutwradDate.length <= 0) {
            this.props.toastErrorMsg('Enter Outward Date', 'dtpOutwradDate')
        } else if (this.state.formData.cmbOutwardType.length <= 0) {
            this.props.toastErrorMsg('Enter Outward Type', 'cmbOutwardType')
        } else if (this.state.formData.txtCardNo.length <= 0) {
            this.props.toastErrorMsg('Enter Member ID', 'txtCardNo')
        } else if (this.state.formData.txtMobileno.length <= 0) {
            this.props.toastErrorMsg('Enter Mobile No', 'txtMobileno')
        } else if (this.state.formData.cmbSupplier.length <= 0) {
            this.props.toastErrorMsg('Enter Supplier', 'cmbSupplier')
        }

        else {
            result = true
        }
        return result
    }

    SaveOutwardEntry = () => {
        if (this.validateOutwardEntry()) {
            const dataLoadStatus = false
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus }, () => {
                const reqData = {
                    'Op': 'SaveOutwardEntry',
                    'bint_outwardno': this.state.formData.txtOutwardNo,
                    'dat_outwarddate': this.state.formData.dtpOutwradDate,
                    'vac_outwardtype': this.state.formData.cmbOutwardType,
                    'bint_memberid': this.state.formData.MemberID,
                    'vac_suppliername': this.state.formData.cmbSupplier,

                }
                fetchOutwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.newOutwardEntry()
                        }
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in SaveOutwardEntry.', error)
                    })
            })

        }

    }
    newOutwardEntry = () => {
        this.setState({
            rows: [],
            rowsCount: 0,
            formData: {
                ...this.state.formData,
                txtOutwardNo: '',
                dtpOutwardDate: get_YMD_from_SYS(new Date()),
            },
        }, () => {
            this.navigateTo('OutwardEntry')
            document.getElementById('dtpOutwardDate').focus()
        })
    }

    DeleteOutwardEntry = (txtOutwardNo, dtpOutwardDate) => {
        if (txtOutwardNo && txtOutwardNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteOutwardEntry',
                    'bint_outwardno': txtOutwardNo,
                    'dat_outwarddate': dtpOutwardDate
                }
                fetchOutwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.newOutwardEntry()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in DeleteOutwardEntry. ' + error)
                    })
            })
        }
        return true
    }
    DeleteOutwardItemEntry = (OutwardNo, OutwardItemNo, OutwardDate) => {
        if (OutwardNo && OutwardNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteOutwardItemEntry',
                    bint_outwardno: OutwardNo,
                    bint_outwarditemno: OutwardItemNo,
                    dat_outwarddate: OutwardDate,
                }
                fetchOutwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            let OutwradItemList = res.data.OutwardItemEntryList
                            this.setState({
                                dataLoadStatus: true,
                                rows: OutwradItemList,
                                rowsCount: OutwradItemList.length
                            }, () => {
                                // this.listSales()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteOutwarditem. ' + error)
                    })
            })
        }
        return true
    }

    onFormLoad = () => {
        this.setState({
            dataLoadStatus: false
        }, () => {
            let EditOutwardno = 0, EditOutwardDate = ''
            if (this.props.params.EditOutwardno && (this.props.params.EditOutwardDate.substring(1).length) > 0) {
                var res = this.props.params.EditInwardno.split(":")
                EditOutwardno = res[1]
                EditOutwardDate = res[2]
                let reqData = {
                    Op: 'EditOutwardEntry',
                    bint_outwardno: EditOutwardno,
                    dat_outwarddate: EditOutwardDate
                }
                fetchOutwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        let rowsCount = (res.data.InwardItemEntryList ? res.data.OutwardItemEntryList.length : 0)
                        let rows = (res.data.InwardItemEntryList ? res.data.OutwardItemEntryList : [])
                        this.setState({
                            dataLoadStatus: true,
                            OutwardEntryList: res.data.OutwardEntryList,
                            OutwardItemEntryList: res.data.OutwardItemEntryList,
                            ProductCategoryList: res.data.ProductCategoryList,
                            ProductMasterList: res.data.ProductMasterList,
                            rows,
                            rowsCount,
                            formData: {
                                ...this.state.formData,
                                txtOutwardNo: EditOutwardno,
                                dtpOutwardDate: get_YMD_from_SYS(EditOutwardDate),
                            }
                        }, () => {
                            // console.log("this.state.rows",this.state.rows);
                        })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in onFormLoad. ' + error)
                    })
            } else {
                let reqData = {
                    Op: 'OutwardEntryList',
                    // FromDate: '17/10/2022',
                    // ToDate: '17/10/2022',
                }
                fetchOutwardList(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        this.setState({
                            dataLoadStatus: true,
                            ProductCategoryList: res.data.ProductCategoryList,
                            ProductMasterList: res.data.ProductMasterList,
                        }, () => {
                            this.newOutwardEntry()
                        })
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in onFormLoad. ' + error)
                    })
            }
        })
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'CtTxt',
                id: 'txtProductCode',
                label: 'Product Code',
                value: this.state.searchData.ProductCode,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'txtProductCategory',
                label: 'Product Category',
                value: this.state.searchData.ProductCategory,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'txtProductName',
                label: 'Product Name',
                value: this.state.searchData.ProductName,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'txtQty',
                label: 'Qty',
                value: this.state.searchData.Qty,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            },

            {
                cType: 'CtTxt',
                id: 'txtNote',
                label: 'Note',
                value: this.state.searchData.Note,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
            },
        )
        this.setState({ searchColumns })
    }

    // getInfo = () => {
    //     this.setState({ dataLoadStatus: false }, () => {
    //         let FromDate = this.state.formData.dtpFromDate,
    //             ToDate = this.state.formData.dtpToDate
    //         const reqData = {
    //             Op: 'InwardEntryList',
    //             FromDate,
    //             ToDate,
    //         }
    //         fetchInwardList(reqData)
    //             .then(res => {
    //                 const rows = res.data.ProductMasterList
    //                 const rowsCount = rows.length
    //                 this.setState({ rows, rowsCount, dataLoadStatus: true })
    //             })
    //             .catch(error => {
    //                 this.props.toastMsg('error', 'Unkonwn Error occured in getInfo. ' + error)
    //             })
    //     })
    // }
    //#endregion function

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Outward Entry</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} marginTop={0}>
                    <Grid item>
                        <CtTxt
                            id='txtOutwardNo.'
                            label='Outward No.'
                            width='150'
                            handleOnChange={this.handleOnChangeTrim}
                            // value={this.state.formData.dtpFromDate}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item>
                        <CtDtp
                            id='dtpOutwardDate'
                            label='Outward Date'
                            width='150'
                            handleOnChange={this.handleOnChangeTrim}
                            // value={this.state.formData.dtpToDate}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item>
                        <CtCmb
                            id='cmbOutwardType'
                            label='Outward Type'
                            items={this.state.OutwardType}
                            //  value={this.state.formData.cmbTaxName}
                            // disabled={this.state.disabledProfileData.AccountTypeDS}
                            handleOnChange={this.handleOnChange}
                            width={180}
                            maxLength={50}
                            colID='Type'
                        />
                    </Grid>
                    <Grid item>
                        <CtTxtAdornNum
                            id='txtAnMemberCardNo'
                            label='Member ID'
                            adornment='TED'
                            value={this.state.formData.txtAnMemberCardNo}
                            handleOnChange={this.handleOnChange}
                            width='120'
                            maxLength={7}
                        />
                    </Grid>
                    <Grid item>
                        <CtTxt
                            id='txtMobileNo'
                            label='Mobile No'
                            // value={this.state.formData.txtMobileNo}
                            handleOnChange={this.handleOnChange}
                            width={this.CtWidth}
                            maxLength={10}
                        />
                    </Grid>
                    <Grid item>
                        <CtCmb
                            id='cmbSupplier'
                            label='Supplier'
                            items={this.state.Supplier}
                            //  value={this.state.formData.cmbTaxName}
                            // disabled={this.state.disabledProfileData.AccountTypeDS}
                            handleOnChange={this.handleOnChange}
                            width={180}
                            maxLength={50}
                        //  colID='Type'
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Gate Pass Print / Outward Print' width={this.state.cWidth - 150} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Save' width={this.state.cWidth - 150} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='New' width={this.state.cWidth - 150} />
                    </Grid>
                    {localStorage.getItem('De') == 1 ?
                        <Grid item>
                            <CtBtn label='Delete' width={this.state.cWidth - 150} />
                        </Grid>
                        : ""}
                </Grid>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={() => { this.onNewEntryClick() }}
                    toolTipNewEntry='New Outward Item Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>

            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HoCtToastContainer(withRouter(OutwardEntry)))