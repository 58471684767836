import React from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default function CtDtp({
  id, label, disabled, defaultValue, value, width, handleOnChange, variant,
  onKeyDown, defaultAction, validateInput
}) {
  const classes = useStyles();
  let style = {}
  if (Number(width) > 0) {
    style.width = width + 'px'
  }
  const handleOnKeyDown = (onKeyDown) ? ((event) => { onKeyDown(event, 1, defaultAction, validateInput) }) : (null)
  let curVariant = variant ? variant : 'standard'

  return (
    <TextField
      id={id}
      name={id}
      label={label}
      type="date"
      // disabled={disabled}
      value={value}
      defaultValue={defaultValue}
      className={classes.textField}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{ readOnly: disabled }}
      style={style}
      variant={curVariant}
    />
  );
}