import { Grid, Box } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import React, { Component } from 'react'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import HOCVerifyIsUser from './HOC/HOCVerifyIsUser'
import { fetchProductCategoryMaster } from './API/API'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import AlertResponsiveDialog from './CustomTool/AlertResponsiveDialog'

export class ProductCategoryMaster extends Component {
    state = {
        cWidth: 290,
        statusCount: 0,
        status: [
            { status: 'Active' },
            { status: 'Block' },
        ],
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_productcategorysrno', label: 'ID' },
            { id: 'vac_productcategory', label: 'Product Category Name' },
            { id: 'vac_status', label: 'Status' },
            { id: 'int_status', label: 'Status', hide: 'true' },

        ],
        rows: [],
        formData: {
            txtId: '',
            txtProductCategoryName: '',
            cmbStatus: 'Active',
        },

        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'ProductCategoryMaster:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: 'ProductCategoryMaster:', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'bint_productcategorysrno', 'vac_productcategory', 'vac_status'
        ],
        searchData: {
            Id: '',
            ProductCategoryName: '',
            Status: 'ALL',

        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 5,


    }

    //#region handle
    handleOnSearchInputChange = (e) => {
        // console.log('handleOnSearchInputChange', [e.target.name], e.target.value, this.state.searchData);
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
            // console.log(this.state.searchData)
        })
    }

    handleOnSearchInputChangeTrim = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value.trim() }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        if (searchOpen === true && this.state.rows.length > 0) {
            this.state.rows.map((row) => {
                if (
                    searchOpen === true &&
                    (
                        (this.state.searchData.Status === 'ALL' || this.state.searchData.Status === row['Status']) &&
                        (this.state.searchData.ProductCategoryName.length === 0 || (row['vac_productcategory'] && row['vac_productcategory'].toString().toLowerCase().includes(this.state.searchData.ProductCategoryName.toLowerCase()))) &&
                        (this.state.searchData.Id.length === 0 || (row['bint_productcategorysrno'] && row['bint_productcategorysrno'].toString().includes(this.state.searchData.Id)))

                    )
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        })
    }

    handleOnChangeTrim = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        })
    }
    handleARDonActionClick = (values) => {
        console.log('values', values)
        const txtId = values[0].value,
            txtProductCategoryName = values[1].value,
            cmbstatus = values[2].value,
            actionName = values[0].actionName
        if (actionName === "Edit") {
            this.setState({
                formData: {
                    ...this.state.formData, txtId, txtProductCategoryName, cmbstatus
                }
            })
        }
        else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete  : ' + txtProductCategoryName + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.DeleteProductCategoryMaster(txtId)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />

                this.setState({ ARD })
            })
        }
        else {
            let rows = this.state.rows.filter((row) => {
                if (row.Id !== txtId && row.ProductCategoryName !== txtProductCategoryName && row.Status !== cmbstatus)
                    return row
            })
            const rowsCount = rows.length
            this.setState({ rows, rowsCount })
        }
    }
    //#endregion handle

    //#region Component

    componentDidMount() {
        document.title = 'TED : Product Category Master'
        this.props.setActiveMenu("ProductCategoryMaster")
        document.getElementById('txtProductCategoryName').focus()
        this.setSearchColumns()
        this.getInfo()
    }

    componentWillMount() {
        this.props.verifyUserID(localStorage.getItem('UI'))
    }

    //#endregion component

    //#region function
    setSearchColumns = () => {
        let searchColumns = [], statusCount = this.state.status.length, Searchstatus = []
        if (this.state.status.length > 0) {
            Searchstatus.push({ 'status': 'ALL' })
            this.state.status.map((stat) => {
                Searchstatus.push({ 'status': stat.status })
            })
            console.log('Searchstatus', Searchstatus)
        }

        searchColumns.push(
            {
                cType: 'CtTxt',
                id: 'Id',
                label: 'Id',
                value: this.state.searchData.Id,
                handleOnChange: this.handleOnSearchInputChange,
                width: '100',
                maxLength: '100'
            }, {
            cType: 'CtTxt',
            id: 'ProductCategoryName',
            label: 'Product Category Name',
            value: this.state.searchData.ProductCategoryName,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            maxLength: '100'
        }, {
            cType: 'CtCmb',
            id: 'Status',
            items: Searchstatus,
            label: 'Status',
            value: this.state.searchData.Status,
            handleOnChange: this.handleOnSearchInputChange,
            width: '100',
            colID: 'status',
        })
        this.setState({ searchColumns, statusCount }, () => {
            console.log(this.state.searchColumns)
        })
    }

    getInfo = () => {
        let dataLoadStatus = false
        this.setState({ dataLoadStatus }, () => {
            const reqData = {
                'Op': 'ProductCategoryMasterList',
            }
            fetchProductCategoryMaster(reqData)
                .then(res => {
                    dataLoadStatus = true
                    let rows = res.data.ProductCategoryMasterList,
                        rowsCount = rows.length
                    this.setState({
                        dataLoadStatus,
                        rows, rowsCount
                    })
                })
                .catch(error => {
                    console.log('Unknown error occurred in onFormLoad.', error)
                })
        })
    }

    validateProductCategoyMaster = () => {
        let result = false
        if (this.state.formData.txtProductCategoryName.length <= 0) {
            this.props.toastErrorMsg('Enter Product Category Name', 'txtProductCategoryName')
        } else if (this.state.formData.cmbStatus.length <= 0) {
            this.props.toastErrorMsg('Enter cmbStatus', 'cmbStatus')
        }

        else {
            result = true
        }
        return result
    }

    clearInfo = (DefaultActive = false) => {
        const txtProductCategoryName = '',
            cmbStatus = DefaultActive ? 'Active' : this.state.formData.cmbStatus,
            txtId = ''
        this.setState({
            formData: {
                ...this.state.formData, txtProductCategoryName, cmbStatus, txtId
            }
        }, () => {
            document.getElementById('txtProductCategoryName').focus()
        })
    }

    SaveProductCategoryMaster = () => {
        if (this.validateProductCategoyMaster()) {
            const dataLoadStatus = false
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus }, () => {
                const reqData = {
                    'Op': 'SaveProductCategoryMaster',
                    'bint_productcategorysrno': this.state.formData.txtId,
                    'vac_productcategory': this.state.formData.txtProductCategoryName,
                    'int_status': this.state.formData.cmbStatus === 'Active' ? 1 : 2,
                }
                fetchProductCategoryMaster(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.getInfo()
                            this.clearInfo(true)
                        }
                    })
                    .catch(error => {
                        console.log('Unknown error occurred in SaveProductCategoryMaster.', error)
                    })
            })

        }
    }

    DeleteProductCategoryMaster = (txtId) => {
        this.props.notifyProcessing()
        this.setState({ dataLoadStatus: false }, () => {
            const reqData = {
                'Op': 'DeleteProductCategoryMaster',
                'bint_productcategorysrno': txtId
            }
            fetchProductCategoryMaster(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    if (res.data.msgType === 'success') {
                        this.setState({ ARD: null }, () => { this.getInfo() })
                    } else {
                        this.setState({ dataLoadStatus: true })
                    }
                })
                .catch(error => {
                    this.props.updateProcessing('error', 'Unknown error occurred in delete DeleteProductCategoryMaster. ' + error)
                })
        })
        return false
    }
    //#endregion function

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)

        return (

            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Product Category Master</b></label>
                </Grid>

                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3} marginTop={0}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} marginTop={0}>
                        <Grid item>
                            <CtTxt
                                id='txtProductCategoryName'
                                label='Product Category Name*'
                                value={this.state.formData.txtProductCategoryName}
                                // disabled={this.state.disabledProfileData.AccountTypeDS}
                                handleOnChange={this.handleOnChange}
                                width={this.state.cWidth}
                                maxLength={100}
                            />
                        </Grid>
                        <Grid item>
                            <CtCmb
                                id='cmbStatus'
                                label='Status*'
                                items={this.state.status}
                                value={this.state.formData.cmbStatus}
                                // disabled={this.state.disabledProfileData.AccountTypeDS}
                                handleOnChange={this.handleOnChange}
                                width='150'
                                colID='status'

                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CtBtn label='Save' onClick={this.SaveProductCategoryMaster} /> &nbsp;&nbsp;
                        {/* <CtBtn label='Delete' />&nbsp;&nbsp; */}
                        <CtBtn label='New' onClick={this.clearInfo} />
                    </Grid>

                </Grid>
                <br />
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <TableList
                        columns={this.state.columns}
                        counter={this.state.counter}
                        rows={rows}
                        rowsCount={rowsCount}
                        rowsPerPage={this.state.rowsPerPage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        onActionClick={this.handleARDonActionClick}
                        actionList={this.state.actionList}
                        keyColumn={this.state.keyColumn}
                        handleOnSearchChange={this.handleOnSearchChange}
                        searchColumns={this.state.searchColumns}
                        searchData={this.state.searchData}
                        provideSearch={true}
                        // provideSearch={true}
                        onNewEntryClick={this.onNewEntryClick}
                    // toolTipNewEntry='New Product Entry'
                    />
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>


            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HOCVerifyIsUser(HoCtToastContainer(withRouter(ProductCategoryMaster))))