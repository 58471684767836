import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, InputLabel, Box } from '@mui/material'
import { withRouter } from './HOC/withRouter'
import CtTxt from './CustomTool/CtTxt'
import CtBtn from './CustomTool/CtBtn'
import { mapStatetoProps, mapSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import { fetchMember } from './API/API'
import MemberTable from './MemberTable'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import Progress from './CustomTool/Progress'

class MemberID extends Component {

    state = {
        formData: {
            txtMemberID: this.props.memberInfo.CardNo,
            txtKidName: ''
        },
        disableAddBtn: true,
        ARD: null,
    }

    //#region Component
    componentDidMount() {
        this.props.setActiveMenu("MemberID")
        //this.onFormLoad()
        document.title = 'TED : Member ID Entry'
        document.getElementById('txtMemberID').focus()
        this.onNewEntryClick()
    }
    //#endregion Component

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    handleOnChange = (e) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value }
        }, () => {
            let disableAddBtn = this.state.disableAddBtn
            if (this.state.formData.txtMemberID.length === 0 || this.state.formData.txtKidName.length === 0) {
                disableAddBtn = true
            } else {
                disableAddBtn = false
            }
            if (disableAddBtn !== this.state.disableAddBtn) {
                this.setState({ disableAddBtn })
            }
        })
    }

    validateMemberID = () => {
        let validateMemberID = false
        if (this.state.formData.txtMemberID === undefined || Number(this.state.formData.txtMemberID) < 0) {
            this.props.toastErrorMsg('Enter Member ID', 'txtMemberID')
        }else if (this.state.formData.txtKidName === undefined || this.state.formData.txtKidName === "") {
            this.props.toastErrorMsg('Enter Member Name', 'txtKidName')
        } else {
            validateMemberID = true
        }
        return validateMemberID
    }

    onNewEntryClick = () => {
        this.setState({
            dataLoadStatus: false,
            formData: {
                ...this.state.formData,
                txtMemberID: '',
                txtKidName: ''
            }
        })
    }

    addMemberToList = () => {
        if (this.validateMemberID()) {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'GetMemberID',
                    vac_cardno: this.state.formData.txtMemberID
                }
                fetchMember(reqData)
                    .then(res => {
                        let MemberID = '',
                            CardNo = this.state.formData.txtMemberID
                        if (Number(res.data.bint_memberid) === 0) {
                            this.props.updateProcessing('error', 'Not Valid Member ID.')
                        } else {
                            this.props.updateProcessing('success', 'Member ID added into Group.')
                            MemberID = res.data.bint_memberid
                        }
                        this.setState({
                            dataLoadStatus: true
                        }, () => {
                            if (Number(res.data.bint_memberid) > 0) {
                                this.props.setMemberIDInfo(MemberID, CardNo)
                                this.onNewEntryClick()
                            }
                        })
                    })
                    .catch(error => {
                        this.props.toastMsg('Unkonwn Error occured in saveMemberID.' + error)
                    })
            })
        }
    }

    ValidateMemberInfo = () => {
        let result = false
        if (Number(this.state.formData.txtMemberID) <= 0 && this.state.formData.txtKidName <= 0) {
            this.props.toastErrorMsg('Enter Member ID OR Kid Name and Try Again', 'txtMemberID')
        } else if (Number(this.state.formData.txtMemberID) > 0 && Number(this.state.formData.txtMemberID).toString().length < 6) {
            this.props.toastErrorMsg('Enter Valid Member ID', 'txtMemberID')
        } else {
            result = true
        }
        return result
    }

    onSearchBtnClick = () => {
        if (this.ValidateMemberInfo()) {
            this.props.notifyProcessing()
            let reqData = {
                Op: 'GetKidsInfo',
                vac_cardno: this.state.formData.txtMemberID,
                vac_mobileno: '',
                vac_kidsname: this.state.formData.txtKidName,
            }
            fetchMember(reqData)
                .then(res => {
                    this.props.updateProcessing(res.data.msgType, res.data.message)
                    // console.log('res.data.KidsInfo', res.data.KidsInfo)
                    if (res.data.KidsInfo.length > 1) {
                        let ARD = null
                        this.setState({ ARD }, () => {
                            ARD = <AlertResponsiveDialog
                                labelDisagree='Close'
                                alertMessage={
                                    <MemberTable rows={res.data.KidsInfo}
                                        openerForMemberTable='GroupMaster'
                                        // navigateToMemberDashboard={this.navigateToMemberDashboard}
                                        setReduxMemberInfo_Navigate={this.setSearchValueToTextBox}
                                    />
                                }
                                defaultOpenDialog={true}
                                onYesClickCloseIfExeSuccessfully={true}
                            />
                            this.setState({ ARD })
                        })
                    } else {
                        if (res.data.KidsInfo) {
                            this.setSearchValueToTextBox(res.data.KidsInfo[0].vac_cardno, res.data.KidsInfo[0].vac_kidname)
                        }
                    }
                })
        }

    }

    setSearchValueToTextBox = (txtMemberID, txtKidName) => {
        this.setState({
            formData: {
                ...this.state.formData,
                txtMemberID,
                txtKidName
            },
            disableAddBtn: false,
            // ARD:null,
        }, () => {
            // alert('setSearchValueToTextBox')
            this.setState({ ARD: null })
        })
    }
    //#endregion

    render() {
        return (
            <div>
                {/*  <Progress color='secondary' display={this.state.dataLoadStatus !== true} /> */}
                <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2} marginTop={0} marginLeft={0}>
                    <Grid item>
                        <CtTxt
                            id='txtMemberID'
                            label='Member ID'
                            value={this.state.formData.txtMemberID}
                            handleOnChange={this.handleOnChange}
                            width='90'
                            maxLength={10}
                        />
                    </Grid>
                    <Grid item>
                        <InputLabel>OR</InputLabel>
                    </Grid>
                    <Grid item>
                        <CtTxt
                            id='txtKidName'
                            label='Kid Name'
                            value={this.state.formData.txtKidName}
                            handleOnChange={this.handleOnChange}
                            width={200}
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Search' disabled={!this.state.disableAddBtn} onClick={() => { this.onSearchBtnClick() }} />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Add' disabled={this.state.disableAddBtn} onClick={() => { this.addMemberToList() }} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}

export default connect(mapStatetoProps, mapSetActiveMenuToProps)(HoCtToastContainer(withRouter(MemberID)))
