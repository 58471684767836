import React, { Component } from 'react'
import HoCtToastContainer from './HOC/HoCtToastContainer'
import { Grid, Box, Avatar, Button, Radio, FormControlLabel } from '@mui/material'
import MemberDashboardIcon from './images/memberdashboard.png'
import { withRouter } from './HOC/withRouter'
import TableList from './CustomTool/TableList'
import CtDtp from './CustomTool/CtDtp'
import CtRadioButtonsGroup from './CustomTool/CtRadioButtonsGroup'
import CtBtn from './CustomTool/CtBtn'
import CtCmb from './CustomTool/CtCmb'
import { Edit, Delete } from '@mui/icons-material';
import { mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps } from '../mapRedux/mapReduxProps'
import { connect } from 'react-redux'
import Progress from './CustomTool/Progress'
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { get_30DaysBack_FromCurrentDate, get_YMD_from_SYS, get_YMD_from_DMY } from './SystemUtility/SystemUtility'
import { fetchSales } from './API/API'
import HOCMemberInfo from './HOC/HOCMemberInfo'

class SalesList extends Component {
    state = {
        status: [],
        columns: [
            { id: 'Action', label: 'Search', name: 'Search' },
            { id: 'bint_invoiceno', label: 'InvoiceNo' },
            { id: 'dat_salesdate', label: 'Date' },
            { id: 'vac_kidname', label: 'Kid Name' },
            { id: 'vac_mobileno', label: 'Mobile No' },
            { id: 'dec_netamount', label: 'Grand Total' },
        ],
        rows: [],
        formData: {
            dtpFromDate: '',
            dtpToDate: '',
            rdbSalesListType: 'Member Only',
        },
        actionList: [
            { name: 'Edit', icon: <Edit />, link: 'SalesEntry:', actionType: 'AlertResponsive' },
            { name: 'Delete', icon: <Delete />, link: '', actionType: 'AlertResponsive', hide: Number(localStorage.getItem('De')) === 0 },
        ],
        keyColumn: [
            'bint_invoiceno', 'dat_salesdate'
        ],
        searchData: {
            bint_invoiceno: '',
            dat_salesdate: '',
            vac_kidname: '',
            vac_mobileno: '',
            dec_netamount: ''
        },
        searchColumns: [],
        searchRows: [],
        searchOpen: false,
        searchRowsCount: 0,
        dataLoadStatus: true,
        ARD: null,
        rowsCount: 0,
        rowsPerPage: 10,
        checkItemsSalesListType: [
            { text: 'Member Only' },
            { text: 'All Sales' },
        ],
    }

    //#region Component
    componentDidMount() {
        document.title = 'TED : Sales List'
        this.props.setActiveMenu("SalesList")
        // document.getElementById('txtProductCategoryName').focus()
        this.setSearchColumns()
        /* SM=SalesMode; MS=MemberSales; GS=GuestSales */
        if (localStorage.getItem('SM') && localStorage.getItem('SM') === "MS") {
            this.props.verifyMemberInfo(this.props.memberInfo, this.props.setMemberInfo, this.onFormLoad)
        } else {
            let checkItemsSalesListType = [
                { text: 'Guest Sales Only' },
            ]
            this.setState({
                checkItemsSalesListType, formData: {
                    ...this.state.formData, rdbSalesListType: 'Guest Sales Only',
                }
            }, () => {
                this.onFormLoad()
            })

        }
    }
    //#endregion

    //#region handle
    handleOnSearchInputChange = (e) => {
        this.setState({
            searchData: { ...this.state.searchData, [e.target.name]: e.target.value }
        }, () => {
            this.handleOnSearchChange(this.state.searchOpen, this.state.searchData)
        })
    }

    handleOnSearchChange = (searchOpen) => {
        let searchRows = []
        // console.log("this.state.rowsCount",this.state.rowsCount);
        if (searchOpen === true && this.state.rowsCount > 0) {
            this.state.rows.map((row) => {
                // console.log("searchOpen", searchOpen);
                if (searchOpen === true && (
                    (this.state.searchData.bint_invoiceno.length === 0 || row['bint_invoiceno'].toString().includes(this.state.searchData.bint_invoiceno)) &&
                    (this.state.searchData.vac_kidname.length === 0 || row['vac_kidname'].toString().toLowerCase().includes(this.state.searchData.vac_kidname.toLowerCase())) &&
                    (this.state.searchData.vac_mobileno.length === 0 || row['vac_mobileno'].toString().includes(this.state.searchData.vac_mobileno)))
                ) {
                    searchRows.push(row)
                }
            })
            const searchRowsCount = searchRows.length
            this.setState({
                searchOpen,
                searchRows,
                searchRowsCount,
                counter: this.state.counter + 1,
            }, () => {
            })
        } else {
            this.setState({ searchOpen })
        }
    }

    handleChangeRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage })
    }

    handleOnChangeTrim = (e) => {
        const ctrlName = e.target.name
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value.trim() }
        }, () => {
            if (ctrlName === 'rdbSalesListType') {
                // alert(this.state.formData.rdbSalesListType)
                this.listSales()
            }
            // console.log(this.state.formData.rdbSalesListType)
            // console.log(this.state.checkItemsSalesListType)
        })
    }

    handleARDonActionClick = (values) => {
        // console.log('values', values)
        const txtInvoiceNo = values[0].value,
            actionName = values[0].actionName,
            datSalesDate = get_YMD_from_DMY(values[1].value),
            EditInvoiceNo = txtInvoiceNo + ":" + datSalesDate
        if (actionName === "Edit") {
            if (localStorage.getItem('SM') && localStorage.getItem('SM') === "MS") {
                this.navigateTo('SalesEntry:' + EditInvoiceNo)
            } else {
                this.navigateTo('GuestSales:' + EditInvoiceNo)
            }
        } else if (actionName === "Delete") {
            this.setState({ ARD: null }, () => {
                let ARD = <AlertResponsiveDialog
                    labelAgree='Delete'
                    labelDisagree='Cancel'
                    alertTitle={'Do you want to Delete  : ' + txtInvoiceNo + ' ?'}
                    alertMessage=''
                    handleOnClickYes={() => {
                        return this.deleteSales(txtInvoiceNo, datSalesDate)
                    }}
                    defaultOpenDialog={true}
                    onYesClickCloseIfExeSuccessfully={true}
                />
                this.setState({ ARD })
            })
        }
    }
    //#endregion handle

    //#region Function
    navigateTo = (link) => {
        this.props.navigate('/' + link)
    }

    setSearchColumns = () => {
        let searchColumns = []
        searchColumns.push(
            {
                cType: 'CtTxt',
                id: 'bint_invoiceno',
                label: 'Invoice No',
                value: this.state.searchData.bint_invoiceno,
                handleOnChange: this.handleOnSearchInputChange,
                width: '150',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'Date',
                width: '100'
            },
            {
                cType: 'CtTxt',
                id: 'vac_kidname',
                label: 'Kid Name',
                value: this.state.searchData.vac_kidname,
                handleOnChange: this.handleOnSearchInputChange,
                width: '200',
                maxLength: '100'
            },
            {
                cType: 'CtTxt',
                id: 'vac_mobileno',
                label: 'MobileNo',
                value: this.state.searchData.vac_mobileno,
                handleOnChange: this.handleOnSearchInputChange,
                width: '120',
                maxLength: '100'
            },
            {
                cType: 'Lbl',
                label: 'Grand Total',
                width: '100'
            },)
        this.setState({ searchColumns })
    }

    onFormLoad = () => {
        let dtpFromDate = get_30DaysBack_FromCurrentDate(),
            dtpToDate = get_YMD_from_SYS(new Date())
        this.setState({
            formData: {
                ...this.state.formData,
                dtpFromDate, dtpToDate
            }
        }, () => {
            this.listSales()
        })
    }

    listSales = () => {
        this.setState({ dataLoadStatus: false }, () => {
            let FromDate = this.state.formData.dtpFromDate,
                ToDate = this.state.formData.dtpToDate
            let reqData = {}
            if (localStorage.getItem('SM' === 'MS')) {
                reqData = {
                    Op: 'SalesList',
                    FromDate,
                    ToDate,
                    SalesListType: this.state.formData.rdbSalesListType,
                    bint_memberid: this.props.memberInfo.MemberID
                }
            } else {
                reqData = {
                    Op: 'GuestSalesList',
                    FromDate,
                    ToDate,
                }
            }
            fetchSales(reqData)
                .then(res => {
                    const rows = res.data.SalesList
                    const rowsCount = rows.length
                    this.setState({ rows, rowsCount, dataLoadStatus: true })
                })
                .catch(error => {
                    this.props.toastMsg('error', 'Unkonwn Error occured in listSales. ' + error)
                })
        })
    }

    deleteSales = (InvoiceNo, SalesDate) => {
        if (InvoiceNo && InvoiceNo != "") {
            this.props.notifyProcessing()
            this.setState({ dataLoadStatus: false }, () => {
                let reqData = {
                    Op: 'DeleteSales',
                    bint_invoiceno: InvoiceNo,
                    dat_salesdate: SalesDate
                }
                fetchSales(reqData)
                    .then(res => {
                        this.props.updateProcessing(res.data.msgType, res.data.message)
                        if (res.data.msgType === 'success') {
                            this.setState({
                                dataLoadStatus: true,
                            }, () => {
                                this.listSales()
                            })
                        }
                    })
                    .catch(error => {
                        this.props.toastMsg('error', 'Unkonwn Error occured in deleteSales. ' + error)
                    })
            })
        }
        return true
    }

    openEntryForm = () => {
        if (localStorage.getItem('SM') && localStorage.getItem('SM') === "MS") {
            this.navigateTo('SalesEntry')
        } else {
            this.navigateTo('GuestSales')
        }
    }
    //#endregion

    render() {
        let rows = (this.state.searchOpen) ? (this.state.searchRows) : (this.state.rows)
        let rowsCount = (this.state.searchOpen) ? (this.state.searchRowsCount) : (this.state.rowsCount)
        return (
            <div>
                <Progress color='secondary' display={this.state.dataLoadStatus !== true} />
                <Grid container direction="row" justifyContent="center" alignItems="center" className="title" >
                    <label htmlFor="Title" style={{ fontSize: '25px', color: '#6f7689' }}><b>Sales List</b></label>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} marginTop={0}>
                    <Grid item>
                        <Button
                            variant="outlined"
                            style={{ backgroundColor: '#d6ddf0', borderRadius: '10px' }}
                            startIcon={<Avatar src={MemberDashboardIcon} sx={{ width: 56, height: 56 }} />}
                            onClick={() => { this.navigateTo('MemberDashboard') }}>Member Dashboard
                        </Button>
                    </Grid>
                    <Grid item>
                        <CtDtp
                            id='dtpFromDate'
                            label='From Date'
                            width='150'
                            handleOnChange={this.handleOnChangeTrim}
                            defaultValue={this.state.formData.dtpFromDate}
                            value={this.state.formData.dtpFromDate}
                            onKeyDown={this.props.onKeyDown}
                        // disabled={this.state.disabledProfileData.DOBDS}
                        />
                    </Grid>
                    <Grid item>
                        <CtDtp
                            id='dtpToDate'
                            label='To Date'
                            width='150'
                            handleOnChange={this.handleOnChangeTrim}
                            defaultValue={this.state.formData.dtpToDate}
                            value={this.state.formData.dtpToDate}
                            onKeyDown={this.props.onKeyDown}
                        // disabled={this.state.disabledProfileData.DOBDS}
                        />
                    </Grid>
                    <Grid item>
                        <CtBtn label='Load' width={this.state.cWidth - 150} onClick={this.listSales} />
                    </Grid>
                    <Grid item>
                        <CtRadioButtonsGroup
                            id='rdbSalesListType'
                            items={this.state.checkItemsSalesListType}
                            value={this.state.formData.rdbSalesListType}
                            handleOnChange={this.handleOnChangeTrim}
                            label='Sales List Type'
                            required
                        />
                    </Grid>
                </Grid>
                <br />
                <TableList
                    columns={this.state.columns}
                    counter={this.state.counter}
                    rows={rows}
                    rowsCount={rowsCount}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onActionClick={this.handleARDonActionClick}
                    actionList={this.state.actionList}
                    keyColumn={this.state.keyColumn}
                    handleOnSearchChange={this.handleOnSearchChange}
                    searchColumns={this.state.searchColumns}
                    searchData={this.state.searchData}
                    provideSearch={true}
                    onNewEntryClick={this.openEntryForm}
                    toolTipNewEntry='New Sales Entry'
                />
                <Grid item>
                    <Box display={{ xs: 'none' }} style={{ textAlign: 'right' }}>
                        {this.state.ARD}
                    </Box>
                </Grid>
            </div>
        )
    }
}
export default connect(mapStatetoProps, mapSetMemberInfoSetActiveMenuToProps)(HOCMemberInfo(HoCtToastContainer(withRouter(SalesList))))